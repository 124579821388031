import { useParams } from 'react-router-dom'

import styles from './DocumentBackOffice.module.scss'
import DocumentMorePopover from '../DocumentMorePopover/DocumentMorePopover'
import FavoriteButton from '../FavoriteButton/FavoriteButton'

const DocumentBackOfficeHeader = (): JSX.Element => {
  const { documentId = '' } = useParams()

  return (
    <header className={styles.root}>
      <DocumentMorePopover className={styles.moreBtn} documentId={documentId} />
      <FavoriteButton className={styles.favBtn} />
    </header>
  )
}

export default DocumentBackOfficeHeader
