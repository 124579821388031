import clsx from 'clsx'
import { AlertCircle } from 'react-feather'

import styles from './Tab.module.scss'
import { TabProps } from './Tabs.type'
import Badge from '../Badge/Badge'
import IconContainer from '../IconContainer/IconContainer'
import Typography from '../Typography/Typography'

const Tab: React.FC<TabProps> = ({
  children,
  badgeContent,
  variant = 'primary',
  current = false,
  size = 'medium',
  error = false,
  fullWidth = false,
  onClick,
  endAdornment,
}) => {
  const classes = clsx(
    styles.tab,
    styles[`sz-${size}`],
    styles[`va-${variant}`],
    {
      [styles.isCurrent]: current,
      [styles.isFullWidth]: fullWidth,
    }
  )

  return (
    <div className={classes} onClick={onClick}>
      <Typography element="span" size={size} className={styles.text}>
        {children}
      </Typography>
      {error && (
        <IconContainer
          className={styles.icon}
          icon={<AlertCircle />}
          color="error"
          size="small"
        />
      )}
      {!error && !!badgeContent && (
        <Badge
          className={styles.icon}
          size="small"
          color={current ? 'primary' : 'general'}
        >
          {badgeContent}
        </Badge>
      )}
      {endAdornment}
    </div>
  )
}

export default Tab
