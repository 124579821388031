import { PracticeQueryParams } from 'constants/application-constants'

import { useMemo, useState } from 'react'

import { FileCheckIcon } from 'assets/Icons'
import clsx from 'clsx'
import {
  Badge,
  DropdownItem,
  IconContainer,
  Popover,
  Typography,
} from 'components/common'
import { t } from 'i18next'
import { Check, ChevronDown, Edit2, Eye, IconProps } from 'react-feather'
import { useParams, useSearchParams } from 'react-router-dom'
import { Practice } from 'redux-store/models'
import { CurrentRevisions, SelectOption } from 'types'

import styles from './RevisionSelect.module.scss'
import { practicesApi } from '../../api'

export const RevisionSelect = () => {
  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentRevisionStatus = searchParams.get(
    PracticeQueryParams.REVISION_STATUS
  )
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const httpGetPracticeState =
    practicesApi.endpoints.getPracticeById.useQueryState(id ?? '')

  const options = useMemo(() => {
    return createSelectOptions(httpGetPracticeState.data?.data)
  }, [httpGetPracticeState.data])

  const handleOpen = (evt: React.MouseEvent) =>
    setAnchorEl(evt.currentTarget as HTMLDivElement)

  const handleClose = () => setAnchorEl(null)

  const handleRevisionSelect = (status: string | null) => () => {
    if (status) {
      searchParams.set(PracticeQueryParams.REVISION_STATUS, status)
      setSearchParams(searchParams, { replace: true })
    }
    handleClose()
  }

  const getCheckedIcon = (status: string) => {
    if (currentRevisionStatus === status) {
      return <Check />
    }
  }

  const getRevisionToggleLabel = () => {
    if (currentRevisionStatus === 'draft') {
      return t('practice.toggleDraft')
    } else if (currentRevisionStatus === 'approval') {
      return t('practice.toggleApproval')
    } else {
      return t('practice.togglePublished')
    }
  }

  return (
    <>
      <Badge
        rounded
        color="primary"
        size="small"
        onClick={handleOpen}
        className={clsx(styles.handle, { [styles.handleOpen]: anchorEl })}
      >
        {getRevisionToggleLabel()}

        <IconContainer icon={<ChevronDown />} size="x-small" />
      </Badge>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <div className={styles.options}>
          {options.map((option) => (
            <DropdownItem
              key={option.id}
              onClick={handleRevisionSelect(option.id)}
              trailingIcon={getCheckedIcon(option.id)}
              icon={option.startAdornment as IconProps}
              iconColor={option.disabled ? 'default' : 'primary'}
              disabled={option.disabled}
              className={styles.option}
            >
              <Typography
                size="small"
                color={option.disabled ? 'label' : 'primary'}
              >
                {option.label}
              </Typography>
              <Typography size="small" color="label">
                {option.description}
              </Typography>
            </DropdownItem>
          ))}
        </div>
      </Popover>
    </>
  )
}

export default RevisionSelect

const createSelectOptions = (
  practice: (Practice & CurrentRevisions) | undefined
): SelectOption[] => {
  return [
    {
      id: 'draft',
      label: t('practice.toggleDraft'),
      disabled: !practice?.current_draftish_revision,
      value: {
        id: practice?.current_draftish_revision?.id,
        status: 'draft',
      },
      description: t('practice.toggleDraftDescription'),
      startAdornment: <Edit2 />,
    },
    {
      id: 'approval',
      label: t('practice.toggleApproval'),
      value: {
        id: practice?.current_approvalish_revision?.id,
        status: 'approval',
      },
      disabled: !practice?.current_approvalish_revision,
      description: practice?.current_approvalish_revision
        ? t('practice.toggleApprovalDescription')
        : t('practice.toggleApprovalDescriptionDisabled'),
      startAdornment: <FileCheckIcon />,
    },
    {
      id: 'published',
      label: t('practice.togglePublished'),
      value: {
        id: practice?.latest_published_revision?.id,
        status: 'published',
      },
      disabled: !practice?.latest_published_revision,
      description: practice?.latest_published_revision
        ? t('practice.togglePublishedDescription')
        : t('practice.togglePublishedDescriptionDisabled'),
      startAdornment: <Eye />,
    },
  ]
}
