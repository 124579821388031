import React from 'react'

const SortArrows = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#5D5F7A"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M14 9L18 3L22 9" />
    <line x1="18.0502" y1="4.01685" x2="18.0502" y2="20.2499" />
    <path d="M2 15L6 21L10 15" />
    <line
      x1="0.75"
      y1="-0.75"
      x2="16.9831"
      y2="-0.75"
      transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 5.3302 20.7332)"
    />
  </svg>
)

export default SortArrows
