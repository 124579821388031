import { AccessRights } from 'constants/application-constants'

import { extractIds, formatDateForInput } from 'helpers'
import { Metadata } from 'types'

import { FormMetadataValues } from '../types'

const mapMetadataToForm = (
  metadata: Metadata[],
  currentMetadata: FormMetadataValues[]
): Partial<FormMetadataValues>[] => {
  let updatedLanguages: string[] = []

  // Create the data for the languages present in the form
  const updatedData = metadata.map((item) => {
    updatedLanguages.push(item.language.code)

    return {
      altTopic: extractIds(item.alternative_topics),
      mainTopic: extractOption(item.main_topic),
      certificationScope: extractIds(item.certification_scopes),
      code: item.code,
      collections: extractIds(item.collections),
      countries: item.countries?.map((country) => country.code),
      accessRights: AccessRights.find(
        (ar) => ar.id === String(item.access_rights)
      ),
      description: item.description ?? undefined,
      targetGroup: item.target_group ?? undefined,
      departments: extractIds(item.departments),
      effectiveAt: formatDateForInput(item.effective_at),
      externalReferenceLinks: item.external_references,
      longTitle: item.subject ?? undefined,
      title: item.title,
      keywords: item.keywords?.split(','),
      language: item.language?.code,
      locations: extractIds(item.locations),
      legalEntities: extractIds(item.legal_entities),
      contact: {
        id: item.contact_user?.id ?? '',
        label: item.contact_user?.name ?? '',
      },
    }
  })

  //   Preserves the languages not present in the applied metadata
  const nonUpdatedData = currentMetadata.filter(
    ({ language }) => updatedLanguages.indexOf(language) === -1
  )

  return [...updatedData, ...nonUpdatedData]
}

export default mapMetadataToForm

type Option = { id: string; name: string; [x: string]: any }

const extractOption = (data: Option | null) =>
  data
    ? {
        id: data.id,
        label: data.name,
      }
    : undefined
