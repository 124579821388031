import Logger from './logger'

export const buildQueryString = (queryMap: null | Record<string, unknown>) => {
  if (!queryMap) {
    return ''
  }

  //   Create an array with valid options
  const validQueryArray = Object.entries(queryMap).reduce(
    (acc, [paramName, paramValue]) => {
      // Reject undefined, null & empty string (but not 'false')
      if (typeof paramValue !== 'boolean' && !paramValue) {
        return acc
      }

      // Reject empty array
      if (Array.isArray(paramValue) && paramValue.length <= 0) {
        return acc
      }

      if (typeof paramValue === 'object' && !Array.isArray(paramValue)) {
        Logger.warn(
          `QUERY BUILDER: parameter value is type "object" for ${paramName}`
        )
      }

      // Value type coerced to string (for arrays,  {a: [1,2,3]} => a=1,2,3)
      acc.push(`${paramName}=${paramValue}`)

      return acc
    },
    [] as string[]
  )

  if (validQueryArray.length <= 0) {
    return ''
  }

  return '?' + validQueryArray.join('&')
}
