import { useEffect } from 'react'

import { selectAppLoading } from 'components/features/Other/api'
import { userApi } from 'components/features/User/api'
import { useAppSelector } from 'hooks'
import { useNavigate } from 'react-router-dom'

export const useUserOnboarding = () => {
  const navigate = useNavigate()
  const isAppLoading = useAppSelector(selectAppLoading)
  const httpGetUser = userApi.endpoints.getCurrentUser.useQueryState(null)

  const onboardingCompleted = httpGetUser.data?.data.onboarding_completed

  useEffect(() => {
    // Only redirects if `isUserOnboardCompleted` has a boolean value but not when it is undefined
    if (!isAppLoading && onboardingCompleted === false) {
      navigate('/user/onboard', {
        state: {
          backgroundLocation: '/',
        },
      })
    }
  }, [isAppLoading, navigate, onboardingCompleted])
}
