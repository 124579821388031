import React, { Suspense } from 'react'

import App from 'components/app/App'
import { LoadingPage } from 'components/common'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import store from 'redux-store'

import 'config/i18n'

import 'styles/main.scss'
import 'video.js/dist/video-js.css'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Suspense fallback={<LoadingPage />}>
          <App />
        </Suspense>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
