import React, { Fragment, useEffect } from 'react'

import clsx from 'clsx'
import FocusTrap from 'focus-trap-react'
import { useKeyPress } from 'hooks'
import ReactDOM from 'react-dom'

import styles from './Modal.module.scss'

interface ModalProps {
  children: React.ReactNode
  open?: boolean
  className?: string
  rootClassName?: string
  handleClose?: () => void
  handleOpen?: () => void
  portalRoot?: Element | HTMLElement
  noFocusTrap?: boolean
}

const Modal = ({
  children,
  className,
  open,
  handleClose,
  handleOpen,
  portalRoot,
  noFocusTrap,
  rootClassName,
  ...props
}: ModalProps) => {
  const classes = clsx(styles.modal, className)

  useEffect(() => {
    // stops background scrolling when modal is open
    const { scrollY } = window
    const bodyTag = document.getElementsByTagName('body')[0]
    if (open) {
      bodyTag.style.bottom = `${scrollY}px`
      bodyTag.classList.add('modal-is-open')
    } else {
      bodyTag.classList.remove('modal-is-open')
      window.scrollTo(0, scrollY)
      bodyTag.style.bottom = ''
    }
    return () => {
      bodyTag.classList.remove('modal-is-open')
      window.scrollTo(0, scrollY)
      bodyTag.style.bottom = ''
    }
  }, [open])

  const escKeyPressed = useKeyPress('Escape')

  useEffect(() => {
    if (escKeyPressed && open && handleClose) {
      handleClose()
    }
  }, [escKeyPressed, handleClose, open])

  useEffect(() => {
    if (open && handleOpen) {
      handleOpen()
    }
  }, [handleOpen, open])

  const handleClick = () => {
    if (handleClose) {
      handleClose()
    }
  }

  const modalRootAnchor =
    portalRoot || (document.getElementsByTagName('body')[0] as HTMLElement)

  if (!open) return null

  const FocusWrapper = noFocusTrap ? Fragment : FocusTrap

  return ReactDOM.createPortal(
    <FocusWrapper>
      <div
        className={clsx(styles.background, rootClassName)}
        onClick={handleClick}
        {...props}
      >
        <div className={classes} onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </FocusWrapper>,
    modalRootAnchor
  )
}

export default Modal
