const FileTimesIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 3.83399C3 2.45327 4.11929 1.33398 5.5 1.33398H7.18421C9.11721 1.33398 10.6842 2.90099 10.6842 4.83399V6.00065C10.6842 7.10522 11.5796 8.00065 12.6842 8.00065H13.5C15.433 8.00065 17 9.56765 17 11.5007V16.1673C17 17.548 15.8807 18.6673 14.5 18.6673H5.5C4.11929 18.6673 3 17.548 3 16.1673V3.83399Z" />
      <path d="M3 3.83399C3 2.45327 4.11929 1.33398 5.5 1.33398H6.99708C9.50439 1.33398 11.8838 2.44096 13.4988 4.35883L15.0017 6.14354C16.2923 7.67605 17 9.61518 17 11.6187V16.1673C17 17.548 15.8807 18.6673 14.5 18.6673H5.5C4.11929 18.6673 3 17.548 3 16.1673V3.83399Z" />
      <path
        d="M6.66602 10L11.9886 15.3226"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66602 15.3223L11.9886 9.99969"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FileTimesIcon
