import {
  Permissions,
  PracticeQueryParams,
} from 'constants/application-constants'

import { useMemo } from 'react'

import EditIcon from 'assets/Icons/EditIcon'
import {
  Avatar,
  Button,
  Card,
  LoadingIndicator,
  Modal,
  Separator,
  Typography,
} from 'components/common'
import { createReadableMetadata, practiceModalCloseLocation } from 'helpers'
import { useAppSelector, usePermissions, useFrontOfficeCheck } from 'hooks'
import { t } from 'i18next'
import { Mail, X } from 'react-feather'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import styles from './MetadataDetailsModal.module.scss'
import MetadataProperty from './MetadataProperty'
import { practicesApi, selectCurrentRevision } from '../../api'

const MetadataDetailsModal = () => {
  const currentRevision = useAppSelector(selectCurrentRevision)
  const { id: practiceId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const isFrontOffice = useFrontOfficeCheck()
  const [searchParams] = useSearchParams()
  const revisionStatus = searchParams.get(PracticeQueryParams.REVISION_STATUS)

  const backgroundLink = practiceModalCloseLocation(
    isFrontOffice,
    location,
    practiceId ?? ''
  )

  const { mixed: allPermissions } = usePermissions(practiceId)

  const httpGetRevision = practicesApi.useGetRevisionByIdQuery(
    currentRevision?.id ?? '',
    {
      skip: !currentRevision?.id,
    }
  )

  const httpGetExistingPractices =
    practicesApi.useGetExistingPracticesQuery(null)

  const canEditDetails =
    !isFrontOffice &&
    !!revisionStatus &&
    revisionStatus !== 'published' &&
    allPermissions !== null &&
    allPermissions[Permissions.UPDATE_PRACTICE]

  const propertyData = useMemo(() => {
    if (!httpGetRevision.isSuccess || !httpGetExistingPractices.isSuccess) {
      return null
    }

    const metadata = httpGetRevision.data.data.metadata.find(
      ({ language }) =>
        searchParams.get(PracticeQueryParams.METADATA_LANGUAGE) ===
        language.code
    )
    if (metadata) {
      return createReadableMetadata(
        metadata,
        httpGetRevision.data.data,
        httpGetExistingPractices.data.data
      )
    }

    return null
  }, [
    httpGetRevision.data,
    httpGetRevision.isSuccess,
    searchParams,
    httpGetExistingPractices.data,
    httpGetExistingPractices.isSuccess,
  ])

  const author = (() => {
    if (
      httpGetRevision.isSuccess &&
      httpGetRevision.data.data.contact_users.length > 0
    )
      return httpGetRevision.data.data.contact_users[0]
  })()

  const handleCloseModal = () => {
    if (practiceId) {
      navigate(backgroundLink, { replace: true })
    } else {
      navigate(-1)
    }
  }

  const handleEditClick = () => {
    const backgroundLink = location.pathname.split('/details')[0]
    navigate({
      pathname: `${backgroundLink}/edit`,
      hash: location.hash,
      search: location.search,
    })
  }

  return (
    <Modal open handleClose={handleCloseModal} className={styles.modal}>
      <Card className={styles.card}>
        <Card.Header className={styles.cardHeader}>
          <div className={styles.cardTitle}>
            <Typography
              type="display"
              size="x-small"
              fontWeight="bold"
              element="h1"
            >
              {t('metadata.details')}
            </Typography>
            {canEditDetails && (
              <Button
                variant="text"
                onClick={handleEditClick}
                size="x-small"
                icon={<EditIcon />}
                iconLabel={t('metadata.editAria') ?? ''}
              />
            )}
          </div>

          <Button
            size="x-small"
            icon={<X />}
            variant="text"
            iconLabel={t('metadata.closeAria') ?? ''}
            onClick={handleCloseModal}
          />
        </Card.Header>
        <Card.Header className={styles.cardHeaderMobile}>
          <Typography
            type="text"
            fontWeight="bold"
            className={styles.title}
            element="h1"
          >
            {t('metadata.details')}
          </Typography>
          <Button
            className={styles.btnClose}
            size="x-small"
            variant="text"
            iconLabel={t('metadata.closeAria') ?? ''}
            onClick={handleCloseModal}
          >
            {t('common.close')}
          </Button>
        </Card.Header>
        <Card.Body className={styles.cardBody}>
          {(httpGetRevision.isUninitialized || httpGetRevision.isFetching) && (
            <div>
              <LoadingIndicator />
            </div>
          )}
          {httpGetRevision.isSuccess && !!propertyData && (
            <>
              <div className={styles.section}>
                <Typography fontWeight="medium" element="h2" size="large">
                  {t('metadata.general')}
                </Typography>
                <div>
                  {propertyData.general?.map(({ title, value }) => (
                    <MetadataProperty
                      key={title}
                      title={t(title)}
                      value={value}
                    />
                  ))}
                </div>
              </div>

              <div className={styles.section}>
                <Typography fontWeight="medium" element="h2" size="large">
                  {t('metadata.gentopic')}
                </Typography>
                <div className={styles.topic}>
                  {propertyData.topic.map(({ title, value }) => (
                    <MetadataProperty
                      key={title}
                      title={t(title)}
                      value={value}
                    />
                  ))}
                </div>
              </div>

              <div className={styles.section}>
                <Typography fontWeight="medium" element="h2" size="large">
                  {t('metadata.scope')}
                </Typography>

                <div>
                  {propertyData.scope?.map(({ title, value }) => (
                    <MetadataProperty
                      key={title}
                      title={t(title)}
                      value={value}
                    />
                  ))}
                </div>
              </div>

              <div className={styles.section}>
                <Typography fontWeight="medium" element="h2" size="large">
                  {t('metadata.references')}
                </Typography>

                <div className={styles.topic}>
                  {propertyData.references?.map(({ title, value }) => (
                    <MetadataProperty
                      key={title}
                      title={t(title)}
                      value={value}
                    />
                  ))}
                </div>
              </div>
            </>
          )}

          {!!author && (
            <div className={styles.contact}>
              <Separator />

              <div className={styles.contactInfo}>
                <Avatar
                  src={author?.avatar_public_path}
                  content={author?.initials ?? ''}
                />

                <div className={styles.authorDescription}>
                  <Typography fontWeight="medium">{author?.name}</Typography>
                  <Typography color="label">{t('practice.author')}</Typography>
                </div>
              </div>

              {!!author?.email && (
                <Button
                  to={`mailto:${author?.email}`}
                  as="link"
                  size="small"
                  variant="subtle"
                  icon={<Mail />}
                >
                  {t('practice.toContact')}
                </Button>
              )}
            </div>
          )}
        </Card.Body>
      </Card>
    </Modal>
  )
}

export default MetadataDetailsModal
