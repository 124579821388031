import { Card, QibriLogo, Typography } from 'components/common'
import { t } from 'i18next'

import styles from './SsoPage.module.scss'
import { SsoForm } from '../../components'

const SsoPage = () => {
  return (
    <div className={styles.root}>
      <Card className={styles.card}>
        <Card.Body className={styles.cardBody}>
          <div className={styles.header}>
            <QibriLogo className={styles.logo} />
            <Typography
              type="display"
              fontWeight="bold"
              className="is-not-mobile"
            >
              {t('sso.title')}
            </Typography>
          </div>
          <SsoForm />
          <div className={styles.footer}>
            <a className={styles.fakeLink} href={'/login'}>
              {t('sso.switch')}
            </a>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default SsoPage
