import { forwardRef } from 'react'

import clsx from 'clsx'

import styles from './BasicInput.module.scss'

interface BasicInputProps extends React.HTMLProps<HTMLInputElement> {}

const BasicInput = forwardRef<HTMLInputElement, BasicInputProps>(
  ({ className, ...props }, ref): JSX.Element => {
    const classes = clsx(styles.root, className)
    return <input ref={ref} className={classes} {...props} />
  }
)

export default BasicInput
