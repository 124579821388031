import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { ReduxRootState } from 'types'

/**
 * @function useAppSelector - A wrapper around  `useSelector` hook to make it TypeScript friendly
 *
 * [More info](https://redux.js.org/usage/usage-with-typescript#define-typed-hooks)
 */

const useAppSelector: TypedUseSelectorHook<ReduxRootState> = useSelector

export default useAppSelector
