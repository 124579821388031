import { InputWrapper } from 'components/common'
import TextField, {
  TextFieldProps,
} from 'components/common/TextField/TextField'
import { FieldHookConfig, useField } from 'formik'
import { t } from 'i18next'

import { preventSubmitOnEnter } from '../../../helpers'

type FTextFieldProps = TextFieldProps &
  FieldHookConfig<string | number> & {
    helperText?: string | null
    optional?: boolean
  }

const FTextField = ({
  id,
  label,
  type,
  helperText,
  optional,
  ...props
}: FTextFieldProps): JSX.Element => {
  const [field, meta] = useField(props)

  const { onBlur, value, onChange, name } = field
  const { error, touched } = meta

  const { name: _name, className, ref, ...rest } = props

  return (
    <InputWrapper
      helperText={touched && error ? t(error) : helperText}
      color={touched && error ? 'error' : 'basic'}
      className={className}
    >
      <TextField
        id={id}
        label={optional ? `${label} (${t('common.optional')})` : label}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onKeyPress={preventSubmitOnEnter}
        type={type}
        isError={touched && Boolean(error)}
        ref={ref as any}
        {...rest}
      />
    </InputWrapper>
  )
}

FTextField.displayName = 'FTextField'
export default FTextField
