import { OfficeName } from 'constants/application-constants'

import { Location } from 'react-router-dom'
import {
  ApprovalAssignee,
  ApprovalStep,
  ApprovalStepStatus,
  Metadata,
} from 'types'

import { Revision } from '../../redux-store/models'
import { formatDateNumeric } from '../date-helpers'

export function extractApprovalStepStatus(
  assignees: ApprovalAssignee[]
): ApprovalStepStatus {
  const allStatuses = assignees.map((assignee) => assignee.status)
  const totalAssignees = assignees.length

  if (allStatuses.includes('rejected')) {
    return 'rejected'
  }

  if (allStatuses.includes('next')) {
    return 'next'
  }

  const allAreNext = allStatuses.every((status) => status === 'approved')
  if (allAreNext && totalAssignees !== 0) {
    return 'approved'
  }

  return 'open'
}

export function determineCurrentStep(steps: ApprovalStep[]) {
  let currentStep: {
    index: number
    step: ApprovalStep
    status: 'rejected' | 'next'
  } | null = null

  for (let i = 0; i < steps.length; i++) {
    let step = steps[i]
    if (step.assignees.length <= 0) {
      // If a step has no assignees, move onto next step
      continue
    }

    const stepStatus = extractApprovalStepStatus(step.assignees)

    // if a step has the status of rejected or next make it the current step
    if (stepStatus === 'rejected' || stepStatus === 'next') {
      currentStep = {
        step: step,
        index: i,
        status: stepStatus,
      }
      break
    }
  }

  return currentStep
}

export const addOrRemoveApprover = (
  approvers: ApprovalAssignee[],
  newApprover: ApprovalAssignee
) => {
  const approverInList = approvers.find(
    (approver) => approver.id === newApprover?.id
  )

  if (approverInList) {
    approvers = approvers.filter((approver) => approver.id !== newApprover?.id)
  } else {
    approvers = [...approvers, newApprover as ApprovalAssignee]
  }

  return approvers
}

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text)
}

export const practiceModalCloseLocation = (
  isFrontOffice: boolean,
  location: Location,
  practiceId: string
) => {
  return `/${
    isFrontOffice ? OfficeName.FRONT : OfficeName.BACK
  }/practices/${practiceId}${location.search}${location.hash}`.trim()
}

export const createReadableMetadata = (
  metadata: Metadata,
  revision: Revision | null,
  practices: { id: string; name: string }[],
  isDocument = false
) => {
  return {
    general: [
      { title: 'metadata.title', value: metadata.title },
      { title: 'metadata.subject', value: metadata.subject },
      { title: 'metadata.code', value: metadata.code },
      { title: 'metadata.description', value: metadata.description },
      { title: 'metadata.contact', value: metadata.contact_user?.name },
      {
        title: 'metadata.class',
        value: revision?.practice_class ? revision.practice_class.name : '-',
      },
      { title: 'metadata.originalLanguage', value: metadata.language.long },
      { title: 'metadata.keywords', value: metadata.keywords },
    ],
    topic: [
      { title: 'metadata.topic', value: metadata.main_topic?.name },
      {
        title: 'metadata.altTopic',
        value:
          metadata.alternative_topics && metadata.alternative_topics?.length > 0
            ? metadata.alternative_topics?.map((topic) => topic.name).join(', ')
            : '-',
      },
    ],
    scope: [
      { title: 'metadata.targetGroup', value: metadata.target_group },
      {
        title: 'metadata.organizationalReferences',
        value: metadata.departments?.map((item) => item.name).join(', '),
      },
      {
        title: 'metadata.legalEntities',
        value:
          metadata.legal_entities && metadata.legal_entities?.length > 0
            ? metadata.legal_entities?.map((scope) => scope.name).join(', ')
            : '-',
      },
      {
        title: 'metadata.collections',
        value:
          metadata.collections && metadata.collections?.length > 0
            ? metadata.collections
                ?.map((collection) => collection.name)
                .join(', ')
            : '-',
      },
      {
        title: 'metadata.scopeCertification',
        value:
          metadata.certification_scopes &&
          metadata.certification_scopes?.length > 0
            ? metadata.certification_scopes
                ?.map((entity) => entity.name)
                .join(', ')
            : '-',
      },
      {
        title: 'metadata.locations',
        value: metadata.locations?.map((location) => location.name).join(', '),
      },
      {
        title: 'metadata.country',
        value: metadata.countries?.map((country) => country.long).join(', '),
      },
      {
        title: 'metadata.validDate',
        value: metadata.effective_at
          ? formatDateNumeric(metadata.effective_at)
          : null,
      },
    ],
    references: [
      {
        title: 'metadata.internalReferences',
        value:
          metadata.internal_references &&
          metadata.internal_references?.length > 0
            ? metadata.internal_references
                .map((internal_reference) => {
                  const filtered = practices.filter(
                    (p) => p.id === internal_reference
                  )
                  if (filtered.length > 0) return filtered[0].name
                  return false
                })
                .join(', ')
            : '-',
      },
      {
        title: 'metadata.obsoleteGuidelines',
        value: metadata.obsolete_practices ? metadata.obsolete_practices : '-',
      },
      {
        title: 'metadata.externalReferences',
        value: metadata.external_references,
      },
      {
        title: 'metadata.publishedAt',
        value: isDocument
          ? metadata.published_at
            ? formatDateNumeric(metadata.published_at)
            : '-'
          : revision?.published_at
          ? formatDateNumeric(revision.published_at)
          : '-',
      },
      {
        title: 'practice.nextRevisionCycle',
        value: revision?.expires_at
          ? formatDateNumeric(revision.expires_at)
          : '-',
      },
      {
        title: 'metadata.version',
        value: metadata?.version_label ? metadata.version_label : '-',
      },
    ],
  }
}

export * from './mapGuidelineFormToResponse'
export * from './createSelectOptions'
