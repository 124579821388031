import { lazy, Suspense, useEffect } from 'react'

import { Button, LoadingIndicator } from 'components/common'
import { setPracticeId } from 'components/features/Approval'
import { useAppDispatch } from 'hooks'
import { t } from 'i18next'
import { Info, Link, MessageCircle } from 'react-feather'

import styles from './ApprovalSidebarPage.module.scss'
import { practicesApi } from '../../api'

const ApprovalStartPage = lazy(
  () =>
    import(
      'components/features/Approval/components/ApprovalStartPage/ApprovalStartPage'
    )
)
const OngoingApproval = lazy(
  () =>
    import(
      'components/features/Approval/components/OngoingApproval/OngoingApproval'
    )
)

interface Props {
  practiceId: string
  onActionClick: (page: string | null) => () => void
}

const ApprovalSidebarPage = ({
  onActionClick,
  practiceId,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const httpGetPractice = practicesApi.useGetPracticeByIdQuery(practiceId, {
    skip: !practiceId,
  })

  useEffect(() => {
    dispatch(setPracticeId(practiceId))

    return () => {
      setPracticeId(null)
    }
  }, [dispatch, practiceId])

  return (
    <div className={styles.root}>
      <div className={styles.pageActions}>
        <Button
          variant="subtle"
          size="x-small"
          icon={<MessageCircle />}
          aria-label="View comments"
          onClick={onActionClick('comments')}
        />
        <Button
          onClick={onActionClick('link')}
          variant="subtle"
          size="small"
          icon={<Link />}
        />
        <Button
          onClick={onActionClick('details')}
          variant="subtle"
          size="x-small"
          icon={<Info />}
        >
          {t('practice.details')}
        </Button>
      </div>

      {httpGetPractice.isFetching && <LoadingIndicator />}
      {!httpGetPractice.isFetching &&
      httpGetPractice.isSuccess &&
      httpGetPractice.data.data.current_approvalish_revision !== null ? (
        <Suspense fallback={<div />}>
          <OngoingApproval
            revisionStatus={
              httpGetPractice.data.data.current_approvalish_revision.status
            }
          />
        </Suspense>
      ) : (
        <Suspense fallback={<div />}>
          <ApprovalStartPage />
        </Suspense>
      )}
    </div>
  )
}

export default ApprovalSidebarPage
