import React, { useRef } from 'react'

import clsx from 'clsx'

import styles from './TextArea.module.scss'
import { TextAreaProps } from './TextArea.types'
import InputRoot from '../InputRoot/InputRoot'

const TextArea = ({
  id,
  label,
  startAdornment,
  endAdornment,

  className,
  onChange,
  disabled,
  size = 'medium',

  value,
  isClearable: clearable,
  setInputValue,
  isError,
  rows = 4,
  ...rest
}: TextAreaProps): JSX.Element => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const isActive: boolean = Boolean(value)

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange && !disabled) {
      onChange(event)
    }
  }

  const handleInputClear = () => {
    if (!disabled && setInputValue) {
      setInputValue('')
      focusField()
    }
  }

  const focusField = () => {
    if (textareaRef.current) {
      textareaRef.current.focus()
    }
  }

  return (
    <InputRoot
      label={label ?? ''}
      id={id}
      className={clsx(styles.root, className)}
      disabled={disabled}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      handleContainerClick={focusField}
      handleClear={handleInputClear}
      isClearable={clearable}
      isActive={isActive}
      size={size}
      isError={isError}
    >
      <textarea
        id={id}
        ref={textareaRef}
        disabled={disabled}
        onChange={handleChange}
        className={styles.input}
        value={value}
        rows={rows}
        {...rest}
      />
    </InputRoot>
  )
}

export default TextArea
