import { useCallback, useState } from 'react'

import clsx from 'clsx'

import styles from './Avatar.module.scss'
import { AvatarProps } from './Avatar.types'

const Avatar = ({
  content,
  onClick,
  src,
  size = 'medium',
  badge,
  badgePosition = 'bottom-right',
  className,
  ref,
}: AvatarProps): JSX.Element => {
  const [imageError, setImageError] = useState(false)
  const classes = clsx(
    styles.root,
    styles[`sz-${size}`],
    {
      [styles.isClickable]: Boolean(onClick),
    },
    className
  )

  const hasImageError = useCallback(() => {
    setImageError(true)
  }, [])

  return (
    <div className={classes} onClick={onClick} ref={ref}>
      {src && !imageError ? (
        <figure className={styles.imageContainer}>
          <img
            src={src}
            alt=""
            className={styles.image}
            onError={hasImageError}
          />
        </figure>
      ) : (
        <span className={styles.content}>{content}</span>
      )}
      {badge && (
        <span className={clsx(styles.badge, styles[`badge-${badgePosition}`])}>
          {badge}
        </span>
      )}
    </div>
  )
}

export default Avatar
