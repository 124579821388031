import LocationValidationState from 'constants/enums/LocationValidationState'

import { useState } from 'react'

import { X } from 'assets/Icons'
import { Button, Typography } from 'components/common'
import FocusTrap from 'focus-trap-react'
import { getUnknownProps } from 'helpers'
import { useAppDispatch, useAppSelector } from 'hooks'
import { t } from 'i18next'
import { useLocation } from 'react-router-dom'

import styles from './UpdateDocMetadata.module.scss'
import { userApi } from '../../../User/api'
import {
  replaceDocumentActions as actions,
  replaceDocumentSelectors,
} from '../../api'
import { DocumentsForm } from '../DocumentsForm'
import { DocumentsFormValues } from '../DocumentsForm/types'

const ID_PREFIX = 'update-document'

interface UpdateDocMetadataProps {
  onAbort: () => void
  onSubmit: () => void
}

const UpdateDocMetadata = ({ onAbort, onSubmit }: UpdateDocMetadataProps) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { newFile, currentFile, initialFormValues } = useAppSelector(
    replaceDocumentSelectors.selectReplaceDocument
  )
  const [formValid, setFormValidity] = useState(false)

  const validateOnMount =
    getUnknownProps(
      location.state,
      LocationValidationState.VALIDATE_ON_MOUNT
    ) ?? true // fixme: this is a dirty hack, because location.state is not passed down for some reason
  const validationLevel =
    getUnknownProps(location.state, LocationValidationState.VALIDATION_LEVEL) ??
    'strict' // fixme: this is a dirty hack, because location.state is not passed down for some reason

  const { data: user } = userApi.endpoints.getCurrentUser.useQueryState(null)
  const companyLanguage =
    user?.data.customer?.company_language ?? user?.data.language ?? 'en'

  const handleSubmit = () => {
    onSubmit()
  }

  const goToUpload = () => {
    dispatch(actions.setStep('upload-file'))
  }

  const handleValuesChange = (values: DocumentsFormValues) => {
    dispatch(actions.updateFormValues(values))
  }

  const handleDirtyChange = (isDirty: boolean) => {
    dispatch(actions.setFormDirty(isDirty))
  }

  return (
    <FocusTrap>
      <div className={styles.root}>
        <aside className={styles.aside}>
          <Button
            className={styles.closeBtn}
            variant="subtle"
            size="small"
            icon={<X />}
            aria-label={t('newGuideline.closeBtnAria') ?? ''}
            onClick={onAbort}
          />
        </aside>
        <main className={styles.main}>
          <div className={styles.title}>
            <Typography
              element="h1"
              type="display"
              size="x-small"
              fontWeight="bold"
            >
              {t('documentReplace.metadataTitle')}
            </Typography>
            <Typography color="label" element="h2">
              {t('documentReplace.metadataSubtitle')}
            </Typography>
          </div>
          <div className={styles.body}>
            <div className={styles.fileName}>
              <Typography element="h4" size="small" fontWeight="bold">
                {t('documentReplace.fileHeading')}
              </Typography>
              <Typography element="h5" color="label">
                {newFile ? newFile.file?.name : currentFile?.file?.filename}
              </Typography>
            </div>
            <DocumentsForm
              defaultLanguage={companyLanguage}
              defaultValues={initialFormValues ?? undefined}
              onDirtyChange={handleDirtyChange}
              onSubmit={handleSubmit}
              onValidityChange={setFormValidity}
              onValuesChange={handleValuesChange}
              titleIdPrefix={ID_PREFIX}
              validateOnMount={validateOnMount}
              validationLevel={validationLevel}
            />
          </div>

          <footer className={styles.footer}>
            <Button variant="text" size="large" onClick={onAbort}>
              {t('documentReplace.abort')}
            </Button>
            <Button onClick={goToUpload} variant="secondary" size="large">
              {t('documentReplace.submit')}
            </Button>
            <Button
              disabled={!formValid}
              form={ID_PREFIX + 'form'}
              size="large"
              type="submit"
            >
              {t('documentReplace.uploadEdit')}
            </Button>
          </footer>
        </main>
      </div>
    </FocusTrap>
  )
}

export default UpdateDocMetadata
