import { nanoid } from '@reduxjs/toolkit'
import { SelectOption } from 'types'

type CreateOptionConfig = {
  idField: string
  labelField: string
  childrenField?: string
  icon?: {
    field: string
    renderer: (iconFieldValue?: any) => React.ReactNode
  }
  endIcon?: {
    field: string
    renderer: (iconFieldValue?: any) => React.ReactNode
  }

  descriptionField?: string

  disabled?:
    | {
        field: string
        predicate: (value: any) => boolean
      }
    | boolean
}

export const createSelectOptions =
  (config: CreateOptionConfig) =>
  (optionsArr: any[]): SelectOption[] => {
    optionsArr = optionsArr.filter(Boolean)
    return createOptionItem(config, optionsArr)
  }

const createOptionItem = (config: CreateOptionConfig, data: any[]) => {
  const {
    idField = 'id',
    labelField = 'label',
    childrenField = 'children',
    endIcon,
    icon,
    disabled,
    descriptionField,
  } = config

  let result: any[] = []

  for (let i = 0; i < data.length; i++) {
    const option = data[i]

    let out: any

    let isDisabled: boolean = false
    if (disabled && typeof disabled === 'boolean') {
      isDisabled = disabled
    } else if (disabled) {
      isDisabled = disabled.predicate(option[disabled.field])
    }

    out = {
      id: (option[idField] as any) ?? nanoid(),
      label: option[labelField] ?? '',
      value: option,
      disabled: isDisabled,
      description: descriptionField ? option[descriptionField] : undefined,
      endAdornment:
        endIcon && endIcon.field
          ? endIcon.renderer(option[endIcon.field])
          : undefined,
      startAdornment: icon && icon.field ? icon.renderer(option) : undefined,
    }

    if (option[childrenField] && option[childrenField].length > 0) {
      out[childrenField] = createOptionItem(config, option[childrenField])
    }

    result.push(out)
  }

  return result
}
