import { LogoProps } from './types'

export const BrantnerLogo = ({ collapsed = false }: LogoProps) => {
  if (collapsed) {
    return (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.8737 29.5989L17.1149 26.6584C16.8816 26.5876 16.8286 26.7335 16.8625 26.8905L17.1191 27.8125C15.4737 27.7329 13.048 25.6767 13.0756 22.7804C13.0904 21.1134 13.0756 17.671 13.0756 17.671L11.1567 16.2935C11.0969 16.2578 11.045 16.2094 11.0043 16.1515C10.9635 16.0936 10.9348 16.0274 10.92 15.9572C10.9053 15.8871 10.9047 15.8144 10.9185 15.7441C10.9322 15.6737 10.9599 15.607 10.9998 15.5485L13.0756 12.011V1.25484C13.0772 1.22064 13.0718 1.18648 13.0598 1.1546C13.0478 1.12272 13.0295 1.09383 13.0061 1.06983C12.9827 1.04583 12.9547 1.02725 12.9239 1.01532C12.8931 1.00339 12.8603 0.998367 12.8275 1.00058H6.25444C6.19289 0.996189 6.13208 1.01672 6.08468 1.05791C6.03729 1.09909 6.00695 1.15775 6 1.22168V22.8644C6 30.0632 11.937 34.6531 18.2174 34.6531C18.583 34.6729 18.9495 34.6544 19.3115 34.5978L19.7568 35.9243C19.9964 36.1454 20.1597 35.8116 20.1597 35.8116L22.9819 29.8421C23.0285 29.7028 22.9819 29.621 22.8801 29.59"
          fill="#008651"
        />
        <path
          d="M18.9317 11.3619C18.3454 11.3489 17.7589 11.3799 17.1774 11.4545L16.831 10.0965C16.6075 9.84296 16.384 10.1692 16.384 10.1692L13.0318 15.6034C13.0167 15.6246 13.0067 15.6489 13.0024 15.6745C12.9981 15.7001 12.9996 15.7264 13.0069 15.7513C13.0142 15.7762 13.027 15.7992 13.0445 15.8187C13.0619 15.8381 13.0836 15.8534 13.1078 15.8635L18.6948 19.1703C18.9339 19.2628 19.001 19.124 18.9809 18.9652L18.782 17.9754C20.0431 18.1141 21.2089 18.7044 22.0583 19.6345C22.9076 20.5646 23.3815 21.7698 23.3901 23.0215C23.3833 23.8275 23.1797 24.6199 22.7965 25.3318C22.4133 26.0436 21.8619 26.6539 21.1888 27.1109L25.0573 28.9296C25.1257 28.9531 25.1884 28.9906 25.2413 29.0394C25.2941 29.0883 25.336 29.1476 25.3643 29.2135C25.3925 29.2793 25.4064 29.3503 25.4051 29.4217C25.4038 29.4932 25.3873 29.5636 25.3567 29.6285L23.4013 34C25.6503 33.1594 27.5842 31.6593 28.9427 29.7016C30.3012 27.7439 31.0191 25.4226 30.9996 23.0502C30.9996 15.3653 24.8427 11.3663 18.9183 11.3663"
          fill="#777679"
        />
      </svg>
    )
  }

  return (
    <svg
      width="150"
      height="40"
      viewBox="0 0 150 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_418_24020)">
        <path
          d="M82.5789 3.95559C82.3695 3.95559 82.3404 4.18051 82.3404 4.18051V22.4073C82.3568 22.9529 82.4289 23.4954 82.5556 24.0264C83.6008 28.0692 87.2519 29.539 92.9352 29.0348C92.9924 29.0286 93.0453 29.0014 93.0837 28.9584C93.122 28.9154 93.143 28.8597 93.1427 28.8022V23.3923C93.1427 23.1751 93.005 23.1751 92.9352 23.1771C91.1319 23.2449 89.4779 23.3904 88.7353 22.1804C88.4987 21.7926 88.4774 21.1547 88.4774 20.5129L88.4638 15.1651H92.8053C92.8676 15.1656 92.9278 15.1422 92.9733 15.0996C93.0188 15.057 93.0461 14.9985 93.0496 14.9362V9.61946C93.0497 9.59 93.0437 9.56083 93.0319 9.53384C93.02 9.50684 93.0027 9.48262 92.981 9.46271C92.9593 9.44281 92.9336 9.42767 92.9057 9.41827C92.8778 9.40887 92.8482 9.4054 92.8188 9.40811L88.4638 9.39647V4.17276C88.4646 4.14393 88.4595 4.11525 88.4489 4.08843C88.4383 4.06162 88.4224 4.03724 88.4021 4.01676C88.3818 3.99628 88.3575 3.98012 88.3308 3.96928C88.3041 3.95843 88.2755 3.95311 88.2466 3.95365H82.5847"
          fill="#008651"
        />
        <path
          d="M79.1953 16.8482C79.1953 12.4369 75.5112 8.86522 70.7431 8.96798C68.5928 9.01258 66.7565 9.74359 65.5252 10.6763C63.6638 12.084 62.45 14.5116 62.45 16.6756L62.4674 28.7944C62.4674 28.7944 62.479 29.0213 62.6885 29.0213H68.3814C68.5501 29.0213 68.5753 28.7828 68.5753 28.7828V17.6238C68.5753 16.5844 68.7964 15.136 70.78 15.136C72.8082 15.136 72.9982 16.8036 72.9982 17.5811V28.7847C72.9948 28.8152 72.998 28.8461 73.0078 28.8752C73.0175 28.9043 73.0334 28.9309 73.0544 28.9532C73.0754 28.9756 73.1011 28.993 73.1296 29.0045C73.158 29.0159 73.1886 29.021 73.2193 29.0194H78.9626C79.0252 29.0194 79.0853 28.9948 79.1299 28.9509C79.1745 28.907 79.2001 28.8473 79.2011 28.7847L79.1953 16.8482Z"
          fill="#008651"
        />
        <path
          d="M124.336 9.01643C122.958 9.13748 121.618 9.53436 120.396 10.1835C119.175 10.8326 118.096 11.7207 117.225 12.795C116.353 13.8693 115.707 15.1078 115.323 16.437C114.94 17.7662 114.828 19.1589 114.993 20.5323C115.211 22.8591 116.63 25.2227 118.03 26.5607C119.78 28.2695 122.08 29.3005 124.52 29.4692C130.321 29.7193 133.695 26.2465 135.766 22.002C135.786 21.9624 135.791 21.9165 135.778 21.8737C135.766 21.8309 135.738 21.7944 135.7 21.7713L131.336 19.6384C131.319 19.6263 131.299 19.6182 131.279 19.6147C131.259 19.6112 131.238 19.6124 131.218 19.6182C131.198 19.624 131.18 19.6343 131.165 19.6482C131.149 19.6621 131.138 19.6793 131.13 19.6985C131.07 19.857 131 20.0112 130.919 20.16C129.197 23.5823 124.095 25.1219 121.693 21.3389C119.921 18.4304 121.801 13.7554 125.598 13.9028C126.424 13.9551 127.163 14.1277 127.669 14.7133L122.604 18.0096C122.552 18.0736 122.472 18.1279 122.569 18.3063L124.702 21.6026C124.715 21.633 124.734 21.6598 124.76 21.6804C124.786 21.701 124.816 21.7147 124.848 21.7204C124.881 21.726 124.914 21.7233 124.945 21.7125C124.976 21.7018 125.004 21.6833 125.026 21.6588L134.434 15.2736C134.579 15.1728 134.541 15.0991 134.512 15.0332C134.43 14.9033 134.434 14.8975 134.351 14.7676C132.381 11.6244 129.658 8.59373 124.444 8.99704L124.336 9.01643Z"
          fill="#008651"
        />
        <path
          d="M112.535 16.8482C112.535 12.4369 108.851 8.86522 104.083 8.96798C101.934 9.01258 100.096 9.74359 98.8667 10.6763C97.0052 12.084 95.7914 14.5116 95.7914 16.6756L95.8088 28.7944C95.8088 28.7944 95.8224 29.0213 96.0299 29.0213H101.723C101.89 29.0213 101.917 28.7828 101.917 28.7828V17.6238C101.917 16.5844 102.136 15.136 104.119 15.136C106.15 15.136 106.338 16.8036 106.338 17.5811V28.7847C106.334 28.8152 106.338 28.8461 106.347 28.8752C106.357 28.9043 106.373 28.9309 106.394 28.9532C106.415 28.9756 106.441 28.993 106.469 29.0045C106.498 29.0159 106.528 29.021 106.559 29.0194H112.29C112.353 29.0194 112.413 28.9948 112.458 28.9509C112.502 28.907 112.528 28.8473 112.529 28.7847L112.535 16.8482Z"
          fill="#008651"
        />
        <path
          d="M137.767 9.70088C137.767 9.93938 137.767 28.744 137.767 28.744C137.765 28.7742 137.768 28.8046 137.778 28.8332C137.788 28.8619 137.804 28.8882 137.824 28.9103C137.845 28.9325 137.87 28.95 137.898 28.9618C137.926 28.9735 137.956 28.9793 137.986 28.9786H143.92C143.952 28.9811 143.984 28.9768 144.014 28.966C144.044 28.9552 144.072 28.9382 144.095 28.9161C144.118 28.894 144.137 28.8672 144.149 28.8376C144.161 28.8079 144.167 28.776 144.166 28.744V22.5042C144.166 20.2821 143.931 17.9049 144.781 16.7182C145.595 15.5839 146.96 15.6168 148.789 15.5431C148.848 15.5345 148.902 15.5069 148.944 15.4646C148.986 15.4223 149.013 15.3675 149.021 15.3085V9.68537C149.019 9.62401 148.995 9.56554 148.952 9.52142C148.909 9.4773 148.852 9.45071 148.791 9.44687C145.822 9.31308 144.222 10.5502 143.445 12.6075V9.68925C143.445 9.4682 143.202 9.46432 143.202 9.46432H138.006C137.974 9.46325 137.943 9.46863 137.914 9.48011C137.884 9.4916 137.858 9.50895 137.835 9.53111C137.813 9.55326 137.795 9.57975 137.784 9.60895C137.772 9.63814 137.766 9.66943 137.767 9.70088Z"
          fill="#008651"
        />
        <path
          d="M59.14 17.5035C59.0658 16.3253 58.7383 15.177 58.1798 14.137C57.6213 13.0969 56.845 12.1896 55.9038 11.477C53.8349 9.91416 50.428 8.79729 46.9999 9.04936C41.4174 9.46043 37.4444 13.8 37.3784 19.1206C37.3106 24.2862 40.6922 29.1143 46.4608 29.4614C48.3746 29.5797 49.5846 29.2675 50.9962 28.4667C51.0353 28.4458 51.0687 28.4156 51.0934 28.3788C51.1181 28.3419 51.1333 28.2995 51.1377 28.2553L51.1513 23.2469C51.1513 23.053 50.9962 23.0762 50.9186 23.1053C48.3533 24.0748 45.2353 23.3593 44.3027 21.4591C42.5789 17.9514 45.1442 15.1398 47.8084 14.8936C52.4524 14.467 52.7549 18.4904 52.7549 22.9172V28.7653C52.7549 28.7653 52.7684 29.0135 52.9953 29.0135H59.0547C59.2603 29.0135 59.2719 28.775 59.2719 28.775V22.2773C59.2719 20.5477 59.2292 18.7425 59.1381 17.5074"
          fill="#008651"
        />
        <path
          d="M24.5111 9.73577C24.5111 9.97426 24.5111 28.7789 24.5111 28.7789C24.5087 28.8091 24.5126 28.8396 24.5227 28.8683C24.5327 28.8969 24.5487 28.9232 24.5695 28.9453C24.5904 28.9674 24.6156 28.9849 24.6436 28.9966C24.6716 29.0084 24.7018 29.0141 24.7322 29.0135H30.652C30.6841 29.0163 30.7163 29.0122 30.7467 29.0015C30.777 28.9909 30.8048 28.9739 30.8281 28.9517C30.8513 28.9295 30.8696 28.9026 30.8818 28.8728C30.8939 28.843 30.8995 28.811 30.8983 28.7789V22.5391C30.8983 20.317 30.6636 17.9417 31.5129 16.7531C32.3273 15.6168 33.6924 15.6517 35.5209 15.578C35.5796 15.5693 35.6339 15.5416 35.6755 15.4993C35.7171 15.457 35.7439 15.4023 35.7516 15.3434V9.72025C35.7509 9.65966 35.7277 9.6015 35.6865 9.55707C35.6453 9.51263 35.589 9.4851 35.5286 9.47982C32.56 9.34796 30.9603 10.5831 30.1828 12.6404V9.72219C30.1828 9.50114 29.9404 9.49921 29.9404 9.49921H24.7496C24.7182 9.49814 24.6869 9.50351 24.6576 9.515C24.6283 9.52648 24.6017 9.54383 24.5793 9.56599C24.557 9.58815 24.5394 9.61464 24.5277 9.64383C24.516 9.67303 24.5103 9.70431 24.5111 9.73577Z"
          fill="#008651"
        />
        <path
          d="M15.4365 25.0812L10.1701 22.5023C9.95684 22.4403 9.90836 22.5683 9.93939 22.7059L10.174 23.5145C8.66933 23.4447 6.45109 21.6414 6.4763 19.1013C6.48987 17.6393 6.4763 14.6202 6.4763 14.6202L4.72149 13.4122C4.66684 13.3809 4.61936 13.3384 4.58209 13.2876C4.54482 13.2368 4.51859 13.1788 4.50508 13.1173C4.49157 13.0557 4.49107 12.992 4.50363 12.9303C4.51619 12.8686 4.54152 12.8101 4.578 12.7588L6.4763 9.65633V0.223011C6.47773 0.193016 6.47281 0.163059 6.46186 0.135098C6.45091 0.107137 6.43418 0.0818023 6.41277 0.060753C6.39135 0.0397036 6.36573 0.0234136 6.33758 0.0129484C6.30944 0.00248321 6.2794 -0.00192083 6.24944 2.40315e-05H0.238481C0.182191 -0.00383058 0.126585 0.0141771 0.083241 0.0502969C0.0398972 0.0864167 0.0121574 0.137864 0.00579834 0.193926V19.175C0.00579834 25.4884 5.43505 29.5138 11.1784 29.5138C11.5127 29.5313 11.8479 29.515 12.179 29.4653L12.5861 30.6288C12.8053 30.8227 12.9546 30.5299 12.9546 30.5299L15.5354 25.2945C15.578 25.1724 15.5354 25.1006 15.4423 25.0735"
          fill="#008651"
        />
        <path
          d="M11.4965 8.86717C10.9878 8.8558 10.4789 8.88303 9.97433 8.94861L9.67378 7.75417C9.47988 7.53118 9.28598 7.81816 9.28598 7.81816L6.37745 12.5978C6.36439 12.6166 6.35566 12.6379 6.35192 12.6605C6.34818 12.683 6.34952 12.706 6.35584 12.728C6.36216 12.7499 6.3733 12.7701 6.38845 12.7872C6.40359 12.8043 6.42236 12.8178 6.44338 12.8266L11.2909 15.7352C11.4984 15.8166 11.5566 15.6945 11.5391 15.5548L11.3665 14.6842C12.4608 14.8062 13.4723 15.3255 14.2092 16.1435C14.9462 16.9616 15.3573 18.0216 15.3648 19.1226C15.3589 19.8315 15.1822 20.5286 14.8498 21.1547C14.5173 21.7808 14.0388 22.3176 13.4549 22.7195L16.8113 24.3192C16.8707 24.3399 16.9251 24.3728 16.9709 24.4158C17.0168 24.4588 17.0532 24.5109 17.0777 24.5689C17.1021 24.6268 17.1142 24.6892 17.1131 24.7521C17.112 24.8149 17.0977 24.8769 17.0711 24.9339L15.3745 28.7789C17.3258 28.0396 19.0038 26.7201 20.1825 24.9982C21.3612 23.2762 21.984 21.2345 21.9672 19.1478C21.9672 12.3884 16.6252 8.87105 11.4848 8.87105"
          fill="#777679"
        />
        <path
          d="M85.4332 35.1311C85.3539 34.9472 85.2205 34.7918 85.0508 34.6855C84.8811 34.5791 84.6831 34.527 84.483 34.5358C83.8141 34.5358 83.4689 35.1175 83.4689 35.7264C83.4689 36.3352 83.8315 36.9092 84.5393 36.9092C84.8539 36.9125 85.1633 36.8286 85.4332 36.6668V35.1311ZM86.5966 37.9136C86.5966 39.3019 85.6271 40 84.4636 40C83.9103 39.9933 83.3711 39.8247 82.9124 39.5152C82.8777 39.4865 82.8541 39.4466 82.8456 39.4024C82.8371 39.3582 82.8443 39.3124 82.8659 39.2729L83.1451 38.6679C83.1576 38.6472 83.1741 38.6293 83.1937 38.6151C83.2133 38.601 83.2355 38.591 83.2591 38.5858C83.2827 38.5805 83.3071 38.5801 83.3308 38.5846C83.3545 38.589 83.3771 38.5982 83.3972 38.6117C83.7053 38.8053 84.0576 38.9174 84.421 38.9374C85.0803 38.9374 85.4351 38.7144 85.4351 37.9136V37.7895C85.1025 37.9044 84.7534 37.964 84.4016 37.966C83.116 37.966 82.2687 36.979 82.2687 35.7225C82.2687 34.4195 83.1529 33.4713 84.326 33.4713C84.5926 33.4713 84.8561 33.5285 85.0987 33.6393C85.3413 33.75 85.5572 33.9116 85.7318 34.1131L85.8423 33.768C85.8791 33.6478 85.9451 33.5644 86.0478 33.5644H86.391C86.4451 33.5649 86.4968 33.5864 86.5351 33.6245C86.5735 33.6625 86.5956 33.714 86.5966 33.768V37.9136Z"
          fill="#777679"
        />
        <path
          d="M88.1982 33.7719C88.1987 33.718 88.2203 33.6666 88.2584 33.6285C88.2964 33.5904 88.3479 33.5688 88.4018 33.5683H88.7469C88.7907 33.5639 88.8346 33.5757 88.8703 33.6014C88.906 33.6271 88.9311 33.665 88.9408 33.7079L89.0998 34.1267C89.2342 33.9288 89.4144 33.7663 89.625 33.6528C89.8357 33.5394 90.0706 33.4785 90.3098 33.4752C90.8217 33.4752 91.2405 33.6148 91.1183 33.894L90.8391 34.5262C90.83 34.5497 90.816 34.571 90.7981 34.5887C90.7801 34.6064 90.7586 34.6201 90.735 34.6289C90.7113 34.6377 90.6861 34.6414 90.6609 34.6397C90.6358 34.6381 90.6113 34.6311 90.589 34.6192C90.4639 34.5595 90.3262 34.5309 90.1876 34.5359C90.0241 34.5321 89.8625 34.5713 89.7189 34.6496C89.5753 34.7278 89.4548 34.8424 89.3693 34.9818V37.6635C89.3693 37.8225 89.2666 37.8671 89.127 37.8671H88.4095C88.3563 37.8647 88.3059 37.8425 88.2682 37.8048C88.2305 37.7672 88.2083 37.7167 88.2059 37.6635L88.1982 33.7719Z"
          fill="#777679"
        />
        <path
          d="M94.8063 35.1776C94.8024 35.0738 94.7777 34.9719 94.7337 34.8779C94.6896 34.7838 94.6271 34.6996 94.5499 34.6301C94.4727 34.5607 94.3823 34.5074 94.2841 34.4736C94.186 34.4397 94.082 34.4259 93.9784 34.433C93.7599 34.4164 93.5435 34.4844 93.3738 34.623C93.2041 34.7617 93.0943 34.9602 93.067 35.1776H94.8063ZM94.0055 33.4751C94.2677 33.4687 94.5284 33.5158 94.7717 33.6136C95.015 33.7113 95.2358 33.8577 95.4206 34.0438C95.6053 34.2298 95.7502 34.4516 95.8463 34.6956C95.9424 34.9395 95.9877 35.2006 95.9795 35.4626C95.9795 35.5286 95.9794 35.6759 95.962 35.7419C95.9561 35.7932 95.9323 35.8409 95.8947 35.8764C95.8571 35.9119 95.8081 35.9329 95.7565 35.9358H93.0011C93.0112 36.2096 93.1296 36.4682 93.3304 36.6547C93.5311 36.8412 93.7976 36.9404 94.0715 36.9305C94.4062 36.9374 94.7325 36.8254 94.9925 36.6144C95.0953 36.531 95.2077 36.5213 95.2717 36.6144L95.6343 37.0992C95.654 37.1173 95.6694 37.1397 95.6794 37.1645C95.6893 37.1894 95.6936 37.2162 95.692 37.2429C95.6903 37.2697 95.6827 37.2957 95.6697 37.3191C95.6567 37.3426 95.6387 37.3628 95.6169 37.3784C95.1706 37.7591 94.6018 37.9657 94.0152 37.9601C92.7316 37.9601 91.8377 36.9363 91.8377 35.7089C91.8377 34.4815 92.7316 33.4655 94.0055 33.4655"
          fill="#777679"
        />
        <path
          d="M99.9738 35.1776C99.9684 35.0743 99.9426 34.9732 99.8981 34.8799C99.8535 34.7866 99.791 34.7029 99.7141 34.6338C99.6372 34.5647 99.5475 34.5113 99.45 34.4769C99.3525 34.4424 99.2491 34.4275 99.1459 34.433C98.9272 34.4163 98.7105 34.4843 98.5405 34.6229C98.3705 34.7615 98.2603 34.9601 98.2326 35.1776H99.9738ZM99.173 33.4751C99.4351 33.4687 99.6958 33.5158 99.9391 33.6136C100.182 33.7113 100.403 33.8577 100.588 34.0438C100.773 34.2298 100.918 34.4516 101.014 34.6956C101.11 34.9395 101.155 35.2006 101.147 35.4626C101.145 35.556 101.139 35.6492 101.128 35.7419C101.122 35.7916 101.1 35.838 101.065 35.8733C101.03 35.9086 100.983 35.9307 100.934 35.9358H98.1686C98.1733 36.0714 98.2048 36.2048 98.2612 36.3283C98.3176 36.4518 98.3979 36.5629 98.4973 36.6553C98.5967 36.7477 98.7134 36.8196 98.8407 36.8668C98.968 36.914 99.1033 36.9357 99.2389 36.9305C99.5736 36.9367 99.8997 36.8248 100.16 36.6144C100.263 36.531 100.373 36.5213 100.439 36.6144L100.802 37.0992C100.821 37.1173 100.837 37.1397 100.847 37.1645C100.857 37.1894 100.861 37.2162 100.859 37.2429C100.858 37.2697 100.85 37.2957 100.837 37.3191C100.824 37.3426 100.806 37.3628 100.784 37.3784C100.338 37.7588 99.7692 37.9654 99.1827 37.9601C97.8971 37.9601 97.0052 36.9363 97.0052 35.7089C97.0052 34.4815 97.8971 33.4655 99.173 33.4655"
          fill="#777679"
        />
        <path
          d="M102.425 33.7719C102.425 33.7177 102.447 33.666 102.486 33.6278C102.524 33.5897 102.576 33.5683 102.63 33.5683H102.983C103.023 33.5642 103.064 33.5752 103.097 33.5992C103.129 33.6232 103.152 33.6585 103.16 33.6982L103.319 34.0977C103.488 33.8958 103.701 33.735 103.941 33.6272C104.182 33.5194 104.444 33.4675 104.707 33.4753C106.074 33.4753 106.429 34.3963 106.429 35.4395V37.6635C106.426 37.7171 106.404 37.7678 106.366 37.8055C106.328 37.8432 106.277 37.8652 106.223 37.8671H105.469C105.415 37.8666 105.363 37.8451 105.325 37.8071C105.287 37.769 105.264 37.7175 105.263 37.6635V35.4395C105.263 34.881 105.04 34.5359 104.521 34.5359C104.313 34.5302 104.11 34.5928 103.941 34.714C103.773 34.8352 103.649 35.0083 103.588 35.2068V37.6635C103.588 37.8225 103.524 37.8671 103.319 37.8671H102.63C102.577 37.8652 102.526 37.8432 102.488 37.8055C102.45 37.7678 102.427 37.7171 102.425 37.6635V33.7719Z"
          fill="#777679"
        />
        <path
          d="M110.503 37.5801C110.469 37.5532 110.445 37.5156 110.434 37.4735C110.424 37.4313 110.428 37.3869 110.446 37.3474L110.726 36.789C110.735 36.765 110.749 36.7435 110.767 36.726C110.786 36.7086 110.808 36.6957 110.833 36.6883C110.857 36.681 110.883 36.6795 110.908 36.6838C110.933 36.6881 110.957 36.6982 110.978 36.7133C111.26 36.897 111.589 36.994 111.926 36.9926C112.252 36.9926 112.401 36.8723 112.401 36.6862C112.401 36.5001 112.159 36.3139 111.591 36.0909C110.763 35.7729 110.39 35.3832 110.39 34.7336C110.39 34.084 110.885 33.4771 111.941 33.4771C112.388 33.4585 112.831 33.5682 113.217 33.7932C113.266 33.8239 113.301 33.8708 113.318 33.9255C113.336 33.9801 113.333 34.039 113.31 34.0918L113.068 34.5843C113.041 34.6286 112.999 34.6616 112.949 34.6769C112.899 34.6923 112.846 34.689 112.799 34.6677C112.532 34.5357 112.239 34.4629 111.941 34.4544C111.653 34.4544 111.532 34.594 111.532 34.7336C111.532 34.9275 111.746 35.0303 112.145 35.199C112.975 35.5344 113.57 35.8311 113.57 36.6881C113.57 37.3668 112.989 37.9718 111.914 37.9718C111.417 37.9909 110.926 37.8554 110.509 37.584"
          fill="#777679"
        />
        <path
          d="M116.822 36.9092C116.975 36.9045 117.125 36.8696 117.264 36.8067C117.404 36.7438 117.529 36.654 117.634 36.5426C117.739 36.4311 117.82 36.3001 117.874 36.1571C117.928 36.014 117.953 35.8618 117.949 35.709C117.934 35.4904 117.856 35.281 117.723 35.1063C117.591 34.9316 117.411 34.7993 117.205 34.7256C116.999 34.6519 116.775 34.64 116.562 34.6914C116.35 34.7428 116.156 34.8552 116.006 35.0149C115.857 35.1746 115.757 35.3746 115.719 35.5903C115.681 35.8061 115.707 36.0281 115.793 36.2293C115.88 36.4305 116.024 36.602 116.206 36.7228C116.389 36.8435 116.603 36.9083 116.822 36.9092ZM116.822 33.4752C117.269 33.4648 117.709 33.5881 118.086 33.8292C118.463 34.0703 118.759 34.4184 118.936 34.8288C119.114 35.2392 119.165 35.6933 119.084 36.133C119.002 36.5727 118.791 36.978 118.477 37.2971C118.164 37.6161 117.762 37.8343 117.324 37.9238C116.886 38.0133 116.431 37.97 116.017 37.7995C115.604 37.6289 115.251 37.3389 115.003 36.9665C114.755 36.5941 114.624 36.1562 114.627 35.709C114.627 35.1234 114.858 34.5613 115.268 34.1436C115.678 33.7259 116.236 33.4859 116.822 33.4752Z"
          fill="#777679"
        />
        <path
          d="M120.37 31.5575C120.374 31.5045 120.396 31.4545 120.434 31.4168C120.471 31.379 120.521 31.356 120.574 31.3519H121.328C121.382 31.3556 121.432 31.3784 121.47 31.4162C121.507 31.4539 121.53 31.5041 121.534 31.5575V37.6634C121.531 37.717 121.509 37.7677 121.471 37.8054C121.433 37.8431 121.382 37.8651 121.328 37.867H120.574C120.521 37.8647 120.47 37.8424 120.433 37.8048C120.395 37.7671 120.373 37.7167 120.37 37.6634V31.5575Z"
          fill="#777679"
        />
        <path
          d="M123.097 33.7718C123.095 33.7446 123.1 33.7173 123.109 33.6918C123.119 33.6663 123.134 33.6432 123.154 33.624C123.173 33.6048 123.196 33.5899 123.222 33.5803C123.248 33.5707 123.275 33.5666 123.302 33.5682H124.056C124.083 33.5668 124.111 33.5712 124.136 33.5809C124.161 33.5906 124.184 33.6056 124.203 33.6248C124.223 33.644 124.238 33.667 124.247 33.6923C124.257 33.7176 124.261 33.7447 124.26 33.7718V36.0444C124.26 36.6125 124.454 36.9014 124.987 36.9014C125.462 36.9014 125.685 36.4729 125.749 36.1006V33.7718C125.749 33.6051 125.805 33.5682 125.982 33.5682H126.709C126.735 33.5682 126.761 33.5735 126.785 33.5839C126.81 33.5942 126.831 33.6094 126.85 33.6284C126.868 33.6475 126.882 33.67 126.891 33.6946C126.9 33.7193 126.904 33.7456 126.903 33.7718V37.6634C126.902 37.7157 126.882 37.7658 126.846 37.8036C126.81 37.8414 126.761 37.8641 126.709 37.867H126.374C126.327 37.8658 126.282 37.8499 126.245 37.8217C126.208 37.7935 126.181 37.7544 126.168 37.71L126.048 37.3474C125.88 37.5363 125.676 37.6889 125.447 37.7959C125.218 37.903 124.97 37.9621 124.718 37.9698C123.385 37.9698 123.097 37.1418 123.097 36.0521V33.7718Z"
          fill="#777679"
        />
        <path
          d="M128.795 34.6095H128.377C128.325 34.6095 128.276 34.5891 128.239 34.5528C128.203 34.5164 128.183 34.4671 128.183 34.4156V33.7816C128.181 33.7553 128.185 33.7291 128.194 33.7044C128.204 33.6797 128.218 33.6572 128.236 33.6382C128.254 33.6191 128.276 33.604 128.3 33.5936C128.324 33.5833 128.35 33.578 128.377 33.578H128.795V32.3855C128.795 32.357 128.8 32.3286 128.81 32.3021C128.82 32.2755 128.836 32.2513 128.856 32.2309C128.876 32.2105 128.899 32.1942 128.926 32.1832C128.952 32.1721 128.98 32.1664 129.009 32.1664H129.765C129.791 32.1663 129.817 32.1717 129.841 32.182C129.866 32.1924 129.887 32.2075 129.906 32.2266C129.924 32.2456 129.938 32.2681 129.947 32.2928C129.956 32.3175 129.96 32.3437 129.959 32.37V33.5625H130.965C130.991 33.5624 131.017 33.5678 131.042 33.5781C131.066 33.5885 131.088 33.6036 131.106 33.6227C131.124 33.6417 131.138 33.6642 131.147 33.6889C131.156 33.7135 131.16 33.7398 131.159 33.7661V34.4001C131.155 34.4502 131.133 34.4973 131.098 34.5328C131.062 34.5683 131.015 34.59 130.965 34.594H129.959V36.5331C129.959 36.822 130.098 36.886 130.314 36.886C130.535 36.8649 130.754 36.818 130.965 36.7463C130.988 36.734 131.013 36.7271 131.039 36.7264C131.065 36.7256 131.09 36.7309 131.114 36.7418C131.137 36.7527 131.158 36.769 131.174 36.7894C131.19 36.8097 131.2 36.8335 131.206 36.8588L131.328 37.4269C131.342 37.4764 131.337 37.5291 131.314 37.5752C131.292 37.6214 131.253 37.6576 131.206 37.6771C130.823 37.8439 130.413 37.9386 129.996 37.9563C129.038 37.9563 128.795 37.3145 128.795 36.5699V34.6095Z"
          fill="#777679"
        />
        <path
          d="M132.555 33.7719C132.556 33.7177 132.578 33.6659 132.616 33.6278C132.654 33.5897 132.706 33.5683 132.761 33.5683H133.513C133.567 33.5683 133.619 33.5897 133.658 33.6278C133.696 33.6659 133.718 33.7177 133.719 33.7719V37.6635C133.717 37.7172 133.694 37.7682 133.656 37.806C133.618 37.8438 133.567 37.8656 133.513 37.8671H132.761C132.707 37.8656 132.656 37.8438 132.618 37.806C132.579 37.7682 132.557 37.7172 132.555 37.6635V33.7719ZM132.388 32.0869C132.387 31.99 132.405 31.8939 132.442 31.8041C132.478 31.7144 132.532 31.6328 132.601 31.5643C132.669 31.4958 132.751 31.4417 132.84 31.4053C132.93 31.3688 133.026 31.3507 133.123 31.352C133.27 31.3508 133.414 31.3935 133.537 31.4744C133.66 31.5553 133.755 31.671 133.812 31.8065C133.869 31.9421 133.884 32.0915 133.856 32.2358C133.827 32.38 133.757 32.5125 133.653 32.6164C133.549 32.7204 133.416 32.791 133.272 32.8194C133.128 32.8478 132.979 32.8327 132.843 32.7759C132.707 32.7191 132.592 32.6233 132.511 32.5006C132.43 32.3779 132.387 32.2339 132.388 32.0869Z"
          fill="#777679"
        />
        <path
          d="M137.255 36.9092C137.408 36.9044 137.558 36.8696 137.698 36.8067C137.837 36.7438 137.963 36.654 138.067 36.5425C138.172 36.4311 138.254 36.3001 138.308 36.157C138.362 36.014 138.387 35.8617 138.382 35.7089C138.367 35.4904 138.289 35.2809 138.157 35.1062C138.025 34.9315 137.844 34.7992 137.638 34.7255C137.432 34.6518 137.209 34.6399 136.996 34.6913C136.783 34.7427 136.59 34.8552 136.44 35.0149C136.29 35.1746 136.19 35.3746 136.152 35.5903C136.114 35.806 136.14 36.0281 136.227 36.2293C136.313 36.4304 136.457 36.6019 136.64 36.7227C136.822 36.8435 137.036 36.9083 137.255 36.9092ZM137.255 33.4752C137.702 33.4652 138.142 33.5887 138.519 33.8301C138.895 34.0715 139.191 34.4197 139.368 34.8301C139.546 35.2406 139.597 35.6946 139.515 36.1342C139.433 36.5738 139.221 36.9789 138.908 37.2978C138.594 37.6166 138.193 37.8346 137.755 37.9239C137.316 38.0133 136.862 37.9699 136.448 37.7993C136.035 37.6287 135.682 37.3386 135.434 36.9662C135.187 36.5939 135.056 36.1561 135.058 35.7089C135.059 35.123 135.289 34.5606 135.7 34.1429C136.111 33.7251 136.669 33.4853 137.255 33.4752Z"
          fill="#777679"
        />
        <path
          d="M140.794 33.7719C140.794 33.7197 140.815 33.6696 140.851 33.6318C140.887 33.594 140.936 33.5713 140.988 33.5683H141.343C141.383 33.565 141.423 33.5764 141.455 33.6002C141.488 33.6241 141.51 33.6589 141.519 33.6982L141.676 34.0977C141.846 33.8958 142.059 33.735 142.299 33.6272C142.539 33.5194 142.801 33.4675 143.065 33.4753C144.434 33.4753 144.786 34.3963 144.786 35.4395V37.6635C144.784 37.7171 144.762 37.7678 144.723 37.8055C144.685 37.8432 144.634 37.8652 144.581 37.8671H143.827C143.773 37.8666 143.721 37.845 143.683 37.8069C143.645 37.7689 143.624 37.7174 143.623 37.6635V35.4395C143.623 34.881 143.4 34.5359 142.878 34.5359C142.671 34.5303 142.468 34.593 142.3 34.7142C142.132 34.8354 142.008 35.0085 141.948 35.2068V37.6635C141.948 37.8225 141.882 37.8671 141.676 37.8671H140.988C140.936 37.8629 140.888 37.8398 140.852 37.8022C140.816 37.7647 140.796 37.7153 140.794 37.6635V33.7719Z"
          fill="#777679"
        />
        <path
          d="M146.221 37.5801C146.187 37.5532 146.163 37.5157 146.153 37.4735C146.143 37.4313 146.147 37.3869 146.165 37.3475L146.444 36.789C146.453 36.7651 146.467 36.7436 146.485 36.7261C146.504 36.7087 146.526 36.6957 146.55 36.6884C146.575 36.681 146.6 36.6795 146.625 36.6838C146.65 36.6882 146.674 36.6983 146.694 36.7134C146.978 36.8963 147.308 36.9932 147.645 36.9926C147.97 36.9926 148.12 36.8724 148.12 36.6863C148.12 36.5001 147.877 36.314 147.309 36.091C146.481 35.773 146.109 35.3832 146.109 34.7337C146.109 34.0841 146.601 33.4772 147.66 33.4772C148.107 33.4589 148.549 33.5686 148.936 33.7932C148.984 33.824 149.02 33.8709 149.037 33.9255C149.054 33.9802 149.051 34.0391 149.029 34.0918L148.787 34.5844C148.759 34.6285 148.716 34.6613 148.666 34.6766C148.617 34.6919 148.563 34.6887 148.515 34.6677C148.249 34.5355 147.957 34.4627 147.66 34.4544C147.371 34.4544 147.251 34.5941 147.251 34.7337C147.251 34.9276 147.464 35.0303 147.866 35.199C148.694 35.5345 149.289 35.8311 149.289 36.6882C149.289 37.3669 148.707 37.9718 147.633 37.9718C147.136 37.9909 146.644 37.8555 146.227 37.584"
          fill="#777679"
        />
      </g>
      <defs>
        <clipPath id="clip0_418_24020">
          <rect width="149.283" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BrantnerLogo
