import { Upload } from 'assets/Icons'
import clsx from 'clsx'
import { getValidPercentage, readableFileSize } from 'helpers'
import { Trash2 } from 'react-feather'
import { UploadFile } from 'types'

import styles from './UploadProgress.module.scss'
import Button from '../Button/Button'
import IconContainer from '../IconContainer/IconContainer'
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator'
import Typography from '../Typography/Typography'

interface UploadProgressProps {
  file: File
  progressPercent: number
  handleDelete: () => void
  status: UploadFile['state']
}

const UploadProgress = ({
  file,
  progressPercent,
  handleDelete,
  status,
}: UploadProgressProps): JSX.Element => {
  const classes = clsx(styles.root, {
    [styles.isRejected]: status === 'rejected',
  })

  const validProgressPercentage = getValidPercentage(progressPercent, 0, 100)

  const fileSizeWithUnits = readableFileSize(file.size)

  return (
    <div className={classes}>
      <div className={styles.content}>
        <IconContainer
          size="small"
          color="primary"
          withBackground
          icon={<Upload />}
        />
        <div className={styles.info}>
          <Typography size="small" fontWeight="medium">
            {file.name}
          </Typography>
          {status !== 'rejected' && (
            <Typography size="small" color="label">
              {fileSizeWithUnits} - {validProgressPercentage}% uploaded
            </Typography>
          )}
          {status === 'rejected' && (
            <Typography size="small" color="error" fontWeight="medium">
              Error uploading
            </Typography>
          )}
        </div>
      </div>
      <div className={styles.actions}>
        {status !== 'uploading' && (
          <Button
            variant="subtle"
            size="x-small"
            iconLabel="Remove file"
            icon={<Trash2 />}
            onClick={handleDelete}
          />
        )}
        {status === 'uploading' && <LoadingIndicator />}
      </div>
    </div>
  )
}

export default UploadProgress
