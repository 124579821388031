import { useState } from 'react'

import { X } from 'assets/Icons'
import { Button, Popover, Typography } from 'components/common'
import { t } from 'i18next'

import styles from './RemoveLanguageButton.module.scss'

interface RemoveLanguageButtonProps {
  onRemoveClick: () => void
}

const RemoveLanguageButton = ({ onRemoveClick }: RemoveLanguageButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleDismiss = () => {
    setAnchorEl(null)
  }

  const handleRemoveClick = () => {
    handleDismiss()
    onRemoveClick()
  }

  const popoverOpen = Boolean(anchorEl)

  return (
    <>
      <button
        onClick={handleClick}
        aria-label={t('languageBar.removeBtnAria') ?? ''}
        className={styles.btn}
        type="button"
      >
        <X />
      </button>
      <Popover
        anchorEl={anchorEl}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        className={styles.popover}
        open={popoverOpen}
        onClose={handleDismiss}
      >
        <div className={styles.sheet}>
          <Typography element="span" fontWeight="bold">
            {t('languageBar.popoverTitle')}
          </Typography>
          <Typography element="span" size="small" color="label">
            {t('languageBar.popoverSubtitle')}
          </Typography>
          <div className={styles.actions}>
            <Button variant="text" size="x-small" onClick={handleDismiss}>
              {t('languageBar.popoverAbort')}
            </Button>
            <Button
              variant="secondary"
              size="x-small"
              color="error"
              onClick={handleRemoveClick}
            >
              {t('languageBar.popoverSubmit')}
            </Button>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default RemoveLanguageButton
