import { capitalizeFirstLetter } from 'helpers'
import { DeepPartial } from 'types'

import createEmptyMetadata from './createEmptyMetadata'
import { DocumentsFormValues, FormMetadataValues, Option } from '../types'

const createInitialData = (
  defaultValues?: DeepPartial<DocumentsFormValues>
): DocumentsFormValues => {
  let metadata: FormMetadataValues[] = []

  if (
    defaultValues &&
    defaultValues.metadata &&
    defaultValues.metadata.length > 0
  ) {
    metadata = defaultValues.metadata.map((defaultData) => ({
      ...createEmptyMetadata(),
      ...defaultData,
    })) as FormMetadataValues[]
  }

  let type: Option | null = null

  if (defaultValues?.type) {
    type = {
      id: defaultValues?.type.id || '',
      label: capitalizeFirstLetter(defaultValues?.type.label || ''),
    }
  }

  let fileLanguage: Option | null = null

  if (defaultValues?.fileLanguage) {
    fileLanguage = {
      id: defaultValues?.fileLanguage.id || '',
      label: capitalizeFirstLetter(defaultValues?.fileLanguage.label || ''),
    }
  }

  return {
    fileLanguage: fileLanguage as Option,
    type: type as Option,
    metadata,
  }
}

export default createInitialData
