import { FileCheckIcon, FileTemplateIcon, FileTimesIcon } from 'assets/Icons'
import clsx from 'clsx'
import { Avatar } from 'components/common'
import { AvatarProps } from 'components/common/Avatar/Avatar.types'
import {
  Box,
  Clock,
  File,
  IconProps,
  Paperclip,
  Search,
  Tag,
  User,
} from 'react-feather'

import styles from './DocumentIcon.module.scss'

type DocumentIconType =
  | 'practice'
  | 'guideline'
  | 'document'
  | 'annex'
  | 'search'
  | 'recent_search'
  | 'template'
  | 'assignee'
  | 'approval'
  | 'approval_declined'
  | 'taxonomy'

interface DocumentIconsProps {
  type: DocumentIconType | string
  avatarProps?: Omit<AvatarProps, 'src' | 'content'>
}

const DocumentIcon = ({
  type,
  avatarProps,
}: DocumentIconsProps): JSX.Element => {
  const iconMap: Record<DocumentIconType, IconProps> = {
    practice: <Box />,
    guideline: <Box />,
    document: <File />,
    annex: <Paperclip />,
    search: <Search />,
    recent_search: <Clock />,
    assignee: <User />,
    taxonomy: <Tag />,
    approval: <FileCheckIcon />,
    template: <FileTemplateIcon />,
    approval_declined: <FileTimesIcon />,
  }

  let iconType: DocumentIconType = 'document'
  if (type in iconMap) {
    iconType = type as DocumentIconType
  }

  const classes = clsx([styles[`ic-${iconType}`], styles.ic])

  return (
    <Avatar className={classes} content={iconMap[iconType]} {...avatarProps} />
  )
}

export default DocumentIcon
