import { Breakpoints } from 'constants/enums/breakpoints'

import { numComparator } from 'helpers/num-helpers'
import { compareBreakpoint } from 'helpers/responsive'

import useWindowSize from './useWindowSize'

const useIsBelowBreakpoint = (breakpoint: Breakpoints | number): boolean => {
  const { width } = useWindowSize()

  if (typeof breakpoint === 'number') {
    return numComparator(breakpoint, width) < 0
  } else {
    return compareBreakpoint(breakpoint)(width) < 0
  }
}

export default useIsBelowBreakpoint
