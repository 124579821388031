import { SelectOption } from 'types'

export const AccessRights: SelectOption[] = [
  {
    id: '0',
    value: 'All',
    label: 'All',
  },
  {
    id: '1',
    value: 'Internally available',
    label: 'Internally available',
  },
  {
    id: '2',
    value: 'On request',
    label: 'On request',
  },
]
