import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import config from 'config'
import { TokenAPIResult } from 'redux-store/models'
import type { ApiSuccessResponse } from 'types'

export const oAuthApi = createApi({
  reducerPath: 'oAuthApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.OAUTH_BASE}`,
  }),
  endpoints: (builder) => ({
    ssoUrlLogin: builder.query<
      ApiSuccessResponse<{ 'redirect-to': string }>,
      string
    >({
      query: (email) => ({
        url: `${config.API_BASE}/sso/login-url?email=${email}`,
      }),
    }),
    login: builder.mutation<
      TokenAPIResult,
      { username: string; password: string }
    >({
      query: (userCredentials) => ({
        url: '/token',
        method: 'POST',
        body: {
          grant_type: 'password',
          username: userCredentials.username,
          password: userCredentials.password,
          client_id: config.OAUTH_CLIENT_ID,
          client_secret: config.OAUTH_CLIENT_SECRET,
          scope: '',
        },
      }),
    }),

    refreshToken: builder.mutation<TokenAPIResult, string>({
      query: (token) => ({
        url: '/token',
        method: 'POST',
        body: {
          grant_type: 'refresh_token',
          refresh_token: token,
          client_id: config.OAUTH_CLIENT_ID,
          client_secret: config.OAUTH_CLIENT_SECRET,
          scope: '',
        },
      }),
    }),
  }),
})

export const { useLoginMutation, useRefreshTokenMutation } = oAuthApi
