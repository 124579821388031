import { createSlice } from '@reduxjs/toolkit'
import { Practice } from 'redux-store/models'
import { Payload, ReduxRootState } from 'types'

interface State {
  minimalPractice: null | Practice
}

const initialState: State = {
  minimalPractice: null,
}

export const minimalPracticeSlice = createSlice({
  name: 'minimalPractice',
  initialState,
  reducers: {
    setMinimalPractice: (state, { payload }: Payload<null | Practice>) => {
      state.minimalPractice = payload
    },
  },
})

export const { setMinimalPractice } = minimalPracticeSlice.actions

export const minimalPracticeReducer = minimalPracticeSlice.reducer

export const selectMinimalPractice = (state: ReduxRootState) =>
  state.minimalPractice.minimalPractice
