import { useEffect, useState } from 'react'

import { Button, Card, Modal, Typography } from 'components/common'
import { practiceModalCloseLocation } from 'helpers'
import { t } from 'i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import styles from './PreCheckModal.module.scss'
import { practicesApi } from '../../api'
import PreCheckCard from '../../components/PreCheckCard/PreCheckCard'

const PreCheckModal = (): JSX.Element => {
  const { id: practiceId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [approvalInProgress, setApprovalInProgress] = useState(false)

  const httpGetPractice = practicesApi.useGetPracticeByIdQuery(
    practiceId ?? '',
    {
      skip: !practiceId,
    }
  )

  useEffect(() => {
    if (
      httpGetPractice.isSuccess &&
      httpGetPractice.data.data.current_approvalish_revision !== null
    ) {
      setApprovalInProgress(true)
    }
  }, [httpGetPractice.data, httpGetPractice.isSuccess])

  const handleModalClose = () => {
    if (practiceId) {
      const backLink = practiceModalCloseLocation(false, location, practiceId)
      navigate(backLink)
    } else {
      navigate('/practices')
    }
  }

  return (
    <Modal open={true} handleClose={handleModalClose}>
      {approvalInProgress ? (
        <Card>
          <Card.Body>
            <Typography type="display" size="x-small" fontWeight="bold">
              {t('preCheck.approvalTitle')}
            </Typography>
            <Typography type="text" color="label">
              {t('preCheck.approvalSubtitle')}
            </Typography>
          </Card.Body>
          <Card.Footer className={styles.footerBtn}>
            <Button onClick={handleModalClose}>
              {t('preCheck.approvalButton')}
            </Button>
          </Card.Footer>
        </Card>
      ) : (
        <PreCheckCard handleCancel={handleModalClose} />
      )}
    </Modal>
  )
}

export default PreCheckModal
