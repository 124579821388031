import { useState } from 'react'

import { Trash } from 'assets/Icons'
import { Button, IconContainer, Popover, Typography } from 'components/common'
import { t } from 'i18next'
import { Plus } from 'react-feather'

import RemoveConfirmModal from './RemoveConfirmModal'
import styles from './TranslationsButton.module.scss'
import { LanguageItem } from './types'

interface TranslationsButtonProps {
  onItemClick: (language: LanguageItem) => void
  options: LanguageItem[]
}

const TranslationsButton = ({
  options,
  onItemClick,
}: TranslationsButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [confirmLanguage, setConfirmLanguage] = useState<LanguageItem | null>(
    null
  )

  const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    evt.stopPropagation()
    setAnchorEl(evt.currentTarget)
  }

  const handleDismiss = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const popoverId = 'language-dropdown' as const

  const handleItemClick =
    (language: LanguageItem) => (evt: React.MouseEvent) => {
      evt.preventDefault()
      evt.stopPropagation()

      if (language.checked) {
        // trigger confirmation modal
        setConfirmLanguage(language)
      } else {
        onItemClick(language)
      }

      handleDismiss()
    }

  const handleModalConfirm = () => {
    if (confirmLanguage) {
      onItemClick(confirmLanguage)
    }
    setConfirmLanguage(null)
    handleDismiss()
  }

  const handleModalAbort = () => {
    setConfirmLanguage(null)
    handleDismiss()
  }

  return (
    <>
      {!!confirmLanguage && (
        <RemoveConfirmModal
          language={confirmLanguage}
          onConfirm={handleModalConfirm}
          onAbort={handleModalAbort}
        />
      )}
      <Button
        onClick={handleClick}
        type="button"
        aria-expanded={open}
        aria-haspopup="dialog"
        aria-controls={popoverId}
        icon={<Plus />}
        variant="text"
        size="small"
      >
        {t('languageBar.btnDropdown')}
      </Button>
      <Popover
        open={open}
        onClose={handleDismiss}
        anchorEl={anchorEl}
        id={popoverId}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <div className={styles.dropdown}>
          {options
            .filter((option) => !option.default)
            .map((option) => (
              <button
                key={`${option.id}-dropdown`}
                onClick={handleItemClick(option)}
                className={styles.languageItem}
                disabled={option.default}
              >
                {/* <Checkbox
                  size="small"
                  checked={option.checked}
                  disabled={option.default}
                  className={styles.btnIcon}
                /> */}
                <IconContainer
                  size="x-small"
                  icon={option.checked ? <Trash /> : <Plus />}
                  color={option.checked ? 'error' : 'default'}
                  className={styles.btnIcon}
                />

                <Typography size="small" element="span">
                  {option.label}
                </Typography>
              </button>
            ))}
        </div>
      </Popover>
    </>
  )
}

export default TranslationsButton
