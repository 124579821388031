import { useFrontOfficeCheck } from 'hooks'

import BackOfficeHeader from './BackOfficeHeader'
import FrontOfficeHeader from './FrontOfficeHeader'

const PracticeHeader = (): JSX.Element => {
  const isFrontOffice = useFrontOfficeCheck()

  return <>{isFrontOffice ? <FrontOfficeHeader /> : <BackOfficeHeader />}</>
}

export default PracticeHeader
