import { Children, cloneElement, forwardRef, useMemo } from 'react'

import CloseIcon from 'assets/Icons/CloseIcon'
import clsx from 'clsx'
import { AlertCircle } from 'react-feather'

import styles from './InputRoot.module.scss'
import { InputRootProps } from './InputRoot.types'

const InputRoot = forwardRef<HTMLDivElement, InputRootProps>(
  (
    {
      className,
      isClearable,
      disabled,
      handleClear,
      handleContainerClick,
      isActive,
      size = 'medium',
      children,
      endAdornment,
      id,
      label,
      startAdornment,
      isError,
      ...props
    },
    ref
  ): JSX.Element => {
    const classes = clsx(
      styles.root,

      styles[`sz-${size}`],
      {
        [styles.isActive]: isActive,
        [styles.isDisabled]: disabled,
        [styles.isError]: isError,
      },
      className
    )

    const childrenArray = Children.toArray(children)
    const firstChild = cloneElement(childrenArray[0] as JSX.Element, {
      id,
      disabled,
    })
    const renderedChildren = useMemo(
      () => [firstChild, ...childrenArray.slice(1)],
      [childrenArray, firstChild]
    )

    return (
      <div
        ref={ref}
        className={classes}
        onClick={handleContainerClick}
        data-testid="text-field-container"
        {...props}
      >
        {startAdornment && (
          <span className={clsx(styles.startAdornment, 'start-adornment')}>
            {startAdornment}
          </span>
        )}
        <span className={styles.field}>
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>

          <span className={styles.input}>{renderedChildren}</span>
        </span>
        <span className={clsx(styles.endAdornment, 'end-adornment')}>
          {isError && <AlertCircle className={styles.endAdornmentError} />}
          {isClearable && (
            <button
              aria-label="Clear selected item"
              title="Clear selected item"
              type="button"
              onClick={handleClear}
              onKeyPress={handleClear}
              className={styles.clearInputAdornment}
            >
              <CloseIcon />
            </button>
          )}
          {endAdornment}
        </span>
      </div>
    )
  }
)

export default InputRoot
