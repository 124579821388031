import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { approvalReducer } from 'components/features/Approval'
import { oAuthApi } from 'components/features/Auth/api'
import oAuthReducer from 'components/features/Auth/api/oAuthSlice'
import { documentApi } from 'components/features/Documents/api/documentsApi'
import replaceDocumentReducer from 'components/features/Documents/api/replaceDocumentSlice'
import {
  filesApi,
  metaApi,
  scopesApi,
  uiReducer,
} from 'components/features/Other/api'
import { permissionsReducer } from 'components/features/Other/api/permissionsSlice'
import { practicesApi } from 'components/features/Practices/api'
import { currentPracticeReducer } from 'components/features/Practices/api/currentPracticeSlice'
import { searchApi } from 'components/features/Search/api'
import { uploadApi } from 'components/features/Upload/api/docUploadApi'
import { docUploadReducer } from 'components/features/Upload/api/docUploadSlice/docUploadSlice'
import { userApi } from 'components/features/User/api'

import { announcementsApi } from '../components/features/Dashboard/api/announcementsApi'
import { minimalPracticeReducer } from '../components/features/Practices/api/minimalPracticeSlice'
import { practiceReducer } from '../components/features/Practices/api/practiceSlice'

export const rootReducer = combineReducers({
  oauth: oAuthReducer,
  ui: uiReducer,
  docsUpload: docUploadReducer,
  practice: practiceReducer,
  minimalPractice: minimalPracticeReducer,
  currentPractice: currentPracticeReducer,
  approval: approvalReducer,
  replaceDocument: replaceDocumentReducer,
  permissions: permissionsReducer,

  /**
   * API Reducers
   */
  [oAuthApi.reducerPath]: oAuthApi.reducer,
  [metaApi.reducerPath]: metaApi.reducer,
  [documentApi.reducerPath]: documentApi.reducer,
  [practicesApi.reducerPath]: practicesApi.reducer,
  [scopesApi.reducerPath]: scopesApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [filesApi.reducerPath]: filesApi.reducer,
  [uploadApi.reducerPath]: uploadApi.reducer,
  [announcementsApi.reducerPath]: announcementsApi.reducer,
})

export const apiMiddleware = [
  oAuthApi.middleware,
  metaApi.middleware,
  documentApi.middleware,
  practicesApi.middleware,
  scopesApi.middleware,
  userApi.middleware,
  searchApi.middleware,
  filesApi.middleware,
  announcementsApi.middleware,
  uploadApi.middleware,
]

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
})

export default store

setupListeners(store.dispatch)
