import { useEffect, useRef, useState } from 'react'

import clsx from 'clsx'

import styles from './ButtonPopover.module.scss'
import CloseIcon from '../../../assets/Icons/CloseIcon'
import Button from '../Button/Button'
import { Popover } from '../Popover'

export interface ButtonPopoverProps {
  id?: string
  buttonLabel?: string
  buttonProps?: Pick<
    React.ComponentProps<typeof Button>,
    'variant' | 'size' | 'children' | 'icon' | 'onClick' | 'color' | 'className'
  >
  anchorOrigin?: React.ComponentProps<typeof Popover>['anchorOrigin']
  transformOrigin?: React.ComponentProps<typeof Popover>['transformOrigin']
  className?: string
  rootClassName?: string
  open?: boolean
  updateOpen?: (isOpen?: boolean) => void

  children: React.ReactNode
}

const ButtonPopover = ({
  children,
  buttonProps,
  id,
  className,
  anchorOrigin,
  transformOrigin,
  open: propOpen,
  updateOpen,
  buttonLabel,
  rootClassName = '',
}: ButtonPopoverProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const btnRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (typeof propOpen === 'boolean') {
      setOpen(propOpen)
    }
  }, [propOpen])

  const handleBtnClick = (evt: React.MouseEvent) => {
    evt.preventDefault()
    evt.stopPropagation()

    if (buttonProps && buttonProps.onClick) {
      buttonProps.onClick(evt as any)
    } else {
      setOpen(true)
    }
  }

  const handleDismiss = () => {
    if (updateOpen) {
      updateOpen()
    }

    if (typeof propOpen !== 'boolean') {
      setOpen(false)
    }
  }

  let css = className
  if (buttonProps?.color === 'warning') {
    css = clsx(
      styles.text,
      styles['co-dark'],
      styles['po-top'],
      styles['ba-center'],
      className
    )
  }

  return (
    <div className={clsx(styles.root, rootClassName)}>
      <Button
        {...buttonProps}
        ref={btnRef}
        onClick={handleBtnClick}
        type="button"
        aria-expanded={open}
        aria-haspopup="dialog"
        aria-controls={id}
      >
        {buttonLabel}
      </Button>
      <Popover
        open={open}
        onClose={handleDismiss}
        anchorEl={btnRef.current}
        id={id}
        className={css}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <button
          aria-label="Close Popover"
          className={styles.closeButton}
          onClick={handleDismiss}
        >
          <CloseIcon />
        </button>
        {children}
      </Popover>
    </div>
  )
}

export default ButtonPopover
