import { SessionStorageItem } from 'constants/storage-constants'

import { Outlet, useParams } from 'react-router-dom'

import {
  DetailsLayout as Layout,
  PracticeHeader,
  PracticeInformation,
} from '../../components'
import { useFrontOfficeParamRevisionStatus } from '../../hooks/useFrontOfficeParamRevisionStatus'
import { useMinimalPractice } from '../../hooks/useMinimalPractice'

const FrontOfficePractice = (): JSX.Element => {
  const { id = '' } = useParams()

  useFrontOfficeParamRevisionStatus(id)
  useMinimalPractice(id)

  let prevPath: Location | string | null = sessionStorage.getItem(
    SessionStorageItem.PREV_LOC
  )
  if (
    prevPath !== 'undefined' &&
    prevPath !== undefined &&
    typeof prevPath === 'string'
  ) {
    prevPath = JSON.parse(prevPath) as Location
  }
  const backLink =
    prevPath !== 'undefined' && !prevPath?.pathname.includes('search')
      ? '/'
      : getSearchLocation()

  /**
   * Breadcrums müssen Hauptmenü Punkte abbilden mit dem ersten Element
   * Back-Button soll in den Breadcrumbs einen Schritt nach oben gehen
   *
   * Navigation Stack mit n Einträgen, die die aktuellen breadcrumbs abbilden:
   * 1. Main Nav Page
   * 2. Subpage
   */

  return (
    <Layout backLink={backLink} header={<PracticeHeader />}>
      <PracticeInformation />
      <Outlet />
    </Layout>
  )
}

export default FrontOfficePractice

const getSearchLocation = () => {
  let prevLoc: any = sessionStorage.getItem(SessionStorageItem.PREV_LOC)

  try {
    return JSON.parse(prevLoc)
  } catch (error) {
    return { pathname: '/' }
  }
}
