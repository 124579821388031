import { createSlice } from '@reduxjs/toolkit'
import { Revision } from 'redux-store/models'
import { Payload, ReduxRootState } from 'types'

interface State {
  currentRevision: null | Revision
}

const initialState: State = {
  currentRevision: null,
}

export const currentPracticeSlice = createSlice({
  name: 'currentPractice',
  initialState,
  reducers: {
    setCurrentRevision: (state, { payload }: Payload<null | Revision>) => {
      state.currentRevision = payload
    },
  },
})

export const { setCurrentRevision } = currentPracticeSlice.actions

export const currentPracticeReducer = currentPracticeSlice.reducer

export const selectCurrentRevision = (state: ReduxRootState) =>
  state.currentPractice.currentRevision
