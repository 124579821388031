import { capitalizeFirstLetter } from 'helpers'

export const storyTitle = (
  elemName: string,
  type:
    | 'Components'
    | 'Pages'
    | 'Forms'
    | 'Features'
    | 'Templates' = 'Components'
) => {
  return `${capitalizeFirstLetter(type)}/${capitalizeFirstLetter(elemName)}`
}

export const disableStoryArgs = <T extends object>(...args: Array<keyof T>) => {
  const defaultDisabled = ['children', 'className', 'data-testid']

  return [...defaultDisabled, ...args].reduce((acc, field) => {
    return {
      ...acc,
      [field]: {
        table: {
          disable: true,
        },
      },
    }
  }, {})
}
