import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { createDraftUploadFile } from 'helpers/application-helpers/createDraftUploadFile'
import { FileInformation } from 'redux-store/models'
import { Payload, ReduxRootState, UploadFile } from 'types'

import { DocumentsFormValues } from '../components/DocumentsForm/types'

type FileData = {
  id: string
  file?: FileInformation
}

type FormStep = 'upload-file' | 'metadata'

interface ReplaceDocumentState {
  currentFile: FileData | null
  newFile: UploadFile | null
  formValues: Partial<DocumentsFormValues> | null
  initialFormValues: Partial<DocumentsFormValues> | null
  currentStep: FormStep
  isFormDirty: boolean
}

const initialState: ReplaceDocumentState = {
  currentFile: null,
  newFile: null,
  formValues: null,
  initialFormValues: null,
  isFormDirty: false,
  currentStep: 'upload-file',
}

const replaceDocumentSlice = createSlice({
  initialState,
  name: 'replaceDocument',
  reducers: {
    setCurrentFile: (state, { payload }: Payload<FileData | null>) => {
      state.currentFile = payload
    },
    setNewFile: (state, { payload }: Payload<File>) => {
      state.newFile = createDraftUploadFile(payload)
    },
    updateNewFile: (state, action: PayloadAction<Partial<UploadFile>>) => {
      if (state.newFile) {
        state.newFile = {
          ...state.newFile,
          ...action.payload,
        }
      }
    },
    deleteNewFile: (state) => {
      state.newFile = null
    },
    setStep: (state, { payload }: Payload<FormStep>) => {
      state.currentStep = payload
    },
    updateFormValues: (
      state,
      action: PayloadAction<Partial<DocumentsFormValues>>
    ) => {
      state.formValues = action.payload
    },
    updateInitFormValues: (
      state,
      action: PayloadAction<Partial<DocumentsFormValues>>
    ) => {
      state.initialFormValues = action.payload
    },
    setFormDirty: (state, action: PayloadAction<boolean>) => {
      state.isFormDirty = action.payload
    },
    resetForm: () => initialState,
  },
})

const replaceDocumentReducer = replaceDocumentSlice.reducer

export const replaceDocumentSelectors = {
  selectStep: (state: ReduxRootState) => state.replaceDocument.currentStep,
  selectReplaceDocument: (state: ReduxRootState) => state.replaceDocument,
  selectNewFile: (state: ReduxRootState) => state.replaceDocument.newFile,
}

export const replaceDocumentActions = replaceDocumentSlice.actions
export default replaceDocumentReducer
