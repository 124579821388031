import { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { useAppDispatch } from '../../../../hooks'
import { Practice } from '../../../../redux-store/models'
import { practicesApi } from '../api'
import { setMinimalPractice } from '../api/minimalPracticeSlice'

export const useMinimalPractice = (practiceId?: string) => {
  const dispatch = useAppDispatch()
  const { id } = useParams()

  const fetchedId = practiceId || id || ''
  const httpGetPractice = practicesApi.useGetMinimalPracticeByIdQuery(
    fetchedId,
    {
      skip: !fetchedId,
    }
  )

  useEffect(() => {
    let practice: null | Practice = null

    if (httpGetPractice.isSuccess) {
      practice = httpGetPractice.data.data
    }

    dispatch(setMinimalPractice(practice))

    return () => {
      dispatch(setMinimalPractice(null))
    }
  }, [dispatch, httpGetPractice.data, httpGetPractice.isSuccess])
}
