import clsx from 'clsx'
import { autoElementId } from 'helpers'

import styles from './Radio.module.scss'
import { RadioProps } from './Radio.types'

const Radio = ({
  id,
  size = 'medium',
  className,
  disabled,
  ...props
}: RadioProps): JSX.Element => {
  const classes = clsx(
    styles.root,
    styles[`sz-${size}`],
    {
      [styles.isDisabled]: disabled,
    },
    className
  )

  const elemId = autoElementId('Radio')(id)

  return (
    <span className={classes}>
      <input
        type="radio"
        id={elemId}
        className={styles.input}
        disabled={disabled}
        {...props}
      />
      <label
        htmlFor={elemId}
        className={styles.box}
        aria-hidden={true}
        data-testid={'radio-fancy-box'}
      />
    </span>
  )
}

export default Radio
