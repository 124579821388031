import { Permissions } from 'constants/application-constants'
import LogCodes from 'constants/enums/logCodes'

import { selectAuthIsAuthenticated } from 'components/features/Auth/api'
import Logger from 'helpers/logger'
import { useAppSelector, usePermissions } from 'hooks'
import { Navigate, useParams } from 'react-router-dom'

interface ProtectedRouteProps {
  children: React.ReactElement

  /**
   * @param {boolean} isNonAuthOnly - Checks if the route should only be accessible when the user is unauthenticated
   * @example '/login' page should not be accessible to an authenticated user
   */
  isNonAuthOnly?: boolean

  /**
   * @param {string[]} permissionsRequired - if passed will check if the current logged in user has the required permissions to access the route or not
   */
  permissionsRequired?: Permissions[]
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  isNonAuthOnly,
  permissionsRequired,
}): React.ReactElement => {
  const isAuthenticated = useAppSelector(selectAuthIsAuthenticated)
  const { id, documentId } = useParams()
  const { mixed: userPermissions } = usePermissions(
    id ?? documentId,
    !isAuthenticated
  )

  if (!isAuthenticated && !isNonAuthOnly) {
    return <Navigate to={'/sso'} />
  }

  if (isAuthenticated && isNonAuthOnly) {
    return <Navigate to={'/'} />
  }

  const enforcePermissions = Array.isArray(permissionsRequired)

  if (enforcePermissions && userPermissions) {
    if (!hasPermissions(userPermissions, permissionsRequired)) {
      Logger.monitor(LogCodes.ERR_DISALLOWED_ACCESS, {
        userPermissions,
        permissionsRequired,
      })

      return <Navigate to={'/forbidden'} state={{ backgroundLocation: '/' }} />
    }
  }

  return children
}

function hasPermissions(
  permissionsMap: Record<Permissions, boolean>,
  permissionsRequired: Permissions[]
): boolean {
  for (let i = 0; i < permissionsRequired.length; i++) {
    if (!permissionsMap[permissionsRequired[i]]) {
      return false
    }
  }

  return true
}

export default ProtectedRoute
