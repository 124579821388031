import clsx from 'clsx'
import {
  selectIsNavigationOpen,
  setNavigationOpen,
} from 'components/features/Other/api'
import { useAppDispatch, useAppSelector } from 'hooks'
import { Outlet, useLocation } from 'react-router-dom'

import DefaultHeader from './DefaultHeader/DefaultHeader'
import styles from './DefaultLayout.module.scss'
import Navigation from './Navigation/Navigation'

interface DefaultLayoutProps {
  className?: string

  bodyProps?: React.HTMLProps<HTMLDivElement>
  headerProps?: {
    className?: string
  }
}

const HideHeaderPaths = ['/login']

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  className,
  bodyProps,
  headerProps,
}) => {
  const { className: bodyClassName, ...restBodyProps } = bodyProps || {}

  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigationOpen = useAppSelector(selectIsNavigationOpen)

  const classes = clsx(styles.root, className)
  const bodyClasses = clsx(styles.main, bodyClassName)

  const toggleNavigation = () => {
    dispatch(setNavigationOpen(!navigationOpen))
  }

  const showHeader = !HideHeaderPaths.includes(location.pathname)

  return (
    <div className={classes}>
      <Navigation
        isCollapsed={!navigationOpen}
        onCollapseClick={toggleNavigation}
        className={styles.navigation}
      />
      <div className={styles.mainContainer} {...restBodyProps}>
        {showHeader && (
          <DefaultHeader
            className={clsx(styles.header, headerProps?.className)}
          />
        )}
        <main className={bodyClasses}>
          {Boolean(children) ? children : <Outlet />}
        </main>
      </div>
    </div>
  )
}

export default DefaultLayout
