import { Children, cloneElement } from 'react'

import clsx from 'clsx'

import styles from './InputGroup.module.scss'

interface InputGroupProps {
  children: JSX.Element | JSX.Element[]
  className?: string
}

const InputGroup = ({ children, className }: InputGroupProps) => {
  return (
    <div className={clsx(styles.group, className)}>
      {Children.map(children, (child) =>
        cloneElement(child, {
          className: clsx(child.props.className, 'is-input-root'),
        })
      )}
    </div>
  )
}

export default InputGroup
