import React, { Children, cloneElement } from 'react'

import clsx from 'clsx'
import { ArrowDown, ArrowUp } from 'react-feather'

import styles from './Table.module.scss'
import {
  TableBodyProps,
  TableCellProps,
  TableComponents,
  TableHeadProps,
  TableProps,
  TableRowProps,
} from './Table.types'
import SortArrows from '../../../assets/Icons/SortArrows'

const TableHead: React.FC<TableHeadProps> = ({ children, ...rest }) => {
  return <thead {...rest}>{children}</thead>
}
const TableBody: React.FC<TableBodyProps> = ({ children, ...rest }) => {
  return <tbody {...rest}>{children}</tbody>
}

const TableRow: React.FC<TableRowProps> = ({
  children,
  isHeadRow,
  isClickable,
  onClick,
  className,
  ...props
}) => {
  const classes = clsx(
    styles.row,
    {
      [styles.isHeadRow]: isHeadRow,
      [styles.isClickableRow]: isClickable,
    },
    className
  )

  const cellProps = (): TableCellProps => {
    let props: TableCellProps = {}

    if (isHeadRow) {
      props.isHead = true
    }

    return props
  }

  return (
    <tr className={classes} onClick={onClick} {...props}>
      {Children.map(children, (child) =>
        cloneElement(child as React.ReactElement, cellProps())
      )}
    </tr>
  )
}

const TableCell: React.FC<TableCellProps> = ({
  children,
  isHead,
  isLead,
  onClick,
  className,
  innerClassName,
  sortDir,
  sortByThisColumn,
  ...props
}) => {
  const classes = clsx(
    styles.cell,
    {
      [styles.isHeadCell]: isHead,
      [styles.isLeadCell]: isLead,
      [styles.clickableCell]: Boolean(onClick),
      [styles.sortableCell]: Boolean(sortDir),
      [styles.activeSortableCell]: sortByThisColumn,
    },
    className
  )

  const innerClasses = clsx(styles.cellText, innerClassName)

  const CellComponent = isHead ? 'th' : 'td'

  return (
    <CellComponent onClick={onClick} className={classes} {...props}>
      <div className={innerClasses}>
        {children}

        {Boolean(sortDir) === true && sortByThisColumn && (
          <div className={styles.sortArrow}>
            {sortDir === 'asc' ? <ArrowUp /> : <ArrowDown />}
          </div>
        )}
        {sortByThisColumn === false && (
          <div className={styles.sortArrow}>
            <SortArrows />
          </div>
        )}
      </div>
    </CellComponent>
  )
}

const Table: React.FC<TableProps> & TableComponents = ({
  children,
  isFullWidth,
  className,
  ...props
}) => {
  const classes = clsx(
    styles.root,
    {
      [styles.isFullWidth]: isFullWidth,
    },
    className
  )

  return (
    <table className={classes} {...props}>
      {children}
    </table>
  )
}

Table.Head = TableHead
Table.Body = TableBody
Table.Cell = TableCell
Table.Row = TableRow

export default Table
