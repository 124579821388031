import { Location } from 'react-router-dom'

import DetailsHeader from './DetailsHeader'
import styles from './DetailsLayout.module.scss'

interface DetailsLayoutProps {
  children: React.ReactNode
  header: React.ReactNode
  backLink: Location | string
}

const DetailsLayout = ({
  children,
  header,
  backLink,
}: DetailsLayoutProps): JSX.Element => {
  return (
    <div className={styles.root}>
      <DetailsHeader backLink={backLink}>{header}</DetailsHeader>
      <main className={styles.main}>{children}</main>
    </div>
  )
}

export default DetailsLayout
