import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import { logout, setToken } from 'components/features/Auth/api'
import config from 'config'
import { authTokenService } from 'helpers/auth-helpers'
import { ReduxRootState } from 'types'

// Used for solving 'API race condition for token acquisition' problem
const mutex = new Mutex()

const formDataBaseQuery = fetchBaseQuery({
  baseUrl: `${config.API_BASE}`,
  prepareHeaders: (headers, { getState }) => {
    const { accessToken, isAuthenticated } = (getState() as ReduxRootState)
      .oauth

    if (accessToken && isAuthenticated) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }

    /**
     * Webkit creates a FormData Boundary and the correct Content-Type has to be something like this:
     *   multipart/form-data; boundary=----WebKitFormBoundaryXLRrfkvi6sUw3wdS
     * When removing this header it gets set automatically and includes the boundary and then it works.
     */
    headers.set('Accept', 'application/json')

    return headers
  },
})

const baseQuery = fetchBaseQuery({
  baseUrl: `${config.API_BASE}`,
  prepareHeaders: (headers, { getState }) => {
    const { accessToken, isAuthenticated } = (getState() as ReduxRootState)
      .oauth

    if (accessToken && isAuthenticated)
      headers.set('Authorization', `Bearer ${accessToken}`)

    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')

    return headers
  },
})

const fetchRefreshToken = async (api: BaseQueryApi, extraOptions: any) =>
  await baseQuery(
    {
      url: `${config.OAUTH_BASE}/token`,
      method: 'POST',
      body: {
        grant_type: 'refresh_token',
        refresh_token: authTokenService.getRefreshToken(),
        client_id: config.OAUTH_CLIENT_ID,
        client_secret: config.OAUTH_CLIENT_SECRET,
        scope: '',
      },
    } as FetchArgs,
    api,
    extraOptions
  )

export const baseQueryWithReauth: BaseQueryFn = async (
  args,
  api,
  extraOptions
) => {
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const mutexLockRelease = await mutex.acquire()

      try {
        const refreshResult = await fetchRefreshToken(api, extraOptions)

        if (refreshResult.data) {
          api.dispatch(setToken(refreshResult.data))
          result = await baseQuery(args, api, extraOptions)
        } else {
          api.dispatch(logout())
        }
      } finally {
        mutexLockRelease()
      }
    } else {
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}

export const formDataBaseQueryWithReAuth: BaseQueryFn = async (
  args,
  api,
  extraOptions
) => {
  await mutex.waitForUnlock()
  let result = await formDataBaseQuery(args, api, extraOptions)

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const mutexLockRelease = await mutex.acquire()

      try {
        const refreshResult = await fetchRefreshToken(api, extraOptions)

        if (refreshResult.data) {
          api.dispatch(setToken(refreshResult.data))
          result = await formDataBaseQuery(args, api, extraOptions)
        } else {
          api.dispatch(logout())
        }
      } finally {
        mutexLockRelease()
      }
    } else {
      await mutex.waitForUnlock()
      result = await formDataBaseQuery(args, api, extraOptions)
    }
  }

  return result
}
