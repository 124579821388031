import { GuidelineFormValues } from 'components/features/Practices/components/GuidelineForm/types'
import { formatDateForInput } from 'helpers'
import { Revision } from 'redux-store/models'
import { DeepPartial } from 'types'

import { AccessRights } from '../../../../../../constants/application-constants/accessRights'

export const mapRevisionValuesToForm = (
  revision: Revision
): DeepPartial<GuidelineFormValues> => {
  return {
    class: {
      id: revision.practice_class.id,
      label: revision.practice_class.name,
    },
    metadata: revision.metadata.map((item) => ({
      altTopic: extractIds(item.alternative_topics),
      mainTopic: item.main_topic
        ? {
            id: item.main_topic.id,
            label: item.main_topic.name,
          }
        : null,
      certificationScope: extractIds(item.certification_scopes),
      code: item.code,
      collections: extractIds(item.collections),
      countries: item.countries?.map((country) => country.code),

      description: item.description ?? undefined,
      targetGroup: item.target_group ?? undefined,
      departments: extractIds(item.departments),
      effectiveAt: formatDateForInput(item.effective_at),
      externalReferenceLinks: item.external_references,
      internalReferenceLinks: item.internal_references,
      longTitle: item.subject ?? undefined,
      title: item.title,
      keywords: item.keywords?.split(',') ?? undefined,
      language: item.language.code,
      locations: extractIds(item.locations),
      legalEntities: extractIds(item.legal_entities),
      obsoletePractices: item.obsolete_practices,
      contact: item.contact_user
        ? {
            id: item.contact_user.id ?? '',
            label: item.contact_user.name ?? '',
          }
        : null,
      accessRights: AccessRights.find(
        (ar) => ar.id === String(item.access_rights)
      ),
    })),
  }
}

const extractIds = (arr: { id: string; [x: string]: any }[] | null) =>
  arr ? arr.map((item) => item.id) : []
