import { DeepPartial } from 'types'

import { UITheme } from '../types'

const RaiffeisenTheme: DeepPartial<UITheme> = {
  color: {
    primary: '#21517A',
    'primary-bg': '#E3EEF7',
    'primary-dark': '#10293D',
    'primary-light': '#B1C1CE',

    secondary: '#21517A',
    'secondary-bg': '#E3EEF7',
    'secondary-dark': '#10293D',
    'secondary-light': '#B1C1CE',

    tertiary: '#21517A',
    'tertiary-bg': '#E3EEF7',
    'tertiary-dark': '#10293D',
    'tertiary-light': '#B1C1CE',

    accent: '#21517A',
    'accent-bg': '#E3EEF7',
    'accent-dark': '#10293D',
    'accent-light': '#B1C1CE',

    'monochrome-ash': '#2B2D33',
    'monochrome-background': '#F4F4F4',
    'monochrome-body': '#373737',
    'monochrome-input': '#E4E4E4',
    'monochrome-label': '#373737',
    'monochrome-line': '#E4E4E4',
    'monochrome-off-black': '#2B2D33',
    'monochrome-off-white': '#FCFCFC',
    'monochrome-placeholder': '#777779',
    'monochrome-white': '#FFFFFF',
    'monochrome-black': '#000000',
    'monochrome-outline': '#909090',

    'success-dark': '#004600',
    success: '#006F00',
    'success-light': '#8DCB8D',
    'success-bg': '#E2F2E2',

    'warning-dark': '#994600',
    warning: '#FF9439',
    'warning-light': '#FFCDA3',
    'warning-bg': '#FFE6D1',

    'error-dark': '#72182B',
    error: '#BC0848',
    'error-light': '#F5C9D1',
    'error-bg': '#FFF0F3',
  },
}

export default RaiffeisenTheme
