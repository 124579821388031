import { nanoid } from '@reduxjs/toolkit'
import { UploadFile } from 'types'

export const createDraftUploadFile = (file: File): UploadFile => {
  return {
    file,
    key: nanoid(),
    state: 'uploading',
  }
}
