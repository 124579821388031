/**
 *
 * @param num1 {number}
 * @param num2 {number}
 * @returns returns -1 when the width is smaller than the breakpoint and 1 if it's larger
 */
export const numComparator = (num1: number, num2: number): -1 | 0 | 1 => {
  if (num1 === num2) {
    return 0
  }

  return num1 < num2 ? -1 : 1
}

/** Restrict value to be between the range [min = 0, value] */
export const clamp = (value: number, min: number = 0) => Math.max(min, value)

// Check if number is between two values
export const isBetween = (value: number, floor: number, ceil: number) =>
  value >= floor && value <= ceil

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min) // The maximum is exclusive and the minimum is inclusive
}
