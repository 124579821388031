import { Breakpoints, BreakpointsStrings } from 'constants/enums/breakpoints'

import { numComparator } from './num-helpers'

interface Responsive {
  breakpoints: Record<BreakpointsStrings, `${any}px`>
}

export const responsive: Responsive = {
  breakpoints: {
    phone: '375px',
    tabPort: '696px',
    tabLand: '834px',
    laptopSmall: '1024px',
    laptop: '1440px',
    desktop: '1441px',
  },
} as const

export const breakpointsOrdered: BreakpointsStrings[] = [
  'phone',
  'tabPort',
  'tabLand',
  'laptopSmall',
  'laptop',
  'desktop',
]

export const parseBreakpointNum = (breakpoint: Breakpoints) => {
  const bpVal = responsive.breakpoints[breakpoint]

  return parseInt(bpVal.replace('px', ''), 10)
}

/**
 *
 * @param breakpoint
 * @returns {numComparator} returns -1 when the width is smaller than the breakpoint and 1 if it's larger
 *
 */
export const compareBreakpoint =
  (breakpoint: Breakpoints) => (width: number) => {
    const bpVal = parseBreakpointNum(breakpoint)

    return numComparator(width, bpVal)
  }
