import { forwardRef, Fragment } from 'react'

import clsx from 'clsx'
import FocusTrap from 'focus-trap-react'
import { createPortal } from 'react-dom'
import { Testable } from 'types/testable'

import styles from './Background.module.scss'

interface BackgroundProps extends Testable {
  children: React.ReactNode
  open?: boolean
  onClick?: (event?: React.MouseEvent<HTMLDivElement>) => void
  color?: 'transparent' | 'tinted'
  className?: string
  portalRoot?: Element | HTMLElement
  disableFocusTrap?: boolean
}

const Background = forwardRef<HTMLDivElement, BackgroundProps>(
  (
    {
      children,
      onClick,
      open = false,
      color = 'transparent',
      className = '',
      'data-testid': testId,
      portalRoot,
      disableFocusTrap = false,
    },
    ref
  ) => {
    const css = clsx(
      styles.root,
      {
        [styles.isOpen]: open,
      },
      styles[`co-${color}`],
      className
    )

    const FocusWrapper = disableFocusTrap || !open ? Fragment : FocusTrap
    const portalRootAnchor =
      portalRoot || (document.getElementsByTagName('body')[0] as HTMLElement)

    return createPortal(
      <FocusWrapper>
        <div
          ref={ref}
          onClick={onClick}
          className={css}
          aria-hidden={true}
          data-testid={testId}
        >
          {children}
        </div>
      </FocusWrapper>,
      portalRootAnchor
    )
  }
)

export default Background
