import { Card, IconContainer, Typography } from 'components/common'
import { IconProps } from 'react-feather'
import { ColorNames } from 'types'

import styles from './DashboardHeroCard.module.scss'

interface DashboardHeroCardProps {
  icon: IconProps
  title: string
  subtitle: string
  iconColor?: ColorNames
}

const DashboardHeroCard = ({
  icon,
  iconColor,
  title,
  subtitle,
}: DashboardHeroCardProps): JSX.Element => {
  return (
    <Card>
      <Card.Body className={styles.body}>
        <div className={styles.text}>
          <Typography
            className={styles.subtitle}
            type="text"
            fontWeight="medium"
          >
            {subtitle}
          </Typography>
          <Typography
            className={styles.title}
            type="display"
            size="medium"
            fontWeight="bold"
          >
            {title}
          </Typography>
        </div>

        <IconContainer
          icon={icon}
          color={iconColor}
          size="medium"
          className={styles.icon}
          withBackground
        />
      </Card.Body>
    </Card>
  )
}

export default DashboardHeroCard
