import { useCallback, useEffect, useState } from 'react'

const useToggle = (
  initialState: boolean = false
): [boolean, (value?: boolean | undefined) => void] => {
  const [state, setState] = useState<boolean>(initialState)

  useEffect(() => {
    setState(initialState)
  }, [initialState])

  /**
   * @param {boolean} value - overrides toggle and sets state to value
   */
  const toggle = useCallback((value?: boolean): void => {
    if (value !== undefined) {
      setState(value)
    } else {
      setState((state) => !state)
    }
  }, [])
  return [state, toggle]
}

export default useToggle
