import {
  Permissions,
  PracticeQueryParams,
} from 'constants/application-constants'
import LocationValidationState from 'constants/enums/LocationValidationState'

import {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { X } from 'assets/Icons'
import {
  Button,
  LoadingIndicator,
  ScrollTracker,
  Typography,
} from 'components/common'
import { userApi } from 'components/features/User/api'
import { getUnknownProps } from 'helpers'
import { useAppSelector, useGenericAPIError, usePermissions } from 'hooks'
import { t } from 'i18next'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import styles from './EditGuideline.module.scss'
import {
  getBackgroundLocation,
  mapFormValuesToBody,
  mapRevisionValuesToForm,
} from './utils'
import { practicesApi } from '../../api'
import { GuidelineFormValues } from '../../components/GuidelineForm/types'
import { useParamRevisionStatus } from '../../hooks/useParamRevisionStatus'

const GuidelineForm = lazy(
  () => import('../../components/GuidelineForm/GuidelineForm')
)

const ID_PREFIX = 'edit-guideline-'

const EditGuideline = () => {
  const titleRef = useRef<HTMLDivElement>(null)

  const [formValid, setFormValid] = useState(false)

  const { currentRevision } = useAppSelector((state) => state.currentPractice)
  const { id: practiceId = '' } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const showErrorToast = useGenericAPIError()
  const { mixed: allPermissions } = usePermissions(practiceId)
  const [searchParams] = useSearchParams()
  useParamRevisionStatus(false, practiceId)

  const validateOnMount = !!getUnknownProps(
    location.state,
    LocationValidationState.VALIDATE_ON_MOUNT
  )
  const validationLevel = getUnknownProps(
    location.state,
    LocationValidationState.VALIDATION_LEVEL
  )
  const hasStrictValidation =
    typeof validationLevel === 'string' && validationLevel === 'strict'

  const backgroundLink = getBackgroundLocation(location, practiceId)

  const closePage = useCallback(() => {
    navigate(backgroundLink)
  }, [backgroundLink, navigate])

  useEffect(() => {
    if (allPermissions && !allPermissions[Permissions.UPDATE_PRACTICE]) {
      closePage()
    }
  }, [allPermissions, closePage])

  useEffect(() => {
    if (!searchParams.get(PracticeQueryParams.REVISION_STATUS)) {
      closePage()
      return
    }
  }, [closePage, searchParams])

  const [httpGetPractice] = practicesApi.useLazyGetPracticeByIdQuery()
  const [httpUpdateGuideline] =
    practicesApi.useUpdateMetadataByPracticeMutation()
  const { data: user, isSuccess: userApiSuccess } =
    userApi.endpoints.getCurrentUser.useQueryState(null)
  const companyLanguage = user
    ? user.data.customer?.company_language ?? user.data.language ?? 'en'
    : 'en'

  const defaultFormValue = useMemo(() => {
    if (!currentRevision) {
      return {
        metadata: [{ language: companyLanguage }],
      }
    }

    return mapRevisionValuesToForm(currentRevision)
  }, [companyLanguage, currentRevision])

  const scrollTargetData = [
    { id: ID_PREFIX + 'general', label: t('metadata.general') },
    { id: ID_PREFIX + 'scope', label: t('metadata.scope') },
    { id: ID_PREFIX + 'topic', label: t('metadata.topic') },
    { id: ID_PREFIX + 'references', label: t('metadata.references') },
    { id: ID_PREFIX + 'contact', label: t('metadata.contact') },
  ]

  const scrollOffsetHeight = titleRef.current
    ? titleRef.current.getBoundingClientRect().bottom
    : 200

  const updateFormValidity = (valid?: boolean) => {
    setFormValid((prev) => (typeof valid === 'boolean' ? valid : !prev))
  }

  const handleSubmit = async (data: GuidelineFormValues) => {
    const payload = mapFormValuesToBody(data)

    try {
      await httpUpdateGuideline({
        practiceId,
        data: payload,
      }).unwrap()
      await httpGetPractice(practiceId).unwrap()
      closePage()
    } catch (error) {
      showErrorToast()
    }
  }

  return (
    <div className={styles.root}>
      <aside className={styles.aside}>
        <Button
          className={styles.closeBtn}
          variant="subtle"
          size="small"
          icon={<X />}
          aria-label={t('editGuideline.closeBtnAria') ?? ''}
          onClick={closePage}
          as="button"
        />
        <nav className={styles.nav}>
          <Typography
            element="h4"
            color="label"
            fontWeight="bold"
            className={styles.title}
          >
            {t('editGuideline.trackerTitle')}
          </Typography>
          <ScrollTracker
            activationHeight={scrollOffsetHeight + 10}
            targetData={scrollTargetData}
          />
        </nav>
      </aside>
      <main className={styles.main}>
        <header ref={titleRef} className={styles.header}>
          <Typography element="h1" type="display" fontWeight="bold">
            {t('editGuideline.title')}
          </Typography>
          <Typography element="p" color="label">
            {t('editGuideline.subtitle')}
          </Typography>
        </header>
        {userApiSuccess && !!currentRevision ? (
          <Suspense fallback={<LoadingIndicator />}>
            <GuidelineForm
              validationLevel={hasStrictValidation ? 'strict' : 'lax'}
              validateOnMount={validateOnMount}
              onSubmit={handleSubmit}
              onValidityChange={updateFormValidity}
              titleIdPrefix={ID_PREFIX}
              defaultValues={defaultFormValue}
              defaultLanguage={companyLanguage}
            />
            <footer className={styles.footer}>
              <Button type="button" variant="text" onClick={closePage}>
                {t('editGuideline.abort')}
              </Button>
              <Button
                type="submit"
                form={ID_PREFIX + 'form'}
                disabled={!formValid}
              >
                {t('editGuideline.submit')}
              </Button>
            </footer>
          </Suspense>
        ) : (
          <LoadingIndicator />
        )}
      </main>
    </div>
  )
}

export default EditGuideline
