import { Location } from 'react-router-dom'

import styles from './DetailsHeader.module.scss'

const DetailsHeader: React.FC<{ backLink: Location | string }> = ({
  children,
}): JSX.Element => {
  // const navigate = useNavigate()

  return (
    <header className={styles.root}>
      {/*<Button*/}
      {/*  icon={<ArrowLeft />}*/}
      {/*  variant="text"*/}
      {/*  size="small"*/}
      {/*  aria-label="Back"*/}
      {/*  onClick={() => navigate(-1)}*/}
      {/*/>*/}
      {children}
    </header>
  )
}

export default DetailsHeader
