import clsx from 'clsx'

import styles from './Typography.module.scss'
import { TypographyProps } from './Typography.types'

const Typography: React.VFC<TypographyProps> = ({
  children,
  className,
  type = 'text',
  fontWeight = 'regular',
  size = 'medium',
  element: Wrapper = 'p',
  color = 'basic',
  ...rest
}) => {
  const classes = clsx(
    styles[type],
    styles[`sz-${size}`],
    styles[`fw-${fontWeight}`],
    styles[`co-${color}`],
    className
  )

  return (
    <Wrapper className={classes} {...rest}>
      {children}
    </Wrapper>
  )
}

export default Typography
