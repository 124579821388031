import React, { useEffect, useRef } from 'react'

import clsx from 'clsx'
import { useSelect } from 'downshift'
import { ChevronUp } from 'react-feather'

import styles from './Select.module.scss'
import { SelectProps } from './Select.types'
import InputRoot from '../InputRoot/InputRoot'
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator'
import SelectDropdown from '../SelectDropdown/SelectDropdown'

const Select = ({
  options,
  label,
  defaultValue,
  parentSelectedValue,
  onSelectedItemChange,
  initialSelectedItem,
  size,
  disabled,
  isLoading,
  isError,
  onFocus,
  onBlur,
  onIsOpenChange,
  className,
  dropdownPosition = 'bottom',
}: SelectProps): JSX.Element => {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    selectedItem,
    getItemProps,
    toggleMenu,
    selectItem,
  } = useSelect({
    items: options,
    itemToString: (item) => (item ? item.label : ''),
    onSelectedItemChange: ({ selectedItem }) => {
      if (onSelectedItemChange) {
        onSelectedItemChange(selectedItem)
      }
    },
    initialSelectedItem,
    onIsOpenChange,
  })

  useEffect(() => {
    if (parentSelectedValue) {
      selectItem(parentSelectedValue)
    }
  }, [parentSelectedValue, selectItem])

  const menuRef = useRef<HTMLUListElement>(null)
  const menuProps = getMenuProps({
    ref: menuRef,
    onBlur: onBlur,
  })
  const toggleButtonProps = getToggleButtonProps({
    onBlur,
    onFocus,
    onClick: (event) => {
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
    },
  })

  const dropdownHandleOnClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (!disabled || !isLoading) {
      toggleMenu()
    }
  }

  const selectedItemLabel = selectedItem ? selectedItem.label : null
  const displayedValue = selectedItemLabel ?? defaultValue
  const dropdownHandle = (
    <span
      onClick={dropdownHandleOnClick}
      className={clsx(styles.handleIcon, {
        [styles.handleIconOpen]: isOpen,
      })}
    >
      {isLoading ? <LoadingIndicator /> : <ChevronUp />}
    </span>
  )

  return (
    <InputRoot
      label={label}
      className={clsx(styles.root, className)}
      startAdornment={selectedItem?.startAdornment}
      endAdornment={dropdownHandle}
      handleContainerClick={toggleMenu}
      isActive={Boolean(displayedValue)}
      size={size}
      disabled={disabled || isLoading}
      isError={isError}
    >
      <div
        tabIndex={0}
        className={clsx(styles.button)}
        disabled={disabled || isLoading}
        {...toggleButtonProps}
      >
        {displayedValue}
      </div>

      <SelectDropdown
        highlightedIndex={highlightedIndex}
        options={options}
        isOpen={isOpen}
        selectedItem={selectedItem}
        getItemProps={getItemProps}
        menuProps={menuProps}
        position={dropdownPosition}
      />
    </InputRoot>
  )
}

export default Select
