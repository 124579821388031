import { HTMLAttributes } from 'react'

import clsx from 'clsx'

import styles from './Accordion.module.scss'

export interface AccordionProps extends HTMLAttributes<HTMLUListElement> {
  /**
   * Specify an optional className to be applied to
   * the container node.
   */
  className?: string
}

const Accordion = ({ className, children, ...props }: AccordionProps) => {
  const css = clsx(styles.root, className)
  return (
    <ul className={css} {...props}>
      {children}
    </ul>
  )
}

export default Accordion
