const Redo = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.22255 5.76391H15.6844L13.1934 3.27363L14.167 2.29169L18.3337 6.45835L14.167 10.625L13.1934 9.64238L15.6823 7.1528H7.22255C6.11748 7.1528 5.05767 7.59178 4.27627 8.37319C3.49487 9.15459 3.05588 10.2144 3.05588 11.3195C3.05588 12.4245 3.49487 13.4843 4.27627 14.2657C5.05767 15.0471 6.11748 15.4861 7.22255 15.4861H12.7781V16.875H7.22255C5.74912 16.875 4.33605 16.2897 3.29418 15.2478C2.25231 14.206 1.66699 12.7929 1.66699 11.3195C1.66699 9.84604 2.25231 8.43296 3.29418 7.39109C4.33605 6.34922 5.74912 5.76391 7.22255 5.76391V5.76391Z"
      fill="currentColor"
    />
  </svg>
)

export default Redo
