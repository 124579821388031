import { nanoid } from '@reduxjs/toolkit'
import clsx from 'clsx'
import { Check } from 'react-feather'

import styles from './Checkbox.module.scss'
import { CheckboxProps } from './Checkbox.types'

const Checkbox = ({
  id,
  size = 'medium',
  className,
  disabled,
  ...props
}: CheckboxProps) => {
  const rootClasses = clsx(
    styles.root,
    {
      [styles.isDisabled]: disabled,
    },
    styles[`sz-${size}`],
    className
  )

  const elemId = id ? id : nanoid()
  const labelledById = `${elemId}-label`

  return (
    <div className={rootClasses}>
      <input
        disabled={disabled}
        id={id}
        type="checkbox"
        className={styles.input}
        aria-labelledby={labelledById}
        {...props}
      />
      <label
        htmlFor={elemId}
        className={styles.box}
        aria-hidden={true}
        data-testid={'checkbox-fancy-box'}
      >
        <Check className={styles.checkIcon} />
      </label>
    </div>
  )
}

export default Checkbox
