import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'redux-store/services/services.utils'

import { UploadDocumentsRequest, UploadDocumentsResponse } from './types'

export const uploadApi = createApi({
  reducerPath: 'uploadApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    uploadDocuments: builder.mutation<
      UploadDocumentsResponse,
      {
        practiceId: string
        data: UploadDocumentsRequest
      }
    >({
      query: ({ data, practiceId }) => ({
        url: `/practices/${practiceId}/upload/metadata`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
})
