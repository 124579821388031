import { Component, ErrorInfo, ReactNode } from 'react'

import Logger from 'helpers/logger'

import styles from './ErrorBoundary.module.scss'
import Button from '../Button/Button'
import Section from '../Templates/Section/Section'
import Typography from '../Typography/Typography'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
  error: string | null
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
  }

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.message }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Logger.error('Uncaught error:', error, errorInfo)
  }

  handleGoToHome = () => {
    window.location.href = '/'
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className={styles.root}>
          <Section>
            <Typography fontWeight="bold" color="primary">
              An error has occurred
            </Typography>
            <Typography type="display" size="x-large" fontWeight="bold">
              {this.state.error}
            </Typography>

            <div className={styles.actions}>
              <Button onClick={this.handleGoToHome}>Take me home</Button>
            </div>
          </Section>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
