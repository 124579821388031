import { DeepPartial } from 'types'

import { UITheme } from './types'

export const setUITheme = (theme: DeepPartial<UITheme>) => {
  if ('color' in theme && typeof theme.color === 'object') {
    Object.entries(theme.color).forEach(([varName, varValue]) => {
      const cssVar = prefixCssVar(varName, 'color')

      if (typeof varValue === 'string') {
        cssVariable.set(cssVar, varValue)
      }
    })
  }
}

const cssVariable = {
  get(varName: string, elem?: HTMLHtmlElement | Element | HTMLElement | null) {
    if (!elem) {
      elem = document.querySelector('html') as HTMLHtmlElement
    }

    const styles = getComputedStyle(elem)

    const cssVar = validCSSVar(varName)

    if (styles) {
      return styles.getPropertyValue(cssVar)
    }
  },
  set(varName: string, varValue: string = '') {
    const cssVar = validCSSVar(varName)

    document.documentElement.style.setProperty(cssVar, varValue)
  },
}

const validCSSVar = (varName: string): string => {
  if (isValidCssVar(varName)) {
    return varName
  }
  return prefixCssVar(varName)
}

const isValidCssVar = (cssVar: string): boolean => {
  if (!cssVar) return false

  if (cssVar.slice(0, 2) !== '--') return false

  return true
}

const prefixCssVar = (simpleVarName: string, prefix: string = ''): string => {
  if (!prefix) {
    return `--${simpleVarName}`
  }

  return `--${prefix}-${simpleVarName}`
}
