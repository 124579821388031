import { useEffect, useMemo, useState } from 'react'

import { Upload } from 'assets/Icons'
import {
  Button,
  Card,
  DefaultLayout,
  LoadingIndicator,
  PageTitle,
  TextField,
} from 'components/common'
import { t } from 'i18next'
import { Outlet, useNavigate } from 'react-router-dom'

import NewDropdownButton from './NewDropdownButton'
import styles from './Practices.module.scss'
import { Practice } from '../../../../../redux-store/models'
import { practicesApi } from '../../api'
import PracticesTable from '../../components/PracticesTable/PracticesTable'

const SHOW_SEARCH_LIMIT = 10
const PAGINATION_PER_PAGE = 50

const PracticesPage = (): JSX.Element => {
  const [search, setSearch] = useState('')
  const [nextPage, setNextPage] = useState<number>(1)
  const [practices, setPractices] = useState<Practice[]>([])
  const [fetchAllPracticesTrigger, lastResult] =
    practicesApi.useLazyGetAllPracticesQuery()
  const navigate = useNavigate()

  useEffect(() => {
    fetchAllPracticesTrigger({
      isBackOffice: true,
      page: nextPage,
      limit: PAGINATION_PER_PAGE,
    }).then(({ isSuccess, data }) => {
      // on success add the practice data to the rendered data once
      if (isSuccess && data?.meta.current_page === nextPage) {
        setPractices((practices) => [...practices, ...data.data])
      }

      // on success, if we are not on the last page, trigger this effect again by increasing `nextPage`
      if (
        isSuccess &&
        data?.meta.last_page &&
        data?.meta.last_page > nextPage
      ) {
        setNextPage(nextPage + 1)
      }
    })
  }, [nextPage, fetchAllPracticesTrigger])

  const filteredSearches = useMemo(() => {
    if (!search) {
      return practices
    }

    return practices.filter((practice) =>
      practice.latest_displayable_revision?.metadata[0]?.title
        .toLowerCase()
        .includes(search.toLowerCase())
    )
  }, [practices, search])

  const navigateUpload = () => {
    navigate('/upload')
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  return (
    <DefaultLayout className="has-bg-hero">
      <Outlet />
      <PageTitle
        title={t('practices.title')}
        btnGroup={
          <div className={styles.actions}>
            <Button
              icon={<Upload />}
              variant="secondary"
              onClick={navigateUpload}
            >
              {t('practices.upload')}
            </Button>
            <NewDropdownButton />
          </div>
        }
      >
        {practices.length >= SHOW_SEARCH_LIMIT && (
          <TextField
            label={t('practices.search')}
            id="filter-practices"
            size="small"
            value={search}
            onChange={handleSearchChange}
            isClearable
          />
        )}
      </PageTitle>
      <Card hasTable>
        <Card.Body>
          {practices.length >= 1 && (
            <PracticesTable navigateToBackOffice practices={filteredSearches} />
          )}
          {Boolean(lastResult.isLoading) ? <LoadingIndicator /> : null}
        </Card.Body>
      </Card>
    </DefaultLayout>
  )
}
export default PracticesPage
