import clsx from 'clsx'
import { Avatar, Typography } from 'components/common'
import { formatDistance } from 'date-fns'
import { Comment as CommentType } from 'types'

import styles from './Comment.module.scss'

interface CommentProps {
  comment: CommentType
}

const Comment = ({ comment }: CommentProps): JSX.Element => {
  const classes = clsx(styles.root)
  const { content, created_at, author } = comment

  return (
    <div className={classes}>
      <Avatar content={author.initials} className={styles.avatar} />
      <div className={styles.content}>
        <div className={styles.userInfo}>
          <Typography fontWeight="medium" size="small" element="span">
            {author.name}
          </Typography>
          {created_at && (
            <Typography size="small" color="label" element="span">
              {formatDistance(new Date(created_at), new Date(), {
                addSuffix: true,
              })}
            </Typography>
          )}
        </div>
        <Typography className={styles.content} size="small">
          {content}
        </Typography>
      </div>
    </div>
  )
}

export default Comment
