const NewPackageIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4678 1.6772C11.3507 1.63136 11.2206 1.63136 11.1034 1.6772L6.17484 3.60577L1.24627 5.53434C1.05459 5.60935 0.928467 5.79414 0.928467 5.99996V18C0.928467 18.2058 1.05459 18.3906 1.24627 18.4656L11.1034 22.3227C11.2206 22.3686 11.3507 22.3686 11.4678 22.3227L15.7405 20.6508C15.4819 20.4068 15.2449 20.1401 15.0329 19.8539L11.7856 21.1245V10.1984L16.3964 8.39416L20.6428 6.73253V10.3367C20.9861 10.3816 21.3202 10.4555 21.6428 10.5559V5.99996C21.6428 5.79414 21.5166 5.60935 21.325 5.53434L11.4678 1.6772ZM16.2142 7.39162L7.72916 4.07139L11.2856 2.67974L19.7706 5.99996L16.2142 7.39162ZM10.7856 10.1984V21.1245L1.92847 17.6587V6.73253L10.7856 10.1984ZM11.2856 9.32019L2.80059 5.99996L6.35704 4.60831L14.8421 7.92854L11.2856 9.32019Z"
      fill="currentColor"
    />
    <path
      d="M20 12.25V19.75"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 16H23.75"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default NewPackageIcon
