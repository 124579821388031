import { Suspense } from 'react'

import { ErrorBoundary, LoadingPage, ToastContainer } from 'components/common'
import { selectAuth } from 'components/features/Auth/api'
import { selectAppLoading } from 'components/features/Other/api'
import { ViewBlocker } from 'components/features/Other/components'
import { userApi } from 'components/features/User/api'
import Routing from 'components/pages/Routing'
import { useAppSelector } from 'hooks'

import styles from './App.module.scss'
import {
  useClientTheme,
  useI18nInitial,
  useInitiateUser,
  useUserOnboarding,
} from './hooks'
import { useNavToLanding } from './hooks/useNavToLanding'
import useStoreLocation from './hooks/useStoreLocation'

const App = () => {
  const { isAuthenticated } = useAppSelector(selectAuth)
  const isAppLoading = useAppSelector(selectAppLoading)

  userApi.useGetCurrentUserQuery(null, {
    skip: !isAuthenticated,
  })
  userApi.useGetBasicUserPermissionsQuery(null, {
    skip: !isAuthenticated,
  })

  useClientTheme()
  useInitiateUser()
  useUserOnboarding()
  useI18nInitial()
  useNavToLanding()
  useStoreLocation()

  return (
    <ErrorBoundary>
      <div className={styles.app}>
        {isAppLoading && <LoadingPage />}
        {!isAppLoading && (
          <Suspense fallback={<LoadingPage />}>
            <Routing />
          </Suspense>
        )}
        <ToastContainer />
        <ViewBlocker breakpoint="tab-land" />
      </div>
    </ErrorBoundary>
  )
}

export default App
