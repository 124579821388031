import { useState } from 'react'

import { FileCheckIcon } from 'assets/Icons'
import clsx from 'clsx'
import {
  Badge,
  DropdownItem,
  IconContainer,
  Popover,
  Typography,
} from 'components/common'
import { t } from 'i18next'
import { Check, ChevronDown, Edit2, Eye } from 'react-feather'

import styles from './DocumentRevisionDropdown.module.scss'
import DocRevisionEnum, { DocRevisions } from '../../enums/DocRevision'

interface Props {
  practiceTitle: string

  currentRevision: DocRevisions
  onRevisionSelect: (val: DocRevisions) => void
}

const DocumentRevisionDropdown = ({
  onRevisionSelect,
  practiceTitle,
  currentRevision,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleOpen = (evt: React.MouseEvent) =>
    setAnchorEl(evt.currentTarget as HTMLDivElement)

  const handleClose = () => setAnchorEl(null)

  const handleRevisionSelect = (status: DocRevisions) => () => {
    onRevisionSelect(status)
    handleClose()
  }

  const getCheckedIcon = (status: DocRevisions) => {
    if (currentRevision === status) {
      return <Check />
    }
  }

  return (
    <>
      <Badge rounded color="primary" size="small" onClick={handleOpen}>
        {currentRevision}
        <IconContainer
          icon={<ChevronDown />}
          size="x-small"
          className={clsx(styles.handle, { [styles.handleOpen]: anchorEl })}
        />
      </Badge>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
      >
        <div className={styles.description}>
          <Typography>{t('document.selectRevision')}</Typography>
          <Typography size="x-small" color="label">
            {practiceTitle}
          </Typography>
        </div>
        <div className={styles.options}>
          <DropdownItem
            onClick={handleRevisionSelect(DocRevisionEnum.draft)}
            trailingIcon={getCheckedIcon(DocRevisionEnum.draft)}
            icon={<Edit2 />}
          >
            <Typography size="small">{t('practice.toggleDraft')}</Typography>
          </DropdownItem>
          <DropdownItem
            onClick={handleRevisionSelect(DocRevisionEnum.approval)}
            trailingIcon={getCheckedIcon(DocRevisionEnum.approval)}
            icon={<FileCheckIcon />}
          >
            <Typography size="small">{t('practice.toggleApproval')}</Typography>
          </DropdownItem>
          <DropdownItem
            onClick={handleRevisionSelect(DocRevisionEnum.published)}
            trailingIcon={getCheckedIcon(DocRevisionEnum.published)}
            icon={<Eye />}
          >
            <Typography size="small">
              {t('practice.togglePublished')}
            </Typography>
          </DropdownItem>
        </div>
      </Popover>
    </>
  )
}

export default DocumentRevisionDropdown
