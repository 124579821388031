import { LocalStorageItem } from 'constants/storage-constants'

export const authTokenService = {
  clearTokens: function () {
    localStorage.removeItem(LocalStorageItem.ACCESS_TOKEN)
    localStorage.removeItem(LocalStorageItem.REFRESH_TOKEN)
  },
  getAccessToken: function () {
    return localStorage.getItem(LocalStorageItem.ACCESS_TOKEN)
  },
  getRefreshToken: function () {
    return localStorage.getItem(LocalStorageItem.REFRESH_TOKEN)
  },
  setAccessToken: function (token: string) {
    localStorage.setItem(LocalStorageItem.ACCESS_TOKEN, token)
  },
  setRefreshToken: function (token: string) {
    localStorage.setItem(LocalStorageItem.REFRESH_TOKEN, token)
  },
  getTokens: function () {
    return {
      accessToken: this.getAccessToken(),
      refreshToken: this.getRefreshToken(),
    }
  },
  setTokens: function (accessToken: string, refreshToken: string) {
    this.setAccessToken(accessToken)
    this.setRefreshToken(refreshToken)
  },
}
