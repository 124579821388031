import ClientLogo from './ClientLogo'

export const getLogo = (clientId: string, isCollapsed: boolean = false) => {
  // TODO: replace with an API call at some point

  const response = {
    data: {
      collapsed: <ClientLogo clientId={clientId} collapsed />,
      full: <ClientLogo clientId={clientId} />,
    },
  }

  if (isCollapsed) {
    return response.data.collapsed
  } else {
    return response.data.full
  }
}
