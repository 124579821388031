import { forwardRef } from 'react'

import clsx from 'clsx'
import { Check } from 'react-feather'

import styles from './ListItem.module.scss'

export interface ListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  label: string
  supportText?: string
  description?: string
  startAdornment?: React.ReactNode

  disabled?: boolean
  isSelected?: boolean
  isHighlighted?: boolean
}

const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  (
    {
      label,
      description,
      supportText,
      startAdornment,
      disabled,
      isSelected,
      isHighlighted,
      ...props
    },
    ref
  ): JSX.Element => {
    const classes = clsx(styles.root, {
      [styles.isDisabled]: disabled,
      [styles.isSelected]: isSelected,
      [styles.isHighlighted]: !disabled && isHighlighted,
    })

    return (
      <li
        className={classes}
        role="option"
        aria-selected={isSelected}
        ref={ref}
        {...props}
      >
        {!!startAdornment && (
          <span className={styles.inputAdornment} aria-hidden="true">
            {startAdornment}
          </span>
        )}
        <div className={styles.content}>
          <span className={styles.text}>{label}</span>
          {!!description && (
            <span className={styles.description}>{description}</span>
          )}
        </div>
        {!!supportText && (
          <span className={styles.supportText}>{supportText}</span>
        )}
        {isSelected && (
          <span className={styles.isSelectedIcon} aria-hidden="true">
            <Check />
          </span>
        )}
      </li>
    )
  }
)

export default ListItem
