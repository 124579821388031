import clsx from 'clsx'

import styles from './QibriLogo.module.scss'

interface QibriLogoProps {
  birdOnly?: boolean
  className?: string
  color?: 'standard' | 'black' | 'negative'
  style?: React.CSSProperties
}

const QibriLogo = ({
  birdOnly,
  className,
  color = 'standard',
  style,
}: QibriLogoProps): JSX.Element => {
  const classes = clsx(styles.root, styles[`co-${color}`], className)

  return (
    <svg
      style={style}
      className={classes}
      viewBox={birdOnly ? '0 0 255 239' : '0 0 466 225'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {birdOnly ? (
        <path d="M131.529 57.8702L79.657 10.7354L91.8459 57.8753L131.529 57.8702ZM194.82 59.7721L228.225 52.3324H194.82V59.7721ZM189.383 102.356V51.3052L171.319 44.2418L142.917 60.9722L189.383 102.356ZM115.117 147.904L135.053 63.1894H93.2331L115.117 147.904ZM110.502 151.331L87.72 63.1894H9.74616L110.502 151.331ZM119.391 153.065L187.221 107.807L140 65.5286L119.391 153.065ZM102.852 224.457L181.902 117.738L117.642 160.642L102.852 224.457ZM95.2323 237.943C94.9918 237.633 94.8254 237.273 94.7458 236.89C94.6663 236.506 94.6757 236.11 94.7734 235.731L112.297 159.961L0.912965 62.5486C0.624249 62.3017 0.393003 61.995 0.235339 61.6498C0.0776748 61.3047 -0.00261468 60.9294 6.49299e-05 60.5501C0.00249147 60.165 0.0864249 59.7848 0.246375 59.4343C0.406325 59.0838 0.638688 58.7709 0.928265 58.516C1.4101 58.0986 2.02908 57.8723 2.66736 57.8804H86.3431L72.2671 3.433C72.1349 2.95946 72.1411 2.45816 72.2847 1.98797C72.4283 1.51778 72.7035 1.09821 73.0779 0.778513C73.553 0.361354 74.1589 0.122208 74.7915 0.102179C75.4761 0.0873297 76.1391 0.34117 76.6378 0.809024L138.756 57.2549L169.738 39.0142C170.088 38.8108 170.479 38.6891 170.883 38.6583C171.287 38.6274 171.692 38.6883 172.069 38.8363L192.979 47.0184H252.45C252.786 47.0244 253.118 47.0967 253.426 47.2311C253.734 47.3655 254.013 47.5594 254.245 47.8015C254.673 48.244 254.939 48.8172 255 49.4288C255.08 50.076 254.911 50.7296 254.527 51.2573C254.143 51.785 253.572 52.1475 252.929 52.2714L194.693 65.2489V108.534C194.705 109.205 194.458 109.855 194.004 110.35L99.5112 237.907C99.2771 238.239 98.9674 238.512 98.6076 238.702C98.2477 238.892 97.8478 238.994 97.4407 239H97.41C96.9911 238.996 96.5784 238.899 96.2018 238.716C95.8251 238.533 95.494 238.269 95.2323 237.943Z" />
      ) : (
        <>
          <g>
            <path d="M465.032 78.0664H455.434V87.4268H465.032V78.0664Z" />
            <path d="M465.033 107.402H455.426V195.057H465.033V107.402Z" />
            <path d="M292.282 78.0664H282.684V87.4268H292.282V78.0664Z" />
            <path d="M292.291 107.402H282.684V195.057H292.291V107.402Z" />
            <path d="M422.133 109.475C415.985 112.076 410.739 116.43 407.049 121.992C403.359 127.555 401.388 134.08 401.383 140.755V195.002H410.913V140.721C410.916 137.542 411.545 134.394 412.764 131.458C413.984 128.522 415.77 125.854 418.021 123.609C420.272 121.363 422.944 119.583 425.883 118.369C428.822 117.156 431.972 116.534 435.152 116.539H435.345V106.816C430.804 106.806 426.307 107.71 422.123 109.475" />
            <path d="M381.42 149.09V150.764C381.42 164.596 378.496 175.653 372.647 183.936C372.616 183.971 372.589 184.01 372.565 184.051C367.817 190.715 360.386 194.917 352.298 196.114L351.285 196.259C351.212 196.263 351.14 196.263 351.068 196.259C348.1 196.62 345.099 196.62 342.131 196.259C338.49 195.849 334.933 194.888 331.582 193.407C331.38 193.306 331.215 193.233 331.1 193.171C331.049 193.147 331.001 193.12 330.955 193.089C327.414 191.208 324.314 188.595 321.864 185.422V194.975H312.266V78.0664H321.864V153.336C321.829 157.207 322.206 161.071 322.988 164.863C323.909 169.434 325.597 173.816 327.982 177.822C332.164 184.683 338.405 188.157 346.705 188.244C354.989 188.174 361.232 184.7 365.433 177.822C368.85 172.229 370.892 165.313 371.562 157.075C371.615 156.366 371.668 155.628 371.697 154.899C371.769 153.587 371.803 152.236 371.803 150.861V149.181C371.803 147.652 371.75 146.156 371.644 144.714C371.644 144.231 371.595 143.749 371.562 143.266C370.896 135.546 368.88 129.158 365.515 124.101C361.378 117.903 355.148 114.773 346.826 114.712H331.476V106.148H348.365C348.459 106.143 348.552 106.143 348.645 106.148C351.477 106.281 354.283 106.753 357.003 107.552C359.816 108.389 362.521 109.553 365.062 111.021C365.1 111.036 365.136 111.057 365.168 111.084C368.054 112.961 370.583 115.337 372.638 118.099C378.486 125.819 381.41 136.154 381.41 149.104" />
            <path d="M193.555 153.153V151.503C193.555 137.671 196.479 126.612 202.327 118.326C202.358 118.29 202.386 118.251 202.41 118.21C207.158 111.547 214.589 107.349 222.677 106.148C223.019 106.095 223.362 106.042 223.69 106.003C223.762 105.998 223.835 105.998 223.907 106.003C226.875 105.64 229.876 105.64 232.844 106.003C236.483 106.409 240.039 107.369 243.388 108.85C243.595 108.951 243.759 109.024 243.87 109.086L244.015 109.168C247.554 111.059 250.65 113.68 253.097 116.859V107.287H262.704V224.195H253.102V148.926C253.136 145.056 252.759 141.194 251.977 137.404C251.055 132.832 249.367 128.448 246.983 124.439C242.78 117.578 236.56 114.09 228.26 114.022C219.976 114.09 213.733 117.562 209.532 124.439C206.116 130.036 204.073 136.952 203.404 145.187C203.35 145.901 203.297 146.634 203.264 147.363V147.387C203.196 148.694 203.162 150.045 203.162 151.421V153.1C203.162 154.629 203.215 156.125 203.317 157.568C203.341 158.05 203.37 158.533 203.404 159.015C204.069 166.735 206.085 173.122 209.45 178.175C213.584 184.377 219.812 187.506 228.134 187.564H243.508V196.119H226.619C226.526 196.124 226.432 196.124 226.339 196.119C223.507 195.985 220.702 195.514 217.981 194.715C215.167 193.882 212.462 192.718 209.923 191.246C209.886 191.23 209.85 191.211 209.817 191.188C206.931 189.308 204.401 186.931 202.347 184.167C196.495 176.448 193.571 166.113 193.574 153.162" />
            <path d="M107.672 47.4203L65.2072 8.72893L75.1864 47.4251L107.672 47.4203ZM159.459 48.9835L186.81 42.8752H159.469L159.459 48.9835ZM155.01 83.9548V42.0308L140.244 36.2408L116.995 49.9678L155.01 83.9548ZM94.2375 121.329L110.553 51.7869H76.2914L94.2375 121.329ZM90.4591 124.142L71.8037 51.7676H7.97659L90.4591 124.142ZM97.736 125.56L153.229 88.4083L114.572 53.7024L97.736 125.56ZM84.1956 184.174L148.896 96.5673L96.298 131.789L84.1956 184.174ZM77.961 195.242C77.7638 194.989 77.6269 194.694 77.5609 194.379C77.4948 194.065 77.5013 193.74 77.5798 193.428L91.9309 131.239L0.747964 51.2465C0.512664 51.046 0.323862 50.7967 0.194694 50.5159C0.0655246 50.2351 -0.000910552 49.9295 9.42568e-06 49.6204C0.00185321 49.3034 0.0707358 48.9903 0.202136 48.7018C0.333536 48.4132 0.524482 48.1557 0.762441 47.9462C1.16382 47.5993 1.67966 47.4136 2.2101 47.4251H70.7324L59.156 2.73633C59.0515 2.34569 59.0599 1.93341 59.1804 1.54738C59.3008 1.16134 59.5283 0.817386 59.8364 0.555451C60.2225 0.216939 60.713 0.0210849 61.2261 0.000581178C61.7847 -0.0124973 62.3259 0.195605 62.7317 0.579576L113.583 46.8992L138.975 31.9418C139.26 31.7739 139.581 31.6735 139.911 31.6484C140.241 31.6233 140.573 31.6742 140.881 31.7971L158.012 38.5134H206.653C206.928 38.5161 207.201 38.5734 207.454 38.6819C207.708 38.7904 207.937 38.9479 208.129 39.1455C208.476 39.5134 208.689 39.9878 208.732 40.4916C208.798 41.0259 208.656 41.5649 208.337 41.9982C208.017 42.4315 207.544 42.7263 207.015 42.8221L159.363 53.4804V89.0114C159.358 89.5467 159.157 90.0618 158.798 90.4589L81.4595 195.214C81.2688 195.487 81.0156 195.711 80.7211 195.867C80.4266 196.023 80.0991 196.107 79.7658 196.111H79.7416C79.3987 196.109 79.0606 196.03 78.7524 195.88C78.4442 195.729 78.1737 195.511 77.961 195.242Z" />
          </g>
        </>
      )}
    </svg>
  )
}

export default QibriLogo
