interface AppEnvVariables {
  BASE_URL: string
  API_BASE: string
  OAUTH_BASE: string
  OAUTH_CLIENT_ID: string
  OAUTH_CLIENT_SECRET: string
  IS_DEMO: boolean
  NODE_ENV: 'development' | 'production' | 'test'
  ENVIRONMENT_NAME: string
  BUGSNAG_KEY: string
}

const config: AppEnvVariables = {
  BASE_URL: getEnvOrThrowErr('BASE_URL'),
  API_BASE: getEnvOrThrowErr('API_BASE'),
  OAUTH_BASE: getEnvOrThrowErr('OAUTH_BASE'),
  OAUTH_CLIENT_ID: getEnvOrThrowErr('OAUTH_CLIENT_ID'),
  OAUTH_CLIENT_SECRET: getEnvOrThrowErr('OAUTH_CLIENT_SECRET'),
  IS_DEMO: getEnvFlag(
    getEnvOrThrowErr('IS_DEMO', {
      defaultValue: 'false',
    })
  ),
  NODE_ENV: process.env.NODE_ENV,
  BUGSNAG_KEY: getEnvOrThrowErr('BUGSNAG_KEY', {
    defaultValue: 'no-key',
  }),
  ENVIRONMENT_NAME: getEnvOrThrowErr('ENVIRONMENT_NAME', {
    defaultValue: 'not-specified',
  }),
}

function getEnvOrThrowErr(
  envVarName: string,
  config?: { defaultValue?: string }
) {
  const reactEnvVar = process.env[`REACT_APP_${envVarName}`]

  if (process.env.NODE_ENV !== 'development' && !reactEnvVar) {
    if (!config?.defaultValue) {
      throw Error(`Environment variable undefined: ${envVarName}`)
    }

    return config.defaultValue
  }

  return reactEnvVar ?? ''
}

function getEnvFlag(value?: string | number | boolean) {
  const truthyValues = ['true', '1', 'on', true, 1]

  const envVarValue = String(value).toLowerCase()

  return truthyValues.includes(envVarValue)
}

export default config
