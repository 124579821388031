import { ExchangeTracker, File, Loading } from 'assets/Icons'
import clsx from 'clsx'
import {
  Background,
  Button,
  Card,
  IconContainer,
  Typography,
  UploadFile as UploadFileInput,
  UploadProgress,
} from 'components/common'
import { filesApi } from 'components/features/Other/api'
import { getRandomInt } from 'helpers/num-helpers'
import { useAppDispatch, useAppSelector } from 'hooks'
import { t } from 'i18next'

import styles from './DocumentReplaceFile.module.scss'
import { replaceDocumentActions, replaceDocumentSelectors } from '../../api'

interface Props {
  onAbort: () => void
  onSubmit: () => void
}

const DocumentReplaceFile = ({ onAbort, onSubmit }: Props) => {
  const dispatch = useAppDispatch()
  const { currentFile, newFile, formValues } = useAppSelector(
    replaceDocumentSelectors.selectReplaceDocument
  )

  const [httpUploadFile] = filesApi.useUploadFileMutation()
  const [httpDeleteFile] = filesApi.useDeleteFilesMutation()

  const uploadFile = async (file: File) => {
    dispatch(replaceDocumentActions.setNewFile(file))

    try {
      const response = await httpUploadFile(file).unwrap()
      const fileId = response.data.id
      dispatch(
        replaceDocumentActions.updateNewFile({
          state: 'completed',
          uploadedId: fileId,
        })
      )
    } catch (error) {
      dispatch(
        replaceDocumentActions.updateNewFile({
          state: 'rejected',
        })
      )
    }
  }

  const deleteFile = async () => {
    dispatch(replaceDocumentActions.deleteNewFile())

    try {
      if (newFile?.uploadedId) {
        await httpDeleteFile(newFile?.uploadedId).unwrap()
      }
    } catch (error) {}
  }

  const goToEdit = () => {
    dispatch(replaceDocumentActions.setStep('metadata'))
  }

  const handleSubmit = () => {
    onSubmit()
  }

  const metadata = formValues?.metadata ? formValues?.metadata[0] : null
  const docTitle = metadata?.title
  const docTopics = [
    ...(metadata
      ? [metadata.mainTopic, ...(metadata.altTopic ? metadata.altTopic : [])]
      : []),
  ]
    .map((item) => {
      if (typeof item === 'string') return item

      if (item) {
        return item.label as string
      }

      return null
    })
    .filter(Boolean)
    .join(', ')

  return (
    <Background open color="tinted">
      <Card className={styles.root}>
        <Card.Body>
          <div className={styles.title}>
            <Typography
              element="h1"
              type="display"
              size="x-small"
              fontWeight="bold"
            >
              {t('documentReplace.uploadTitle')}
            </Typography>
            <Typography color="label" element="h2">
              {t('documentReplace.uploadSubtitle')}
            </Typography>
          </div>
          <div className={styles.content}>
            <Typography element="h3" fontWeight="medium">
              {t('documentReplace.replaceFollowing')}
            </Typography>
            <div className={styles.form}>
              {!!currentFile ? (
                <div className={styles.document}>
                  <IconContainer
                    icon={<File />}
                    color="tertiary"
                    withBackground
                  />
                  <div className={styles.documentContent}>
                    <Typography>{docTitle}</Typography>
                    <Typography size="small" color="label">
                      {docTopics}
                    </Typography>
                  </div>
                </div>
              ) : (
                <IconContainer icon={<Loading />} color="primary" spin />
              )}

              <UploadFileInput
                onChange={uploadFile}
                className={clsx(
                  { 'is-hidden': newFile !== null },
                  styles.uploadInput
                )}
              />
              {!!newFile && (
                <div className={styles.uploadProgress}>
                  <IconContainer
                    icon={<ExchangeTracker />}
                    className={styles.exchangeIcon}
                  />
                  <UploadProgress
                    file={newFile.file}
                    progressPercent={
                      newFile.state === 'completed' ? 100 : getRandomInt(65, 85)
                    }
                    handleDelete={deleteFile}
                    status={newFile?.state ?? 'uploading'}
                  />
                </div>
              )}
            </div>
          </div>
        </Card.Body>
        <Card.Footer className={styles.footer}>
          <Button variant="text" size="large" onClick={onAbort}>
            {t('documentReplace.abort')}
          </Button>
          <Button onClick={goToEdit} variant="secondary" size="large">
            {t('documentReplace.uploadEdit')}
          </Button>
          <Button
            disabled={newFile === null || newFile.state !== 'completed'}
            onClick={handleSubmit}
            size="large"
          >
            {t('documentReplace.submit')}
          </Button>
        </Card.Footer>
      </Card>
    </Background>
  )
}

export default DocumentReplaceFile
