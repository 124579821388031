import { Mail } from 'assets/Icons'
import { Avatar, Button, Card, Typography } from 'components/common'
import { truncateWithEllipses } from 'helpers'
import { t } from 'i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Metadata, User } from 'types'

import styles from './MetadataDetailCard.module.scss'
import ReworkPracticeModal from './ReworkPracticeModal'
import { useFrontOfficeCheck, useIsBelowBreakpoint } from '../../../../../hooks'
import { Practice } from '../../../../../redux-store/models'
import WarningAccordion from '../../../../common/Accordion/WarningAccordion'
import { userApi } from '../../../User/api'

interface PracticeDetailCardProps {
  author: User | null
  metadata: Metadata
  practice: Practice
  isModalOpen: boolean
  toggleModal: () => void
}

const MetadataDetailCard = ({
  metadata,
  author,
  practice,
  isModalOpen,
  toggleModal,
}: PracticeDetailCardProps): JSX.Element => {
  const isTabPort = useIsBelowBreakpoint('tabPort')
  const isFrontOffice = useFrontOfficeCheck()

  const cardColumnData = [
    {
      label: t('metadata.description'),
      value: metadata.description ?? '-',
    },
    {
      label: t('metadata.targetGroup'),
      value: metadata.target_group ?? '-',
    },
    {
      label: t('metadata.departments'),
      value: metadata.departments?.length
        ? metadata.departments
            .filter(Boolean)
            .map((org) => org.name)
            .join(', ')
        : '-',
    },
    {
      label: t('metadata.countries'),
      value:
        metadata.countries && metadata.countries?.length > 0
          ? metadata.countries
              .filter(Boolean)
              .map((country) => country.long)
              .join(', ')
          : t('metadata.countryCount', { count: 0 }),
    },
  ]

  const location = useLocation()
  const navigate = useNavigate()

  const httpGetUser = userApi.useGetUserByIdQuery(
    metadata?.contact_user?.id ?? '',
    {
      skip: !metadata.contact_user.id,
    }
  )

  const handleShowDetails = () => {
    navigate(
      {
        ...location,
        pathname: 'details',
      },
      { replace: true }
    )
  }

  let displayedContact = httpGetUser.isSuccess ? httpGetUser.data.data : author

  return (
    <>
      <ReworkPracticeModal
        practice={practice}
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
      />
      <Card data-testid="metadata-details-card" className={styles.root}>
        {isFrontOffice && isTabPort && practice.is_outdated && (
          <WarningAccordion />
        )}
        <Card.Body className={styles.body}>
          {cardColumnData.map(({ label, value }) => (
            <div key={label + value} className={styles.col}>
              <Typography
                color="label"
                size="small"
                fontWeight="medium"
                element="h4"
              >
                {label}
              </Typography>
              <Typography
                size="small"
                element="p"
                className={styles.colContent}
              >
                {truncateWithEllipses(value, 250)}
              </Typography>
            </div>
          ))}
        </Card.Body>
        <Card.Footer className={styles.footer}>
          <div className={styles.author}>
            {!!displayedContact ? (
              <>
                <Avatar
                  src={displayedContact?.avatar_public_path}
                  content={displayedContact?.initials ?? ''}
                />

                <div className={styles.authorDescription}>
                  <Typography fontWeight="medium">
                    {displayedContact?.name}
                  </Typography>
                  <Typography color="label">{t('practice.author')}</Typography>
                </div>
              </>
            ) : (
              <div />
            )}
          </div>
          <div className={styles.actions}>
            {!!displayedContact?.email && (
              <Button
                to={`mailto:${displayedContact?.email}`}
                as="link"
                size="small"
                variant="subtle"
                icon={<Mail />}
              >
                {t('practice.toContact')}
              </Button>
            )}
            <Button
              onClick={handleShowDetails}
              size="small"
              variant="secondary"
            >
              {t('practice.practiceDetails')}
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </>
  )
}

export default MetadataDetailCard
