import { CSSProperties } from 'react'

import clsx from 'clsx'
import { pxToRem } from 'helpers'
import { IconProps } from 'react-feather'
import { ColorNames } from 'types'

import styles from './IconContainer.module.scss'

interface IconContainerProps {
  icon: IconProps | any
  className?: string
  size?: 'x-small' | 'small' | 'medium' | 'large' | number
  color?: ColorNames | 'default' | 'basic'
  withBackground?: boolean

  onClick?: () => void

  spin?: boolean
}

const IconContainer = ({
  icon,
  className,
  color = 'default',
  size = 'medium',
  onClick,
  spin = false,
  withBackground = false,
}: IconContainerProps): JSX.Element => {
  let inlineStyles: CSSProperties = {}
  if (typeof size === 'number') {
    inlineStyles = {
      width: pxToRem(size),
      height: pxToRem(size),
    }
  }

  const classes = clsx(
    styles.root,
    {
      [styles[`sz-${size}`]]: typeof size !== 'number',
      [styles['an-spin']]: spin,
      [styles.hasBackground]: withBackground,
    },
    [styles[`co-${color}`]],
    className
  )

  return (
    <div onClick={onClick} style={inlineStyles} className={classes}>
      {icon}
    </div>
  )
}

export default IconContainer
