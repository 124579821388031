import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react'

import clsx from 'clsx'
import Quill from 'quill'
import QuillCursors from 'quill-cursors'

import styles from './Editor.module.scss'
import { filesApi } from '../../features/Other/api'

interface EditorProps {
  defaultValue?: any
  readOnly: boolean
  onTextChange?: Dispatch<SetStateAction<{ ops: [] } | undefined>>
  onSelectionChange?: Dispatch<SetStateAction<undefined>>
  className?: string
}

Quill.register('modules/cursors', QuillCursors)

const Editor = forwardRef<Quill, EditorProps>(
  (
    { readOnly, defaultValue, onTextChange, onSelectionChange, className },
    forwardedRef
  ) => {
    const ref = useRef<Quill>(null)
    const imageRef = useRef<HTMLInputElement>(null)

    const [uploadFile] = filesApi.useUploadFileMutation()
    const [triggerGetDownloadUrl] =
      filesApi.useLazyGetFileDownloadUrlByIdQuery()

    useImperativeHandle(forwardedRef, () => ref.current as Quill)

    const containerRef = useRef<HTMLDivElement>(null)
    const defaultValueRef = useRef(defaultValue)
    const onTextChangeRef = useRef(onTextChange)
    const onSelectionChangeRef = useRef(onSelectionChange)

    const css = clsx(
      styles.root,
      {
        [styles.readOnly]: readOnly,
      },
      className
    )

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange
      onSelectionChangeRef.current = onSelectionChange
    })

    useEffect(() => {
      if (ref) ref.current?.enable(!readOnly)
    }, [ref, readOnly])

    useEffect(() => {
      // update default value in readonly mode only to display changes
      if (ref && readOnly && defaultValueRef) {
        defaultValueRef.current = defaultValue
        ref.current?.setContents(defaultValue)
      }
    }, [ref, defaultValue, readOnly])

    useEffect(() => {
      const container = containerRef.current
      if (container) {
        const editorContainer = container.appendChild(
          container.ownerDocument.createElement('div')
        )

        const toolbarOptions = readOnly
          ? false
          : {
              container: [
                ['bold', 'italic', 'underline', 'strike'],
                ['link', 'image'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['clean'],
              ],
              handlers: {
                image: (value: string) => {
                  if (value) {
                    if (imageRef.current) imageRef.current.click()
                  } else {
                    if (ref.current) ref.current.format('image', false)
                  }
                },
              },
            }

        const quill = new Quill(editorContainer, {
          theme: 'snow',
          readOnly: readOnly,
          modules: {
            toolbar: toolbarOptions,
            cursors: true,
          },
        })

        // @ts-ignore
        ref.current = quill

        if (defaultValueRef.current) {
          quill.setContents(defaultValueRef.current)
          onTextChangeRef.current?.(defaultValueRef.current)
        }

        quill.on(Quill.events.TEXT_CHANGE, () => {
          // @ts-ignore
          onTextChangeRef.current?.(quill.getContents())
        })

        quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
          // @ts-ignore
          onSelectionChangeRef.current?.(...args)
        })

        return () => {
          // @ts-ignore
          ref.current = null
          container.innerHTML = ''
        }
      }
    }, [ref, readOnly])

    return (
      <>
        <div className={css} ref={containerRef}></div>
        {!readOnly && (
          <input
            ref={imageRef}
            className={styles.hidden}
            type="file"
            onChange={async (e) => {
              const file = (e.target.files as FileList)[0]
              try {
                const response = await uploadFile(file).unwrap()

                if (response.data.id) {
                  triggerGetDownloadUrl({ id: response.data.id }).then(
                    (result) => {
                      // Get cursor location
                      let length = ref.current?.getSelection()?.index
                        ? (ref.current?.getSelection()?.index as number)
                        : 1

                      // Insert image at cursor location
                      ref.current?.insertEmbed(
                        length,
                        'image',
                        `${result.data?.data.file.url}`
                      )

                      // Set cursor to the end
                      ref.current?.setSelection(length + 1)
                    }
                  )
                }
              } catch (e) {
                console.error(e)
              }
            }}
          />
        )}
      </>
    )
  }
)

Editor.displayName = 'Editor'

export default Editor
