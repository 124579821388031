import { useCallback, useEffect } from 'react'

import { addToast } from 'components/features/Other/api'
import { Toast } from 'types'

import useAppDispatch from './useAppDispatch'

/**
 *
 * @param isError - if changes to true, will show error toast. Used as dep for a useEffect
 * @param toastOverride - if provided, will override the toast props
 * @returns a function to manually trigger generic error toast
 */
const useGenericAPIError = (
  isError?: boolean,
  toastOverride?: Partial<Omit<Toast, 'id'>>
) => {
  const dispatch = useAppDispatch()

  const showGenericErrorToast = useCallback(
    (toastProps: Partial<Omit<Toast, 'id'>> | undefined = toastOverride) => {
      dispatch(
        addToast({
          color: 'error',
          content: 'Something went wrong. Please try again later.',
          hasIcon: true,
          ...toastProps,
        })
      )
    },
    [dispatch, toastOverride]
  )

  useEffect(() => {
    if (isError) {
      showGenericErrorToast()
    }
  }, [isError, showGenericErrorToast])

  return showGenericErrorToast
}

export default useGenericAPIError
