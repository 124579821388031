import {
  OfficeName,
  Permissions,
  PracticeQueryParams,
  RevisionStatus,
} from 'constants/application-constants'

import { useEffect, useState } from 'react'

import { MoreVert } from 'assets/Icons'
import { Breadcrumbs, Button, Typography } from 'components/common'
import { BreadcrumbProps } from 'components/common/Breadcrumbs/Breadcrumb.type'
import { useAppSelector, usePermissions } from 'hooks'
import { Home, Star, Edit2 } from 'react-feather'
import { useTranslation } from 'react-i18next'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import styles from './FrontOfficeHeader.module.scss'
import WarningButtonPopover from '../../../../common/ButtonPopover/WarningButtonPopover'
import { selectCurrentRevision } from '../../api'
import { selectMinimalPractice } from '../../api/minimalPracticeSlice'
import FavoriteButton from '../FavoriteButton/FavoriteButton'
import MetadataLanguageDropdown from '../MetadataLanguageDropdown/MetadataLanguageDropdown'

const FrontOfficeHeader = (): JSX.Element => {
  const currentRevision = useAppSelector(selectCurrentRevision)
  const practice = useAppSelector(selectMinimalPractice)
  const navigate = useNavigate()
  const location = useLocation()
  const [referrer, setReferrer] = useState<string | undefined>()
  const [searchParams] = useSearchParams()
  const { id } = useParams()
  const perms = usePermissions(id).mixed
  const isBackOfficeUser = !!perms
    ? perms[Permissions.ACCESS_BACKOFFICE] ?? false
    : false
  const { t } = useTranslation()

  useEffect(() => {
    if (location.state?.referrer === 'favorites' && referrer === undefined) {
      setReferrer(location.state?.referrer)
    }
  }, [setReferrer, location.state?.referrer, referrer])

  const currentMetadata = currentRevision?.metadata.find(
    ({ language }) =>
      searchParams.get(PracticeQueryParams.METADATA_LANGUAGE) === language.code
  )

  const handleEditPracticeClick = () => {
    const backOfficeUrl = `/${OfficeName.BACK}/practices/${id}`

    navigate({
      ...location,
      pathname: backOfficeUrl,
      search: `?${PracticeQueryParams.REVISION_STATUS}=${RevisionStatus.DRAFT}`,
    })
  }

  const handleSidebarOpen = () => {
    navigate(
      {
        ...location,
        hash: 'sidebar',
      },
      { replace: true }
    )
  }

  const getIconCrumb = (): BreadcrumbProps => {
    const crumb = {
      href: referrer === 'favorites' ? '/favorites' : '/',
      title:
        referrer === 'favorites'
          ? t('navigation.favorites')
          : t('navigation.search'),
      icon: referrer === 'favorites' ? <Star /> : <Home />,
      iconOnly: true,
      tooltip:
        referrer === 'favorites'
          ? t('home.backButtonAriaFavorites')
          : t('home.backButtonAria'),
    }

    return crumb
  }

  return (
    <>
      <div className={styles.rootMobile}>
        <div className={styles.title}>
          <Typography type="text" size="small" fontWeight="bold" element="h1">
            {currentMetadata?.title}
          </Typography>
          <Typography type="text" size="small" element="h2" color="label">
            {currentMetadata?.subject}
          </Typography>
        </div>

        <Button
          size="small"
          variant="subtle"
          icon={<MoreVert />}
          onClick={handleSidebarOpen}
          className={styles.sidebarBtn}
          aria-label={t('frontOffice.ariaSidebarBtn') ?? ''}
          as="button"
        />
      </div>
      <div className={styles.root}>
        <nav className={styles.nav}>
          <Breadcrumbs
            crumbs={[
              getIconCrumb(),
              {
                href: location,
                title: currentMetadata?.title ?? t('practice.current'),
              },
            ]}
          />
        </nav>

        {practice?.is_outdated && <WarningButtonPopover />}

        {isBackOfficeUser && (
          <Button
            className={styles.mobileHidden}
            variant="secondary"
            size="small"
            icon={<Edit2 />}
            onClick={handleEditPracticeClick}
          >
            {t('frontOffice.actionEdit')}
          </Button>
        )}
        <MetadataLanguageDropdown />
        {/* TODO: "More" Dropdown */}
        <FavoriteButton className={styles.favBtn} />
      </div>
    </>
  )
}

export default FrontOfficeHeader
