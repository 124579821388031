import { useEffect } from 'react'

import { selectAppLoading } from 'components/features/Other/api'
import { userApi } from 'components/features/User/api'
import { useAppSelector } from 'hooks'
import { useTranslation } from 'react-i18next'

export const useI18nInitial = () => {
  const isAppLoading = useAppSelector(selectAppLoading)
  const { i18n } = useTranslation()

  const httpGetUser = userApi.endpoints.getCurrentUser.useQueryState(null)

  const currentUser = httpGetUser.data?.data

  useEffect(() => {
    if (!isAppLoading && currentUser && currentUser.language) {
      i18n.changeLanguage(currentUser.language)
    }

    if (!isAppLoading && !currentUser) {
      var userLang = navigator.language
      if (userLang.startsWith('de')) {
        i18n.changeLanguage('de')
      }
    }
  }, [currentUser, i18n, isAppLoading])
}
