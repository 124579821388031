import LogCodes, { LogCode } from 'constants/enums/logCodes'
import { LocalStorageItem } from 'constants/storage-constants'

import config from 'config'

const LOG_PREFIX = 'FE' as const

const Logger = {
  log(...args: Parameters<typeof console.log>) {
    if (config.NODE_ENV === 'development') {
      console.log(...args)
    }
  },
  error(...args: Parameters<typeof console.error>) {
    if (config.NODE_ENV === 'production') {
      this.monitor(LogCodes.ERR_GENERIC, args)
    }
    console.error(...args)
  },
  warn(...args: Parameters<typeof console.warn>) {
    if (config.NODE_ENV === 'development') {
      console.warn(...args)
    }
  },
  debug(...args: Parameters<typeof console.debug>) {
    if (config.NODE_ENV === 'development') {
      console.debug(...args)
    }
  },
  monitor(code: LogCode, body?: object | null, message?: string) {
    if (config.NODE_ENV === 'test') return

    const logName = createLogName(code)
    const logBody = createLogBody(body, message)

    if (config.NODE_ENV === 'development') {
      console.groupCollapsed('MONITOR')
      console.log(logName, logBody)
      console.groupEnd()
    } else {
      // TODO: log message to monitor
    }
  },
}

function createLogName(name: string) {
  return `${LOG_PREFIX} | ${name}`
}

function createLogBody(body?: object | null, message?: string) {
  return {
    extra: body,
    message,
    browser: {
      agent: navigator.userAgent,
      language: navigator.language,
    },
    app: {
      build: config.NODE_ENV,
      environment: config.ENVIRONMENT_NAME,
      isDemo: config.IS_DEMO,
    },
    user: {
      id: localStorage.getItem(LocalStorageItem.USER_ID),
    },
    location: createLocation(),
  }
}

function createLocation() {
  const {
    hash,
    host,
    hostname,
    href,
    origin,
    pathname,
    port,
    protocol,
    search,
  } = window.location

  return {
    hash,
    host,
    hostname,
    href,
    origin,
    pathname,
    port,
    protocol,
    search,
  }
}

export default Logger
