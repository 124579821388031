import { useEffect } from 'react'

import clsx from 'clsx'
import { AlertCircle, CheckCircle } from 'react-feather'
import { Toast as ToastType } from 'types'

import styles from './Toast.module.scss'

type ToastProps = Omit<ToastType, 'id'> & {
  className?: string
  handleDismiss?: () => void
}

const Toast = ({
  color = 'primary',
  content,
  className,
  hasIcon,
  dismissTime,
  handleDismiss,
}: ToastProps): JSX.Element => {
  const classes = clsx(styles.root, styles[`co-${color}`], className)

  useEffect(() => {
    let dismissTimeout: NodeJS.Timeout
    if (dismissTime && handleDismiss) {
      dismissTimeout = setTimeout(() => {
        handleDismiss()
      }, dismissTime)
    }

    return () => {
      if (dismissTimeout) {
        clearTimeout(dismissTimeout)
      }
    }
  }, [dismissTime, handleDismiss])

  const renderedIcon = (() => {
    if (!hasIcon) return

    switch (color) {
      case 'error':
        return <AlertCircle />
      case 'primary':
        return <CheckCircle />
      case 'success':
        return <CheckCircle />
      case 'warning':
        return <AlertCircle />
    }
  })()

  return (
    <div className={classes}>
      {hasIcon && <span className={styles.icon}>{renderedIcon}</span>}
      <div className={styles.content}>{content}</div>
    </div>
  )
}

export default Toast
