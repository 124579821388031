import { CreatePracticeRequest } from 'components/features/Practices/api/practicesApi/practicesApi.types'
import { GuidelineFormValues } from 'components/features/Practices/components/GuidelineForm/types'

export const mapGuidelineFormToResponse = (
  data: GuidelineFormValues
): CreatePracticeRequest => {
  let metadataPayloadArr: CreatePracticeRequest['metadata'] = []

  data.metadata.forEach((item) => {
    metadataPayloadArr.push({
      title: item.title,
      contact_user_id: item.contact?.id ?? '',
      main_topic_id: item.mainTopic?.id ?? '',
      language_code: item.language,
      code: item.code,
      subject: item.longTitle,
      description: item.description,
      target_group: item.targetGroup,
      external_references: item.externalReferenceLinks,
      obsolete_practices: item.obsoletePractices,
      effective_at: item.effectiveAt,
      keywords: item.keywords?.join(', '),
      countries: item.countries,
      alternative_topic_ids: item.altTopic,
      locations: item.locations,
      departments: item.departments,
      legal_entities: item.legalEntities,
      certification_scopes: item.certificationScope,
      collections: item.collections,
      access_rights: item.accessRights?.id ?? '',
    })
  })

  return {
    metadata: metadataPayloadArr,
    revision: {
      practice_class_id: data.class?.id || '',
    },
  }
}
