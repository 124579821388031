const Trash = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 4.3335H15L14.6375 11.5832C14.5045 14.2442 12.3081 16.3335 9.64375 16.3335H8.35625C5.69188 16.3335 3.49554 14.2442 3.36248 11.5832L3 4.3335Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M5.66675 4.3335V4.3335C5.66675 2.86074 6.86065 1.66683 8.33341 1.66683H9.66675C11.1395 1.66683 12.3334 2.86074 12.3334 4.3335V4.3335"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M1.66675 4.3335H16.3334"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11 8.3335V11.6668"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7 8.3335V11.6668"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Trash
