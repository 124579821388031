const FileCheckIcon = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5"
  >
    <path d="M3.5 4C3.5 2.61929 4.61929 1.5 6 1.5H9.42105C11.354 1.5 12.9211 3.067 12.9211 5V7.5C12.9211 8.60457 13.8165 9.5 14.9211 9.5H17C18.933 9.5 20.5 11.067 20.5 13V20C20.5 21.3807 19.3807 22.5 18 22.5H6C4.61929 22.5 3.5 21.3807 3.5 20V4Z" />
    <path d="M3.5 4C3.5 2.61929 4.61929 1.5 6 1.5H9.23393C11.7412 1.5 14.1206 2.60698 15.7357 4.52485L18.5017 7.80956C19.7923 9.34207 20.5 11.2812 20.5 13.2847V20C20.5 21.3807 19.3807 22.5 18 22.5H6C4.61929 22.5 3.5 21.3807 3.5 20V4Z" />
    <path d="M7 15.1567L10.0784 18.2352L16.2353 12.0783" />
  </svg>
)

export default FileCheckIcon
