import LoadingIndicator from 'components/common/LoadingIndicator/LoadingIndicator'

import styles from './LoadingPage.module.scss'

interface LoadingPageProps {
  indicatorProps?: React.ComponentProps<typeof LoadingIndicator>
}

const LoadingPage = ({ indicatorProps }: LoadingPageProps) => (
  <div className={styles.root}>
    <LoadingIndicator {...indicatorProps} />
  </div>
)

export default LoadingPage
