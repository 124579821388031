import StarFilled from 'assets/Icons/StarFilled'
import { Button } from 'components/common'
import { addToast, metaApi } from 'components/features/Other/api'
import { useAppDispatch } from 'hooks'
import { t } from 'i18next'
import { Star } from 'react-feather'
import { useParams } from 'react-router-dom'

import { practicesApi } from '../../api'

interface Props {
  className?: string
}

const FavoriteButton = ({ className = '' }: Props): JSX.Element | null => {
  const { id } = useParams()
  const dispatch = useAppDispatch()

  const httpGetPractice = practicesApi.useGetMinimalPracticeByIdQuery(
    id ?? '',
    {
      skip: !id, // entityType == 'document'
    }
  )

  const [httpMarkFavorite, httpMarkFavoriteState] =
    metaApi.useAddStarredItemMutation()
  const [httpUnmarkFavorite, httpUnmarkFavoriteState] =
    metaApi.useDeleteStarredItemsMutation()

  const handleClick = () => {
    if (!httpGetPractice.isSuccess || !id) return

    const { starred_item_id: starId } = httpGetPractice.data.data

    const entityType = 'practice' // | "document"
    const refetchFn = httpGetPractice.refetch

    try {
      if (starId) {
        httpUnmarkFavorite(id)

        dispatch(
          addToast({
            color: 'success',
            content: t('practice.favoriteRemoved'),
          })
        )
      } else {
        httpMarkFavorite({ type: entityType, id })
        dispatch(
          addToast({
            color: 'success',
            content: t('practice.favoriteAdded'),
          })
        )
      }
    } catch (error) {
      dispatch(
        addToast({
          color: 'error',
          content: t('error.general'),
        })
      )
    } finally {
      refetchFn()
    }
  }

  const starId = httpGetPractice.data?.data.starred_item_id

  return (
    <Button
      size="small"
      icon={!!starId ? <StarFilled /> : <Star />}
      className={className}
      onClick={handleClick}
      disabled={
        !httpGetPractice.isSuccess ||
        httpMarkFavoriteState.isLoading ||
        httpUnmarkFavoriteState.isLoading ||
        httpGetPractice.isFetching
      }
    >
      {!!starId ? t('practice.unmarkFavorite') : t('practice.markFavorite')}
    </Button>
  )
}

export default FavoriteButton
