import clsx from 'clsx'
import { IconProps } from 'react-feather'
import { ColorNames } from 'types'

import styles from './DropdownItem.module.scss'
import IconContainer from '../IconContainer/IconContainer'

interface Props {
  children: React.ReactNode
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  icon?: IconProps | null
  iconColor?: ColorNames | 'default'
  trailingIcon?: IconProps | null
  trailingIconColor?: ColorNames | 'default'
  disabled?: boolean
}

const DropdownItem = ({
  children,
  onClick,
  icon,
  iconColor = 'primary',
  className,
  trailingIcon,
  trailingIconColor = 'primary',
  disabled = false,
}: Props) => {
  const css = clsx(
    styles.root,
    {
      [styles.disabled]: disabled,
    },
    className
  )

  return (
    <button onClick={onClick} className={css} disabled={disabled}>
      {!!icon && (
        <IconContainer
          size="x-small"
          className={styles.icon}
          color={iconColor}
          icon={icon}
        />
      )}
      <span className={styles.content}>{children}</span>
      {!!trailingIcon && (
        <IconContainer
          size="x-small"
          className={styles.icon}
          color={trailingIconColor}
          icon={trailingIcon}
        />
      )}
    </button>
  )
}

export default DropdownItem
