import { createSlice } from '@reduxjs/toolkit'
import { Payload, ReduxRootState } from 'types'

interface ApprovalState {
  practiceId: string | null
}

const initialState: ApprovalState = {
  practiceId: null,
}

export const approvalSlice = createSlice({
  name: 'approval',
  initialState,
  reducers: {
    setPracticeId: (state, { payload }: Payload<string | null>) => {
      state.practiceId = payload
    },
  },
})

export const { setPracticeId } = approvalSlice.actions

export const approvalReducer = approvalSlice.reducer

export const selectApproval = (state: ReduxRootState) => state.approval

export default approvalReducer
