import React, { useCallback, useLayoutEffect, useRef } from 'react'

import clsx from 'clsx'
import useWindowSize from 'hooks/useWindowSize'

import styles from './Popover.module.scss'
import { PopoverProps } from './Popover.types'
import { getPositioningStyle } from './Popover.utils'
import { Background } from '../Background'

const Popover = ({
  anchorEl,
  children,
  anchorOrigin = {
    horizontal: 'right',
    vertical: 'bottom',
  },
  transformOrigin = {
    horizontal: 'left',
    vertical: 'top',
  },
  className = '',
  open = false,
  onClose,
  'data-testid': testId,
}: PopoverProps) => {
  const css = clsx(styles.root, className)
  const childRef = useRef<HTMLDivElement>(null)

  const { height: windowHeight, width: windowWidth } = useWindowSize()

  const setPosition = useCallback(() => {
    if (anchorEl && childRef.current) {
      const parentRect = anchorEl.getBoundingClientRect()
      const elemRect = childRef.current.getBoundingClientRect()
      const positionCoordinates = getPositioningStyle(
        elemRect,
        parentRect,
        transformOrigin,
        anchorOrigin
      )

      childRef.current.style.top = positionCoordinates.top
      childRef.current.style.left = positionCoordinates.left
    }
  }, [anchorEl, anchorOrigin, transformOrigin])

  useLayoutEffect(() => {
    if (open) {
      setPosition()
    }
  }, [open, setPosition, windowWidth, windowHeight])

  useLayoutEffect(() => {
    window.addEventListener('scroll', setPosition)
    return () => {
      window.removeEventListener('scroll', setPosition)
    }
  }, [setPosition])

  const handleClose = useCallback(
    (evt?: React.MouseEvent) => {
      evt?.stopPropagation()

      if (onClose) {
        onClose()
      }
    },
    [onClose]
  )

  const handleEscPress = (evt: React.KeyboardEvent) => {
    evt.stopPropagation()
    if (evt.key === 'Escape' && open && handleClose) {
      handleClose()
    }
  }

  return (
    <Background open={open} onClick={handleClose}>
      <div
        onKeyDown={handleEscPress}
        data-testid={testId}
        ref={childRef}
        className={css}
        onClick={(e) => e.stopPropagation()}
      >
        {open && children}
      </div>
    </Background>
  )
}

export default Popover
