import { Typography } from 'components/common'

import styles from './MetadataProperty.module.scss'

interface Props {
  title: string
  value?: string | null
}

const MetadataProperty = ({ title, value }: Props) => (
  <div className={styles.property}>
    <Typography
      className={styles.title}
      color="label"
      size="small"
      fontWeight="medium"
    >
      {title}
    </Typography>
    <Typography className={styles.content} element="span">
      {value ?? '-'}
    </Typography>
  </div>
)

export default MetadataProperty
