import React, { Fragment, useLayoutEffect, useState } from 'react'

import clsx from 'clsx'

import useScrollspy from './hooks/useScrollspy'
import styles from './ScrollTracker.module.scss'

type DataNode = {
  id: string
  label: string
}

type ScrollNode = DataNode & {
  children?: DataNode[]
}

interface ScrollTrackerProps {
  targetData: ScrollNode[]
  activationHeight?: number
  scrollOnClick?: boolean
}

const ScrollTracker = ({
  targetData,
  activationHeight = 0,
  scrollOnClick = true,
}: ScrollTrackerProps) => {
  const [currentId, setCurrentId] = useState<string>('')

  const activeId = useScrollspy(flattenDataNodes(targetData), activationHeight)

  useLayoutEffect(() => {
    if (activeId) {
      setCurrentId(activeId)
    }
  }, [activeId])

  const handleClick = (node: DataNode) => (evt: React.MouseEvent) => {
    evt.stopPropagation()
    scrollToTarget(node.id)
  }

  const handleKeyDown = (node: DataNode) => (evt: React.KeyboardEvent) => {
    switch (evt.key) {
      case 'Enter':
      case 'Space':
        evt.stopPropagation()
        scrollToTarget(node.id)
        break
    }
  }

  const scrollToTarget = (id: string) => {
    if (!scrollOnClick) {
      return
    }

    const element = document.getElementById(id ?? currentId)

    if (element) {
      window.scrollTo({
        top: element?.offsetTop - activationHeight + 2,
        behavior: 'smooth',
      })
    }

    if (id) {
      setCurrentId(id)
    }
  }

  return (
    <ul className={styles.root}>
      {targetData.map((node) => (
        <Fragment key={node.id + node.label}>
          <li
            tabIndex={0}
            onClick={handleClick(node)}
            onKeyDown={handleKeyDown(node)}
            className={clsx(styles.node, {
              [styles.active]: currentId === node.id,
            })}
          >
            <span className={styles.nodeLabel}>{node.label}</span>
          </li>
          {node.children && (
            <ul>
              {node.children.map((childNode) => (
                <li
                  key={childNode.id + childNode.label}
                  tabIndex={0}
                  onClick={handleClick(childNode)}
                  onKeyDown={handleKeyDown(childNode)}
                  className={clsx(styles.childNode, {
                    [styles.active]: currentId === childNode.id,
                  })}
                >
                  <span className={styles.nodeLabel}>{childNode.label}</span>
                </li>
              ))}
            </ul>
          )}
        </Fragment>
      ))}
    </ul>
  )
}

export default ScrollTracker

function flattenDataNodes(data: ScrollNode[]) {
  return data.reduce((acc, data) => {
    acc.push(data.id)

    if (data.children) {
      acc = [...acc, ...data.children.map((child) => child.id)]
    }

    return acc
  }, [] as string[])
}
