import clsx from 'clsx'
import { ChevronsRight } from 'react-feather'

import styles from './ActionBar.module.scss'
import {
  ActionBarActionsProps,
  ActionBarComponents,
  ActionBarPageProps,
  ActionBarPagesProps,
  ActionBarProps,
} from './ActionBar.types'
import Button from '../Button/Button'

const ActionBar: React.FC<ActionBarProps> & ActionBarComponents = ({
  isOpen,
  children,
  className,
}): JSX.Element => {
  const classes = clsx(
    styles.root,
    {
      [styles.isOpen]: isOpen,
    },
    className
  )

  return <div className={classes}>{children}</div>
}

const ActionBarActions: React.FC<ActionBarActionsProps> = ({
  children,
  className,
}) => {
  const classes = clsx(styles.actions, className)
  return <div className={classes}>{children}</div>
}

const ActionBarPages: React.FC<ActionBarPagesProps> = ({
  children,
  className,
  onBackClick,
  showBackButton = true,
}) => {
  const classes = clsx(styles.pages, className)

  const hasBackButton = Boolean(onBackClick) && showBackButton

  return (
    <div className={classes}>
      {hasBackButton && (
        <Button
          className={styles.backBtn}
          onClick={onBackClick}
          size="small"
          variant="subtle"
          icon={<ChevronsRight />}
          aria-label="close action bar"
        />
      )}

      {children}
    </div>
  )
}

const ActionBarPage: React.FC<ActionBarPageProps> = ({
  children,
  className,
}: ActionBarPageProps) => {
  const classes = clsx(styles.page, className)
  return <div className={classes}>{children}</div>
}

ActionBar.Actions = ActionBarActions
ActionBar.Pages = ActionBarPages
ActionBar.Page = ActionBarPage

export default ActionBar
