import { NewFile, NewPackageIcon, Plus } from 'assets/Icons'
import { ButtonPopover, DropdownItem, Typography } from 'components/common'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const NewDropdownButton = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleRedirect = (type: 'practice' | 'document') => () => {
    if (type === 'practice') {
      navigate('/new-guideline')
    }
    if (type === 'document') {
      navigate('/new-document')
    }
  }

  return (
    <>
      <ButtonPopover
        buttonProps={{
          variant: 'primary',
          icon: <Plus />,
          children: t('practices.new'),
        }}
        // className={styles.listItems}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <DropdownItem onClick={handleRedirect('document')} icon={<NewFile />}>
          <Typography element="span">{t('practices.newDocument')}</Typography>
        </DropdownItem>
        <DropdownItem
          onClick={handleRedirect('practice')}
          icon={<NewPackageIcon />}
        >
          <Typography element="span">{t('practices.newGuideline')}</Typography>
        </DropdownItem>
      </ButtonPopover>
    </>
  )
}

export default NewDropdownButton
