import { SessionStorageItem } from 'constants/storage-constants'

import { useEffect } from 'react'

import usePrevious from 'hooks/usePrevious'
import { useLocation } from 'react-router-dom'

const useStoreLocation = () => {
  const location = useLocation()
  const prevLocation = usePrevious(location)

  useEffect(() => {
    // store previous location

    if (prevLocation?.pathname !== location.pathname) {
      sessionStorage.setItem(
        SessionStorageItem.PREV_LOC,
        JSON.stringify(prevLocation)
      )
    }
  }, [location.pathname, prevLocation])

  useEffect(() => {
    let hist: any = sessionStorage.getItem(SessionStorageItem.HISTORY)
    hist = hist ? JSON.parse(hist) : []

    if (
      Array.isArray(hist) &&
      hist.length > 0 &&
      hist[hist.length - 1].pathname !== location.pathname
    ) {
      hist.push(location)
    }

    if (Array.isArray(hist) && hist.length === 0) {
      hist.push(location)
    }

    sessionStorage.setItem(SessionStorageItem.HISTORY, JSON.stringify(hist))
  }, [location, prevLocation?.pathname])
}

export default useStoreLocation
