import { InputWrapper, TextArea } from 'components/common'
import { TextAreaProps } from 'components/common/TextArea/TextArea.types'
import { FieldHookConfig, useField } from 'formik'
import { t } from 'i18next'

type FTextAreaProps = TextAreaProps &
  FieldHookConfig<string | number> & {
    helperText?: string | null
    optional?: boolean
  }

const FTextArea = ({
  id,
  label,
  helperText,
  optional = false,
  ...props
}: FTextAreaProps): JSX.Element => {
  const [field, meta, helpers] = useField(props)

  const { onBlur, value, onChange, name } = field
  const { error, touched } = meta
  const { setValue } = helpers

  const { name: _name, ...rest } = props

  const handleBlur: React.FocusEventHandler<HTMLTextAreaElement> = (evt) => {
    if (rest.onBlur) {
      rest.onBlur(evt)
    }

    onBlur(evt)
  }

  return (
    <InputWrapper
      helperText={touched && error ? t(error) : helperText}
      color={touched && error ? 'error' : 'basic'}
    >
      <TextArea
        id={id}
        label={optional ? `${label} (${t('common.optional')})` : label}
        name={name}
        value={value}
        onBlur={handleBlur}
        onChange={onChange}
        setInputValue={setValue}
        isError={touched && Boolean(error)}
        {...rest}
      />
    </InputWrapper>
  )
}

FTextArea.displayName = 'FTextArea'
export default FTextArea
