import { Button, Card, Modal, Typography } from 'components/common'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import styles from './NoPermissionModal.module.scss'

const NoPermissionModal = (): JSX.Element => {
  const navigate = useNavigate()

  const handlePrimaryAction = () => {
    navigate('/')
  }

  return (
    <Modal open>
      <Card className={styles.root}>
        <Card.Body className={styles.body}>
          <Typography
            type="display"
            size="small"
            fontWeight="bold"
            element="h1"
          >
            {t('noPermission.genericTitle')}
          </Typography>
          <Typography color="label">
            {t('noPermission.genericDescription')}
          </Typography>
          <Button size="large" onClick={handlePrimaryAction}>
            {t('noPermission.action')}
          </Button>
        </Card.Body>
      </Card>
    </Modal>
  )
}

export default NoPermissionModal
