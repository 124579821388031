import clsx from 'clsx'
import { Link } from 'react-router-dom'

import styles from './NavigationItem.module.scss'
import { NavigationItemProps } from './NavigationItem.types'

const NavigationItem = ({
  children,
  icon,
  isCollapsed,
  to,
  isSelected,
  displayOnMobile,
  ...props
}: NavigationItemProps): JSX.Element => {
  const classes = clsx(styles.root, {
    [styles.isCollapsed]: isCollapsed,
    [styles.isSelected]: isSelected,
    [styles.hideOnMobile]: displayOnMobile === false,
  })

  return (
    <Link aria-label={children} to={to} className={classes} {...props}>
      <div className={styles.wrapper}>
        <span className={styles.icon}>{icon}</span>
        {!isCollapsed && <span className={styles.text}>{children}</span>}
      </div>
    </Link>
  )
}

export default NavigationItem
