import React from 'react'

const EditIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2803 1.2256C17.2812 1.22527 17.2821 1.22495 17.283 1.22462C17.3423 1.20266 17.433 1.17181 17.5498 1.14017C17.7842 1.07669 18.1184 1.01142 18.5119 1.006C19.2888 0.995282 20.3075 1.2158 21.2737 2.18198C22.2399 3.14816 22.4604 4.1669 22.4497 4.94382C22.4443 5.33726 22.379 5.67153 22.3155 5.9059C22.2839 6.02267 22.253 6.11337 22.2311 6.1727C22.2307 6.17359 22.2304 6.17447 22.2301 6.17535L8.02524 20.3802L2.40052 21.0552L3.07549 15.4304L17.2803 1.2256ZM22.1998 6.25151L22.1999 6.2515L22.1998 6.25151Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M14.2671 3.53162L19.6546 8.9191"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default EditIcon
