const Send = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.22681 12.827C1.39906 12.5087 1.36461 11.3503 2.17198 10.9833L20.9069 2.46743C21.7481 2.08508 22.6134 2.95043 22.2311 3.7916L13.7152 22.5265C13.3482 23.3339 12.1898 23.2994 11.8715 22.4717L9.35195 15.9209C9.25038 15.6568 9.04168 15.4481 8.77759 15.3466L2.22681 12.827Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default Send
