import { OfficeName } from 'constants/application-constants'

import { useLocation } from 'react-router-dom'

const useFrontOfficeCheck = () => {
  const location = useLocation()

  return location.pathname.includes(OfficeName.FRONT)
}

export default useFrontOfficeCheck
