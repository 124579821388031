import React from 'react'

const StarIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5386 2.10531C11.7097 1.69547 12.2903 1.69547 12.4614 2.10531L14.9489 8.06407C15.0173 8.22787 15.1673 8.34314 15.3432 8.36694L21.8584 9.24874C22.2918 9.3074 22.4463 9.85506 22.1074 10.1316L17.0603 14.2502C16.9127 14.3707 16.8463 14.5647 16.8893 14.7503L18.504 21.7279C18.6074 22.1747 18.105 22.5133 17.7296 22.2499L12.2872 18.4301C12.1149 18.3091 11.8851 18.3091 11.7128 18.4301L6.27034 22.2499C5.89499 22.5134 5.39259 22.1747 5.49597 21.7279L7.11054 14.7503C7.15351 14.5647 7.08719 14.3707 6.93954 14.2502L1.89253 10.1316C1.55365 9.85506 1.70815 9.30741 2.1416 9.24874L8.65677 8.36694C8.83266 8.34314 8.98274 8.22787 9.05112 8.06408L11.5386 2.10531Z"
      fill="#FCFCFC"
      stroke="#FCFCFC"
      strokeWidth="1.5"
      strokeMiterlimit="3.3292"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default StarIcon
