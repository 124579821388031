import {
  OfficeName,
  PracticeQueryParams,
} from 'constants/application-constants'

import React, { useMemo } from 'react'

import { Avatar, Table, Typography } from 'components/common'
import { DocumentIcon } from 'components/features/Other/components'
import { capitalizeFirstLetter, formatDateNumeric } from 'helpers'
import { useFrontOfficeCheck, useSortable } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import { DocumentEntity, RevisionStatus } from 'redux-store/models'

import styles from './DocumentsTable.module.scss'
import DocumentDownloadUrl from '../DocumentDownloadUrl/DocumentDownloadUrl'
import DocumentMorePopover from '../DocumentMorePopover/DocumentMorePopover'

interface DocumentsTableProps {
  documents: DocumentEntity[]
  status?: string | null
  labelledBy?: string
}

type DocumentsTableSortBy = 'name' | 'type' | 'author' | 'updatedAt'

const DocumentsTable = ({
  documents,
  status,
  labelledBy,
}: DocumentsTableProps): JSX.Element => {
  const { sortBy, sortDir, handleSortClick } =
    useSortable<DocumentsTableSortBy>({
      sortByDefault: 'name',
      sortDirDefault: 'asc',
    })
  const { t } = useTranslation()
  const isFrontOffice = useFrontOfficeCheck()
  const [searchParams] = useSearchParams()

  const revisionStatus =
    searchParams.get(PracticeQueryParams.REVISION_STATUS) || ''

  const documentsTableData = useMemo(() => {
    const data = documents.map((document) => {
      var metadata = null
      let temp
      // depending on the revision status, choose the correct metadata to display
      switch (status) {
        case RevisionStatus.draft:
        case RevisionStatus.precheck:
          temp = document.latest_draft_version?.metadata
          metadata = temp && 0 in temp ? temp[0] : undefined
          break

        case RevisionStatus.approval:
        case RevisionStatus.preapproval:
          temp = document.current_approvalish_version?.metadata
          metadata = temp && 0 in temp ? temp[0] : undefined
          break

        case RevisionStatus.published:
          temp = document.latest_approved_version?.metadata
          metadata = temp && 0 in temp ? temp[0] : undefined
          break

        default:
          metadata = document.metadata
      }

      /**
       * fixme: we don't want to show documents if they are not available in this revision, BUT we are only linking
       *        documents from previous revisions. Those need to be displayed here and be editable. This is too much
       *        logic on the view layer. BE should just return the correct revisions without FE knowing something about
       *        the versioning. We need a major refactor there.
       */
      // if (metadata === undefined) {
      //   return undefined
      // }

      return {
        name: metadata?.title ?? '',
        updatedAt: metadata?.published_at ?? '',
        documentId: document.id,
        type: document.type,
        display_type: capitalizeFirstLetter(document.type),
        author: metadata?.contact_user?.name ?? '',
        initials: metadata?.contact_user?.initials ?? '',
        avatar_public_path: metadata?.contact_user?.avatar_public_path ?? '',
      }
    })

    if (sortBy) {
      return data.sort((a, b) => {
        const _a = a[sortBy]?.toLowerCase() ?? ''
        const _b = b[sortBy]?.toLowerCase() ?? ''
        if (_a < _b) {
          return sortDir === 'asc' ? -1 : 1
        } else if (_a > _b) {
          return sortDir === 'asc' ? 1 : -1
        } else {
          return 0
        }
      })
    }

    return data

    // .filter((n) => n)
  }, [documents, status, sortBy, sortDir])

  return (
    <>
      <Table isFullWidth className={styles.root} aria-labelledby={labelledBy}>
        <Table.Head>
          <Table.Row isHeadRow>
            <Table.Cell
              onClick={() => handleSortClick('name')}
              sortDir={sortDir}
              sortByThisColumn={sortBy === 'name'}
            >
              {t('documentsTable.head1')}
            </Table.Cell>
            <Table.Cell
              onClick={() => handleSortClick('type')}
              sortDir={sortDir}
              sortByThisColumn={sortBy === 'type'}
            >
              {t('documentsTable.head2')}
            </Table.Cell>
            <Table.Cell
              onClick={() => handleSortClick('author')}
              sortDir={sortDir}
              sortByThisColumn={sortBy === 'author'}
            >
              {t('documentsTable.head3')}
            </Table.Cell>
            <Table.Cell
              onClick={() => handleSortClick('updatedAt')}
              sortDir={sortDir}
              sortByThisColumn={sortBy === 'updatedAt'}
            >
              {t('documentsTable.head4')}
            </Table.Cell>
            <Table.Cell
              className={styles.actionCell}
              aria-label={t('documentsTable.head5Aria') ?? ''}
            />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {documentsTableData.map((document) => (
            <Table.Row key={document?.documentId} isClickable>
              <Table.Cell className={styles.name} isLead>
                <Link
                  to={{
                    pathname: `/${
                      isFrontOffice ? OfficeName.FRONT : OfficeName.BACK
                    }/documents/${document?.documentId}`,
                    search:
                      !isFrontOffice && revisionStatus
                        ? `rs=${revisionStatus}`
                        : '',
                  }}
                  aria-label={document.name + ', go to detail'}
                />
                <DocumentIcon
                  type={document?.type ?? ''}
                  avatarProps={{
                    size: 'medium',
                  }}
                />
                <Typography>{document?.name}</Typography>
              </Table.Cell>
              <Table.Cell>{document?.display_type}</Table.Cell>
              <Table.Cell>
                <div className={styles.contentAuthor}>
                  <Avatar
                    content={document?.initials}
                    size="small"
                    src={document?.avatar_public_path}
                  />
                  {document?.author}
                </div>
              </Table.Cell>
              <Table.Cell>
                {document?.updatedAt
                  ? formatDateNumeric(document?.updatedAt)
                  : ''}
              </Table.Cell>
              <Table.Cell innerClassName={styles.actionCell}>
                {document?.documentId && (
                  <>
                    {!isFrontOffice && (
                      <DocumentMorePopover
                        documentId={document?.documentId}
                        documentType={document?.type}
                      />
                    )}

                    {isFrontOffice && (
                      <DocumentDownloadUrl
                        documentId={document?.documentId}
                        documentType={document?.type}
                      />
                    )}
                  </>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  )
}

export default DocumentsTable
