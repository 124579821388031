import { createSlice } from '@reduxjs/toolkit'
import { Practice } from 'redux-store/models'
import { Payload, ReduxRootState } from 'types'

interface State {
  practice: null | Practice
}

const initialState: State = {
  practice: null,
}

export const practiceSlice = createSlice({
  name: 'practice',
  initialState,
  reducers: {
    setPractice: (state, { payload }: Payload<null | Practice>) => {
      state.practice = payload
    },
  },
})

export const { setPractice } = practiceSlice.actions

export const practiceReducer = practiceSlice.reducer

export const selectPractice = (state: ReduxRootState) => state.practice.practice
