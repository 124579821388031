import { LocalStorageItem } from 'constants/storage-constants'

import { useCallback, useEffect } from 'react'

import { oAuthApi, selectAuth } from 'components/features/Auth/api'
import { addToast, setAppLoading } from 'components/features/Other/api'
import { userApi } from 'components/features/User/api'
import { useAppDispatch, useAppSelector, useIsMount } from 'hooks'
import { useSearchParams } from 'react-router-dom'

export const useInitiateUser = () => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { refreshToken: localRefreshToken, isAuthenticated } =
    useAppSelector(selectAuth)

  const [performTokenRefresh] = oAuthApi.useRefreshTokenMutation()
  const httpGetUser = userApi.endpoints.getCurrentUser.useQueryState(null)

  useEffect(() => {
    if (httpGetUser.isSuccess) {
      localStorage.setItem(LocalStorageItem.USER_ID, httpGetUser.data.data.id)
    }
  }, [httpGetUser.data, httpGetUser.isSuccess])

  const getSSOToken = useCallback(() => {
    const ssoRefreshToken = searchParams.get('t') || null
    if (ssoRefreshToken) {
      searchParams.delete('t')
    }

    const ssoErrorMessage = searchParams.get('e')
    if (ssoErrorMessage) {
      searchParams.delete('e')
      dispatch(addToast({ color: 'error', content: ssoErrorMessage }))
    }

    setSearchParams(searchParams)
    return ssoRefreshToken
  }, [dispatch, searchParams, setSearchParams])

  const checkTokenOnStart = useCallback(async () => {
    let refreshToken = localRefreshToken
    if (!isAuthenticated) {
      refreshToken = getSSOToken() || localRefreshToken
    }

    if (refreshToken && !isAuthenticated) {
      await performTokenRefresh(refreshToken)
    }

    dispatch(setAppLoading(false))
  }, [
    getSSOToken,
    localRefreshToken,
    isAuthenticated,
    dispatch,
    performTokenRefresh,
  ])

  const isMount = useIsMount()

  useEffect(() => {
    if (isMount) {
      checkTokenOnStart()
    }
  }, [isAuthenticated, isMount, checkTokenOnStart])
}
