import DocRevisionEnum, {
  DocRevisions,
} from 'components/features/Documents/enums/DocRevision'

export const getPreferredStatus = (disabled: DocRevisions[]): DocRevisions => {
  if (!disabled.includes(DocRevisionEnum.published)) {
    return DocRevisionEnum.published
  }
  if (!disabled.includes(DocRevisionEnum.approval)) {
    return DocRevisionEnum.approval
  }
  if (!disabled.includes(DocRevisionEnum.draft)) {
    return DocRevisionEnum.draft
  }

  return DocRevisionEnum.draft
}

export const isValidStatus = (status: string | null) => {
  const validRevisions: string[] = [
    DocRevisionEnum.draft,
    DocRevisionEnum.approval,
    DocRevisionEnum.published,
  ]

  return !!status && !!validRevisions.includes(status)
}
