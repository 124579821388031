import { createApi } from '@reduxjs/toolkit/query/react'
import { formDataBaseQueryWithReAuth } from 'redux-store/services/services.utils'
import { ApiSuccessResponse, UploadedFile } from 'types'

import {
  DownloadUrl,
  GetDownloadUrlRequest,
} from '../../Documents/api/documentApi.types'

type ReplaceFileRequest = {
  oldFileId: string
  newFileId: string
}

export const filesApi = createApi({
  reducerPath: 'filesApi',
  baseQuery: formDataBaseQueryWithReAuth,
  endpoints: (builder) => ({
    uploadFile: builder.mutation<{ data: UploadedFile }, File>({
      query: (file) => {
        const body = new FormData()
        body.append('file', file)

        return {
          url: 'files/upload',
          method: 'POST',
          body: body,
        }
      },
    }),

    deleteFiles: builder.mutation<any, string>({
      query: (fileId) => ({ url: `files/${fileId}/hard`, method: 'DELETE' }),
    }),

    replaceFile: builder.mutation<null, ReplaceFileRequest>({
      query: ({ newFileId, oldFileId }) => ({
        url: `files/${oldFileId}/replace-with/${newFileId}`,
        method: 'POST',
      }),
    }),

    getFileDownloadUrlById: builder.query<
      ApiSuccessResponse<DownloadUrl>,
      GetDownloadUrlRequest
    >({
      query: ({ id, format }) =>
        `files/${id}/download-url${format === 'pdf' ? '?format=pdf' : ''}`,
    }),
  }),
})
