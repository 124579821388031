import { OfficeName } from 'constants/application-constants'

import { Location as RouterLocation } from 'react-router-dom'

export const getBackgroundLocation = (
  location: RouterLocation,
  practiceId?: string,
  isFrontOffice?: boolean
) => {
  if (!practiceId) {
    return '/practices'
  }

  return `/${
    isFrontOffice ? OfficeName.FRONT : OfficeName.BACK
  }/practices/${practiceId}${location.search}${location.hash}`.trim()
}
