import { Button, Tooltip, Typography } from 'components/common'
import { t } from 'i18next'
import { Edit2 } from 'react-feather'

import styles from './MissingDoc.module.scss'

interface Props {
  title: string
  onActionClick: () => void
}

const MissingDoc = ({ title, onActionClick }: Props) => {
  return (
    <div className={styles.document}>
      <Typography element={'span'} fontWeight={'regular'} size={'small'}>
        {title}
      </Typography>
      <Tooltip content={t('approvalStart.step3DocumentTip')} position={'left'}>
        <Button
          size="small"
          icon={<Edit2 />}
          variant="subtle"
          onClick={onActionClick}
          aria-label={t('approvalStart.step3DocumentTip') ?? ''}
        />
      </Tooltip>
    </div>
  )
}

export default MissingDoc
