import { LocalStorageItem } from 'constants/storage-constants'

import { createSlice, nanoid } from '@reduxjs/toolkit'
import { Toast } from 'types'

interface UIState {
  isAppLoading: boolean
  navigation: {
    isOpen: boolean
  }
  toasts: Toast[]
}

const initialState: UIState = {
  isAppLoading: true,
  navigation: {
    isOpen:
      localStorage.getItem(LocalStorageItem.DEFAULT_NAV_PREFERENCE) === 'open',
  },
  toasts: [],
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setAppLoading(state, { payload }: { payload: boolean }) {
      state.isAppLoading = payload
    },
    setNavigationOpen(state, { payload }: { payload: boolean }) {
      state.navigation.isOpen = payload
      localStorage.setItem(
        LocalStorageItem.DEFAULT_NAV_PREFERENCE,
        payload ? 'open' : 'closed'
      )
    },
    addToast(state, { payload }: { payload: Omit<Toast, 'id'> }) {
      const toast = {
        ...payload,
        id: nanoid(),
      }
      state.toasts.push(toast)
    },
    removeToast(state, { payload }: { payload: Toast['id'] }) {
      state.toasts = state.toasts.filter((toast) => toast.id !== payload)
    },
  },
})

export const { setAppLoading, setNavigationOpen, addToast, removeToast } =
  uiSlice.actions

export const uiReducer = uiSlice.reducer

export * from './uiSlice.selectors'

export default uiReducer
