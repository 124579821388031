export const dropdownPosition = (
  parentRef: HTMLElement | null,
  childRef: HTMLElement | null,
  options: {
    defaultPosition?: 'top' | 'bottom' | 'auto'
  }
) => {
  /**
   TODO:
    * enhance return positions: top, bottom, left, right
   (will be needed for Tooltip)
   */

  const { defaultPosition = 'auto' } = options

  if (!parentRef) return defaultPosition
  if (!childRef) return defaultPosition

  const parentRect = parentRef.getBoundingClientRect()
  const childRect = childRef.getBoundingClientRect()

  const windowHeight = window.innerHeight
  const remainingSpace = windowHeight - parentRect.bottom

  if (remainingSpace < childRect.height) {
    return 'top'
  }

  return defaultPosition ?? 'auto'
}

export const pxToRem = (px: number, baseFontSize: number = 16) => {
  return `${px / baseFontSize}rem`
}
