import { useState } from 'react'

import './Tree.scss'
import { ArrowChevronForward } from 'assets/Icons'
import RcTree from 'rc-tree'
import { EventDataNode, Key } from 'rc-tree/lib/interface'

import TreeCheckbox from './TreeCheckbox'

const FIELD_NAMES = {
  key: 'id',
  title: 'label',
  children: 'children',
} as const

interface TreeNode {
  id: string
  label?: string
  children?: TreeNode[]
  [x: string | number]: unknown
}

export type TreeProps = {
  data: TreeNode[]

  activeKey?: Key
  onActiveKeyChange?: (key: Key) => void

  checkedKeys: Key[]
  onItemSelect?: (items: string[], item: TreeNode) => void

  defaultCheckedKeys?: (string | number)[]
}

const Tree = ({
  data,
  checkedKeys,
  onItemSelect,
  activeKey,
  onActiveKeyChange,
  defaultCheckedKeys,
}: TreeProps) => {
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([])

  const handleExpand = (expandedKeys: any) => {
    setExpandedKeys(expandedKeys)
  }

  const handleCheck = (
    checkedKeys: Key[],
    checkInfo: EventDataNode<{
      node: { key: string; title?: string; children?: any[] }
    }>
  ) => {
    if (onItemSelect) {
      onItemSelect(checkedKeys as string[], {
        id: checkInfo.node.key,
        label: checkInfo.node.title ?? checkInfo.node.key,
        children: checkInfo.node.children,
      })
    }
  }

  return (
    <RcTree
      defaultCheckedKeys={defaultCheckedKeys}
      treeData={data as any}
      fieldNames={FIELD_NAMES}
      rootClassName="tree-root"
      prefixCls={'tree'}
      focusable={true}
      multiple={true}
      checkedKeys={checkedKeys}
      onCheck={handleCheck as any}
      expandedKeys={expandedKeys}
      onExpand={handleExpand}
      activeKey={activeKey}
      onActiveChange={onActiveKeyChange}
      selectable={false}
      // checkable element - Used to trigger select from anywhere on the item
      checkable={<span className="tree-checkbox-background" />}
      icon={(props) => (
        <TreeCheckbox
          active={!!props.active}
          variant={
            props.checked
              ? 'checked'
              : props.halfChecked
              ? 'half-checked'
              : 'unchecked'
          }
        />
      )}
      switcherIcon={<ArrowChevronForward />}
    />
  )
}

export default Tree
