const Mail = () => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5C1 3.89543 1.89543 3 3 3H23C24.1046 3 25 3.89543 25 5V19C25 20.1046 24.1046 21 23 21H3C1.89543 21 1 20.1046 1 19V5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M2.28033 4.28033C1.80786 3.80786 2.14248 3 2.81066 3H23.1893C23.8575 3 24.1921 3.80786 23.7197 4.28033L15.1213 12.8787C13.9497 14.0503 12.0503 14.0503 10.8787 12.8787L2.28033 4.28033Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Mail
