export enum LocalStorageItem {
  ACCESS_TOKEN = '_at',
  REFRESH_TOKEN = '_rt',
  USER_ID = '_uid',
  DEFAULT_NAV_PREFERENCE = '_ui_def_nav_pref',
  LANDING_LOCATION = '_lloc',
}

export enum SessionStorageItem {
  PREV_LOC = 'ploc',
  HISTORY = 'hist',
}
