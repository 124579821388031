import { FileInformation } from 'redux-store/models'

import { DisplayedFile, DocumentCategories } from '../../types'

export const createFileData = (file: FileInformation): DisplayedFile => {
  const fileType = extractDocCategory(file.extension)

  return {
    type: fileType,
    extension: file.extension,
    id: file.id,
    mime: file.mime,
    src: file.public_path,
    name: file.filename,
    pdf:
      fileType === 'pdf'
        ? {
            progress: file.pdf_status,
            publicSrc: file.public_pdf_path,
            filename: file.pdf_filename,
          }
        : null,
  }
}

const extractDocCategory = (extension: string): DocumentCategories => {
  const extDb = new Map()

  // 'pdf' also includes are transload-it friendly that are not images, videos or audio
  extDb.set('pdf', [
    'doc',
    'docx',
    'html',
    'xhtml',
    'xml',
    'pdf',
    'ai',
    'eps',
    'ps',
    'txt',
    'text',
    'csv',
    'xls',
    'xlsx',
    'xla',
    'oda',
    'odt',
    'odd',
    'ott',
    'ppt',
    'pptx',
    'ppz',
    'pps',
    'pot',
    'rtf',
    'rtx',
    'latex',
    'vtt',
    'srt',
  ])
  extDb.set('image', ['jpg', 'jpeg', 'png', 'gif', 'svg'])
  extDb.set('video', ['mp4', 'mov', '3gp', 'mov'])
  extDb.set('audio', ['mp3', 'wav', 'ogg', 'aac'])

  if (extDb.get('pdf').includes(extension)) {
    return 'pdf'
  } else if (extDb.get('image').includes(extension)) {
    return 'image'
  } else if (extDb.get('video').includes(extension)) {
    return 'video'
  } else if (extDb.get('audio').includes(extension)) {
    return 'audio'
  } else {
    return 'unviewable'
  }
}
