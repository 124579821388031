import React, { useEffect, useMemo, useState } from 'react'

import clsx from 'clsx'
import {
  Avatar,
  Button,
  IconContainer,
  LoadingIndicator,
  TextField,
  Typography,
} from 'components/common'
import { userApi } from 'components/features/User/api'
import { useDebounce } from 'hooks'
import { Frown, Trash2 } from 'react-feather'
import { User } from 'types'

import styles from './AddCollaboratorsForm.module.scss'
import { practicesApi } from '../../api'

interface AddCollaboratorsFormProps {
  practiceId: string
  className?: string
}

interface CollaboratorState {
  [userId: string]: User | null
}

const createCollaboratorStateData = (collaborator: User) => ({
  [collaborator.id]: collaborator,
})

const updateCollaboratorsState =
  (updatedCollaborators: User[]) => (prevState: CollaboratorState) => {
    let newCollaborators = { ...prevState }
    updatedCollaborators.forEach((user) => {
      newCollaborators = {
        ...newCollaborators,
        ...createCollaboratorStateData(user),
      }
    })

    return newCollaborators
  }

const AddCollaboratorsForm = ({
  className,
  practiceId,
}: AddCollaboratorsFormProps): JSX.Element => {
  const classes = clsx(styles.root, className)

  const [userSearch, setUserSearch] = useState<string>('')
  const debouncedInput = useDebounce(userSearch, 500)
  const [selectedCollaborators, setSelectedCollaborators] =
    useState<CollaboratorState>({})

  const getCollaboratorsApi = practicesApi.useGetCollaboratorsByPracticeIdQuery(
    practiceId ?? '',
    {
      skip: practiceId.length <= 0,
    }
  )

  const validAutocompleteInput = debouncedInput.length > 2
  const searchUserApi = userApi.useGetPrivilegedUsersListQuery(
    {
      search: debouncedInput,
    },
    {
      skip: !validAutocompleteInput,
    }
  )

  const searchedUsersList = useMemo(() => {
    if (!searchUserApi.isSuccess) return []

    return searchUserApi.data.data.filter(
      (user) =>
        !(user.id in selectedCollaborators) || !selectedCollaborators[user.id]
    )
  }, [searchUserApi.data, searchUserApi.isSuccess, selectedCollaborators])

  const [postCollaborator, postCollaboratorApi] =
    practicesApi.useAddCollaboratorsMutation()
  const [deleteCollaborator, deleteCollaboratorApi] =
    practicesApi.useDeleteCollaboratorsMutation()

  useEffect(() => {
    if (getCollaboratorsApi.isSuccess) {
      setSelectedCollaborators(
        updateCollaboratorsState(getCollaboratorsApi.data.data)
      )
    }
  }, [getCollaboratorsApi.data, getCollaboratorsApi.isSuccess])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserSearch(event.target.value)
  }

  const handleRemoveCollaborator = (collaborator: User) => async () => {
    try {
      await deleteCollaborator({
        practiceId: practiceId ?? '',
        collaboratorId: collaborator.id,
      }).unwrap()
      getCollaboratorsApi.refetch()
      setSelectedCollaborators((prevState) => ({
        ...prevState,
        [collaborator.id]: null,
      }))
    } catch (error) {}
  }

  const handleInviteCollaborator = (userId: string) => async () => {
    try {
      await postCollaborator({
        practiceId: practiceId ?? '',
        userIds: [userId],
      }).unwrap()
      getCollaboratorsApi.refetch()
    } catch (error) {}
  }

  if (postCollaboratorApi.isLoading || deleteCollaboratorApi.isLoading) {
    return (
      <div className={styles.loading}>
        <LoadingIndicator />
      </div>
    )
  }

  return (
    <div className={classes}>
      <TextField
        label="Collaborator"
        value={userSearch}
        onChange={handleSearchChange}
        id="search-collaborator-input"
        className={styles.input}
        endAdornment={searchUserApi.isFetching && <LoadingIndicator />}
      />
      {!searchUserApi.isFetching && searchUserApi.isSuccess && (
        <div className={styles.userList}>
          {searchedUsersList.length > 0 &&
            searchedUsersList.map((user) => (
              <div
                key={`collaborator-${user.id}`}
                className={styles.userListItem}
              >
                <div className={styles.userInfo}>
                  <Avatar
                    content={user.initials}
                    src={user.avatar_public_path}
                  />
                  <Typography>{user.name}</Typography>
                </div>
                <Button
                  onClick={handleInviteCollaborator(user.id)}
                  variant="subtle"
                  size="x-small"
                >
                  Invite
                  <span className="sr-only">{user.name}</span>
                </Button>
              </div>
            ))}

          {searchUserApi.data.data.length <= 0 && (
            <Typography color="label" className={styles.noData}>
              <IconContainer icon={<Frown />} />
              No person with this name was found
            </Typography>
          )}
        </div>
      )}

      {Object.values(selectedCollaborators).length !== 0 &&
        !deleteCollaboratorApi.isLoading && (
          <div className={styles.userList}>
            {Object.values(selectedCollaborators)
              .filter((user) => Boolean(user))
              .map((user) => (
                <div
                  key={`selected-collaborator-${user?.id}`}
                  className={styles.userListItem}
                >
                  <div className={styles.userInfo}>
                    <Avatar
                      content={user?.initials as string}
                      src={user?.avatar_public_path}
                    />
                    <Typography>{user?.name}</Typography>
                  </div>
                  <Button
                    onClick={handleRemoveCollaborator(user as User)}
                    variant="subtle"
                    size="x-small"
                    icon={<Trash2 />}
                    iconLabel="Remove collaborator"
                  />
                </div>
              ))}
          </div>
        )}
    </div>
  )
}

export default AddCollaboratorsForm
