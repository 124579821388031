import { useRef } from 'react'

import { autoElementId } from 'helpers'

const useId = (prefix: string = 'id'): string => {
  const uuid = autoElementId(prefix)()
  const generatedId = useRef(uuid)

  return generatedId.current
}

export default useId
