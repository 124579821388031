import type { HTMLAttributes } from 'react'

import clsx from 'clsx'

import styles from './SectionHeader.module.scss'

export interface SectionHeaderProps extends HTMLAttributes<HTMLDivElement> {
  title: string
  description?: string | null
  children?: React.ReactNode
  small?: boolean
}

export const SectionHeader = ({
  title,
  description,
  children,
  small,
  ...props
}: SectionHeaderProps) => {
  const classes = clsx(styles.root)

  return (
    <div className={classes} {...props}>
      <div className={styles.textWrapper}>
        {small ? (
          <h3 className={styles.smallTitle}>{title}</h3>
        ) : (
          <h2 className={styles.title}>{title}</h2>
        )}
        {description && <p className={styles.description}>{description}</p>}
      </div>

      {children}
    </div>
  )
}

export default SectionHeader
