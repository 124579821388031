// import DetailsHeader from './DetailsHeader'
import clsx from 'clsx'

import DocumentDetailsHeader from './DocumentDetailsHeader'
import styles from './DocumentDetailsLayout.module.scss'

interface DetailsLayoutProps {
  children: React.ReactNode
  mainClassName?: string
}

const DocumentDetailsLayout = ({
  children,
  mainClassName,
}: DetailsLayoutProps): JSX.Element => {
  return (
    <div className={styles.root}>
      <DocumentDetailsHeader />
      <main className={clsx(styles.main, mainClassName)}>{children}</main>
    </div>
  )
}

export default DocumentDetailsLayout
