import { useEffect } from 'react'

import { useAppDispatch } from 'hooks'
import { useSearchParams } from 'react-router-dom'
import { Revision } from 'redux-store/models'

import { practicesApi, setCurrentRevision } from '../api'

/**
 *
 * Hook used for setting the revision status in query params according to view type(front-office/back-office) and practice ID
 *
 * @param isFrontOffice - {Boolean} - is the current view front office?
 * @param practiceId - {string} - id of the practice for which the status needs to be set
 * @returns current revision
 */
export const useFrontOfficeParamRevisionStatus = (practiceId: string) => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const httpGetPractice = practicesApi.useGetMinimalPracticeByIdQuery(
    practiceId,
    {
      skip: !practiceId,
    }
  )

  // TODO: causes lost language parameter (ml)
  // const setRevisionParam = useCallback(() => {
  //   searchParams.set(PracticeQueryParams.REVISION_STATUS, 'published')
  //   setSearchParams(searchParams, { replace: true })
  // }, [searchParams, setSearchParams])
  //
  // useEffect(() => {
  //   if (httpGetPractice.isSuccess) {
  //     setRevisionParam()
  //   }
  // }, [httpGetPractice.data, httpGetPractice.isSuccess, setRevisionParam])

  useEffect(() => {
    let revision: null | Revision = null

    if (httpGetPractice.isSuccess) {
      revision = httpGetPractice.data.data.latest_published_revision
    }

    dispatch(setCurrentRevision(revision))

    return () => {
      dispatch(setCurrentRevision(null))
    }
  }, [dispatch, httpGetPractice.data, httpGetPractice.isSuccess, searchParams])
}
