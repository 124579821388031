import { useState } from 'react'

type SortDirs = 'asc' | 'desc'

type UseSortableInitialData = {
  sortByDefault?: string
  sortDirDefault?: SortDirs
}

const useSortable = <T>({
  sortByDefault,
  sortDirDefault,
}: UseSortableInitialData) => {
  const [sortBy, setSortBy] = useState<T | undefined>(sortByDefault as any)
  const [sortDir, setSortDir] = useState<SortDirs>(sortDirDefault as SortDirs)

  const handleSortClick = (nextSortBy: T) => {
    if (nextSortBy !== sortBy) {
      setSortBy(nextSortBy)
    } else {
      if (sortDir === 'asc') {
        setSortDir('desc')
      } else {
        setSortDir('asc')
      }
    }
  }

  return { sortBy, sortDir, handleSortClick }
}

export default useSortable
