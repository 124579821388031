import React, { forwardRef, useRef } from 'react'

import { InputSizes } from 'types'

import styles from './TextField.module.scss'
import BasicInput from '../BasicInput/BasicInput'
import InputRoot from '../InputRoot/InputRoot'

export interface TextFieldProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  id: string
  label?: string | null

  size?: InputSizes
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode

  disabled?: boolean
  type?: Extract<
    React.InputHTMLAttributes<HTMLInputElement>['type'],
    'text' | 'email' | 'password' | 'number' | 'date'
  >

  isClearable?: boolean
  isError?: boolean
}

const TextFieldComponent = forwardRef<any, TextFieldProps>(
  (
    {
      id,
      label,
      startAdornment,
      endAdornment,

      className,
      onChange,
      disabled,
      size = 'medium',
      type = 'text',
      value,
      isClearable: clearable,
      isError,
      ...rest
    },
    ref
  ): JSX.Element => {
    const inputRef = useRef<HTMLInputElement>(null)
    const isActive: boolean = Boolean(value)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange && !disabled) {
        onChange(event)
      }
    }

    const handleInputClear = () => {
      if (!disabled && onChange) {
        onChange({
          target: { value: '' },
        } as React.ChangeEvent<HTMLInputElement>)

        focusField()
      }
    }

    const focusField = () => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }

    return (
      <InputRoot
        label={label ?? ''}
        id={id}
        className={className}
        disabled={disabled}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        handleContainerClick={focusField}
        handleClear={handleInputClear}
        isClearable={clearable}
        isActive={isActive}
        size={size}
        isError={isError}
      >
        <BasicInput
          id={id}
          ref={ref ? ref : inputRef}
          type={type}
          disabled={disabled}
          onChange={handleChange}
          className={styles.input}
          value={value}
          {...rest}
        />
      </InputRoot>
    )
  }
)

const TextField = TextFieldComponent

export default TextField
