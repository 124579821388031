import { Card, IconContainer, Typography } from 'components/common'
import { t } from 'i18next'
import { Calendar, HelpCircle } from 'react-feather'

import styles from './RevisionCycleCard.module.scss'
import { formatDateNumeric } from '../../../../../helpers'
import { Practice, Revision } from '../../../../../redux-store/models'
import WarningButtonPopover from '../../../../common/ButtonPopover/WarningButtonPopover'

interface RevisionCycleCardProps {
  practice: Practice | null
  currentRevision: Revision
  toggleModal: () => void
}

const RevisionCycleCard = ({
  practice,
  currentRevision,
  toggleModal,
}: RevisionCycleCardProps) => {
  return (
    <Card data-testid="revision-cycle-card" className={styles.root}>
      <IconContainer
        className={styles.roundIcon}
        icon={<Calendar />}
        size="small"
        color="primary"
        withBackground={true}
      />
      <div className={styles.section}>
        <Typography color="label" size="small" fontWeight="medium" element="h4">
          {t('practice.nextRevisionCycle')}
        </Typography>
        <Typography size="x-large" fontWeight="bold">
          {currentRevision.expires_at
            ? formatDateNumeric(currentRevision.expires_at)
            : t('practice.expiryDateNotSet')}
        </Typography>
      </div>
      {practice?.is_outdated && (
        <div className={styles.section}>
          <WarningButtonPopover isFrontOffice={false} />
        </div>
      )}
      {!practice?.is_outdated && (
        <div className={styles.section}>
          <span className={styles.fakeLink} onClick={toggleModal}>
            <IconContainer
              className={styles.left}
              icon={<HelpCircle />}
              size="x-small"
              color="default"
              withBackground={false}
            />
            {t('practice.isRevisionOverdue')}
          </span>
        </div>
      )}
    </Card>
  )
}

export default RevisionCycleCard
