import React, { useState } from 'react'

type UseDragAndDropCallback = (files: DataTransferItemList) => void

const useDragAndDrop = (callback: UseDragAndDropCallback) => {
  const [dragOver, setDragOver] = useState<boolean>(false)
  const [fileDropError, setFileDropError] = useState<string>('')

  const onDragOver = (event: React.DragEvent) => {
    event.stopPropagation()
    event.preventDefault()

    setDragOver(true)
  }

  const onDragEnter = (event: React.DragEvent) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const onDragLeave = (event: React.DragEvent) => {
    event.stopPropagation()
    event.preventDefault()

    setDragOver(false)
  }

  const onDrop = (event: React.DragEvent) => {
    event.stopPropagation()
    event.preventDefault()

    if (callback) callback(event.dataTransfer.items)
  }

  return {
    dragOver,
    setDragOver,
    onDragOver,
    onDragEnter,
    onDragLeave,
    fileDropError,
    setFileDropError,
    onDrop,
  }
}

export default useDragAndDrop
