import { Mail } from 'assets/Icons'
import { Button } from 'components/common'
import { FTextField } from 'components/formik-factory'
import { Form, Formik } from 'formik'
import Logger from 'helpers/logger'
import { useGenericAPIError } from 'hooks'
import { t } from 'i18next'

import styles from './SsoForm.module.scss'
import { ssoValidationSchema } from './SsoForm.validation-schema'
import { oAuthApi } from '../../api'

interface SsoFormValues {
  email: string
}

const initialValues: SsoFormValues = {
  email: '',
}

const SsoForm = () => {
  const [performSsoLogin, { isFetching, isError }] =
    oAuthApi.useLazySsoUrlLoginQuery()

  useGenericAPIError(isError)

  const handleSubmit = async ({ email }: SsoFormValues) => {
    try {
      const response = await performSsoLogin(email).unwrap()

      if (!response.data) {
        throw new Error(t('error.general') ?? '')
      }

      const redirectUrl = new URL(response.data['redirect-to'])
      redirectUrl.searchParams.append('email', email)

      // simulates a HTTP redirect
      window.location.replace(redirectUrl)
    } catch (error) {
      Logger.error(error)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ssoValidationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form className={styles.root}>
          <FTextField
            id="sso-email"
            name="email"
            type="email"
            label={t('sso.email')}
            startAdornment={<Mail />}
            size="medium"
            className={styles.input}
          />
          <Button type="submit" disabled={isFetching}>
            {t('sso.submit')}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default SsoForm
