import { useEffect, useState } from 'react'

import { Check } from 'assets/Icons'
import clsx from 'clsx'
import {
  Badge,
  Button,
  Card,
  IconContainer,
  LoadingIndicator,
  SectionHeader,
  Typography,
} from 'components/common'
import { LanguageBar } from 'components/features/Other/components/LanguageBar'
import { userApi } from 'components/features/User/api'
import {
  FMultiSelect,
  FSelect,
  FTextArea,
  FTextField,
} from 'components/formik-factory'
import { Form, FormikProps, withFormik } from 'formik'
import { preventSubmitOnEnter } from 'helpers'
import { clamp } from 'helpers/num-helpers'
import { usePopulateMetadata } from 'hooks'
import { t } from 'i18next'

import styles from './DocumentForm.module.scss'
import { documentFormSchema } from './DocumentForm.validation-schema'
import {
  DocumentsFormProps,
  DocumentsFormValues,
  FormMetadataValues,
  Option,
} from './types'
import {
  createEmptyMetadata,
  createInitialData,
  mapMetadataToForm,
} from './utils'

const EmptyDiv = () => <div />

const DocumentsFormComponent = ({
  onValidityChange,
  practiceMetadata,
  defaultLanguage,
  titleIdPrefix,
  onApplyMetadata,
  onValuesChange,
  onDirtyChange,
  onSubmit: _onSubmit, // used in 'withFormik' HOC
  defaultValues: _defaultValues, // used in 'withFormik' HOC
  errors,
  values,
  setFieldValue,
  isValid,
  dirty,
  validationLevel,
}: DocumentsFormProps & FormikProps<DocumentsFormValues>) => {
  const idPrefix = titleIdPrefix ? titleIdPrefix : ''

  const isStrictValidation =
    typeof validationLevel === 'string' && validationLevel === 'strict'

  const [languageTab, setLanguageTab] = useState(0)
  const [metadataApplied, setMetadataApplied] = useState(false)

  const options = usePopulateMetadata('document')
  const userIdApi = userApi.useGetUserByIdQuery(
    values.metadata[languageTab]?.contact?.id ?? '',
    {
      skip:
        !values.metadata ||
        values.metadata.length - 1 > languageTab ||
        !values.metadata[languageTab] ||
        !values.metadata[languageTab].contact,
    }
  )

  useEffect(() => {
    setMetadataApplied(false)
  }, [practiceMetadata])

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(dirty && isValid)
    }
  }, [dirty, isValid, onValidityChange])

  useEffect(() => {
    if (onValuesChange) {
      onValuesChange(values)
    }
  }, [onValuesChange, values])

  useEffect(() => {
    if (onDirtyChange) {
      onDirtyChange(dirty)
    }
  }, [dirty, onDirtyChange])

  const getFieldName = (index: number) => (name: keyof FormMetadataValues) => {
    return `metadata.${index}.${name}`
  }

  const updateTab = (newTabValue: number) => {
    setLanguageTab(clamp(newTabValue))
  }

  const handleApplyMetadata = () => {
    if (!practiceMetadata || practiceMetadata.length < 1) {
      return
    }

    if (onApplyMetadata) {
      onApplyMetadata()
    }

    setFieldValue(
      'metadata',
      mapMetadataToForm(practiceMetadata, values.metadata)
    )

    setMetadataApplied(true)
  }

  const handleLanguage = {
    add: (language: string) => {
      setFieldValue('metadata', [
        ...values.metadata,
        createEmptyMetadata({ language }),
      ])

      setLanguageTab(values.metadata.length) // switches to latest tab
    },
    remove: (language: string) => {
      setFieldValue(
        'metadata',
        values.metadata.filter((option) => option.language !== language)
      )
      setLanguageTab(0) // switch to first tab
    },
  }

  return (
    <Form
      id={idPrefix + 'form'}
      className={styles.root}
      onKeyDown={preventSubmitOnEnter}
    >
      <div className={styles.row}>
        <FSelect
          label={t('documentForm.docType')}
          id="type"
          name="type"
          options={options.docTypes}
        />
        <FSelect
          label={t('documentForm.fileLanguage')}
          id="fileLanguage"
          name="fileLanguage"
          options={options.languages}
        />
      </div>
      {!!practiceMetadata && practiceMetadata.length >= 1 && (
        <div className={styles.row}>
          <Card color="primary" className={styles.applyMetadata} noDropShadow>
            <Card.Body className={styles.body}>
              <div className={styles.content}>
                <Typography fontWeight="bold">
                  {t('documentForm.applyMetadataTitle')}
                </Typography>
                <Typography color="label">
                  {t('documentForm.applyMetadataSubtitle')}
                </Typography>
              </div>

              {metadataApplied ? (
                <Badge color="success" className={styles.applied}>
                  <IconContainer icon={<Check />} size="small" />
                  {t('documentForm.metadataApplied')}
                </Badge>
              ) : (
                <Button
                  type="button"
                  size="small"
                  variant="secondary"
                  className={styles.button}
                  onClick={handleApplyMetadata}
                >
                  {t('documentForm.applyMetadataAction')}
                </Button>
              )}
            </Card.Body>
          </Card>
        </div>
      )}

      <LanguageBar
        activeTabIndex={languageTab}
        languageOptions={options.languages}
        currentLanguages={values?.metadata?.map(({ language }) => language)}
        defaultLanguage={defaultLanguage}
        errors={null && errors && errors.metadata}
        onAddLanguage={handleLanguage.add}
        onRemoveLanguage={handleLanguage.remove}
        onTabClick={updateTab}
        className={styles.languageBar}
      />
      {values.metadata.map((item, index) => {
        const fieldName = getFieldName(index)

        return (
          <div
            key={'new-doc-metadata' + item.language}
            className={clsx(styles.formBody, {
              [styles.active]: index === languageTab,
            })}
          >
            <fieldset className={styles.section} id={idPrefix + 'general'}>
              <SectionHeader title={t('metadata.general')} small />
              <div className={styles.row}>
                <FTextField
                  name={fieldName('title')}
                  id={fieldName('title')}
                  label={t('metadata.title')}
                  helperText={t('documentForm.titleHelper')}
                />
                <EmptyDiv />
              </div>
              <div className={styles.row}>
                <FTextField
                  optional
                  name={fieldName('longTitle')}
                  id={fieldName('longTitle')}
                  label={t('metadata.subject')}
                  helperText={t('documentForm.subjectHelper')}
                />
              </div>
              <div className={styles.row}>
                <FTextArea
                  optional={!isStrictValidation}
                  name={fieldName('description')}
                  id={fieldName('description')}
                  label={t('metadata.description')}
                  helperText={t('documentForm.descriptionHelper')}
                />
              </div>
              <div className={styles.row}>
                <FTextField
                  name={fieldName('code')}
                  id={fieldName('code')}
                  label={t('metadata.code')}
                />
                <EmptyDiv />
              </div>
            </fieldset>
            <fieldset className={styles.section} id={idPrefix + 'scope'}>
              <SectionHeader title={t('metadata.scope')} small />
              <div className={styles.row}>
                <FMultiSelect
                  optional={!isStrictValidation}
                  name={fieldName('departments')}
                  label={t('metadata.departments')}
                  data={options.departments as Option[]}
                />
                <FTextField
                  optional={!isStrictValidation}
                  name={fieldName('targetGroup')}
                  id={fieldName('targetGroup')}
                  label={t('metadata.targetGroup')}
                />
              </div>
              <div className={styles.row}>
                <FMultiSelect
                  optional={!isStrictValidation}
                  name={fieldName('locations')}
                  label={t('metadata.locations')}
                  data={options.locations as Option[]}
                />
                <FMultiSelect
                  optional={!isStrictValidation}
                  name={fieldName('countries')}
                  label={t('metadata.countries')}
                  data={options.countries as Option[]}
                />
              </div>
              <div className={styles.row}>
                <FMultiSelect
                  optional={!isStrictValidation}
                  name={fieldName('legalEntities')}
                  label={t('metadata.legalEntities')}
                  data={options.legalEntities as Option[]}
                />
                <FTextField
                  optional={!isStrictValidation}
                  name={fieldName('effectiveAt')}
                  id={fieldName('effectiveAt')}
                  label={t('metadata.effectiveAt')}
                  type="date"
                />
              </div>
              <div className={styles.row}>
                <FMultiSelect
                  optional={!isStrictValidation}
                  name={fieldName('keywords')}
                  label={t('metadata.keywords')}
                  creatable
                  createOnBlur
                  hideDropdown
                />
              </div>
            </fieldset>
            <fieldset className={styles.section} id={idPrefix + 'topic'}>
              <SectionHeader title={t('metadata.topic')} small />

              <div className={styles.row}>
                <FSelect
                  label={t('metadata.topic')}
                  name={fieldName('mainTopic')}
                  options={options.flatTopics}
                />
              </div>
              <div className={styles.row}>
                <FMultiSelect
                  optional
                  name={fieldName('altTopic')}
                  label={t('metadata.altTopic')}
                  data={options.topics as Option[]}
                />
              </div>
            </fieldset>
            <fieldset className={styles.section} id={idPrefix + 'references'}>
              <SectionHeader title={t('metadata.references')} small />
              <div className={styles.row}>
                <FSelect
                  label={t('metadata.accessRights')}
                  name={fieldName('accessRights')}
                  options={options.accessRights as any}
                />
                <FMultiSelect
                  optional
                  name={fieldName('collections')}
                  label={t('metadata.collections')}
                  data={options.collections as Option[]}
                />
              </div>
              <div className={styles.row}>
                <FMultiSelect
                  optional
                  name={fieldName('internalReferenceLinks')}
                  label={t('metadata.internalReferences')}
                  data={options.guidelines as Option[]}
                />
                <FTextField
                  optional
                  name={fieldName('externalReferenceLinks')}
                  id={fieldName('externalReferenceLinks')}
                  label={t('metadata.externalReferences')}
                />
              </div>

              <div className={styles.row}>
                <FMultiSelect
                  optional
                  name={fieldName('certificationScope')}
                  label={t('metadata.scopeCertification')}
                  data={options.scopes as Option[]}
                />
                <EmptyDiv />
              </div>
            </fieldset>
            <fieldset className={styles.section} id={idPrefix + 'contact'}>
              <SectionHeader title={t('metadata.contact')} small />

              <div className={styles.row}>
                <FSelect
                  label={t('metadata.teamMember')}
                  name={fieldName('contact')}
                  options={options.users}
                  dropdownPosition="top"
                />
                <EmptyDiv />
              </div>

              {userIdApi.isFetching && (
                <div className={styles.row}>
                  <LoadingIndicator />
                  <EmptyDiv />
                </div>
              )}
              {!userIdApi.isFetching && userIdApi.isSuccess && (
                <div className={styles.userRow}>
                  <div className={styles.userRow}>
                    {userIdApi.data.data?.departments &&
                      userIdApi.data.data.departments.length > 0 && (
                        <div className={styles.userInfo}>
                          <Typography size="small" color="label">
                            {t('documentForm.department')}
                          </Typography>
                          <Typography>
                            {userIdApi.data.data.departments
                              .map((dept) => dept.name)
                              .join(', ')}
                          </Typography>
                        </div>
                      )}
                    <div className={styles.userInfo}>
                      <Typography size="small" color="label">
                        {t('documentForm.email')}
                      </Typography>
                      <Typography>{userIdApi.data.data.email}</Typography>
                    </div>
                  </div>
                  <EmptyDiv />
                </div>
              )}
            </fieldset>
          </div>
        )
      })}
    </Form>
  )
}

const DocumentsForm = withFormik<DocumentsFormProps, DocumentsFormValues>({
  mapPropsToValues: (props) => {
    return createInitialData(props.defaultValues)
  },

  mapPropsToTouched: (props) => {
    if (props.validateOnMount === true) {
      const initialData = createInitialData(props.defaultValues)

      return {
        fileLanguage: true,
        type: true,
        metadata: initialData.metadata.map((dataObj) =>
          Object.keys(dataObj).reduce(
            (acc, key) => ({
              ...acc,
              [key]: true,
            }),
            {}
          )
        ),
      } as any
    }

    return {}
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    if (props.onSubmit) {
      props.onSubmit(values)
      setSubmitting(false)
    }
  },

  validationSchema: (props: DocumentsFormProps) => {
    const validationLevel = props.validationLevel ?? 'lax'

    return documentFormSchema(validationLevel)
  },
})(DocumentsFormComponent)

export default DocumentsForm
