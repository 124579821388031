import React, { useRef } from 'react'

import clsx from 'clsx'
import { useDragAndDrop } from 'hooks'
import { File as FileIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'

import styles from './UploadFile.module.scss'
import Avatar from '../Avatar/Avatar'
import Typography from '../Typography/Typography'

interface UploadFileProps {
  validationMessage?: string | null
  fileLimitMessage?: string | null
  onChange?: (file: File) => void
  onExceedLimit?: (file: File) => void
  className?: string
}

const FILE_UPLOAD_LIMIT = 5

const UploadFile = ({
  onChange,
  validationMessage,
  fileLimitMessage,
  onExceedLimit,
  className,
}: UploadFileProps): JSX.Element => {
  const dragAndDropCallback = (items: DataTransferItemList) => {
    // disable hover state
    setDragOver(false)

    // handle uploaded files
    ;[...items].forEach((item, idx) => {
      if (idx < FILE_UPLOAD_LIMIT && item.kind === 'file') {
        const file = item.getAsFile()

        if (file) {
          handleFile(file)
        }
      }
    })
  }

  const {
    setDragOver,
    onDragLeave,
    onDragEnter,
    onDragOver,
    dragOver,
    onDrop,
  } = useDragAndDrop(dragAndDropCallback)
  const { t } = useTranslation()

  const inputRef = useRef<HTMLInputElement | null>(null)

  // TODO: add validation

  const classes = clsx(
    styles.root,
    {
      [styles.isActive]: dragOver,
    },
    className
  )

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files as FileList

    const { limitedFiles, exceedingFiles } = handleValidation(files)

    if (onExceedLimit) {
      exceedingFiles.forEach((file: File) => onExceedLimit(file))
    }

    limitedFiles.forEach((file: File) => handleFile(file))

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const handleFile = (file: File) => {
    if (onChange) {
      onChange(file)
    }
  }

  const handleValidation = (files: FileList) => {
    const filesArray = Array.from(files)

    return {
      limitedFiles: filesArray.slice(0, FILE_UPLOAD_LIMIT),
      exceedingFiles: filesArray.slice(FILE_UPLOAD_LIMIT),
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    const { key } = event

    if (key === 'Enter') {
      inputRef.current?.click()
    }
  }

  return (
    <>
      <label
        htmlFor="upload-file"
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        className={classes}
        tabIndex={0}
        role="button"
        onKeyDown={handleKeyDown}
      >
        <Avatar content={<FileIcon />} size="small" />
        <div className={styles.actionMessage}>
          <Typography size="small" fontWeight="medium" color="primary">
            {t('uploadDocs.dropareaPrimary')}
          </Typography>
          &nbsp;
          <Typography color="label" size="small">
            {t('uploadDocs.dropareaSecondary')}
          </Typography>
        </div>
        {validationMessage && (
          <Typography color="label" size="small">
            {validationMessage}
          </Typography>
        )}
        {fileLimitMessage && (
          <Typography color="label" size="small">
            {fileLimitMessage}
          </Typography>
        )}
      </label>
      <input
        className={styles.input}
        type="file"
        name="file"
        id="upload-file"
        onChange={onFileChange}
        ref={inputRef}
        multiple
      />
    </>
  )
}

export default UploadFile
