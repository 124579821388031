import { lazy, Suspense, useState } from 'react'

import clsx from 'clsx'
import {
  Card,
  DefaultLayout,
  LoadingIndicator,
  PageTitle,
  Section,
  SectionHeader,
} from 'components/common'
import AnnouncementCard from 'components/features/Dashboard/components/AnnouncementCard/AnnouncementCard'
import useIsBelowBreakpoint from 'hooks/useIsBelowBreakpoint'
import { useTranslation } from 'react-i18next'

import styles from './FrontOfficePractices.module.scss'
import { Permissions } from '../../../../../constants/application-constants'
import { usePermissions } from '../../../../../hooks'
import { announcementsApi } from '../../../Dashboard/api/announcementsApi'
import { practicesApi } from '../../api'
import PracticesTable from '../../components/PracticesTable/PracticesTable'

const SearchBarMobile = lazy(
  () =>
    import('components/features/Search/components/SearchBar/SearchBarMobile')
)
const SearchBar = lazy(
  () => import('components/features/Search/components/SearchBar/SearchBar')
)

const ChapsSidebar = lazy(
  () =>
    import('components/features/Search/components/ChapsSidebar/ChapsSidebar')
)

const FrontOfficePractices = (): JSX.Element => {
  const { t } = useTranslation()
  const [showChapsSidebar, setShowChapsSidebar] = useState<boolean>(false)
  const httpGetAllPractices = practicesApi.useGetRecommendedPracticesQuery({
    limit: 15,
  })
  const httpGetAnnouncements = announcementsApi.useGetAnnouncementsQuery(null)
  const perms = usePermissions().mixed
  const canUpdateAnnouncements = !!perms
    ? perms[Permissions.UPDATE_ANNOUNCEMENT] ?? false
    : false

  const isTouchBp = useIsBelowBreakpoint('tabLand')

  const [updatesCollapsed, setUpdatesCollapsed] = useState(true)

  const toggleChapsBar = () => {
    setShowChapsSidebar((prevState) => !prevState)
  }

  return (
    <>
      <DefaultLayout
        bodyProps={{
          className: styles.body,
        }}
      >
        <PageTitle title={t('home.title')}>
          {!isTouchBp ? (
            <Suspense fallback={<div />}>
              <SearchBar toggleChapsBar={toggleChapsBar} />
            </Suspense>
          ) : (
            <Suspense fallback={<div />}>
              <SearchBarMobile />
            </Suspense>
          )}
        </PageTitle>

        {httpGetAnnouncements.isSuccess &&
          (canUpdateAnnouncements ||
            httpGetAnnouncements.data?.data.filter((a) => a.content).length >
              0) && (
            <Section className={clsx(styles.section, styles.highlightSection)}>
              <SectionHeader title={t('home.updates')} />

              <div className={styles.highlights}>
                {httpGetAnnouncements.data?.data.map((item) => {
                  if (!item.content && !canUpdateAnnouncements) return <></>
                  return (
                    <AnnouncementCard
                      key={item.id}
                      canUpdateAnnouncements={canUpdateAnnouncements}
                      className={styles.highlight}
                      updatesCollapsed={updatesCollapsed}
                      toggleCollapsed={() =>
                        setUpdatesCollapsed(!updatesCollapsed)
                      }
                      data={{
                        id: item.id,
                        title: item.title,
                        content: item.content,
                        updatedAt: item.updated_at,
                      }}
                    />
                  )
                })}
              </div>
            </Section>
          )}

        {httpGetAllPractices.isSuccess &&
          httpGetAllPractices.data.data.length > 0 && (
            <Section className={clsx(styles.section)}>
              <SectionHeader
                title={t('home.tableHeader')}
                id="recommended-practices-header"
              />

              <Card hasTable>
                <Card.Body>
                  <PracticesTable
                    labelledBy="recommended-practices-header"
                    practices={httpGetAllPractices.data.data}
                  />
                </Card.Body>
              </Card>
            </Section>
          )}
        {httpGetAllPractices.isLoading && <LoadingIndicator />}
      </DefaultLayout>
      {false && (
        <Suspense fallback={<div />}>
          <ChapsSidebar
            toggleChapsBar={toggleChapsBar}
            showChapsSidebar={showChapsSidebar}
          />
        </Suspense>
      )}
    </>
  )
}

export default FrontOfficePractices
