import { PracticeQueryParams } from 'constants/application-constants'

import { useCallback, useEffect } from 'react'

import { useAppDispatch } from 'hooks'
import { useSearchParams } from 'react-router-dom'
import { Revision } from 'redux-store/models'

import { practicesApi, setCurrentRevision } from '../api'

/**
 *
 * Hook used for setting the revision status in query params according to view type(front-office/back-office) and practice ID
 *
 * @param isFrontOffice - {Boolean} - is the current view front office?
 * @param practiceId - {string} - id of the practice for which the status needs to be set
 * @returns current revision
 */
export const useParamRevisionStatus = (
  isFrontOffice: boolean,
  practiceId: string
) => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const httpGetPractice = practicesApi.useGetPracticeByIdQuery(practiceId, {
    skip: !practiceId,
  })

  const setRevisionParam = useCallback(
    (param: 'published' | 'draft' | 'approval') => {
      if (param) {
        searchParams.set(PracticeQueryParams.REVISION_STATUS, param)
      } else {
        searchParams.delete(PracticeQueryParams.REVISION_STATUS)
      }
      setSearchParams(searchParams, { replace: true })
    },
    [searchParams, setSearchParams]
  )

  useEffect(() => {
    if (isFrontOffice) {
      // status for front office is implicitly derived to always be "published"
      setRevisionParam('published')
      return
    }

    // Back-office status logic 👇
    if (!httpGetPractice.isSuccess) return

    const paramRevision = searchParams.get(PracticeQueryParams.REVISION_STATUS)
    const data = httpGetPractice.data.data

    // Check if the revision for this param exists or not
    // If not then set according to priority
    if (paramRevision) {
      // query param "revision status" is set to "draft" & draft revision doesn't exist
      if (paramRevision === 'draft' && !data.current_draftish_revision) {
        if (data.current_approvalish_revision) {
          setRevisionParam('approval')
        } else {
          setRevisionParam('published')
        }
      }

      // query param "revision status" is set to "approval" & approval revision doesn't exist
      if (paramRevision === 'approval' && !data.current_approvalish_revision) {
        if (data.current_draftish_revision) {
          setRevisionParam('draft')
        } else {
          setRevisionParam('published')
        }
      }

      // query param "revision status" is set to "published" & published revision doesn't exist
      if (paramRevision === 'published' && !data.latest_published_revision) {
        if (data.current_draftish_revision) {
          setRevisionParam('draft')
        } else if (data.current_approvalish_revision) {
          setRevisionParam('approval')
        }
      }
    } else {
      // set revision according to priority

      if (data.current_draftish_revision) {
        setRevisionParam('draft')
      } else if (data.current_approvalish_revision) {
        setRevisionParam('approval')
      } else {
        setRevisionParam('published')
      }
    }
  }, [
    httpGetPractice.data,
    httpGetPractice.isSuccess,
    isFrontOffice,
    searchParams,
    setRevisionParam,
  ])

  useEffect(() => {
    let revision: null | Revision = null

    if (httpGetPractice.isSuccess) {
      const paramRevision = searchParams.get(
        PracticeQueryParams.REVISION_STATUS
      )
      const data = httpGetPractice.data.data

      if (isFrontOffice) {
        revision = data.latest_displayable_revision
      } else if (paramRevision === 'draft') {
        revision = data.current_draftish_revision
      } else if (paramRevision === 'approval') {
        revision = data.current_approvalish_revision
      } else if (paramRevision === 'published') {
        revision = data.latest_published_revision
      } else {
        revision = data.latest_displayable_revision
      }
    }

    dispatch(setCurrentRevision(revision))

    return () => {
      dispatch(setCurrentRevision(null))
    }
  }, [
    dispatch,
    httpGetPractice.data,
    httpGetPractice.isSuccess,
    isFrontOffice,
    searchParams,
  ])
}
