import { InputWrapper, Select } from 'components/common'
import { SelectProps } from 'components/common/Select/Select.types'
import { FieldHookConfig, useField } from 'formik'
import { t } from 'i18next'
import { SelectOption } from 'types'

type FSelectProps = SelectProps &
  FieldHookConfig<SelectOption | null | undefined> & {
    helperText?: string | null
    optional?: boolean
    errorProp?: string // the error property to use when the error element is an object
  }

const FSelect = ({
  label,
  helperText,
  options,
  optional = false,
  errorProp = 'id',
  ...props
}: FSelectProps): JSX.Element => {
  const [field, meta, helpers] = useField(props)

  const { value } = field
  const { error, touched } = meta
  const { setValue, setTouched } = helpers

  const { onSelectedItemChange: propOnSelectedItem, ...rest } = props

  const handleSelectedItemChange = (selectedItem?: SelectOption | null) => {
    setTouched(true)
    setValue(selectedItem)

    if (propOnSelectedItem) {
      propOnSelectedItem(selectedItem)
    }
  }

  const handleBlur = () => {
    setTouched(true)
  }

  const hasError = touched && !!error

  let errorText = error
  if (hasError && typeof error === 'object') {
    errorText = error[errorProp ?? 'id']
  }

  return (
    <InputWrapper
      helperText={hasError ? t(errorText as string) : helperText}
      color={hasError ? 'error' : 'basic'}
    >
      <Select
        label={optional ? `${label} (${t('common.optional')})` : label}
        options={options}
        onSelectedItemChange={handleSelectedItemChange}
        parentSelectedValue={value}
        isError={hasError}
        onBlur={handleBlur}
        {...rest}
      />
    </InputWrapper>
  )
}

FSelect.displayName = 'FSelect'
export default FSelect
