import React, { useEffect, useRef, useState } from 'react'

import clsx from 'clsx'
import { useCombobox } from 'downshift'
import { dropdownPosition } from 'helpers'
import { SelectOption } from 'types'

import styles from './Autocomplete.module.scss'
import { AutocompleteProps } from './Autocomplete.types'
import BasicInput from '../BasicInput/BasicInput'
import InputRoot from '../InputRoot/InputRoot'
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator'
import NoData from '../NoData/NoData'
import SelectDropdown from '../SelectDropdown/SelectDropdown'

const Autocomplete = ({
  id,
  options,
  label,
  className,
  handleInputValueChange,
  selectedItem,
  handleSelectedItemChange,
  size,
  isLoading,
  noDataRenderer,
  hideDropdown,
  resetOnSelect,
  onClearClick,
  startAdornment,
}: AutocompleteProps): JSX.Element => {
  // ? This state only helps us with internal filtering function. Is it worth the complexity or should we lift it to always be a parent's concern?
  const [itemOptions, setItemOptions] = useState<SelectOption[]>(options)

  useEffect(() => {
    setItemOptions(options)
  }, [options])

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    reset,
    inputValue,
    selectedItem: currentSelectedItem,
  } = useCombobox({
    id,
    items: itemOptions,
    itemToString: (item) => (item ? item.label : ''),
    onInputValueChange: ({ inputValue }) => {
      if (handleInputValueChange && inputValue) {
        handleInputValueChange(inputValue)
      } else {
        setItemOptions(
          options.filter((item) =>
            item.label
              .toLowerCase()
              .includes((inputValue as string).toLowerCase())
          )
        )
      }
    },
    selectedItem,
    onSelectedItemChange: ({ selectedItem }) => {
      if (handleSelectedItemChange && selectedItem) {
        handleSelectedItemChange(selectedItem)
      }

      if (resetOnSelect) {
        reset()
      }
    },
  })

  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)

  const inputProps = getInputProps({
    ref: inputRef,
  })
  const comboboxProps = getComboboxProps({
    ref: containerRef,
  })
  const menuProps = getMenuProps({
    ref: menuRef,
  })

  const isDropdownOpen =
    isOpen && !isLoading && !hideDropdown && Boolean(inputValue)
  const classes = clsx(styles.root, className)

  const onContainerClick = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const menuDropdownPosition = dropdownPosition(
    containerRef.current,
    menuRef.current,
    {
      defaultPosition: 'bottom',
    }
  )

  const handleClear = () => {
    reset()

    if (onClearClick) {
      onClearClick()
    }
  }

  return (
    <>
      <InputRoot
        className={classes}
        handleContainerClick={onContainerClick}
        label={label}
        isClearable
        handleClear={handleClear}
        isActive={Boolean(inputValue)}
        size={size}
        startAdornment={
          currentSelectedItem && currentSelectedItem.startAdornment
            ? currentSelectedItem.startAdornment
            : startAdornment
            ? startAdornment
            : null
        }
        endAdornment={isLoading && <LoadingIndicator />}
        {...comboboxProps}
      >
        <BasicInput {...inputProps} />
        <SelectDropdown
          highlightedIndex={highlightedIndex}
          options={itemOptions}
          isOpen={isDropdownOpen}
          selectedItem={currentSelectedItem}
          getItemProps={getItemProps}
          menuProps={menuProps}
          position={menuDropdownPosition}
          noDataElement={
            noDataRenderer ? (
              noDataRenderer
            ) : (
              <NoData
                title="No results found"
                subtitle={`Your search '${inputValue}' did not match any results. Please try again.`}
              />
            )
          }
        />
      </InputRoot>
    </>
  )
}

export default Autocomplete
