import { Permissions, RevisionStatuses } from 'constants/application-constants'

import { ReactElement } from 'react'

import {
  ApiSuccessResponse,
  Metadata,
  PdfProgress,
  PracticeClass,
  User,
} from 'types'

import { Option } from '../../components/features/Documents/components/DocumentsForm/types'

export * from './auth'
export * from './user'
export * from './search'
export * from './practice'
export * from './documents'
export * from './misc'

export interface Role {
  id: string
  name: string
  permissions: Permissions
  created_at: string
  updated_at: string

  user_id: string
  foreign_id: string
  foreign_type: string

  user?: User
}

export type PermissionsResponse = ApiSuccessResponse<
  Record<Permissions, boolean>
>

export interface Practice {
  id: string
  author: User | null
  starred_item_id: string | null
  is_outdated: boolean | null
  revision_statuses: Lowercase<RevisionStatuses>[]
  latest_displayable_revision: Revision | null
  latest_published_revision: Revision | null
  current_draftish_revision: Revision | null
  current_approvalish_revision: Revision | null
  due_date: string | null
}

export enum RevisionStatus {
  draft = 'draft',
  precheck = 'precheck',
  preapproval = 'preapproval',
  approval = 'approval',
  aborted = 'aborted',
  rejected = 'rejected',
  published = 'published',
}

export interface Revision {
  id: string
  practice_id: string
  practice_class: PracticeClass
  status: string
  created_at: string
  expires_at: string
  published_at: string
  updated_at: string

  metadata: Metadata[]
  main_document: DocumentEntity[]
  documents: DocumentEntity[]

  approval_process: {
    id: string
    name: string
    practice_id: string
    revision_id: string
    customer_id: string
    author_id: string
    practice_class_id: string
    overridden_by: string | null
    overridden_at: string | null
    overridden_comment: string | null
    aborted_by: string | null
    aborted_at: string | null
    aborted_comment: string | null
    due_date: string
    length_of_approval: 'minor' | 'major'
    publication_date: string | null
    validity_date: string | null
    comment: string | null
    send_pre_notice: boolean
    is_active: boolean
    created_at: string
  } | null

  attachments?: DocumentEntity[]
  number_of_documents?: number
  number_of_attachments?: number

  ready_for_approval: null | {
    ok: boolean
    reason: ApprovalRejectReasons
  }

  contact_users: User[]
}

export type ApprovalRejectReasons = {
  no_main_document?: boolean
  revision_metadata_missing?: boolean
  documents_metadata_missing?: string[]
}

export interface DocumentEntity {
  id: string
  name: string
  customer_id: string
  author_id: string
  practice_id: string
  type: string
  amendment_parent_document: string | null
  starred_item_id: string | null
  created_at: string
  show_amendment: null

  revision?: Revision
  author?: User
  versions?: Version[]
  latest_approved_version?: Version | null
  current_approvalish_version?: Version | null
  latest_draft_version?: Version | null
  metadata: Metadata
}

export interface FileInformation {
  author_id: string
  extension: string // TODO: add a file extension type instead of string
  filename: string
  filesize: number
  id: string
  mime: string
  language: Option
  pdf_filename: string | null
  pdf_status: null | PdfProgress
  public_path: string
  public_pdf_path: string | null
}

export interface Version {
  id: string
  document_id: string
  status: keyof typeof RevisionStatus
  created_at: string
  expires_at: string
  published_at: string | null

  document?: DocumentEntity
  metadata: Metadata[]
  public_path: string
  public_pdf_path: null | string
  files: FileInformation[]
  version_label: string | null
}

export interface Translation {
  id: string
  file_id: string
  metadata_id: string
  created_at: string
  updated_at: string

  file?: FileEntity
}

export interface FileEntity {
  id: string
  author_id: string
  filename: string
  filesize: number
  mime: string
  original_filename: string
  path?: string
  full_path?: string
  created_at: string
  updated_at: string

  author?: User
}

export interface PracticeListItem {
  name: string | undefined
  topic: string | undefined
  language: string | undefined
  updated_at: string | undefined | Date
  link_target: string
  counter?: number
  documents?: DocumentEntity[]
  icon?: ReactElement
}

export interface DocumentListItem {
  name: string | undefined
  language: string | undefined
  updated_at: string | undefined | Date
  link_target: string
  icon?: ReactElement
}

export type MetadataResponse = Metadata

export type MetadataRequest = Pick<
  Metadata,
  | 'title'
  | 'code'
  | 'subject'
  | 'description'
  | 'target_group'
  | 'objective'
  | 'external_references'
  | 'keywords'
  | 'effective_at'
> & {
  language_code: string

  // UUIDs for the following
  main_topic_id: string
  alternative_topic_ids: string[]
  countries: string[]
  locations: string[]
  departments: string[]
  legal_entities: string[]
  certification_scopes: string[]
  collections: string[]
  version_label?: string
}

//   interface PracticesUuidPostBody {
//     revision: {
//       practice_class_id: string; // uuid
//       published_at: string; // time string
//       expires_at: string; // time string
//     };
//     metadata: MetadataPostDto;
//   }
