import { ActionMinus, Check } from 'assets/Icons'
import clsx from 'clsx'

import styles from './TreeCheckbox.module.scss'

interface TreeCheckboxProps {
  variant: 'checked' | 'half-checked' | 'unchecked'
  active: boolean
}

const TreeCheckbox = ({ variant, active }: TreeCheckboxProps) => {
  return (
    <span
      className={clsx(
        styles.root,
        styles[`va-${variant}`],
        {
          [styles.isActive]: active,
        },
        'tree-checkbox-custom'
      )}
    >
      {variant === 'checked' ? <Check /> : <ActionMinus />}
    </span>
  )
}

export default TreeCheckbox
