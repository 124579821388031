import clsx from 'clsx'
import { removeToast, selectToasts } from 'components/features/Other/api'
import { useAppDispatch, useAppSelector } from 'hooks'
import { createPortal } from 'react-dom'

import styles from './ToastContainer.module.scss'
import Toast from '../Toast/Toast'

interface ToastContainerProps {
  position?: 'bottom' // TODO: add other positions
  defaultDismissTime?: number
}

const ToastContainer = ({
  position = 'bottom',
  defaultDismissTime = 3000,
}: ToastContainerProps): JSX.Element => {
  const stateToasts = useAppSelector(selectToasts)
  const dispatch = useAppDispatch()
  const classes = clsx(styles.root, styles[`pos-${position}`])

  const handleDismissToast = (toastId: string) => () => {
    dispatch(removeToast(toastId))
  }

  return createPortal(
    <div className={classes}>
      {stateToasts.map((toast) => (
        <Toast
          key={toast.id}
          color={toast.color}
          content={toast.content}
          hasIcon={toast.hasIcon ?? true}
          dismissTime={toast.dismissTime ?? defaultDismissTime}
          handleDismiss={handleDismissToast(toast.id)}
        />
      ))}
    </div>,
    document.getElementById('root') as HTMLElement
  )
}

export default ToastContainer
