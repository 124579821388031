import { createSlice } from '@reduxjs/toolkit'
import { authTokenService } from 'helpers/auth-helpers'

import { oAuthApi } from '../oAuthApi'

interface OauthState {
  accessToken: string | null
  refreshToken: string | null
  isAuthenticated: boolean
}

const initialState: OauthState = {
  accessToken: authTokenService.getAccessToken(),
  refreshToken: authTokenService.getRefreshToken(),
  isAuthenticated: false,
}

export const oAuthSlice = createSlice({
  name: 'oauth',
  initialState,
  reducers: {
    setToken(state, { payload }) {
      state.accessToken = payload.access_token
      state.refreshToken = payload.refresh_token

      authTokenService.setTokens(payload.access_token, payload.refresh_token)
    },
    logout(state) {
      state.accessToken = null
      state.refreshToken = null
      state.isAuthenticated = false

      authTokenService.clearTokens()
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      oAuthApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        const { access_token, refresh_token } = payload

        state.accessToken = access_token
        state.refreshToken = refresh_token
        state.isAuthenticated = true

        authTokenService.setTokens(access_token, refresh_token)
      }
    )

    builder.addMatcher(
      oAuthApi.endpoints.refreshToken.matchFulfilled,
      (state, { payload }) => {
        const { access_token, refresh_token } = payload

        state.accessToken = access_token
        state.refreshToken = refresh_token
        state.isAuthenticated = true

        authTokenService.setTokens(access_token, refresh_token)
      }
    )
  },
})

export const { setToken, logout } = oAuthSlice.actions

const oAuthReducer = oAuthSlice.reducer
export default oAuthReducer

export * from './oAuthSlice.selectors'
