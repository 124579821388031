const ArrowGoingUp = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66431 5.43604L18.3637 5.6361L18.5638 15.3355"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="17.4905"
        y1="5.84712"
        x2="5.65842"
        y2="17.6792"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default ArrowGoingUp
