import React, { Children, cloneElement } from 'react'

import clsx from 'clsx'

import Step from './Step'
import styles from './Stepper.module.scss'

type StepProps = React.ComponentProps<typeof Step>

type StepperComponent = React.VFC<{
  children: React.ReactElement<StepProps> | React.ReactElement<StepProps>[]
  className?: string
  orientation?: 'vertical' | 'horizontal'
}> & {
  Step: React.FC<StepProps>
}

const Stepper: StepperComponent = ({
  children,
  className,
  orientation = 'vertical',
}): JSX.Element => {
  return (
    <div className={clsx(styles.root, className, styles[`or-${orientation}`])}>
      {Children.map(children, (step) =>
        cloneElement(step as React.ReactElement, {
          orientation,
        })
      )}
    </div>
  )
}

;(Step as React.FC).displayName = 'Stepper.Step'
Stepper.Step = Step

export default Stepper
