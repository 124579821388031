import { useLayoutEffect, useState } from 'react'

import { clamp, isBetween } from 'helpers/num-helpers'

const useScrollspy = (
  ids: string[],
  offset: number = 0,
  containerEl?: HTMLElement
) => {
  const [activeId, setActiveId] = useState('')

  useLayoutEffect(() => {
    const listener = () => {
      let scroll = window.scrollY

      if (containerEl) {
        scroll = containerEl.scrollTop
      }

      const position = ids
        .map((id) => {
          const element = document.getElementById(id)

          if (!element) return { id, top: -1, bottom: -1 }

          const rect = element.getBoundingClientRect()
          const top = clamp(rect.top + scroll - offset)
          const bottom = clamp(rect.bottom + scroll - offset)

          return { id, top, bottom }
        })
        .find(({ top, bottom }) => isBetween(scroll, top, bottom))

      // TODO: if focus-within said - set as active id (usual method not performant)

      setActiveId(position?.id || '')
    }

    listener()

    if (containerEl) {
      containerEl.addEventListener('resize', listener)
      containerEl.addEventListener('scroll', listener)
    } else {
      window.addEventListener('resize', listener)
      window.addEventListener('scroll', listener)
    }

    return () => {
      if (containerEl) {
        containerEl.removeEventListener('resize', listener)
        containerEl.removeEventListener('scroll', listener)
      } else {
        window.removeEventListener('resize', listener)
        window.removeEventListener('scroll', listener)
      }
    }
  }, [containerEl, ids, offset])

  return activeId
}

export default useScrollspy
