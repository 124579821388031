import { DeepPartial } from 'types'

import { UITheme } from '../types'

const BrantnerTheme: DeepPartial<UITheme> = {
  color: {
    primary: '#008651',
    'primary-bg': '#d1e0e0',
    'primary-dark': '#2d5940',
    'primary-light': '#d1e0e0',

    secondary: '#008651',
    'secondary-bg': '#d1e0e0',
    'secondary-dark': '#2d5940',
    'secondary-light': '#d1e0e0',

    tertiary: '#008651',
    'tertiary-bg': '#d1e0e0',
    'tertiary-dark': '#2d5940',
    'tertiary-light': '#d1e0e0',

    accent: '#008651',
    'accent-bg': '#d1e0e0',
    'accent-dark': '#2d5940',
    'accent-light': '#d1e0e0',

    'monochrome-ash': '#262338',
    'monochrome-background': '#e6ebeb',
    'monochrome-body': '#424242',
    'monochrome-input': '#d2d0cf',
    'monochrome-label': '#424242',
    'monochrome-line': '#d2d0cf',
    'monochrome-off-black': '#14142b',
    'monochrome-off-white': '#fcfcfc',
    'monochrome-placeholder': '#777779',
    'monochrome-white': '#ffffff',
    'monochrome-black': '#000000',
    'monochrome-outline': '#777779',
  },
}

export default BrantnerTheme
