import React, { forwardRef } from 'react'

import clsx from 'clsx'
import { Link } from 'react-router-dom'

import styles from './Button.module.scss'
import { ButtonProps } from './Button.types'

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      icon,
      iconLabel,

      children,

      trailingIcon,
      trailingIconLabel,

      className,
      variant,
      color = 'primary',
      size = 'medium',

      ...rest
    },
    ref
  ): JSX.Element => {
    const isIconOnlyButton = (icon || trailingIcon) && !children
    const defaultVariant = rest.as === 'link' ? 'text' : 'primary'

    const css = clsx(
      styles.root,
      styles[`va-${variant ?? defaultVariant}`],
      styles[`si-${size}`],
      styles[`co-${color}`],
      {
        [styles.isIconOnly]: isIconOnlyButton,
        [styles.isLink]: rest.as === 'link',
      },
      className
    )

    const ButtonChild = () => (
      <>
        {icon && (
          <i
            aria-hidden={!isIconOnlyButton}
            aria-label={iconLabel}
            className={clsx(styles.btnIcon, 'btn-icon')}
          >
            {icon}
          </i>
        )}
        {children}
        {trailingIcon && (
          <i
            aria-hidden={!isIconOnlyButton}
            aria-label={trailingIconLabel}
            className={styles.btnTrailIcon}
          >
            {trailingIcon}
          </i>
        )}
      </>
    )

    // `rest.as` instead of only `as` because'as' is a keyword in TypeScript but not in JavaScript
    if (rest.as === 'link') {
      if (typeof rest.to === 'string' && rest.to.startsWith('mailto')) {
        const { to, ...restProps } = rest

        return (
          <a className={css} {...restProps} href={to}>
            <ButtonChild />
          </a>
        )
      }

      return (
        <Link className={css} {...rest}>
          <ButtonChild />
        </Link>
      )
    } else {
      const { onClick, disabled } = rest

      const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
      ) => {
        if (onClick && !disabled) {
          onClick(event)
        }
      }

      return (
        <button
          ref={ref}
          type={rest.type ?? 'button'}
          {...rest}
          onClick={handleClick}
          className={css}
        >
          <ButtonChild />
        </button>
      )
    }
  }
)

export default Button
