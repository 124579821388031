import { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { useAppDispatch } from '../../../../hooks'
import { Practice } from '../../../../redux-store/models'
import { practicesApi } from '../api'
import { setPractice } from '../api/practiceSlice'

export const usePractice = (
  practiceId?: string,
  refetchOnMountOrArgChange: boolean = true
) => {
  const dispatch = useAppDispatch()
  const { id } = useParams()

  const fetchedId = practiceId || id || ''
  const httpGetPractice = practicesApi.useGetPracticeByIdQuery(fetchedId, {
    skip: !fetchedId,
    refetchOnMountOrArgChange: refetchOnMountOrArgChange,
  })

  useEffect(() => {
    let practice: null | Practice = null

    if (httpGetPractice.isSuccess) {
      const data = httpGetPractice.data.data
      practice = data
    }

    dispatch(setPractice(practice))

    return () => {
      dispatch(setPractice(null))
    }
  }, [dispatch, httpGetPractice.data, httpGetPractice.isSuccess])
}
