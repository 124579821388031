import { format } from 'date-fns'

export const formatDateForInput = (date: string | null | undefined): string => {
  if (!date) return ''

  return format(new Date(date), 'yyyy-MM-dd')
}

export const formatDateNumeric = (date: Date | number | string) => {
  try {
    return format(new Date(date), 'dd.MM.yyyy')
  } catch (err) {
    return ''
  }
}

export const epochToInputDate = (epoch: number) => {
  return format(new Date(epoch), 'yyyy-MM-dd')
}
