import { createApi } from '@reduxjs/toolkit/query/react'

import { UpdateAnnouncementRequest } from './announcementsApi.types'
import { baseQueryWithReauth } from '../../../../../redux-store/services/services.utils'
import { ApiSuccessResponse } from '../../../../../types'
import { Announcement } from '../../../../../types/announcement'

export const announcementsApi = createApi({
  reducerPath: 'announcementsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAnnouncements: builder.query<ApiSuccessResponse<Announcement[]>, null>({
      query: () => '/announcements',
    }),
    updateAnnouncement: builder.mutation<
      Announcement,
      UpdateAnnouncementRequest
    >({
      query: ({ id, data }) => ({
        url: `announcements/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
  }),
})

export const { useGetAnnouncementsQuery, useUpdateAnnouncementMutation } =
  announcementsApi
