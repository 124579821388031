import clsx from 'clsx'
import { IconProps, Search } from 'react-feather'

import styles from './NoData.module.scss'
import IconContainer from '../IconContainer/IconContainer'
import Typography from '../Typography/Typography'

interface NoDataProps {
  title: string
  subtitle?: string | null
  children?: React.ReactNode
  className?: string
  icon?: IconProps
}

const NoData = ({
  title,
  children,
  className,
  subtitle,
  icon,
}: NoDataProps): JSX.Element => {
  const classes = clsx(styles.root, className)

  return (
    <div className={classes}>
      <IconContainer
        size="medium"
        withBackground
        color="primary"
        icon={icon ? icon : <Search />}
      />
      <div className={styles.textWrapper}>
        <Typography
          className={styles.title}
          element="h3"
          size="x-large"
          fontWeight="medium"
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography className={styles.subtitle} color="label">
            {subtitle}
          </Typography>
        )}
      </div>
      {children}
    </div>
  )
}

export default NoData
