import { Background, Button, Card, Typography } from 'components/common'
import FocusTrap from 'focus-trap-react'
import { t } from 'i18next'

import styles from './RemoveConfirmModal.module.scss'
import { LanguageItem } from './types'

interface RemoveConfirmModalProps {
  language: LanguageItem
  onConfirm: () => void
  onAbort: () => void
}

const RemoveConfirmModal = ({
  onConfirm,
  language,
  onAbort,
}: RemoveConfirmModalProps) => {
  return (
    <Background open={!!language} color="tinted">
      <FocusTrap>
        <Card className={styles.main}>
          <Card.Body className={styles.body}>
            <Typography type="display" size="small" fontWeight="bold">
              {t('languageBar.removeTitle', { language: language.label })}
            </Typography>
            <div className="subtitle">
              <Typography type="text" size="medium" color="label">
                {t('languageBar.removeSubtitle1')}
              </Typography>
              <Typography type="text" size="medium" color="label">
                {t('languageBar.removeSubtitle2')}
              </Typography>
            </div>
            <div className={styles.actions}>
              <Button size="large" variant="text" onClick={onAbort}>
                {t('languageBar.removeAbort')}
              </Button>
              <Button size="large" color="error" onClick={onConfirm}>
                {t('languageBar.removeConfirm')}
              </Button>
            </div>
          </Card.Body>
        </Card>
      </FocusTrap>
    </Background>
  )
}

export default RemoveConfirmModal
