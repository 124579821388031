import { useLayoutEffect, useState } from 'react'

import { throttle } from 'helpers'

const THROTTLE_DELAY = 250

const useWindowSize = (): { width: number; height: number } => {
  const [windowSize, setWindowSize] = useState({
    width: NaN,
    height: NaN,
  })

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    const handler = throttle(handleResize, THROTTLE_DELAY)
    window.addEventListener('resize', handler)

    // run once just to get the window size on mount
    handleResize()

    return () => window.removeEventListener('resize', handler)
  }, [])

  return windowSize
}

export default useWindowSize
