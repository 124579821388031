import React from 'react'

import { Radio } from 'components/common'
import { RadioProps } from 'components/common/Radio/Radio.types'
import { FieldHookConfig, useField } from 'formik'

type FRadioProps = FieldHookConfig<string | number> &
  RadioProps & {
    value: string | number
  }

const FRadio = ({ ...props }: FRadioProps) => {
  const [field, , helpers] = useField(props)

  const { name } = field
  const { setValue } = helpers

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const { name: _name, ...rest } = props
  return <Radio name={name} onChange={handleChange} {...rest} />
}

export default FRadio
