import { ApiSuccessResponse, LegalEntity } from 'types'

export type GetLanguagesConfig = null | {
  activeOnly: boolean
  includeCustomerLanguage: boolean
}

export enum GetLanguagesQuery {
  onlyActive = 'show_only_active',
  includeCustomerLanguage = 'include_company_language',
}

export type GetTopicsListConfig = null | {
  includeInterestData?: boolean
  depth?: number
}

export enum GetTopicsListQuery {
  includeInterestData = 'include_interest_data',
  depth = 'depth',
}

export type GetLegalEntitiesResponse = ApiSuccessResponse<LegalEntity[]>
