import { forwardRef } from 'react'

import clsx from 'clsx'

import styles from './Card.module.scss'
import {
  CardBodyProps,
  CardComponent,
  CardFooterProps,
  CardHeaderProps,
  CardProps,
} from './Card.types'

const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      children,
      className,
      noDropShadow,
      hasTable,
      color = 'default',
      ...props
    },
    ref
  ) => {
    const classes = clsx(
      styles.root,
      styles[`co-${color}`],
      {
        [styles.hasNoDropShadow]: noDropShadow,
        [styles.hasTable]: hasTable,
      },
      className
    )

    return (
      <div ref={ref} className={classes} {...props}>
        {children}
      </div>
    )
  }
) as CardComponent

const CardHeader: React.FC<CardHeaderProps> = ({
  children,
  className,
  ...props
}) => {
  const classes = clsx(styles.header, className)

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  )
}

const CardBody: React.FC<CardBodyProps> = ({
  children,
  className,
  ...props
}) => {
  const classes = clsx(styles.body, className)

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  )
}

const CardFooter: React.FC<CardFooterProps> = ({
  children,
  className,
  ...props
}) => {
  const classes = clsx(styles.footer, className)

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  )
}

Card.Header = CardHeader
Card.Body = CardBody
Card.Footer = CardFooter

export default Card
