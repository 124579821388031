import { Card, QibriLogo, Typography } from 'components/common'
import { useTranslation } from 'react-i18next'

import styles from './LoginPage.module.scss'
import { LoginForm } from '../../components'

const LoginPage = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <Card className={styles.card}>
        <Card.Body className={styles.cardBody}>
          <div className={styles.header}>
            <QibriLogo className={styles.logo} />
            <Typography
              type="display"
              fontWeight="bold"
              element="h1"
              className="is-not-mobile"
            >
              {t('login.title')}
            </Typography>
            <Typography
              type="display"
              size="x-small"
              fontWeight="bold"
              element="h1"
              className="is-only-mobile"
            >
              {t('login.title')}
            </Typography>
          </div>
          <LoginForm />
          {/*<Button variant="text" size="small" as="link" to="/forgot-password">*/}
          {/*  {t('login.forgetPassword')}*/}
          {/*</Button>*/}
          <div className={styles.footer}>
            <a className={styles.fakeLink} href={'/sso'}>
              {t('login.sso')}
            </a>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default LoginPage
