import {
  Button,
  Card,
  IconContainer,
  Modal,
  Separator,
  Typography,
} from 'components/common'
import { addToast } from 'components/features/Other/api'
import { copyToClipboard, practiceModalCloseLocation } from 'helpers'
import { useAppDispatch } from 'hooks'
import { t } from 'i18next'
import { Link, Shield, X } from 'react-feather'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import styles from './CollaboratorModal.module.scss'
import AddCollaboratorsForm from '../../components/AddCollaboratorsForm/AddCollaboratorsForm'

const CollaboratorModal = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const { id = '' } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const handleModalClose = () => {
    let backLocation = practiceModalCloseLocation(false, location, id)

    navigate(backLocation)
  }

  const handlePreCheckClick = () => {
    const backgroundLink = location.pathname.split('/collaborate')[0]
    navigate({
      pathname: `${backgroundLink}/pre-check`,
      hash: location.hash,
      search: location.search,
    })
  }

  const handleCopyLink = () => {
    copyToClipboard(window.location.href.split('/collaborate')[0])

    dispatch(
      addToast({
        color: 'success',
        content: t('collaboratorModal.copyToast'),
      })
    )
  }

  return (
    <Modal open handleClose={handleModalClose}>
      <Card className={styles.card}>
        <Card.Header className={styles.header}>
          <div>
            <Typography
              type="display"
              size="x-small"
              fontWeight="bold"
              element="h2"
            >
              {t('collaboratorModal.title')}
            </Typography>
            <Typography color="label">
              {t('collaboratorModal.subtitle')}
            </Typography>
          </div>
          <Button
            size="x-small"
            variant="text"
            icon={<X />}
            iconLabel={t('collaboratorModal.closeModal') ?? ''}
            onClick={handleModalClose}
          />
        </Card.Header>
        <Card.Body className={styles.cardBody}>
          <AddCollaboratorsForm
            className={styles.collaboratorForm}
            practiceId={id ?? ''}
          />
          <Separator marginSize="small" />

          <div className={styles.actions}>
            {!!id && (
              <Button
                onClick={handlePreCheckClick}
                variant="text"
                className={styles.action}
              >
                <div className={styles.actionIcon}>
                  <IconContainer
                    color="primary"
                    size="small"
                    icon={<Shield />}
                  />
                </div>
                <div className={styles.actionDescription}>
                  <Typography fontWeight="medium" color="primary">
                    {t('collaboratorModal.actionDescription')}
                  </Typography>
                  <Typography>
                    {t('collaboratorModal.actionDescriptionSub')}
                  </Typography>
                </div>
              </Button>
            )}
            <Button
              onClick={handleCopyLink}
              variant="text"
              className={styles.action}
            >
              <div className={styles.actionIcon}>
                <IconContainer color="primary" size="small" icon={<Link />} />
              </div>
              <div className={styles.actionDescription}>
                <Typography fontWeight="medium" color="primary">
                  {t('collaboratorModal.copyLink')}
                </Typography>
                <Typography type="text">
                  {t('collaboratorModal.copyLinkSub')}
                </Typography>
              </div>
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Modal>
  )
}

export default CollaboratorModal
