import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js'
import { format } from 'date-fns'
import { getCssVariable } from 'helpers'
import { Bar } from 'react-chartjs-2'

interface ReviewPendingChartProps {
  data: {
    [year in string]: number
  }
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const barChartOptions: ChartOptions<'bar'> = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        stepSize: 10,
      },
      grid: {
        display: false,
      },
    },
  },
}

const ReviewPendingChart = ({ data }: ReviewPendingChartProps): JSX.Element => {
  const dataArr = Object.entries(data)
  const barChartData: ChartData<'bar'> = {
    // WARNING: smart apostrophe used in the formatting of label (second parameter of the format function) - while harmless to use, it looks exactly like dumb apostrophe leading to developer headaches, hair loss and broken keyboards.
    // Why use it then? It's a different character and is not interpreted as start of string by JavaScript.
    labels: dataArr.map((item) => format(new Date(item[0]), 'MMM ‘yy')),
    datasets: [
      {
        label: '',
        data: dataArr.map((item) => item[1]),
        barThickness: 16,
        borderRadius: 1000,
      },
    ],
  }

  return (
    <Bar
      options={{
        ...barChartOptions,
        backgroundColor: getCssVariable('--color-primary'),
      }}
      data={barChartData}
    />
  )
}

export default ReviewPendingChart
