import { navLinks } from 'constants/navigation-constants'

import clsx from 'clsx'
import { Button, QibriLogo } from 'components/common'
import { selectAuth } from 'components/features/Auth/api'
import { userApi } from 'components/features/User/api'
import { useAppSelector, usePermissions } from 'hooks'
import { ChevronsLeft, ChevronsRight } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { CLIENT_DATA } from 'temp/temp-constants'
import { getLogo } from 'temp/utils'

import styles from './Navigation.module.scss'
import { NavigationProps } from './Navigation.types'
import NavigationItem from '../NavigationItem/NavigationItem'

const Navigation = ({
  isCollapsed,
  onCollapseClick,
  className,
}: NavigationProps): JSX.Element => {
  const classes = clsx(
    styles.root,
    {
      [styles.isCollapsed]: isCollapsed,
    },
    className
  )

  const { isAuthenticated } = useAppSelector(selectAuth)
  const { mixed: allPermissions } = usePermissions()
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const currentUserQuery = userApi.useGetCurrentUserQuery(null, {
    skip: !isAuthenticated,
  })

  const filterValidNavLinks = () => {
    if (allPermissions === null) {
      return navLinks
    }

    return navLinks.filter((navLink) => {
      const navPermissions = navLink.requiredPermissions

      if (!navPermissions || navPermissions.length <= 0) {
        return true
      }

      // Return 'false' if any permission is found to be false
      return !navPermissions.find((permission) => !allPermissions[permission])
    })
  }

  const renderedLinks = filterValidNavLinks()

  const clientId = currentUserQuery.data?.data.customer?.id || ''
  const isNotQibri = Object.values(CLIENT_DATA).includes(clientId)
  const companyLogo = getLogo(clientId, isCollapsed)

  return (
    <div className={classes}>
      <Link
        to="/"
        className={clsx(styles.logoContainer, {
          [styles.isQibri]: !isNotQibri,
        })}
      >
        <span className="sr-only">Back to Start</span>
        {isNotQibri ? (
          companyLogo
        ) : (
          <QibriLogo birdOnly={isCollapsed} className={styles.logo} />
        )}
      </Link>

      <nav className={styles.navigation}>
        {renderedLinks.map(
          ({ to, label, icon, i18nKey, displayOnMobile }, idx) => (
            <NavigationItem
              isSelected={to === pathname}
              isCollapsed={isCollapsed}
              key={`nav-${idx}-${label}`}
              to={to}
              icon={icon}
              displayOnMobile={displayOnMobile}
            >
              {t(i18nKey) ?? ''}
            </NavigationItem>
          )
        )}
      </nav>

      <div className={styles.actions}>
        <Button
          className={styles.collapseBtn}
          data-testid="expand-button"
          variant="text"
          size="x-small"
          icon={isCollapsed ? <ChevronsRight /> : <ChevronsLeft />}
          onClick={onCollapseClick}
          aria-label={
            isCollapsed ? (t('navigation.expand') as string) : undefined
          }
        >
          {isCollapsed ? null : t('navigation.collapse')}
        </Button>
      </div>
    </div>
  )
}

export default Navigation
