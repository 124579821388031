import clsx from 'clsx'

import styles from './Toggle.module.scss'
import { ToggleProps } from './Toggle.types'

const Toggle = ({
  disabled,
  id,
  size = 'medium',
  className,
  ...props
}: ToggleProps) => {
  const classes = clsx(
    styles.root,
    styles[`sz-${size}`],
    {
      [styles.isDisabled]: disabled,
    },
    className
  )

  return (
    <div className={classes}>
      <input
        disabled={disabled}
        id={id}
        type="checkbox"
        className={styles.input}
        {...props}
      />
      <label
        htmlFor={id}
        className={styles.toggle}
        aria-hidden={true}
        data-testid="toggle-fancy-box"
      />
    </div>
  )
}

export default Toggle
