import { useEffect, useMemo, useState } from 'react'

import { Autocomplete, Avatar, Button, Typography } from 'components/common'
import { userApi } from 'components/features/User/api'
import { createSelectOptions, debounce } from 'helpers'
import { t } from 'i18next'
import { Trash2, Users } from 'react-feather'
import { useParams } from 'react-router-dom'
import { SelectOption, User } from 'types'

import styles from './PreCheckAddUsersForm.module.scss'
import { PreCheckAddUsersFormProps } from '../PreCheckCard.types'

const PreCheckAddUsersForm = ({
  handleReviewerSelect,
  reviewers,
  handleCancel,
  handleContinue,
}: PreCheckAddUsersFormProps): JSX.Element => {
  const { id: practiceId } = useParams()
  const [debouncedInput, setDebouncedInput] = useState('')

  const [
    fetchSearchReviewers,
    { data: searchData, isFetching, isSuccess, isUninitialized },
  ] = userApi.useLazySearchPracticeUsersQuery()

  useEffect(() => {
    if (debouncedInput && practiceId) {
      fetchSearchReviewers({
        search: debouncedInput,
        userType: 'feedbackers',
      })
    }
  }, [debouncedInput, fetchSearchReviewers, practiceId])

  const onReviewerSelect = (item?: SelectOption | null) => {
    if (item) {
      handleReviewerSelect(item.value as User)
    }
  }

  const handleInputChange = debounce((value?: string) => {
    setDebouncedInput(value ?? '')
  }, 1000)

  const reviewerOptions = useMemo(() => {
    if (!isSuccess || !searchData) return []

    return createSelectOptions({
      idField: 'id',
      labelField: 'name',
      icon: {
        field: 'avatar_public_path',
        renderer: (user: User) => (
          <Avatar
            size="x-small"
            src={user.avatar_public_path}
            content={user.initials}
          />
        ),
      },
    })(searchData.data)
  }, [isSuccess, searchData])

  return (
    <div className={styles.root}>
      <Autocomplete
        handleInputValueChange={handleInputChange}
        handleSelectedItemChange={onReviewerSelect}
        label="Reviewer"
        options={reviewerOptions}
        hideDropdown={isUninitialized}
        isLoading={isFetching}
        resetOnSelect
      />

      <div className={styles.reviewers}>
        {Boolean(reviewers.length) ? (
          reviewers.map((reviewer) => (
            <div className={styles.reviewerDisplay} key={reviewer.id}>
              <div className={styles.reviewerInfo}>
                <Avatar
                  className={styles.reviewerAvatar}
                  content={reviewer.initials ?? ''}
                  src={reviewer.avatar_public_path}
                />
                <Typography>{reviewer.name}</Typography>
              </div>
              <Button
                onClick={() => {
                  handleReviewerSelect(reviewer)
                }}
                size="small"
                variant="subtle"
                icon={<Trash2 />}
              />
            </div>
          ))
        ) : (
          <Button
            className={styles.importReviewersBtn}
            variant="subtle"
            size="small"
            icon={<Users />}
          >
            {t('preCheck.importBtn')}
          </Button>
        )}
      </div>

      <div className={styles.footer}>
        <Button size="medium" variant="text" onClick={handleCancel}>
          {t('preCheck.abort')}
        </Button>
        <Button
          disabled={reviewers.length < 1}
          size="medium"
          onClick={handleContinue}
        >
          {t('preCheck.submit')}
        </Button>
      </div>
    </div>
  )
}

export default PreCheckAddUsersForm
