import { Modal } from 'components/common'

import styles from './UserOnboardingPage.module.scss'
import { UserOnboarding } from '../../components'

const UserOnboardingPage = (): JSX.Element => {
  return (
    <Modal open noFocusTrap className={styles.modal}>
      <UserOnboarding />
    </Modal>
  )
}

export default UserOnboardingPage
