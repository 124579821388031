import { PayloadAction, createSlice, nanoid } from '@reduxjs/toolkit'
import { DocumentsFormValues } from 'components/features/Documents/components/DocumentsForm/types'
import { GuidelineFormValues } from 'components/features/Practices/components/GuidelineForm/types'
import { isBetween } from 'helpers/num-helpers'
import { UploadFile } from 'types'

type DocUploadState = {
  step: number
  activeDocTab: string | null

  documentFiles: Record<string, UploadFile & { hasValidMetadata: boolean }>
  documentFormData: Record<string, DocumentsFormValues>

  docIds: string[]
  limitGuidelineToParam: boolean
  guidelineType: 'new' | 'existing'
  guidelineId: string | null
  guidelineData: GuidelineFormValues | null
}

const initialState: DocUploadState = {
  step: 1,
  activeDocTab: null,

  documentFiles: {},
  documentFormData: {},
  docIds: [],
  limitGuidelineToParam: false,

  guidelineId: null,
  guidelineData: null,
  guidelineType: 'existing',
}

const createDraftDoc = (
  fileData: UploadFile
): UploadFile & { hasValidMetadata: boolean } => {
  const fileKey = fileData.key ?? nanoid()

  return {
    ...fileData,
    key: fileKey,
    hasValidMetadata: false,
  }
}

const docUploadSlice = createSlice({
  name: 'docUpload',
  initialState,
  reducers: {
    reset() {
      return initialState
    },
    setStep(state, action: PayloadAction<DocUploadState['step']>) {
      if (isBetween(action.payload, 1, 3)) {
        state.step = action.payload
      }
    },
    incrementStep(state) {
      if (state.step < 3) {
        state.step++
      }
    },
    decrementStep(state) {
      if (state.step > 1) {
        state.step--
      }
    },
    createDocument(state, action: PayloadAction<UploadFile>) {
      const newDoc = createDraftDoc(action.payload)
      const newDocId = newDoc.key

      state.documentFiles = {
        ...state.documentFiles,
        [newDocId]: newDoc,
      }

      if (newDocId) {
        state.docIds = [...state.docIds, newDocId]
      }
    },
    removeDocument(state, action: PayloadAction<string>) {
      const deletedDocId = action.payload

      state.docIds = state.docIds.filter((docId) => docId !== deletedDocId)

      delete state.documentFiles[deletedDocId]

      if (deletedDocId in state.documentFormData) {
        delete state.documentFormData[deletedDocId]
      }
    },
    updateFileStatus(
      state,
      action: PayloadAction<{
        docId: string
        status: UploadFile['state']
        uploadedId?: UploadFile['uploadedId']
      }>
    ) {
      const { docId, status, uploadedId } = action.payload

      if (state.documentFiles[docId]) {
        state.documentFiles[docId].state = status
        state.documentFiles[docId].uploadedId = uploadedId
      }
    },
    updateDocForm(
      state,
      action: PayloadAction<{
        docId: string
        value: DocumentsFormValues
      }>
    ) {
      state.documentFormData[action.payload.docId] = action.payload.value
    },
    setDocTab(state, action: PayloadAction<string>) {
      const newTabId = action.payload

      if (Object.keys(state.documentFiles).includes(newTabId)) {
        state.activeDocTab = newTabId
      }
    },
    setDocValidity(state, action: PayloadAction<[string, boolean]>) {
      const [docId, isValid] = action.payload

      if (docId in state.documentFiles) {
        state.documentFiles[docId].hasValidMetadata = isValid
      }
    },
    setGuidelineLimit(state, action: PayloadAction<boolean>) {
      state.limitGuidelineToParam = action.payload
    },
    setGuidelineId(
      state,
      action: PayloadAction<DocUploadState['guidelineId']>
    ) {
      state.guidelineId = action.payload
    },
    setGuidelineType(
      state,
      action: PayloadAction<DocUploadState['guidelineType']>
    ) {
      state.guidelineType = action.payload
    },
    setGuidelineData(
      state,
      action: PayloadAction<DocUploadState['guidelineData']>
    ) {
      state.guidelineData = action.payload
    },
  },
})

export const docUploadActions = docUploadSlice.actions
export const docUploadReducer = docUploadSlice.reducer
