import clsx from 'clsx'
import { Sizes } from 'types'

import styles from './LoadingIndicator.module.scss'

interface LoadingIndicatorProps {
  size?: Sizes
}

const LoadingIndicator = ({
  size = 'medium',
}: LoadingIndicatorProps): JSX.Element => {
  const classes = clsx(styles.root, styles[`sz-${size}`])

  return (
    <div data-testid="loading-indicator" className={classes}>
      <span className={styles.circle} />
    </div>
  )
}

export default LoadingIndicator
