import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import clsx from 'clsx'
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  LoadingIndicator,
  Typography,
} from 'components/common'
import { metaApi } from 'components/features/Other/api'
import { t } from 'i18next'
import { BarChart2 } from 'react-feather'

import styles from './UserOnboarding.module.scss'

interface UserOnboardingInterestsProps {
  selectedTopics: string[]
  isSubmitting: boolean
  onSubmit: () => void
  onBackClick: () => void
  onTopicSelect: Dispatch<SetStateAction<string[]>>
}

const UserOnboardingInterests = ({
  selectedTopics,
  isSubmitting,
  onTopicSelect,
  onBackClick,
  onSubmit,
}: UserOnboardingInterestsProps): JSX.Element => {
  /**
   * quick and dirty switch for initializing the selected topics prefilled from the admin panel only once
   */
  const [prefillingOfTopicsDone, setPrefillingOfTopicsDone] =
    useState<boolean>(false)

  const httpGetTopics = metaApi.useGetTopicsListQuery(
    {
      depth: 1,
      includeInterestData: true,
    },
    { refetchOnMountOrArgChange: true }
  )

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    const topicIsSelected = selectedTopics.includes(value)

    if (topicIsSelected) {
      onTopicSelect((prev) => prev.filter((topic) => topic !== value))
    } else {
      onTopicSelect((prev) => [...prev, value])
    }
  }

  /**
   * Make sure to properly populate the topics with the ones that have already been selected in the admin panel
   */
  useEffect(() => {
    if (
      prefillingOfTopicsDone !== true &&
      httpGetTopics.isSuccess &&
      httpGetTopics.data?.data !== undefined
    ) {
      const topicsOfInterest = httpGetTopics.data.data.filter(
        (topic) => topic.interested === true
      )

      topicsOfInterest.forEach((topic) => {
        if (!selectedTopics.includes(topic.id)) {
          onTopicSelect((prev) => [...prev, topic.id])
        }
      })
      setPrefillingOfTopicsDone(true)
    }
  }, [
    httpGetTopics.data?.data,
    httpGetTopics.isSuccess,
    selectedTopics,
    prefillingOfTopicsDone,
    onTopicSelect,
  ])

  useEffect(() => {
    if (
      prefillingOfTopicsDone === true &&
      httpGetTopics.data?.data !== undefined
    ) {
      httpGetTopics.data.data.forEach((topic) => {
        if (topic.interested && !selectedTopics.includes(topic.id)) {
          onTopicSelect((prev) => [...prev, topic.id])
        }

        if (!topic.interested && selectedTopics.includes(topic.id)) {
          onTopicSelect((prev) => prev.filter((t) => t !== topic.id))
        }
      })
    }
  }, [httpGetTopics.data?.data, prefillingOfTopicsDone]) // eslint-disable-line react-hooks/exhaustive-deps

  const isFormValid = selectedTopics.length > 0

  return (
    <Card className={styles.page}>
      <Card.Header className={styles.title}>
        <Avatar
          size="x-large"
          className={styles.avatar}
          content={<BarChart2 />}
        />
        <Typography type="display" size="x-small" fontWeight="bold">
          {t('onboarding.title')}
        </Typography>
        <Typography color="label">{t('onboarding.subtitle')}</Typography>
      </Card.Header>
      <Card.Body className={styles.interestsBody}>
        <div className={styles.topics}>
          {httpGetTopics.isFetching && (
            <div className={styles.loading}>
              <LoadingIndicator />
            </div>
          )}
          {!httpGetTopics.isFetching &&
            httpGetTopics.isSuccess &&
            httpGetTopics.data.data.map((topic, index) => {
              const isChecked = selectedTopics.includes(topic.id)

              return (
                <label
                  className={clsx(styles.topic, {
                    [styles.topicChecked]: isChecked,
                  })}
                  key={`topics-${topic.id}`}
                >
                  <div className={styles.box}>
                    <Checkbox
                      id={`cb-${topic.id}-${index}`}
                      name={topic.name}
                      value={topic.id}
                      checked={isChecked}
                      onChange={handleCheckChange}
                    />
                  </div>
                  <Typography
                    className={styles.topicName}
                    color={isChecked ? 'primary' : 'label'}
                  >
                    {topic.name}
                  </Typography>
                </label>
              )
            })}
        </div>
      </Card.Body>
      <Card.Footer className={styles.actions}>
        <Button variant="text" onClick={onBackClick}>
          {t('onboarding.back')}
        </Button>
        <Button disabled={!isFormValid || isSubmitting} onClick={onSubmit}>
          {t('onboarding.submit')}
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default UserOnboardingInterests
