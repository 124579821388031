import { LocalStorageItem } from 'constants/storage-constants'

import { useEffect } from 'react'

import { selectAppLoading } from 'components/features/Other/api'
import { userApi } from 'components/features/User/api'
import { useAppSelector, useIsMount } from 'hooks'
import { useLocation, useNavigate } from 'react-router-dom'

export const useNavToLanding = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const isAppLoading = useAppSelector(selectAppLoading)
  const isMount = useIsMount()
  const httpGetUser = userApi.endpoints.getCurrentUser.useQueryState(null)

  const onboardingCompleted = httpGetUser.data?.data.onboarding_completed

  useEffect(() => {
    if (isMount && !['/', '/login'].includes(location.pathname)) {
      localStorage.setItem(
        LocalStorageItem.LANDING_LOCATION,
        JSON.stringify(location)
      )
    }
  }, [isMount, location])

  useEffect(() => {
    const landingLoc = localStorage.getItem(LocalStorageItem.LANDING_LOCATION)

    if (
      !isAppLoading &&
      httpGetUser.isSuccess &&
      onboardingCompleted &&
      landingLoc
    ) {
      navigate(JSON.parse(landingLoc))
      localStorage.removeItem(LocalStorageItem.LANDING_LOCATION)
    }
  }, [httpGetUser.isSuccess, isAppLoading, navigate, onboardingCompleted])
}
