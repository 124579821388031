import { nanoid } from '@reduxjs/toolkit'

export * from './cookie-helpers'
export * from './storybook-helpers'
export * from './text-helpers'
export * from './date-helpers'
export * from './auth-helpers'
export * from './style-helpers'
export * from './application-helpers'
export * from './data-helpers'
export * from './url-helpers'
export * from './search-helpers'
export * from './encryption'
export * from './theme-helpers'
export * from './form-helpers'
export * from './quill-helpers'

export const safeString = (text: string): string =>
  text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

export const getByPath = (object: any, path?: string) => {
  if (!path) {
    return
  }

  return path.split('.').reduce((acc, value) => acc[value], object)
}

export const getProp = (
  object: Object,
  path: string | string[],
  defaultValue?: Object
): any => {
  // Get a property out of a deeply nested object Does not handle anything but nested object graph
  if (!path) {
    return object
  }

  const normalizedPath = Array.isArray(path)
    ? path
    : path.split('.').filter((item) => item.length)

  if (!normalizedPath.length) {
    return Boolean(object) ? defaultValue : object
  }
  // @ts-ignore
  return getProp(object[normalizedPath.shift()], normalizedPath, defaultValue)
}

export const debounce = (fn: Function, delay = 0) => {
  let timerId: NodeJS.Timeout | null = null

  return (...args: any) => {
    if (timerId) {
      clearTimeout(timerId)
    }

    timerId = setTimeout(() => {
      fn(...args)
      timerId = null
    }, delay)
  }
}

export const throttle = (fn: Function, interval: number = 0) => {
  let sleep = false

  return (args: any) => {
    if (sleep) {
      return
    }

    fn(args)
    sleep = true

    setTimeout(() => {
      sleep = false
    }, interval)
  }
}

export const isEqual = (a: any, b: any) =>
  JSON.stringify(a) === JSON.stringify(b)

export const autoElementId = (prefix: string) => (id?: string) => {
  if (id) return `${prefix}-${id}`

  const uuid = nanoid()
  return `${prefix}-${uuid}`
}

export const isDisabledFormik = (
  isSubmitting: boolean,
  isValid: boolean = true,
  dirty: boolean = true
) => isSubmitting || !isValid || !dirty
