import { OfficeName } from 'constants/application-constants'

import { Suspense, lazy, useRef, useState } from 'react'

import { X } from 'assets/Icons'
import {
  Button,
  LoadingIndicator,
  PageTitle,
  Typography,
} from 'components/common'
import ScrollTracker from 'components/common/ScrollTracker/ScrollTracker'
import { userApi } from 'components/features/User/api'
import { mapGuidelineFormToResponse } from 'helpers'
import { useGenericAPIError } from 'hooks'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import styles from './NewGuideline.module.scss'
import { practicesApi } from '../../api'
import { GuidelineFormValues } from '../../components/GuidelineForm/types'

const GuidelineForm = lazy(
  () => import('../../components/GuidelineForm/GuidelineForm')
)

const ID_PREFIX = 'new-'

const NewGuideline = () => {
  const titleRef = useRef<HTMLDivElement>(null)

  const [formValid, setFormValid] = useState(false)
  const navigate = useNavigate()
  const showErrorToast = useGenericAPIError()

  const [httpCreatePractice] = practicesApi.useCreatePracticeMutation()
  const { data: user, isSuccess: userApiSuccess } =
    userApi.endpoints.getCurrentUser.useQueryState(null)
  const companyLanguage = user
    ? user.data.customer?.company_language ?? user.data.language ?? 'en'
    : 'en'

  const defaultFormValue = {
    metadata: [{ language: companyLanguage }],
  }

  const scrollTargetData = [
    { id: ID_PREFIX + 'general', label: t('metadata.general') },
    { id: ID_PREFIX + 'scope', label: t('metadata.scope') },
    { id: ID_PREFIX + 'topic', label: t('metadata.topic') },
    { id: ID_PREFIX + 'references', label: t('metadata.references') },
    { id: ID_PREFIX + 'contact', label: t('metadata.contact') },
  ]

  const scrollOffsetHeight = titleRef.current
    ? titleRef.current.getBoundingClientRect().bottom
    : 200

  const updateFormValidity = (valid: boolean, dirty?: boolean) => {
    setFormValid(valid && !!dirty)
  }

  const handleSubmit = async (data: GuidelineFormValues) => {
    const payload = mapGuidelineFormToResponse(data)

    try {
      const response = await httpCreatePractice(payload).unwrap()
      const newPracticeId = response.data.id
      navigate(`/${OfficeName.BACK}/practices/${newPracticeId}`)
    } catch (error) {
      showErrorToast()
    }
  }

  const navigateToPractices = () => {
    navigate('/practices')
  }

  const isFormValid = () => {
    return formValid
  }

  return (
    <div className={styles.root}>
      <aside className={styles.aside}>
        <Button
          className={styles.closeBtn}
          variant="subtle"
          size="small"
          icon={<X />}
          aria-label={t('newGuideline.closeBtnAria') ?? ''}
          onClick={navigateToPractices}
          as="button"
        />
        <nav className={styles.nav}>
          <Typography
            element="h4"
            color="label"
            fontWeight="bold"
            className={styles.title}
          >
            {t('newGuideline.trackerTitle')}
          </Typography>
          <ScrollTracker
            activationHeight={scrollOffsetHeight + 10}
            targetData={scrollTargetData}
          />
        </nav>
      </aside>
      <main className={styles.main}>
        <header ref={titleRef}>
          <PageTitle
            title={t('newGuideline.title')}
            description={t('newGuideline.subtitle')}
          />
        </header>

        {userApiSuccess ? (
          <Suspense fallback={<LoadingIndicator />}>
            <GuidelineForm
              onSubmit={handleSubmit}
              onValidityChange={updateFormValidity}
              titleIdPrefix={ID_PREFIX}
              defaultValues={defaultFormValue}
              defaultLanguage={companyLanguage}
            />
            <footer className={styles.footer}>
              <Button
                type="button"
                variant="text"
                onClick={navigateToPractices}
              >
                {t('newGuideline.abort')}
              </Button>
              <Button
                type="submit"
                form={ID_PREFIX + 'form'}
                disabled={!isFormValid()}
              >
                {t('newGuideline.submit')}
              </Button>
            </footer>
          </Suspense>
        ) : (
          <LoadingIndicator />
        )}
      </main>
    </div>
  )
}

export default NewGuideline
