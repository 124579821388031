import { Permissions } from 'constants/application-constants'

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type PermissionsMap = Record<Permissions, boolean>

interface PermissionsState {
  general: PermissionsMap | null
  guidelines: { [guidelineId: string]: PermissionsMap | null }
  enforcedGuideline: string | null
}

const initialState: PermissionsState = {
  general: null,
  guidelines: {},
  enforcedGuideline: null,
}

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setGeneralPermission(state, action: PayloadAction<PermissionsMap>) {
      state.general = action.payload
    },
    setGuidelinePermission(
      state,
      action: PayloadAction<[string, PermissionsMap]>
    ) {
      const [guidelineId, permissions] = action.payload
      state.guidelines[guidelineId] = permissions
    },
    setEnforcedGuideline(
      state,
      action: PayloadAction<PermissionsState['enforcedGuideline']>
    ) {
      state.enforcedGuideline = action.payload
    },
  },
})

export const permissionsActions = permissionsSlice.actions
export const permissionsReducer = permissionsSlice.reducer
