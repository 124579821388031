import { OfficeName } from 'constants/application-constants'

import { Avatar, Card, Table, Typography } from 'components/common'
import { DocumentIcon } from 'components/features/Other/components'
import { formatDistance } from 'date-fns'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
import { Approval } from 'redux-store/models'

import styles from './ApprovalsTable.module.scss'

interface ApprovalsTableProps {
  data: Approval[]
  labelledBy?: string
}

const ApprovalsTable = ({
  data,
  labelledBy,
}: ApprovalsTableProps): JSX.Element => {
  return (
    <Card hasTable>
      <Card.Body>
        <Table aria-labelledby={labelledBy} className={styles.root} isFullWidth>
          <Table.Head>
            <Table.Row isHeadRow>
              <Table.Cell>{t('approvalsTable.name')}</Table.Cell>
              <Table.Cell>{t('approvalsTable.author')}</Table.Cell>
              <Table.Cell>{t('approvalsTable.validity_date')}</Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.map((item) => (
              <Table.Row key={`approvals-row-${item.id}`} isClickable>
                <Table.Cell isLead>
                  <Link
                    to={`/${OfficeName.BACK}/practices/${item.practice_id}?rs=approval&ml=en#approval`}
                    aria-label={item.practice_id + ', go to practice page'}
                  />
                  <div className={styles.name}>
                    <DocumentIcon
                      type="practice"
                      avatarProps={{
                        size: 'medium',
                      }}
                    />
                    <Typography>{item.name}</Typography>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div className={styles.contentAuthor}>
                    <Avatar
                      content={item.author.initials}
                      size="small"
                      src={item.author.avatar_public_path ?? ''}
                    />
                    {item.author.name}
                  </div>
                </Table.Cell>
                <Table.Cell className={styles.content}>
                  <Typography size="small" color="label">
                    {formatDistance(new Date(item.due_date), new Date(), {
                      addSuffix: true,
                    })}
                  </Typography>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default ApprovalsTable
