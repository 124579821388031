import React from 'react'

const MasterIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#402C77"
    strokeWidth="1.5"
  >
    <rect
      x="13.9395"
      y="30.439"
      width="8.33333"
      height="8.33333"
      transform="rotate(-45 13.9395 30.439)"
    />
    <rect
      x="3.33301"
      y="19.8325"
      width="8.33333"
      height="8.33333"
      transform="rotate(-45 3.33301 19.8325)"
    />
    <rect
      x="24.5469"
      y="19.8325"
      width="8.33333"
      height="8.33333"
      transform="rotate(-45 24.5469 19.8325)"
    />
    <rect
      x="13.9395"
      y="9.22607"
      width="8.33333"
      height="8.33333"
      transform="rotate(-45 13.9395 9.22607)"
    />
  </svg>
)

export default MasterIcon
