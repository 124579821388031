import clsx from 'clsx'
import { Check } from 'react-feather'

import styles from './Step.module.scss'
import Typography from '../Typography/Typography'

interface StepProps {
  /**
   * @prop - text for the progress step bubble
   */
  stage: React.ReactNode
  /**
   * @prop - text/component for the title of the step
   */
  title: JSX.Element | string | null
  /**
   * @prop - status of the step
   */
  status: 'complete' | 'incomplete' | 'current'
  /**
   * @prop - is the step disabled?
   */

  /**
   * @prop - the direction in which the step is oriented to render
   */
  orientation?: 'vertical' | 'horizontal'
  /**
   * @prop - text/component for the subtitle of the step
   */
  subtitle?: JSX.Element | string | null

  /**
   * @prop - content that will be below the title
   */
  children?: React.ReactNode
}

const Step = ({
  stage,
  status = 'incomplete',
  title,
  subtitle,
  children,
  orientation = 'vertical',
}: StepProps) => {
  const css = clsx(
    styles.root,
    styles[`st-${status}`],
    styles[`or-${orientation}`]
  )

  const titleContent =
    typeof title === 'string' ? (
      <Typography element="span" size="small" fontWeight="medium">
        {title}
      </Typography>
    ) : (
      title
    )

  const subtitleContent =
    typeof subtitle === 'string' ? (
      <Typography
        className={styles.subtitle}
        element="span"
        size="small"
        fontWeight="regular"
        color="label"
      >
        {subtitle}
      </Typography>
    ) : (
      subtitle
    )

  let stageContent =
    typeof stage === 'string' ? (
      <Typography size="x-small" fontWeight="regular">
        {stage}
      </Typography>
    ) : (
      stage
    )
  if (status === 'complete') {
    stageContent = <Check />
  }

  return (
    <div className={css}>
      <div className={styles.stage}>{stageContent}</div>
      <span className={styles.title}>{titleContent}</span>
      <span className={styles.line} />
      {status !== 'complete' && (
        <div className={styles.content}>
          {subtitleContent}
          {children}
        </div>
      )}
      {status === 'complete' && <div className={styles.space} />}
    </div>
  )
}

export default Step
