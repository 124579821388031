import { Toggle } from 'components/common'
import { ToggleProps } from 'components/common/Toggle/Toggle.types'
import { FieldHookConfig, useField } from 'formik'

type FToggleProps = FieldHookConfig<boolean> & ToggleProps

const FToggle = ({ ...props }: FToggleProps): JSX.Element => {
  const [field, , helpers] = useField(props)

  const { value, name } = field
  const { setValue } = helpers

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked)
  }

  const { name: _name, checked: _checked, ...rest } = props
  return (
    <Toggle onChange={handleChange} name={name} checked={value} {...rest} />
  )
}

export default FToggle
