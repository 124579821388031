import React from 'react'

import clsx from 'clsx'

import styles from './Section.module.scss'

interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
}

const Section = ({ children, className }: SectionProps) => (
  <section className={clsx(styles.section, className)}>{children}</section>
)

export default Section
