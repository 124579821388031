import CloseIcon from 'assets/Icons/CloseIcon'
import clsx from 'clsx'

import styles from './Badge.module.scss'
import { BadgeProps } from './Badge.types'

const Badge = ({
  children,
  className,
  onDismiss,
  color = 'general',
  size = 'medium',
  dismissible,
  dismissLabel,
  rounded = false,
  onClick,
}: BadgeProps): JSX.Element => {
  const classes = clsx(
    styles.root,
    styles[`sz-${size}`],
    styles[`co-${color}`],
    {
      [styles.isRounded]: rounded,
      [styles.clickable]: onClick,
    },
    className
  )

  const showDismiss = Boolean(onDismiss) && dismissible

  const handleDismiss = (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
    evt.stopPropagation()
    if (onDismiss) {
      onDismiss(evt)
    }
  }

  const handleClick = (evt: React.MouseEvent) => {
    evt.stopPropagation()
    if (onClick) {
      onClick(evt)
    }
  }
  const handleKeydown = (evt: React.KeyboardEvent) => {
    evt.stopPropagation()
    if (onClick && evt.key === 'Enter') {
      onClick(evt as any)
    }
  }

  return (
    <div
      className={classes}
      tabIndex={onClick ? 0 : undefined}
      onClick={handleClick}
      onKeyDown={handleKeydown}
    >
      {children}
      {showDismiss && (
        <button
          type="button"
          data-testid="badge-dismiss-button"
          onClick={handleDismiss}
          className={styles.dismissButton}
          aria-label={dismissLabel}
        >
          <CloseIcon />
        </button>
      )}
    </div>
  )
}

export default Badge
