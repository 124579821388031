import {
  OfficeName,
  PracticeQueryParams,
} from 'constants/application-constants'

import { useEffect } from 'react'

import { Breadcrumbs } from 'components/common'
import { practicesApi } from 'components/features/Practices/api'
import { truncateWithEllipses } from 'helpers'
import { useFrontOfficeCheck } from 'hooks'
import { t } from 'i18next'
import { Folder, Home } from 'react-feather'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

import styles from './DocumentDetailsHeader.module.scss'
import { getPreferredStatus, isValidStatus } from './utils'
import { BreadcrumbProps } from '../../../../common/Breadcrumbs/Breadcrumb.type'
import { documentApi } from '../../api/documentsApi'
import { DocDetailQuery } from '../../enums'
import DocRevisionEnum, { DocRevisions } from '../../enums/DocRevision'
import DocumentBackOfficeHeader from '../DocumentBackofficeHeader/DocumentBackOfficeHeader'
import DocumentFrontOfficeHeader from '../DocumentFrontOfficeHeader/DocumentFrontOfficeHeader'
import DocumentRevisionDropdown from '../DocumentRevisionDropdown/DocumentRevisionDropdown'

const TITLE_BREADCRUMB_LEN = 50 as const

const DocumentDetailsHeader = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { documentId = '' } = useParams()
  const practiceId = searchParams.get(DocDetailQuery.PRACTICE_ID)
  const revisionStatus = searchParams.get(PracticeQueryParams.REVISION_STATUS)
  // const navigate = useNavigate()
  const location = useLocation()

  const getDocTitle = (httpGetDoc: any, revisionStatus: DocRevisions) => {
    if (revisionStatus === DocRevisionEnum.draft)
      return httpGetDoc?.data?.data.latest_draft_version?.metadata[0]?.title
    if (revisionStatus === DocRevisionEnum.approval)
      return httpGetDoc?.data?.data.current_approvalish_version?.metadata[0]
        ?.title
    return httpGetDoc?.data?.data.metadata?.title
  }

  useEffect(() => {
    if (!isValidStatus(revisionStatus)) {
      searchParams.set(
        PracticeQueryParams.REVISION_STATUS,
        getPreferredStatus([])
      )
      setSearchParams(searchParams, { replace: true })
    }
  }, [revisionStatus, searchParams, setSearchParams])

  const httpGetPractice = practicesApi.useGetPracticeByIdQuery(
    practiceId ?? '',
    {
      skip: !practiceId,
    }
  )
  const httpGetDoc =
    documentApi.endpoints.getDocumentById.useQueryState(documentId)
  const docTitle = getDocTitle(httpGetDoc, revisionStatus as DocRevisionEnum)
  const isFrontOffice = useFrontOfficeCheck()

  const guidelineTitle =
    httpGetPractice.data?.data.latest_displayable_revision?.metadata[0]?.title

  const setRevisionStatus = (status: DocRevisions) => {
    searchParams.set(PracticeQueryParams.REVISION_STATUS, status)
    setSearchParams(searchParams)
  }

  const BreadcrumbIcon = () => {
    if (isFrontOffice) {
      return <Home />
    } else {
      return <Folder />
    }
  }

  const crumbsData: BreadcrumbProps[] = [
    {
      href: `${isFrontOffice ? '/' : '/practices'}`,
      title: `${isFrontOffice ? 'Home' : 'Guidelines'}`,
      icon: BreadcrumbIcon(),
      iconOnly: true,
      tooltip: `${
        isFrontOffice ? t('home.backButtonAria') : t('home.backToGuideline')
      }`,
    },
    {
      href: `/${
        isFrontOffice ? OfficeName.FRONT : OfficeName.BACK
      }/practices/${practiceId}?rs=${revisionStatus}`,
      title: truncateWithEllipses(guidelineTitle ?? '', TITLE_BREADCRUMB_LEN),
      tooltip:
        guidelineTitle && guidelineTitle.length > TITLE_BREADCRUMB_LEN
          ? guidelineTitle
          : undefined,
      tag: isFrontOffice ? undefined : (
        <DocumentRevisionDropdown
          practiceTitle={guidelineTitle ?? ''}
          onRevisionSelect={setRevisionStatus}
          currentRevision={revisionStatus as DocRevisionEnum}
        />
      ),
    },
    {
      href: location,
      title: docTitle ?? 'Current document',
    },
  ]

  return (
    <header className={styles.root}>
      <div className={styles.col}>
        {/*<Button*/}
        {/*  onClick={() => navigate(-1)}*/}
        {/*  icon={<ArrowLeft />}*/}
        {/*  iconLabel="go back"*/}
        {/*  variant="text"*/}
        {/*  size="small"*/}
        {/*/>*/}
        <Breadcrumbs crumbs={crumbsData} />
      </div>
      <div className={styles.col}></div>
      <div className={styles.col}>
        {isFrontOffice ? (
          <DocumentFrontOfficeHeader />
        ) : (
          <DocumentBackOfficeHeader />
        )}
      </div>
    </header>
  )
}

export default DocumentDetailsHeader
