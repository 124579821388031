import { t } from 'i18next'
import { AlertTriangle } from 'react-feather'

import Accordion from './Accordion'
import styles from './Accordion.module.scss'
import AccordionItem from '../AccordionItem/AccordionItem'

const WarningAccordion = () => {
  return (
    <Accordion className={styles.spaceBottom}>
      <AccordionItem
        color="warning"
        icon={<AlertTriangle />}
        title={t('practice.outDated') ?? ''}
      >
        {t('practice.outDatedContent')}
      </AccordionItem>
    </Accordion>
  )
}

export default WarningAccordion
