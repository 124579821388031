import * as yup from 'yup'

const requiredOption = yup
  .object()
  .shape({
    name: yup.string(),
    description: yup.string(),
    id: yup.string(),
  })
  .nullable()
  .required('This field is required.')

const schema = {
  location: requiredOption,
  department: requiredOption,
}

export const UserOnboardingInfoValidationSchema = yup.object().shape(schema)
