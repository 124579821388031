import React, { Children } from 'react'

import clsx from 'clsx'

import Tab from './Tab'
import styles from './Tabs.module.scss'
import { TabsComponent } from './Tabs.type'

const Tabs: TabsComponent = ({
  children,
  className,
  variant = 'primary',
  fullWidth = false,
  ...rest
}) => {
  const css = clsx(styles.container, styles[`va-${variant}`], className)

  return (
    <div className={css}>
      {Children.map(children, (tab) =>
        React.cloneElement(tab as React.ReactElement, {
          fullWidth,
          variant,
          ...rest,
        })
      )}
    </div>
  )
}
Tabs.Tab = Tab

export default Tabs
