import ExpandedLogo from './raiff_logo.png'
import { LogoProps } from './types'

const RaiffeisenLogo = ({ collapsed = false }: LogoProps) => {
  if (collapsed) {
    return (
      <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35 35H0V-0.000499725H35V35Z"
          fill="#FEE600"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.3089 15.0111L29.7692 10.5508L30.9625 11.744L30.9696 11.7369C30.9696 10.9716 30.7439 8.3362 28.6278 6.27396C26.2584 3.96354 23.8865 5.31483 23.0691 6.13073L23.0694 6.13098L18.7737 10.4268L19.8376 11.4907L17.5 13.828L15.1627 11.4907L16.2265 10.4268L11.9309 6.13098L11.9311 6.13073C11.1138 5.31483 8.74186 3.96354 6.37242 6.27396C4.25635 8.3362 4.03064 10.9716 4.03064 11.7369L4.0378 11.744L5.23104 10.5508L9.69136 15.0111L10.9197 13.7826V10.8447L12.1833 12.1083V15.6616L13.9247 17.4033L4.89297 26.435L8.46824 30.0103L17.5 20.9785L26.532 30.0103L30.1073 26.435L21.0753 17.4033L22.817 15.6616V12.1083L24.0806 10.8447V13.7826L25.3089 15.0111Z"
          fill="#2B2D33"
        />
      </svg>
    )
  }

  return <img src={ExpandedLogo} alt="company logo" />
}

export default RaiffeisenLogo
