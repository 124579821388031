import React, { HTMLAttributes, useRef, useState } from 'react'

import clsx from 'clsx'
import { ChevronDown, ChevronUp, IconProps } from 'react-feather'

import styles from './AccordionItem.module.scss'
import { useId } from '../../../hooks'
import { ColorNames } from '../../../types'
import Button from '../Button/Button'

export interface AccordionItemProps extends HTMLAttributes<HTMLLIElement> {
  title: string
  color?: Extract<ColorNames, 'primary' | 'warning'>
  icon?: IconProps
  open?: boolean
  className?: string
}

const AccordionItem = ({
  title,
  children,
  color = 'primary',
  icon = undefined,
  className,
  open = false,
  ...props
}: AccordionItemProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(open)
  const id = useId('accordion-item')
  const content = useRef<HTMLDivElement>(null)
  const css = clsx(styles.root, styles[`co-${color}`], className)

  const onClick = (_evt: React.MouseEvent) => {
    // type guard for ref
    if (!content.current) {
      return
    }

    if (isOpen) {
      // accordion closes
      content.current.style.maxBlockSize = ''
    } else {
      // accordion opens
      content.current.style.maxBlockSize =
        content.current.scrollHeight + 32 + 'px'
    }

    const nextValue = !isOpen
    setIsOpen(nextValue)
  }

  const trailingIcon = isOpen ? <ChevronUp /> : <ChevronDown />
  return (
    <li {...props} className={css}>
      <Button
        className={styles.button}
        trailingIcon={trailingIcon}
        onClick={onClick}
        aria-controls={id}
        aria-expanded={isOpen}
        color={color}
        icon={icon}
      >
        {title}
      </Button>
      <div ref={content} className={styles.wrapper}>
        <div id={id} className={styles.content}>
          {children}
        </div>
      </div>
    </li>
  )
}

export default AccordionItem
