import { Folder, Home, IconProps, PieChart, Star } from 'react-feather'

import { Permissions } from './application-constants'

interface NavLink {
  to: string
  icon: IconProps
  label: string
  i18nKey: string
  requiredPermissions?: Permissions[]
  displayOnMobile?: boolean
}

export const navLinks: NavLink[] = [
  {
    to: '/',
    icon: <Home />,
    label: 'Home',
    i18nKey: 'navigation.search',
  },
  {
    to: '/practices',
    icon: <Folder />,
    label: 'Practices',
    i18nKey: 'navigation.practices',
    requiredPermissions: [Permissions.ACCESS_BACKOFFICE],
    displayOnMobile: false,
  },
  {
    to: '/overview',
    icon: <PieChart />,
    label: 'Dashboard',
    i18nKey: 'navigation.dashboard',
    requiredPermissions: [Permissions.ACCESS_BACKOFFICE],
  },
  {
    to: '/favorites',
    icon: <Star />,
    label: 'Favorites',
    i18nKey: 'navigation.favorites',
  },
]
