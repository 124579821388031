const NewFile = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9913 22.4761H5.93443C4.82986 22.4761 3.93443 21.5807 3.93443 20.4761V5.5C3.93443 4.39543 4.82986 3.5 5.93443 3.5H8.68337C11.0432 3.5 13.2826 4.54186 14.8027 6.34691L17.216 9.21276C18.0351 10.1854 18.6047 11.332 18.8888 12.5539C18.9807 12.55 19.073 12.548 19.1658 12.548C19.4211 12.548 19.6729 12.5631 19.9203 12.5926C19.6241 11.1188 18.9614 9.73291 17.9809 8.56863L15.5676 5.70278C13.8575 3.6721 11.3382 2.5 8.68337 2.5H5.93443C4.27758 2.5 2.93443 3.84314 2.93443 5.5V20.4761C2.93443 22.133 4.27758 23.4761 5.93443 23.4761H14.8603C14.5371 23.1745 14.2456 22.8393 13.9913 22.4761Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3671 22.4761H5.93443C4.82986 22.4761 3.93443 21.5807 3.93443 20.4761V5.5C3.93443 4.39543 4.82986 3.5 5.93443 3.5H8.87049C10.5273 3.5 11.8705 4.84315 11.8705 6.5V8.62768C11.8705 10.0084 12.9898 11.1277 14.3705 11.1277H16.0967C17.5501 11.1277 18.762 12.1611 19.0378 13.5333C19.364 13.4798 19.6986 13.4512 20.0395 13.4498C19.7175 11.5635 18.0748 10.1277 16.0967 10.1277H14.3705C13.5421 10.1277 12.8705 9.45611 12.8705 8.62768V6.5C12.8705 4.29086 11.0796 2.5 8.87049 2.5H5.93443C4.27758 2.5 2.93443 3.84314 2.93443 5.5V20.4761C2.93443 22.133 4.27758 23.4761 5.93443 23.4761H14.9632C14.7352 23.1633 14.5353 22.8287 14.3671 22.4761Z"
        fill="currentColor"
      />
      <path
        d="M19 15.75V23.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.25 19.5H22.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default NewFile
