import { RevisionStatus } from 'constants/application-constants'

import { useEffect, useState } from 'react'

import { Card, Typography } from 'components/common'
import { formatISO } from 'date-fns'
import { FormikHelpers } from 'formik'
import { t } from 'i18next'
import { useParams } from 'react-router'
import { User } from 'types'

import PreCheckAddUsersForm from './PreCheckAddUsersForm/PreCheckAddUsersForm'
import styles from './PreCheckCard.module.scss'
import {
  PreCheckCardProps,
  PreCheckDetailsFormData,
  PreCheckSteps,
} from './PreCheckCard.types'
import PreCheckDetailsForm from './PreCheckDetailsForm/PreCheckDetailsForm'
import PreCheckStarted from './PreCheckStarted/PreCheckStarted'
import { practicesApi } from '../../api'

const PreCheckCard = ({
  handleCancel,
}: PreCheckCardProps): JSX.Element | null => {
  const [step, setStep] = useState<PreCheckSteps>('default')
  const [reviewers, setReviewers] = useState<User[]>([])
  const { id: practiceId } = useParams()

  const [startPreCheck] = practicesApi.useStartPreCheckByPracticeIdMutation()

  const httpGetPractice = practicesApi.useGetPracticeByIdQuery(
    practiceId ?? '',
    {
      skip: !practiceId,
    }
  )

  useEffect(() => {
    if (
      httpGetPractice.isSuccess &&
      httpGetPractice.data.data.revision_statuses.includes(
        RevisionStatus.PRECHECK
      )
    ) {
      setStep('started')
    }
  }, [httpGetPractice.data, httpGetPractice.isSuccess])

  const handleStep = (step: PreCheckSteps) => () => {
    setStep(step)
  }

  const handleReviewerSelect = (newReviewer: User) => {
    // FIXME: slow - need to optimize; setReviewers state update is slow

    let currentReviewers = reviewers

    const hasReviewerAlready = currentReviewers.find(
      (reviewer) => reviewer.id === newReviewer.id
    )

    if (hasReviewerAlready) {
      currentReviewers = currentReviewers.filter(
        (reviewer) => reviewer.id !== newReviewer.id
      )
    } else {
      currentReviewers.push(newReviewer)
    }

    setReviewers(currentReviewers)
  }

  const handlePreCheckSubmit = async (
    values: PreCheckDetailsFormData,
    { setSubmitting }: FormikHelpers<PreCheckDetailsFormData>
  ) => {
    if (!practiceId) {
      return
    }

    const payload = {
      id: practiceId,
      comment: values.comment ?? '',
      dueDate: formatISO(new Date(values.dueDate)),
      title: values.title,
      users: reviewers.map((reviewer) => reviewer.id),
    }

    try {
      await startPreCheck(payload)
      httpGetPractice.refetch()
      setStep('started')
    } catch (error) {
      // TODO: handle error
    } finally {
      setSubmitting(false)
    }
  }

  if (httpGetPractice.isFetching) {
    return null
  }

  return (
    <Card className={styles.root}>
      {step !== 'started' && (
        <Card.Header>
          <div className={styles.title}>
            <Typography type="display" size="x-small" fontWeight="bold">
              {t('preCheck.title')}
            </Typography>
            <Typography size="small" color="label" fontWeight="medium">
              {step === 'default' ? '1' : '2'}/2
            </Typography>
          </div>
          <Typography size="small" color="label">
            {t('preCheck.subtitle')}
          </Typography>
        </Card.Header>
      )}
      <Card.Body>
        {step === 'default' && (
          <PreCheckAddUsersForm
            reviewers={reviewers}
            handleReviewerSelect={handleReviewerSelect}
            handleCancel={handleCancel}
            handleContinue={handleStep('details')}
          />
        )}

        {step === 'details' && (
          <PreCheckDetailsForm
            id="pre-check-details"
            handleSubmit={handlePreCheckSubmit}
            reviewers={reviewers}
            handleCancel={handleCancel}
            handleEditReviewers={handleStep('default')}
          />
        )}

        {step === 'started' && <PreCheckStarted />}
      </Card.Body>
    </Card>
  )
}

export default PreCheckCard
