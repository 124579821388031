import { Position } from './Popover.types'

function getOffsetTop(rect: DOMRect, vertical: Position['vertical']) {
  let offset = 0

  if (vertical === 'center') {
    offset = rect.height / 2
  }
  if (vertical === 'bottom') {
    offset = rect.height
  }

  return offset
}

function getOffsetLeft(rect: DOMRect, horizontal: Position['horizontal']) {
  let offset = 0
  if (horizontal === 'center') {
    offset = rect.width / 2
  } else if (horizontal === 'right') {
    offset = rect.width
  }

  return offset
}

function getTransformOrigin(elemRect: DOMRect, transformOrigin: Position) {
  return {
    vertical: getOffsetTop(elemRect, transformOrigin.vertical),
    horizontal: getOffsetLeft(elemRect, transformOrigin.horizontal),
  }
}

function getAnchorOffset(anchorRect: DOMRect, anchorOrigin: Position) {
  return {
    top: anchorRect.top + getOffsetTop(anchorRect, anchorOrigin.vertical),
    left: anchorRect.left + getOffsetLeft(anchorRect, anchorOrigin.horizontal),
  }
}

export function getPositioningStyle(
  elemRect: DOMRect,
  anchorRect: DOMRect,
  transformOrigin: Position,
  anchorOrigin: Position
) {
  const elemTransformOrigin = getTransformOrigin(elemRect, transformOrigin)
  const anchorOffset = getAnchorOffset(anchorRect, anchorOrigin)

  let top = anchorOffset.top - elemTransformOrigin.vertical
  let left = anchorOffset.left - elemTransformOrigin.horizontal
  const bottom = top + elemRect.height
  const right = left + elemRect.width

  // Window thresholds taking required margin into account
  const heightThreshold = window.innerHeight
  const widthThreshold = window.innerWidth

  // Check if the vertical axis needs shifting
  if (bottom > heightThreshold) {
    const diff = bottom - heightThreshold
    top -= diff
    elemTransformOrigin.vertical += diff
  }

  // Check if the horizontal axis needs shifting
  if (right > widthThreshold) {
    const diff = right - widthThreshold
    left -= diff
    elemTransformOrigin.horizontal += diff
  }

  return {
    top: `${Math.round(top)}px`,
    left: `${Math.round(left)}px`,
  }
}
