import { cloneElement } from 'react'

import clsx from 'clsx'

import styles from './ChoiceItem.module.scss'
import { ChoiceItemProps } from './ChoiceItem.types'

const ChoiceItem = ({
  id,
  element,
  className,
  label,
  description,
  disabled,
  size = 'medium',
}: ChoiceItemProps): JSX.Element => {
  const classes = clsx(
    styles.root,
    styles[`sz-${size}`],
    { [styles.isDisabled]: disabled },
    className
  )

  const labelledById = `${id}-label`

  return (
    <div className={classes}>
      <div className={styles.element}>
        {cloneElement(element, {
          id,
          'aria-labelledby': labelledById,
          disabled,
        })}
      </div>
      <div className={clsx(styles.text, 'choice-item__text')}>
        <label
          id={labelledById}
          htmlFor={id}
          className={clsx(styles.label, 'choice-item__label')}
          aria-describedby={id}
        >
          {label}
        </label>
        {description && (
          <span
            className={clsx(styles.description, 'choice-item__description')}
            id={id}
          >
            {description}
          </span>
        )}
      </div>
    </div>
  )
}

export default ChoiceItem
