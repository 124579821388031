import { useMemo } from 'react'

import clsx from 'clsx'
import { Sizes } from 'types'

import styles from './AvatarGroup.module.scss'
import Avatar from '../Avatar/Avatar'
import { AvatarProps } from '../Avatar/Avatar.types'

interface AvatarGroupProps {
  items: {
    id: string
    content: AvatarProps['content']
    src?: AvatarProps['src']
  }[]

  size?: Extract<Sizes, 'small' | 'medium' | 'large'>

  /**
   * @prop {number} maxVisibleItems - should be a whole number `[0-infinity]`
   *  Shows all items if a negative value if passed.
   */
  maxVisibleItems?: number
  commonAvatarProps?: Partial<Omit<AvatarProps, 'content' | 'src' | 'size'>>
  className?: string
  onClick?: () => void
  ref?: React.Ref<any>
}

const AvatarGroup = ({
  items,
  commonAvatarProps,
  className,
  maxVisibleItems = 5,
  size = 'medium',
  onClick,
}: AvatarGroupProps): JSX.Element => {
  const classes = clsx(styles.root, styles[`sz-${size}`], className)
  const avatarClasses = clsx(styles.avatar, commonAvatarProps?.className)

  const totalItems = items.length

  const isMaxVisibleItemsValid = maxVisibleItems >= 0
  const totalVisibleItems = isMaxVisibleItemsValid
    ? Math.min(maxVisibleItems, totalItems)
    : totalItems

  const hasItemsHidden = totalVisibleItems < totalItems

  const visibleItems = useMemo(() => {
    if (!hasItemsHidden) return items

    return items.slice(0, totalVisibleItems)
  }, [hasItemsHidden, items, totalVisibleItems])

  return (
    <div data-testid="avatar-group" className={classes} onClick={onClick}>
      {visibleItems.map((item) => (
        <Avatar
          key={`avatar-group-${item.id}`}
          content={item.content}
          src={item.src}
          className={avatarClasses}
          badge={commonAvatarProps?.badge}
          badgePosition={commonAvatarProps?.badgePosition}
          onClick={commonAvatarProps?.onClick}
          size={size}
        />
      ))}
      {hasItemsHidden && (
        <Avatar
          data-testid="avatar-group-hidden-items"
          className={avatarClasses}
          content={`+${totalItems - totalVisibleItems}`}
          badge={commonAvatarProps?.badge}
          badgePosition={commonAvatarProps?.badgePosition}
          onClick={commonAvatarProps?.onClick}
          size={size}
        />
      )}
    </div>
  )
}

export default AvatarGroup
