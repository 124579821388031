import { PracticeQueryParams } from 'constants/application-constants'

import { lazy, Suspense, useMemo } from 'react'

import { LoadingIndicator, NoData } from 'components/common'
import { t } from 'i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { DocumentEntity } from 'redux-store/models'

import styles from './DocumentInformation.module.scss'
import { createFileData } from './DocumentInformation.utils'
import NoDocCard from './NoDocCard'
import { documentApi } from '../../api/documentsApi'
import { DocDetailQuery } from '../../enums'
import DocRevisionEnum from '../../enums/DocRevision'
import {
  getPreferredStatus,
  isValidStatus,
} from '../DocumentDetailsLayout/utils'

const DocumentAudio = lazy(
  () => import('../DocumentDisplay/DocumentAudio/DocumentAudio')
)
const DocumentImage = lazy(
  () => import('../DocumentDisplay/DocumentImage/DocumentImage')
)
const DocumentPdf = lazy(
  () => import('../DocumentDisplay/DocumentPdf/DocumentPdf')
)
const DocumentSideBar = lazy(() => import('../DocumentSideBar/DocumentSideBar'))
const DocumentVideo = lazy(
  () => import('../DocumentDisplay/DocumentVideo/DocumentVideo')
)

const DocumentInformation = () => {
  const { documentId } = useParams()
  const [searchParams] = useSearchParams()
  const revisionStatus = searchParams.get(PracticeQueryParams.REVISION_STATUS)
  const practiceId = searchParams.get(DocDetailQuery.PRACTICE_ID)

  const httpGetDocument = documentApi.useGetDocumentByIdQuery(
    documentId ?? '',
    {
      skip: !documentId,
    }
  )

  const displayedFile = useMemo(() => {
    if (!httpGetDocument.isSuccess) return null

    const file = getCurrentDisplayedFile(
      revisionStatus,
      httpGetDocument.data.data
    )

    if (!file) return null

    return createFileData(file)
  }, [httpGetDocument.data, httpGetDocument.isSuccess, revisionStatus])

  return (
    <div className={styles.root}>
      <div className={styles.main}>
        {!httpGetDocument.isFetching && !!displayedFile && (
          <>
            {displayedFile.type === 'image' && (
              <Suspense fallback={<LoadingIndicator />}>
                <DocumentImage file={displayedFile} />
              </Suspense>
            )}
            {displayedFile.type === 'video' && (
              <Suspense fallback={<LoadingIndicator />}>
                <DocumentVideo file={displayedFile} />
              </Suspense>
            )}
            {displayedFile.type === 'pdf' && (
              <Suspense fallback={<LoadingIndicator />}>
                <DocumentPdf file={displayedFile} />
              </Suspense>
            )}
            {displayedFile.type === 'audio' && (
              <Suspense fallback={<LoadingIndicator />}>
                <DocumentAudio file={displayedFile} />
              </Suspense>
            )}
            {displayedFile.type === 'unviewable' && (
              <Suspense fallback={<LoadingIndicator />}>
                <NoData title={t('document.fileUnViewable')} />
              </Suspense>
            )}
          </>
        )}
        {httpGetDocument.isSuccess && !displayedFile && (
          <div className={styles.noData}>
            <NoDocCard
              practiceId={practiceId}
              revisionStatus={revisionStatus}
            />
          </div>
        )}
        {httpGetDocument.isFetching && <LoadingIndicator />}
      </div>
      {!!displayedFile?.type && <DocumentSideBar />}
    </div>
  )
}

export default DocumentInformation

const getCurrentDisplayedFile = (
  revisionStatus: string | null,
  docData: DocumentEntity
) => {
  // TODO: this logic should be moved to back-end (will require DTO restructure)

  let status = revisionStatus

  if (!isValidStatus(status)) {
    status = getPreferredStatus([])
  }

  if (status === DocRevisionEnum.draft) {
    return docData.latest_draft_version?.files[0]
  }
  if (status === DocRevisionEnum.approval) {
    return docData.current_approvalish_version?.files[0]
  }
  if (status === DocRevisionEnum.published) {
    return docData.latest_approved_version?.files[0]
  }

  return null
}
