import { Permissions } from 'constants/application-constants'

import { useEffect, useMemo } from 'react'

import { permissionsActions } from 'components/features/Other/api/permissionsSlice'
import { userApi } from 'components/features/User/api'
import { useDispatch } from 'react-redux'

import useAppSelector from './useAppSelector'
import { documentApi } from '../components/features/Documents/api/documentsApi'

type PermissionsMap = Record<Permissions, boolean> | null

type UsePermissionsReturn = {
  /**
   * @param mixed - permissions that are currently prevalent according to precedence
   */
  mixed: PermissionsMap

  /**
   * @param isBackOfficeUser - Flag that is true is the user is a Back-office user
   */
  isBackOfficeUser: boolean
}

const usePermissions = (
  guidelineId?: string,
  skip?: boolean
): UsePermissionsReturn => {
  const dispatch = useDispatch()
  const permits = useAppSelector((state) => state.permissions)
  const document = documentApi.useGetDocumentByIdQuery(guidelineId ?? '')
  if (document.isSuccess) {
    guidelineId = document.data?.data.practice_id
  }

  var httpGetGuidelinePermissions =
    userApi.useGetUserPermissionsByPracticeIdQuery(guidelineId ?? '', {
      skip: skip || !guidelineId,
    })
  const httpGetGeneralPermissions = userApi.useGetBasicUserPermissionsQuery(
    null,
    { skip: skip }
  )

  useEffect(() => {
    if (httpGetGeneralPermissions.isSuccess) {
      const generalPermits = httpGetGeneralPermissions.data.data
      // Update general permissions
      if (!guidelineId) {
        dispatch(permissionsActions.setGeneralPermission(generalPermits))
      }
    }
  }, [
    dispatch,
    httpGetGeneralPermissions.data,
    httpGetGeneralPermissions.isSuccess,
    guidelineId,
  ])

  useEffect(() => {
    if (guidelineId && httpGetGuidelinePermissions.isSuccess) {
      const practicePermits = httpGetGuidelinePermissions.data.data
      // update guideline permissions
      dispatch(
        permissionsActions.setGuidelinePermission([
          guidelineId,
          practicePermits,
        ])
      )
    }
  }, [
    guidelineId,
    dispatch,
    httpGetGuidelinePermissions.data,
    httpGetGuidelinePermissions.isSuccess,
  ])

  const currentGuidelinePermits = useMemo(() => {
    return httpGetGuidelinePermissions.data?.data ?? null
  }, [httpGetGuidelinePermissions.data?.data])
  const generalPermits = useMemo(() => {
    if (!!guidelineId) return null
    return permits.general
  }, [permits.general, guidelineId])

  return useMemo(
    () => ({
      mixed: currentGuidelinePermits ?? generalPermits,
      general: permits.general,
      currentGuideline: currentGuidelinePermits,
      isBackOfficeUser:
        !!permits.general && !!permits.general[Permissions.ACCESS_BACKOFFICE],
    }),
    [currentGuidelinePermits, generalPermits, permits.general]
  )
}
export default usePermissions
