import { t } from 'i18next'
import { AlertTriangle } from 'react-feather'

import styles from './ButtonPopover.module.scss'
import { ButtonPopover, Typography } from '../index'

const WarningButtonPopover = ({
  isFrontOffice = true,
}: {
  isFrontOffice?: boolean
}) => {
  const buttonLabel = isFrontOffice
    ? t('practice.outDated')
    : t('practice.revisionOverdue')
  const popoverTitle = isFrontOffice
    ? t('practice.outDatedLong')
    : t('practice.revisionNecessary')
  const popoverBody = isFrontOffice
    ? t('practice.outDatedContent')
    : t('practice.revisionNecessaryLong')

  return (
    <ButtonPopover
      buttonLabel={buttonLabel}
      buttonProps={{
        color: 'warning',
        icon: <AlertTriangle />,
        size: 'small',
        className: styles.roundButton,
      }}
      transformOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
    >
      <div>
        <Typography fontWeight="medium" element="h4">
          {popoverTitle}
        </Typography>
        <Typography className={styles.contentBody} element="p">
          {popoverBody}
        </Typography>
      </div>
    </ButtonPopover>
  )
}

export default WarningButtonPopover
