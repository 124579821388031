import { OfficeName } from 'constants/application-constants'

import { Button, Card, Typography } from 'components/common'
import { useFrontOfficeCheck } from 'hooks'
import { t } from 'i18next'

import styles from './NoDocCard.module.scss'
import DocRevisionEnum from '../../enums/DocRevision'

interface Props {
  practiceId: string | null
  revisionStatus: string | null
}

const NoDocCard = ({ practiceId, revisionStatus }: Props) => {
  const isFrontOffice = useFrontOfficeCheck()
  const querySearch = `rs=${
    revisionStatus ? revisionStatus : DocRevisionEnum.published
  }`

  return (
    <Card>
      <Card.Body className={styles.body}>
        <Typography size="small" type="display" fontWeight="bold">
          {t('document.noDocTitle')}
        </Typography>
        <Typography color="label">{t('document.noDocSubtitle')}</Typography>
        <Button
          variant="primary"
          as="link"
          to={{
            pathname: `/${
              isFrontOffice ? OfficeName.FRONT : OfficeName.BACK
            }/practices/${practiceId}`,
            search: querySearch,
          }}
          className={styles.action}
        >
          {t('document.noDocAction')}
        </Button>
      </Card.Body>
    </Card>
  )
}

export default NoDocCard
