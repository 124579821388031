import {
  Button,
  Card,
  IconContainer,
  Modal,
  Typography,
} from 'components/common'
import { t } from 'i18next'
import { HelpCircle } from 'react-feather'

import styles from './ReworkPracticeModal.module.scss'
import { useAppDispatch } from '../../../../../hooks'
import { Practice } from '../../../../../redux-store/models'
import { addToast } from '../../../Other/api'
import { practicesApi } from '../../api'
import { setPractice } from '../../api/practiceSlice'

interface ReworkPracticeProps {
  practice: Practice
  isModalOpen: boolean
  toggleModal: () => void
}

const ReworkPracticeModal = ({
  practice,
  isModalOpen,
  toggleModal,
}: ReworkPracticeProps) => {
  const dispatch = useAppDispatch()
  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation()
    toggleModal()
  }

  const [httpSetOutdated, httpSetOutdatedState] =
    practicesApi.useSetOutdatedMutation()

  const handleSetOutdated = async (event: React.MouseEvent) => {
    event.stopPropagation()

    try {
      await httpSetOutdated({ practiceId: practice.id }).unwrap()
      dispatch(
        addToast({
          color: 'success',
          content: t('practice.toastSuccess'),
        })
      )
      dispatch(setPractice({ ...practice, is_outdated: true }))
    } catch (error) {
      dispatch(
        addToast({
          color: 'error',
          content: t('saveSearch.toastGeneralError'),
        })
      )
    } finally {
      toggleModal()
    }
  }

  return (
    <Modal
      open={isModalOpen}
      handleClose={toggleModal}
      className={styles.modal}
      rootClassName={styles.modalRoot}
    >
      <Card className={styles.card}>
        <Card.Body className={styles.cardBody}>
          <IconContainer
            className={styles.roundIcon}
            icon={<HelpCircle />}
            size="small"
            color="primary"
            withBackground={true}
          />
          <div className={styles.content}>
            <Typography size="x-large" fontWeight="medium">
              {t('practice.isRevisionOverdue')}
            </Typography>
            <Typography size="large" className={styles.paragraph}>
              {t('practice.revisionOverdueDescription')}
            </Typography>
            <Typography size="large" className={styles.paragraph}>
              {t('practice.revisionOverdueDescriptionPart2')}
            </Typography>
            <Typography
              size="large"
              fontWeight="medium"
              className={styles.paragraph}
            >
              {t('practice.shownUntil')}
            </Typography>
            <img
              className={styles.image}
              alt=""
              src={t('practice.revisionOverdueImage') ?? ''}
            />
          </div>

          <div className={styles.actions}>
            <Button variant="secondary" onClick={handleClose}>
              {t('saveSearch.abort')}
            </Button>
            <Button
              disabled={httpSetOutdatedState.isLoading}
              onClick={handleSetOutdated}
            >
              {t('practice.setOutdated')}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Modal>
  )
}

export default ReworkPracticeModal
