import { UpdatePracticeRequest } from 'components/features/Practices/api/practicesApi/practicesApi.types'
import { GuidelineFormValues } from 'components/features/Practices/components/GuidelineForm/types'

export const mapFormValuesToBody = (
  data: GuidelineFormValues
): UpdatePracticeRequest['data'] => {
  let metadataPayloadArr: UpdatePracticeRequest['data']['metadata'] = []

  data.metadata.forEach((item) => {
    metadataPayloadArr!.push({
      title: item.title,
      language_code: item.language,
      alternative_topic_ids: item.altTopic,
      code: item.code,
      collections: item.collections,
      contact_user_id: item.contact?.id,
      countries: item.countries,
      description: item.description,
      effective_at: item.effectiveAt,
      main_topic_id: item.mainTopic?.id,
      subject: item.longTitle,
      target_group: item.targetGroup,
      certification_scopes: item.certificationScope,
      departments: item.departments,
      external_references: item.externalReferenceLinks,
      obsolete_practices: item.obsoletePractices,
      keywords: item.keywords?.join(', '),
      legal_entities: item.legalEntities,
      locations: item.locations,
      access_rights: item.accessRights?.id ?? '',
      internal_references: item.internalReferenceLinks,
    })
  })

  return {
    revision: {
      practice_class_id: data.class?.id,
    },
    metadata: metadataPayloadArr,
  }
}
