import React from 'react'

import clsx from 'clsx'

import styles from './SelectDropdown.module.scss'
import { SelectDropdownProps } from './SelectDropdown.types'
import Card from '../Card/Card'
import ListItem from '../ListItem/ListItem'

const SelectDropdown = ({
  highlightedIndex,
  options,
  className,
  selectedItem,
  isOpen,
  getItemProps,
  noDataElement,
  position,
  menuProps,
}: SelectDropdownProps): JSX.Element => {
  const classes = clsx(
    styles.root,
    styles[`menuPosition-${position}`],
    {
      [styles.isClosed]: !isOpen,
    },
    className
  )

  return (
    <Card className={classes} aria-hidden={!isOpen} {...menuProps}>
      {isOpen && options.length > 0 && (
        <ul
          className={styles.menu}
          role="listbox"
          id="test"
          tabIndex={-1}
          aria-multiselectable={false}
        >
          {options.map((item, index) => {
            const { onClick, ...itemProps } = getItemProps({
              item,
              index,
            })

            const handleItemClick = (
              event: React.MouseEvent<HTMLLIElement>
            ) => {
              event.stopPropagation()
              onClick(event)
            }

            return (
              <ListItem
                label={item.label}
                description={item.description}
                isSelected={selectedItem?.id === item.id}
                isHighlighted={highlightedIndex === index}
                startAdornment={item.startAdornment}
                disabled={item.disabled}
                key={`${item.label}${index}`}
                onClick={handleItemClick}
                {...itemProps}
              >
                {item.label}
              </ListItem>
            )
          })}
        </ul>
      )}
      {isOpen && options.length === 0 && <Card.Body>{noDataElement}</Card.Body>}
    </Card>
  )
}

export default SelectDropdown
