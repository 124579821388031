import { useRef } from 'react'

import clsx from 'clsx'
import { Button, Card, IconContainer, Typography } from 'components/common'
import { t } from 'i18next'
import Quill from 'quill'
import { Op } from 'quill/core'
import { Calendar, Edit2 } from 'react-feather'

import styles from './AnnouncementCard.module.scss'
import { formatDateNumeric, toDeltaFormat } from '../../../../../helpers'
import { useToggle } from '../../../../../hooks'
import useIsBelowBreakpoint from '../../../../../hooks/useIsBelowBreakpoint'
import Editor from '../../../../common/Editor/Editor'
import AnnouncementEdit from '../AnnouncementEdit/AnnouncementEdit'

interface HighlightsCardProps {
  data: {
    id: string
    title: string
    content: Op[] | undefined
    updatedAt?: string
  }
  className?: string
  canUpdateAnnouncements: boolean
  updatesCollapsed: boolean
  toggleCollapsed: () => void
}

const AnnouncementCard = ({
  data,
  className,
  canUpdateAnnouncements,
  updatesCollapsed,
  toggleCollapsed,
}: HighlightsCardProps): JSX.Element => {
  const quillRef = useRef<Quill>(null)
  const { title, updatedAt, content } = data
  const [isModalOpen, toggleModal] = useToggle()
  const isTouchBp = useIsBelowBreakpoint('tabLand')

  const css = clsx(styles.root, className)

  return (
    <Card className={css}>
      <Card.Body className={styles.container}>
        <div className={styles.heading}>
          <IconContainer color={'primary'} icon={<Calendar />} />
          <div className={styles.header}>
            <Typography size={'small'} color="primary">
              {title}
            </Typography>
            {updatedAt && (
              <Typography>{formatDateNumeric(updatedAt)}</Typography>
            )}
          </div>
          {canUpdateAnnouncements && !isTouchBp && (
            <Button
              onClick={() => toggleModal()}
              variant={'text'}
              size={'small'}
              icon={<Edit2 />}
              className={styles.edit}
            >
              Edit
            </Button>
          )}
        </div>
        <div
          className={clsx(styles.editorWrapper, {
            [styles.collapsed]: updatesCollapsed,
          })}
        >
          <Editor
            ref={quillRef}
            readOnly={true}
            defaultValue={toDeltaFormat(content)}
          />
        </div>
        <div className={styles.collapsedButtonWrapper}>
          <span className={styles.fakeLink} onClick={toggleCollapsed}>
            {updatesCollapsed
              ? t('home.expandUpdate')
              : t('home.collapseUpdate')}
          </span>
        </div>
        {canUpdateAnnouncements && !isTouchBp && (
          <AnnouncementEdit
            data={data}
            isModalOpen={isModalOpen}
            toggleModal={toggleModal}
          />
        )}
      </Card.Body>
    </Card>
  )
}

export default AnnouncementCard
