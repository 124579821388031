export const extractNameInitials = (fullName: string, maxLetters = 2) => {
  return fullName
    .split(' ')
    .map((word) => word[0])
    .slice(0, maxLetters)
    .join('')
}

export const capitalizeFirstLetter = (
  data: string | undefined | null
): string => {
  return typeof data === 'string'
    ? data.charAt(0).toUpperCase() + data.slice(1)
    : ''
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param decimalPoints Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const readableFileSize = (
  bytes: number,
  si = true,
  decimalPoints = 1
) => {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let unitIndex = -1
  const r = 10 ** decimalPoints

  do {
    bytes /= thresh
    ++unitIndex
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    unitIndex < units.length - 1
  )

  return bytes.toFixed(decimalPoints) + ' ' + units[unitIndex]
}

export const truncateWithEllipses = (text: string, max: number) => {
  if (text.length <= max) return text
  return text.substring(0, max - 1) + '...'
}
