import { useEffect } from 'react'

import { Outlet, useParams, useSearchParams } from 'react-router-dom'

import { documentApi } from '../../api/documentsApi'
import DocumentDetailsLayout from '../../components/DocumentDetailsLayout/DocumentDetailsLayout'
import DocumentInformation from '../../components/DocumentInformation/DocumentInformation'
import { DocDetailQuery } from '../../enums'

/**
 * TODO: implement download of un-viewable filetypes
 */
const DocumentPage = () => {
  const { documentId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const httpGetDocument = documentApi.useGetDocumentByIdQuery(
    documentId ?? '',
    {
      skip: !documentId,
    }
  )

  useEffect(() => {
    const practiceId = httpGetDocument.data?.data.practice_id
    const paramPracticeId = searchParams.get(DocDetailQuery.PRACTICE_ID)

    if (practiceId && practiceId !== paramPracticeId) {
      searchParams.set(DocDetailQuery.PRACTICE_ID, practiceId)
      setSearchParams(searchParams, {
        replace: true,
      })
    }
  }, [httpGetDocument.data?.data.practice_id, searchParams, setSearchParams])

  return (
    <DocumentDetailsLayout>
      <DocumentInformation />
      <Outlet />
    </DocumentDetailsLayout>
  )
}

export default DocumentPage
