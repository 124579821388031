import { PracticeQueryParams } from 'constants/application-constants'

import { useEffect, useMemo, useState } from 'react'

import clsx from 'clsx'
import { Select } from 'components/common'
import { useAppSelector } from 'hooks'
import { useSearchParams } from 'react-router-dom'
import { SelectOption } from 'types'

import { selectCurrentRevision } from '../../api'

const MetadataLanguageDropdown = (): JSX.Element | null => {
  const currentRevision = useAppSelector(selectCurrentRevision)
  const [searchParams, setSearchParams] = useSearchParams()

  const [initialLanguage, setInitialLanguage] = useState<SelectOption | null>(
    null
  )

  useEffect(() => {
    // SET THE QUERY PARAM "Metadata Language (ml)"
    const paramLanguage = searchParams.get(
      PracticeQueryParams.METADATA_LANGUAGE
    )
    const currentLanguageRevision = currentRevision?.metadata.find(
      ({ language }) =>
        language.code.toLowerCase() === paramLanguage?.toLowerCase()
    )

    if (paramLanguage && currentLanguageRevision) {
      // check if the param is already set and if the metadata for it in the current revision exists

      setInitialLanguage({
        id: paramLanguage,
        label: paramLanguage.toUpperCase(),
        value: currentLanguageRevision,
      })
    } else if (currentRevision && currentRevision.metadata.length > 0) {
      // if not, then set the new param value
      const newLanguage = currentRevision.metadata[0].language.code
      searchParams.set(PracticeQueryParams.METADATA_LANGUAGE, newLanguage)

      setInitialLanguage({
        id: newLanguage,
        label: newLanguage.toUpperCase(),
        value: currentRevision.metadata[0],
      })
      setSearchParams(searchParams, { replace: true })
    }
  }, [currentRevision, searchParams, setSearchParams])

  const selectOptions: SelectOption[] = useMemo(() => {
    if (!currentRevision) return []

    return (
      currentRevision.metadata?.map((metadata) => ({
        id: metadata.language.code,
        label: metadata.language.code.toUpperCase(),
        value: metadata,
      })) ?? []
    )
  }, [currentRevision])

  const handleItemChange = (item?: SelectOption | null) => {
    if (!item) return

    searchParams.set(PracticeQueryParams.METADATA_LANGUAGE, item.id)
    setSearchParams(searchParams, { replace: true })
  }

  if (initialLanguage === null) {
    return null
  }

  return (
    <Select
      size="small"
      label={
        searchParams
          .get(PracticeQueryParams.METADATA_LANGUAGE)
          ?.toUpperCase() ?? ''
      }
      options={selectOptions}
      initialSelectedItem={initialLanguage}
      className={clsx({
        'is-hidden': selectOptions.length <= 1,
      })}
      onSelectedItemChange={handleItemChange}
    />
  )
}

export default MetadataLanguageDropdown
