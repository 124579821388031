import clsx from 'clsx'
import { Sizes } from 'types'

import styles from './Separator.module.scss'

interface SeparatorProps {
  className?: string
  marginSize?: Extract<Sizes, 'small' | 'medium' | 'large'>
  isVertical?: boolean
  children?: React.ReactNode
}

const Separator = ({
  marginSize = 'medium',
  isVertical,
  className,
  children,
}: SeparatorProps) => {
  const classes = clsx(styles.root, styles[`mg-${marginSize}`], {
    [styles.isVertical]: isVertical,
  })

  return (
    <div
      className={clsx(
        styles.container,
        { [styles.isFlex]: isVertical },
        className
      )}
    >
      <hr className={classes} />

      {Boolean(children) && <div className={styles.content}>{children}</div>}
    </div>
  )
}

export default Separator
