import clsx from 'clsx'
import { Tabs } from 'components/common'
import { t } from 'i18next'

import styles from './LanguageBar.module.scss'
import RemoveLanguageButton from './RemoveLanguageButton'
import TranslationsButton from './TranslationsButton'
import { LanguageItem } from './types'

interface LanguageBarProps {
  /**
   * @property languageOptions - all the available options for a language
   */
  languageOptions: { id: string; label: string }[]

  /**
   * @property currentLanguages - languages that are currently active/selected
   */
  currentLanguages: string[]

  /**
   * @property activeTabIndex - index of the tab of the currently active language
   */
  activeTabIndex: number

  /**
   * @property onTabClick - function triggered when an individual tab is clicked
   */
  onTabClick: (tabIndex: number) => void

  /**
   * @property onRemoveLanguage - function triggered when a remove language action takes place
   */
  onRemoveLanguage: (langCode: string) => void

  /**
   * @property onAddLanguage - function triggered when a add language action takes place
   */
  onAddLanguage: (langCode: string) => void

  /**
   * @property defaultLanguage - this language will always be there by default and cannot be removed
   */
  defaultLanguage?: string

  /**
   * @property errors - array of language codes with errors
   */
  errors?: string[] | null

  /**
   * @property errors - array of language codes with errors
   */
  className?: string
}

const LanguageBar = ({
  activeTabIndex: activeTab,
  currentLanguages,
  defaultLanguage,
  errors,
  languageOptions,
  onAddLanguage,
  onRemoveLanguage,
  onTabClick,
  className,
}: LanguageBarProps) => {
  const handleTabClick = (idx: number) => (evt: React.MouseEvent) => {
    evt.stopPropagation()

    onTabClick(idx)
  }

  const languages: LanguageItem[] = languageOptions.map((language) => ({
    ...language,
    checked: currentLanguages.includes(language.id),
    default: language.id === defaultLanguage,
  }))

  const handleRemoveClick = (langCode: string) => () => {
    onRemoveLanguage(langCode)
  }

  const handleDropdownClick = (language: LanguageItem) => {
    if (language.checked) {
      onRemoveLanguage(language.id)
    } else {
      onAddLanguage(language.id)
    }
  }

  return (
    <div className={clsx(styles.root, className)}>
      <Tabs className={styles.tabs}>
        {languages
          .filter((lang) => !!lang.checked)
          .map((language, idx) => (
            <Tabs.Tab
              key={`language-bar-${language.id}-tab`}
              current={activeTab === idx}
              onClick={handleTabClick(idx)}
              error={activeTab !== idx && errors?.includes(language.id)}
              endAdornment={
                activeTab === idx && !language.default ? (
                  <RemoveLanguageButton
                    onRemoveClick={handleRemoveClick(language.id)}
                  />
                ) : undefined
              }
            >
              {language.default
                ? `${language.label} (${t('languageBar.default')})`
                : language.label}
            </Tabs.Tab>
          ))}
      </Tabs>
      {languages.length > 1 ? (
        <TranslationsButton
          onItemClick={handleDropdownClick}
          options={languages}
        />
      ) : undefined}
    </div>
  )
}

export default LanguageBar
