const Upload = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M2.5 12C2 10.5 2 9 2 7.95636C2 4.47818 5.11447 1 8.95636 1C11.5312 1 13.7793 2.39892 14.9821 4.47818H15.9127C18.7941 4.47818 21.5 6.5 21.5 9C21.5 10 21.5 11 21 12" />
      <path d="M15.5829 14.6685L11.4996 10.7502L7.41622 14.6685" />
      <path d="M11.4995 11.7808V22.7808" />
    </svg>
  )
}

export default Upload
