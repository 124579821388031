import { useEffect, useRef } from 'react'

/**
 *
 * @param value - value you want to track
 * @returns previous value for the param value
 */
const usePrevious = <T extends unknown>(value: T) => {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export default usePrevious
