enum LogCodes {
  ERR_API = '[ERR] API',
  ERR_DISALLOWED_ACCESS = '[ERR] DISALLOWED ACCESS',
  ERR_ERROR_BOUNDARY = '[ERR] ERROR BOUNDARY',
  ERR_GENERIC = '[ERR] GENERIC',
}

export default LogCodes
export type LogCodesString = keyof typeof LogCodes
export type LogCode = LogCodes | LogCodes
