import { forwardRef } from 'react'

import clsx from 'clsx'

import styles from './InputWrapper.module.scss'
import { InputWrapperProps } from './InputWrapper.types'

const InputWrapper = forwardRef<HTMLDivElement, InputWrapperProps>(
  (
    {
      children,

      helperText,
      color = 'basic',
      className,

      ...props
    },
    ref
  ): JSX.Element => {
    const classes = clsx(styles.root, styles[`co-${color}`], className)

    return (
      <div ref={ref} className={classes} {...props}>
        {children}
        {Boolean(helperText) && (
          <span className={styles.helperText}>{helperText}</span>
        )}
      </div>
    )
  }
)

export default InputWrapper
