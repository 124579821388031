import { useEffect } from 'react'

import { userApi } from 'components/features/User/api'
import { setUITheme } from 'config/theme'
import BrantnerTheme from 'config/theme/src/brantner'
import { UITheme } from 'config/theme/types'
import { CLIENT_DATA } from 'temp/temp-constants'
import { DeepPartial } from 'types'

import RaiffeisenTheme from '../../../config/theme/src/raiffeisen'

export const useClientTheme = () => {
  const httpGetUser = userApi.endpoints.getCurrentUser.useQueryState(null)

  const clientId = httpGetUser.data?.data.customer?.id

  useEffect(() => {
    let theme: DeepPartial<UITheme>

    switch (clientId) {
      case CLIENT_DATA.id_br:
        theme = BrantnerTheme
        break
      case CLIENT_DATA.id_ra:
        theme = RaiffeisenTheme
        break
      default:
        theme = {}
    }

    setUITheme(theme)
  }, [clientId])
}
