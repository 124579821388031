import { Send } from 'assets/Icons'
import clsx from 'clsx'
import { Button, LoadingIndicator, SectionHeader } from 'components/common'
import { FTextArea } from 'components/formik-factory'
import { Form, FormikHelpers, FormikProps, withFormik } from 'formik'
import useIsBelowBreakpoint from 'hooks/useIsBelowBreakpoint'
import { t } from 'i18next'
import { CommentFormData } from 'types'

import styles from './CommentForm.module.scss'
import { commentValidationSchema } from './CommentForm.validationSchema'

interface CommentFormProps {
  onSubmit: (
    values: CommentFormData,
    formikHelpers: FormikHelpers<CommentFormData>
  ) => void
}

const CommentFormComponent = ({
  ...formikProps
}: CommentFormProps & FormikProps<CommentFormData>): JSX.Element => {
  const { isSubmitting, isValid, dirty, values } = formikProps

  const css = clsx(styles.root, {
    [styles.active]: !!values.comment,
  })

  const isTouchBp = useIsBelowBreakpoint('tabLand')

  const isButtonDisabled = !isValid || !dirty || isSubmitting || !values.comment

  return (
    <Form className={css}>
      {!isTouchBp && <SectionHeader title={t('comments.title')} />}

      <div className={styles.inputWrapper}>
        <FTextArea
          size="small"
          id="comment-form-name"
          name="comment"
          label={t('comments.submit')}
          className={styles.input}
          rows={2}
        />
      </div>

      <Button
        icon={isSubmitting ? <LoadingIndicator size="x-small" /> : <Send />}
        disabled={isButtonDisabled}
        type="submit"
        size="small"
        className={styles.btnMobile}
      />
      <Button
        icon={isSubmitting ? <LoadingIndicator size="x-small" /> : undefined}
        disabled={isButtonDisabled}
        type="submit"
        size="small"
        className={styles.btn}
      >
        {t('comments.submit')}
      </Button>
    </Form>
  )
}

const CommentForm = withFormik<CommentFormProps, CommentFormData>({
  validationSchema: commentValidationSchema,
  handleSubmit: (values, formikBag) => {
    const { props, ...rest } = formikBag

    rest.setFieldValue('comment', '')
    props.onSubmit(values, rest)
  },
})(CommentFormComponent)

export default CommentForm
