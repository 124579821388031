import { createApi } from '@reduxjs/toolkit/query/react'
import {
  DashboardResponse,
  PaginatedData,
  PermissionsResponse,
} from 'redux-store/models'
import { baseQueryWithReauth } from 'redux-store/services/services.utils'
import { ApiSuccessResponse, User } from 'types'

import { GetUserByIdResponse, GetUsersListApiResponse } from './userApi.types'

export interface UserSearchParams {
  search: string
  userType: 'feedbackers' | 'assignees'
  limit?: number
}

type UserOnboardingAPIArgs = {
  locationID: string
  departmentIds: string[]
  topics: string[]
}

type AuthorsListAPIResponse = {
  data: {
    id: string
    name: string
  }[]
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    logout: builder.query<ApiSuccessResponse<null>, null>({
      query: () => 'logout',
    }),
    getCurrentUser: builder.query<ApiSuccessResponse<User>, null>({
      query: (_args) => 'users/me',
    }),
    getUserById: builder.query<GetUserByIdResponse, string>({
      query: (userId) => `users/${userId}`,
    }),
    getBasicUserPermissions: builder.query<PermissionsResponse, null>({
      query: () => 'permissions',
    }),
    getUserPermissionsByPracticeId: builder.query<PermissionsResponse, string>({
      query: (practiceId) => `permissions/practice/${practiceId}`,
    }),
    searchPracticeUsers: builder.query<PaginatedData<User>, UserSearchParams>({
      query: ({ search, limit, userType }) =>
        `users/${userType}?search=${search}&limit=${limit ?? 20}`,
    }),
    getAuthorsUsersList: builder.query<
      AuthorsListAPIResponse,
      { search?: string } | null
    >({
      query: () => 'users/authors/list',
    }),
    getPrivilegedUsersList: builder.query<
      GetUsersListApiResponse,
      {
        search?: string
      } | null
    >({
      query: (args) => {
        let searchQuery = ''

        if (args?.search) {
          searchQuery += `&search=${args.search}`
        }

        return `users/privileged/list?limit=100${searchQuery}`
      },
    }),
    getDashboardInfo: builder.query<
      ApiSuccessResponse<DashboardResponse>,
      null
    >({
      query: () => 'dashboard',
    }),
    onboardUser: builder.mutation<any, UserOnboardingAPIArgs>({
      query: (args) => ({
        url: 'onboarding',
        method: 'POST',
        body: {
          location_id: args.locationID,
          department_ids: args.departmentIds,
          topics: args.topics,
        },
      }),
    }),
  }),
})

export const {
  useGetCurrentUserQuery,
  useGetBasicUserPermissionsQuery,
  useGetUserPermissionsByPracticeIdQuery,
  useGetUserByIdQuery,
} = userApi
