import clsx from 'clsx'

import styles from './PageTitle.module.scss'

export interface PageTitleProps {
  title: string | any
  description?: string | any
  btnGroup?: React.ReactNode
  children?: React.ReactNode
  divider?: boolean
}

export const PageTitle = ({
  title,
  description,
  btnGroup,
  children,
  divider,
}: PageTitleProps) => {
  const classes = clsx(styles.root)

  return (
    <div className={classes}>
      <div className={styles.topWrapper}>
        <div>
          <h1 className={styles.title}>{title}</h1>
          {description && <p className={styles.description}>{description}</p>}
        </div>

        {btnGroup && <div className={styles.btnGroup}>{btnGroup}</div>}
      </div>
      {children}
      {divider && <hr className={styles.divider}></hr>}
    </div>
  )
}

export default PageTitle
