import { Avatar, Button, Typography } from 'components/common'
import { FTextArea, FTextField } from 'components/formik-factory'
import { add } from 'date-fns'
import { Form, Formik } from 'formik'
import { epochToInputDate } from 'helpers'
import { t } from 'i18next'
import { Edit2 } from 'react-feather'

import styles from './PreCheckDetailsForm.module.scss'
import { preCheckDetailsValidationSchema } from './PreCheckDetailsForm.validationSchema'
import {
  PreCheckDetailsFormData,
  PreCheckDetailsFormProps,
} from '../PreCheckCard.types'

const epochOneWeekFromNow = add(new Date(), { weeks: 1 }).valueOf()

const initialValues: PreCheckDetailsFormData = {
  dueDate: epochToInputDate(epochOneWeekFromNow),
  title: 'You have been invited to give feedback!',
}

const PreCheckDetailsForm = ({
  id,
  reviewers,
  handleSubmit,
  handleCancel,
  handleEditReviewers,
}: PreCheckDetailsFormProps): JSX.Element => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={preCheckDetailsValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty, isSubmitting, isValid }) => (
        <Form id={id}>
          <div className={styles.input}>
            <FTextField
              id="pre-check-date"
              name="dueDate"
              label="Due Date"
              type="date"
            />
            <FTextField
              id="pre-check-title"
              name="title"
              label="Title"
              type="text"
            />
            <FTextArea
              id="pre-check-comment"
              name="comment"
              label="Write a comment"
              autoFocus
              rows={5}
            />
          </div>

          <div className={styles.reviewers}>
            <Typography size="small" fontWeight="medium" element="h3">
              Reviewers ({reviewers.length})
            </Typography>
            <div className={styles.reviewersContent}>
              <div className={styles.reviewersList}>
                {reviewers.map((reviewer) => (
                  <Avatar
                    key={reviewer.id}
                    content={reviewer.initials}
                    src={reviewer.avatar_public_path}
                  />
                ))}
              </div>
              <Button
                variant="subtle"
                size="small"
                type="button"
                icon={<Edit2 />}
                onClick={handleEditReviewers}
                disabled={isSubmitting}
              >
                Edit
              </Button>
            </div>
          </div>

          <div className={styles.footer}>
            <Button size="medium" variant="text" onClick={handleCancel}>
              {t('preCheck.abort')}
            </Button>
            <Button
              disabled={!dirty || !isValid || isSubmitting}
              type="submit"
              size="medium"
              form="pre-check-details"
            >
              {t('preCheck.startBtn')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default PreCheckDetailsForm
