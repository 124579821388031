import React, { Key, useEffect, useMemo, useRef, useState } from 'react'

import { nanoid } from '@reduxjs/toolkit'
import { ArrowChevronDown, X } from 'assets/Icons'
import clsx from 'clsx'
import { useClickOutside } from 'hooks'
import { t } from 'i18next'
import { Sizes } from 'types'

import styles from './MultiSelect.module.scss'
import { nestedFilter } from './utils/nestedFilter'
import Badge from '../Badge/Badge'
import IconContainer from '../IconContainer/IconContainer'
import Tree from '../Tree/Tree'

export interface DataNode {
  label?: string
  id: string
  children?: DataNode[]
  [x: string | number]: unknown
}

export interface MultiSelectProps {
  label: string
  data?: DataNode[]
  size?: Extract<Sizes, 'medium' | 'large'>
  id?: string
  defaultValue?: string[]
  searchable?: boolean
  onSelect?: (allItems: string[], item: string | null) => void
  startAdornment?: React.ReactNode
  closeDropdownOnSelect?: boolean
  creatable?: boolean
  createOnBlur?: boolean
  hideDropdown?: boolean

  onSearchBlur?: () => void

  variant?: 'default' | 'error'
}

const GUTTER_GAP = 8 as const // 8px

const MultiSelectComponent = ({
  id,
  data,
  label,
  defaultValue,
  startAdornment,
  onSearchBlur,
  variant = 'default',
  size = 'medium',
  searchable = true,
  onSelect,
  closeDropdownOnSelect = false,
  hideDropdown = false,
  creatable = false,
  createOnBlur = false,
}: MultiSelectProps) => {
  const multiSelectId = id ? id : nanoid()

  const rootRef = useRef<HTMLDivElement>(null)
  const inputContainerRef = useRef<HTMLDivElement>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)
  const dropdownContainerRef = useRef<HTMLDivElement>(null)

  const [createdOptions, setCreatedOptions] = useState<DataNode[]>([]) // newly created options for the duration of whole render cycle

  const [selectedValues, setSelectedValues] = useState<string[]>([]) // value of the multi-select
  const [showDropdown, setShowDropdown] = useState(false)

  const [search, setSearch] = useState('')
  const [searchFocused, setSearchFocused] = useState(false)
  const [searchStatus, setSearchStatus] = useState<'idle' | 'active'>('idle')

  const [activeKey, setActiveKey] = useState<Key>('') // key of the current active/highlighted dropdown item

  useEffect(() => {
    // Creates selected values based on CSV on blur
    if (!searchFocused && creatable && createOnBlur && !!search) {
      const newValues = search
        .split(',')
        // remove values that are already created in state
        .filter((val) => {
          const validVal = val.trim()
          if (!validVal) {
            return false
          }

          return createdOptions.findIndex((opt) => opt.id === validVal) === -1
        })
        // remove duplicates
        .reduce((acc, val) => {
          if (acc.includes(val)) {
            return acc
          }

          return [...acc, val]
        }, [] as string[])

      const newCreatedOptions = newValues.map(
        (item) =>
          ({
            id: item,
            label: item.trim(),
          } as DataNode)
      )

      const newSelectedValues = [...selectedValues, ...newValues]
      setSelectedValues(newSelectedValues)
      if (onSelect) {
        onSelect(newSelectedValues, null)
      }
      setCreatedOptions((prev) => [...newCreatedOptions, ...prev])
      setSearch('')
      setSearchStatus('idle')
    }
  }, [
    creatable,
    createOnBlur,
    createdOptions,
    onSelect,
    search,
    searchFocused,
    selectedValues,
  ])

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      setSelectedValues(defaultValue)
    }
  }, [defaultValue])

  const handleActiveKeyChange = (key: Key) => {
    setActiveKey(key)
  }

  const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!searchable) {
      return
    }

    const { value: newSearchValue } = evt.target
    setSearch(newSearchValue)
    setSearchStatus('active')
    toggleDropdown(true)
  }

  const handleBadgeDismiss = (item: DataNode) => (evt: React.MouseEvent) => {
    evt.stopPropagation()

    // TODO: also remove all the possible children
    const newSelectedValues = selectedValues.filter(
      (option) => option !== item.id
    )
    setSelectedValues(newSelectedValues)

    if (onSelect) {
      onSelect(newSelectedValues as string[], item.id)
    }

    searchInputRef.current?.focus()
  }

  const handleClearClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation()
    clearInput()
    focusSearch()

    if (onSelect) {
      onSelect([], null)
    }
  }

  const handleDropdownHandleClick = (
    evt: React.MouseEvent<HTMLButtonElement>
  ) => {
    evt.preventDefault()
    evt.stopPropagation()
    toggleDropdown()
  }

  const handleContainerClick = () => {
    toggleDropdown()
    focusSearch()
  }

  const handleSearchSubmit = (evt: React.FormEvent) => {
    evt.preventDefault()
    evt.stopPropagation()

    let validatedSearch = search.trim()

    if (!creatable || validatedSearch === '') {
      return
    }

    let newSelected = selectedValues
    if (!newSelected.includes(validatedSearch)) {
      newSelected = [...newSelected, validatedSearch]
    }

    setSelectedValues(newSelected)

    const isNewCreatedOption =
      createdOptions.findIndex((option) => option.id === validatedSearch) === -1

    if (isNewCreatedOption) {
      const newOption: DataNode = {
        id: validatedSearch,
        label: validatedSearch,
      }
      setCreatedOptions((prev) => [newOption, ...prev])
    }

    setSearch('')
    setSearchStatus('idle')

    if (onSelect) {
      onSelect(newSelected, validatedSearch)
    }
  }

  const handleItemClick = (items: (string | number)[], item: DataNode) => {
    setSelectedValues(items as string[])

    setActiveKey(item.id)

    if (onSelect) {
      onSelect(items as string[], item.id)
      setSearch('')
    }

    if (closeDropdownOnSelect) {
      toggleDropdown(false)
    }
  }

  const keyboardShortcuts: { [x: string]: React.KeyboardEventHandler } = {
    root(evt) {
      evt.stopPropagation()
      evt.preventDefault()

      switch (evt.key) {
        case 'Escape':
          toggleDropdown(false)
          break

        case 'ArrowDown':
          if (memoTreeData.length > 0 && showDropdown) {
            focusOnDropdown()
          }
          break
      }
    },
    input(evt) {
      switch (evt.key) {
        case 'Enter':
          handleSearchSubmit(evt as any)
          toggleDropdown(true)
          break
        case 'ArrowDown':
        case 'Space':
          toggleDropdown(true)
          break
        case 'ArrowUp':
          toggleDropdown(false)
          break
      }
    },
    inputBtn(evt) {
      switch (evt.key) {
        case 'Enter':
        case 'Space':
          evt.stopPropagation()
          break
      }
    },
    searchForm(evt) {
      switch (evt.key) {
        case 'Backspace':
          if (!evt.ctrlKey) {
            if (!creatable) {
              return
            }

            if (search === '' && searchStatus === 'active') {
              // Prevent deleting on backspace while search is active
              setSearchStatus('idle')
            }

            if (
              search === '' &&
              searchStatus === 'idle' &&
              selectedValues.length > 0
            ) {
              const updatedSelected = selectedValues.slice(0, -1)
              setSelectedValues(updatedSelected)

              if (onSelect) {
                onSelect(
                  updatedSelected,
                  selectedValues[selectedValues.length - 1]
                )
              }
            }
          }
          break
      }
    },
  }

  const clearInput = () => {
    setSearch('')
    setSelectedValues([])
  }

  const focusOnDropdown = () => {
    if (dropdownContainerRef.current) {
      const el = dropdownContainerRef.current.getElementsByTagName('input')[0]
      el.focus()
    }
  }

  const toggleDropdown = (open?: boolean) => {
    if (!hideDropdown) {
      setShowDropdown(typeof open === 'boolean' ? open : !showDropdown)
    }
  }

  const focusSearch = () => {
    if (searchable && searchInputRef.current) {
      searchInputRef.current.focus()
    }
  }

  const updateSearchFocus = {
    onFocus: () => {
      setSearchFocused(true)
    },
    onBlur: () => {
      setSearchFocused(false)
      if (onSearchBlur) {
        onSearchBlur()
      }
    },
  }

  const inputRect = inputContainerRef.current?.getBoundingClientRect()

  useClickOutside(rootRef, () => toggleDropdown(false))

  const renderSelected: DataNode[] = useMemo(() => {
    if (hideDropdown && creatable) {
      return selectedValues.map((val) => ({ id: val, label: val }))
    }

    const result: DataNode[] = []
    const searchFn = (option: DataNode) => {
      if (selectedValues.includes(option.id)) {
        result.push(option)
        return
      }

      if (option.children && Array.isArray(option.children)) {
        option.children.forEach((option) => {
          searchFn(option)
        })
      }
    }

    // fetch the object based on "value" field value
    ;[...(data ? data : []), ...createdOptions].forEach(searchFn)

    return result
  }, [creatable, createdOptions, data, hideDropdown, selectedValues])

  const memoTreeData = useMemo(() => {
    let allOptions = [...createdOptions, ...(data ? data : [])]

    if (search) {
      return nestedFilter(search, allOptions, selectedValues)
    }

    return allOptions
  }, [createdOptions, data, search, selectedValues])

  return (
    <div
      className={clsx(styles.root, styles[`va-${variant}`])}
      ref={rootRef}
      onKeyUp={keyboardShortcuts.root}
    >
      {/* input */}
      <div
        className={clsx(styles.input, styles[`sz-${size}`], {
          [styles.isSearchFocused]: searchFocused,
          [styles.hasSearchFilled]: !!search,
          [styles.hasSelectedItems]: selectedValues.length > 0,
        })}
        ref={inputContainerRef}
        onClick={handleContainerClick}
        role="combobox"
        aria-controls={`combobox-${multiSelectId}`}
        aria-expanded={showDropdown}
        onKeyUp={keyboardShortcuts.input}
      >
        <div
          className={clsx(styles.startAdornment, {
            [styles.hasValue]: !!startAdornment,
          })}
        >
          {startAdornment}
        </div>
        <div className={styles.inputField}>
          <label htmlFor={multiSelectId} className={styles.label}>
            {label}
          </label>
          <div className={styles.inputFillable}>
            {renderSelected.map((item) => (
              <Badge
                key={`multi-badge-${item.id}`}
                dismissible
                onDismiss={handleBadgeDismiss(item)}
                color="primary"
                size="small"
                className={styles.selectedBadge}
                aria-label={`Remove ${item.name}`}
                dismissLabel={
                  t('multiSelect.ariaBadgeDismiss', {
                    name: item.label ?? item.id,
                  }) ?? ''
                }
              >
                {item.label ?? item.id}
              </Badge>
            ))}
            <span
              className={clsx(styles.searchForm, {
                [styles.notSearchable]: !searchable,
              })}
            >
              <input
                onSubmit={handleSearchSubmit}
                ref={searchInputRef}
                id={multiSelectId}
                type="text"
                value={search}
                className={styles.searchInput}
                onChange={handleSearchChange}
                onKeyUp={keyboardShortcuts.searchForm}
                onFocus={updateSearchFocus.onFocus}
                onBlur={updateSearchFocus.onBlur}
              />
            </span>
          </div>
        </div>
        <div className={styles.endAdornment}>
          {renderSelected.length > 0 && (
            <button
              type="button"
              title={t('multiSelect.ariaClearable') ?? ''}
              aria-label={t('multiSelect.ariaClearable') ?? ''}
              className={styles.iconBtn}
              onClick={handleClearClick}
              onKeyUp={keyboardShortcuts.inputBtn}
            >
              <IconContainer size="small" icon={<X />} />
            </button>
          )}
          {!hideDropdown && (
            <button
              type="button"
              aria-label={t('multiSelect.ariaDropdownHandle') ?? ''}
              aria-expanded={showDropdown}
              className={clsx(styles.iconBtn, styles.dropdownHandle, {
                [styles.isOpen]: showDropdown,
              })}
              onClick={handleDropdownHandleClick}
              onKeyUp={keyboardShortcuts.inputBtn}
            >
              <IconContainer size="small" icon={<ArrowChevronDown />} />
            </button>
          )}
        </div>
      </div>

      {/* dropdown */}
      <div
        data-testid="multi-select-dropdown"
        className={clsx(styles.dropdownContainer, {
          [styles.isOpen]: !hideDropdown && showDropdown,
        })}
        tabIndex={-1}
        id={`combobox-${multiSelectId}`}
        style={
          inputRect
            ? {
                top: `${inputRect.height + GUTTER_GAP}px`,
                width: `${inputRect.width}px`,
              }
            : undefined
        }
        aria-multiselectable={true}
        ref={dropdownContainerRef}
      >
        {memoTreeData.length < 1 && (
          <div className={styles.noDataItem}>{t('multiSelect.noResults')}</div>
        )}
        <Tree
          data={memoTreeData}
          onItemSelect={handleItemClick}
          checkedKeys={selectedValues}
          defaultCheckedKeys={defaultValue}
          activeKey={activeKey}
          onActiveKeyChange={handleActiveKeyChange}
        />
      </div>
    </div>
  )
}

const MultiSelect = MultiSelectComponent

export default MultiSelect
