import { nanoid } from '@reduxjs/toolkit'
import { UploadFile } from 'types'
export const arrayIncludes =
  <T>(arr: T[]) =>
  (value: T | T[]) => {
    if (!Array.isArray(value)) {
      return arr.includes(value)
    }

    return value.every((item) => arr.includes(item))
  }

export const arrayIncludesAny =
  <T>(arr: T[]) =>
  (value: T | T[]) => {
    if (!Array.isArray(value)) {
      return arr.includes(value)
    }

    return value.some((item) => arr.includes(item))
  }

export const getValidPercentage = (
  percentage: number,
  minPercent: number = 0,
  maxPercent: number = 100
) => {
  if (percentage < minPercent) return minPercent

  if (percentage > maxPercent) return maxPercent

  return percentage
}

export const createFileData = (file: File): UploadFile => {
  return {
    file,
    key: nanoid(),
    state: 'uploading',
  }
}

export const extractMetaItem = (
  questedId: string,
  list: { id: string; name: string }[]
) => list.find((listItem) => listItem.id === questedId)

export const extractIds = (arr: { id: string; [x: string]: any }[] | null) =>
  arr ? arr.map((item) => item.id) : []

/**
 *
 * @param arr Array of type T
 * @returns `arr` with only unique, distinct, non-repeated primitive values present
 *
 * !**warning** - this will not return unique non-primitive value i.e. object of similar shape and values are still treated as different objects by javascript (call by reference)
 */
export const getUniqueArray = <T extends unknown>(arr: T[]): T[] =>
  arr.length > 0 ? [...new Set(arr)] : []

/**
 * @param obj an object with unknown properties and values
 * @param prop the property which you want to check/fetch
 * @returns {any} value of the the property if found
 */
export const getUnknownProps = (obj: unknown, prop: string | number) => {
  if (!obj || typeof obj !== 'object') return

  let out
  let objEntries = Object.entries(obj)

  for (let i = 0; i < objEntries.length; i++) {
    const [field, value] = objEntries[i]

    if (field === prop) {
      out = value
      break
    }
  }

  return out
}
