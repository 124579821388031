import clsx from 'clsx'
import { Typography } from 'components/common'
import { t } from 'i18next'
import { useLocation } from 'react-router-dom'

import styles from './ViewBlocker.module.scss'

interface Props {
  breakpoint: 'desktop' | 'tab-port' | 'tab-land' | 'phone'
  children?: React.ReactNode
}

const responsiveRoutes = [
  '/',
  '/favorites',
  '/overview',
  '/search',
  '/user/onboard',
  '/sso',
  '/login',
  { path: '/front-office', strict: false },
]

const ViewBlocker = ({ breakpoint, children }: Props) => {
  const classes = clsx(styles.root, styles[`bp-${breakpoint}`])
  const location = useLocation()

  if (matchRoute(responsiveRoutes, location.pathname)) {
    return null
  }

  return (
    <div className={classes}>
      {children ? (
        children
      ) : (
        <Typography type="display" fontWeight="bold" size="small" element="h1">
          {t('viewBlock.title')}
        </Typography>
      )}
    </div>
  )
}

const matchRoute = (routes: typeof responsiveRoutes, currentPath: string) => {
  return !!routes.find((route) => {
    if (typeof route === 'string') {
      return route === currentPath
    }

    if (route.strict) {
      return route.path === currentPath
    }

    return currentPath.includes(route.path)
  })
}

export default ViewBlocker
