import {
  Permissions,
  PracticeQueryParams,
} from 'constants/application-constants'

import { CSSProperties, useEffect, useRef } from 'react'

import { FileCheckIcon } from 'assets/Icons'
import {
  Button,
  CommentForm,
  Comments,
  LoadingIndicator,
  NoData,
  Typography,
} from 'components/common'
import { addToast } from 'components/features/Other/api'
import { FormikHelpers } from 'formik'
import {
  useAppDispatch,
  useFrontOfficeCheck,
  useGenericAPIError,
  useIsBelowBreakpoint,
  usePermissions,
} from 'hooks'
import { t } from 'i18next'
import { Info, Link, MessageCircle } from 'react-feather'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import styles from './CommentsSidebarPage.module.scss'
import DocRevisionEnum from '../../../Documents/enums/DocRevision'
import { practicesApi } from '../../api'
import { PracticeSidebarActions } from '../../types'

interface Props {
  onActionClick: (page: PracticeSidebarActions) => () => void
}

const CommentsSidebarPage = ({ onActionClick }: Props): JSX.Element => {
  const commentFormRef = useRef<HTMLDivElement>(null)

  const { id: practiceId } = useParams()
  const dispatch = useAppDispatch()
  const isFrontOffice = useFrontOfficeCheck()
  const isTabPort = useIsBelowBreakpoint('tabPort')
  const [searchParams] = useSearchParams()
  const isPublishedRevision =
    searchParams.get(PracticeQueryParams.REVISION_STATUS) ===
    DocRevisionEnum.published

  useEffect(() => {
    // stops background scrolling when form is open
    const { scrollY } = window
    const bodyTag = document.getElementsByTagName('body')[0]
    if (isTabPort) {
      bodyTag.style.bottom = `${scrollY}px`
      bodyTag.classList.add('modal-is-open')
    }
    return () => {
      bodyTag.classList.remove('modal-is-open')
      window.scrollTo(0, scrollY)
      bodyTag.style.bottom = ''
    }
  }, [isTabPort])

  const {
    isFetching,
    data,
    refetch: httpGetComments,
  } = practicesApi.useGetCommentsByPracticeIdQuery(
    {
      practiceId: practiceId ?? '',
    },
    {
      skip: !practiceId,
    }
  )

  const [httpComment] = practicesApi.useAddCommentToRevisionMutation()

  const { mixed: allPermissions } = usePermissions(practiceId as string)
  const showGenericError = useGenericAPIError()

  const handleCommentSubmit = async (
    values: { comment: string },
    formikHelpers: FormikHelpers<{ comment: string }>
  ) => {
    if (!practiceId) return

    // TODO: Once ui is present for "reply to a comment", add 'parentCommentId' to payload
    const payload = {
      id: practiceId,
      comment: values.comment,
    }

    formikHelpers.setSubmitting(true)
    try {
      await httpComment(payload).unwrap()
      dispatch(
        addToast({
          color: 'success',
          content: 'Comment added',
        })
      )
      formikHelpers.resetForm()
      httpGetComments()
    } catch (error) {
      showGenericError()
    } finally {
      formikHelpers.setSubmitting(false)
    }
  }

  const pageBottomPadding: CSSProperties = isTabPort
    ? {
        paddingBottom: commentFormRef.current?.getBoundingClientRect().height,
      }
    : {}

  return (
    <>
      <div className={styles.pageActions}>
        {!isFrontOffice && !isPublishedRevision && (
          <Button
            onClick={onActionClick('approval')}
            variant="subtle"
            size="small"
            icon={<FileCheckIcon />}
          />
        )}
        <Button
          onClick={onActionClick('link')}
          variant="subtle"
          size="small"
          icon={<Link />}
        />
        <Button
          onClick={onActionClick('details')}
          variant="subtle"
          size="x-small"
          icon={<Info />}
        >
          {t('practice.details')}
        </Button>
      </div>
      <div className={styles.header}>
        <Typography className={styles.title} fontWeight="bold">
          {t('comments.title')}
        </Typography>
        <Button
          className={styles.btnClose}
          size="x-small"
          variant="text"
          iconLabel={t('metadata.closeAria') ?? ''}
          onClick={onActionClick(null)}
        >
          {t('common.close')}
        </Button>
      </div>
      <div className={styles.page} style={pageBottomPadding}>
        {allPermissions?.[Permissions.ADD_COMMENT] && (
          <div className={styles.commentForm} ref={commentFormRef}>
            <CommentForm onSubmit={handleCommentSubmit} />
          </div>
        )}
        {isFetching && <LoadingIndicator />}
        {!isFetching && !!data && <Comments comments={data.data} />}
        {!isFetching && !data?.data && (
          <NoData title={t('comments.noComments')} icon={<MessageCircle />} />
        )}
      </div>
    </>
  )
}

export default CommentsSidebarPage
