import { AccessRights } from 'constants/application-constants/accessRights'

import { useMemo } from 'react'

import { documentApi } from 'components/features/Documents/api/documentsApi'
import { metaApi, scopesApi } from 'components/features/Other/api'
import { practicesApi } from 'components/features/Practices/api'
import { userApi } from 'components/features/User/api'
import { createSelectOptions } from 'helpers'

const createDropdownData = createSelectOptions({
  idField: 'id',
  labelField: 'name',
})

/**
 *
 * @param entityPref {'document' | 'guideline'} - skips fetching options that are unused based on preference.
 * Will fetch all if no argument is passed to the param
 */

const usePopulateMetadata = (entityPref?: 'document' | 'guideline') => {
  const docTypesApi = documentApi.useGetDocumentTypesQuery(
    {
      skip_guideline: true,
    },
    {
      skip: entityPref === 'guideline',
    }
  )
  const langApi = metaApi.useGetLanguagesQuery({
    activeOnly: true,
    includeCustomerLanguage: true,
  })
  const topicsApi = metaApi.useGetTopicsTreeQuery({
    includeInterestData: false,
  })
  const flatTopicsApi = metaApi.useGetTopicsListQuery({
    includeInterestData: false,
  })
  const privilegedUsersApi = userApi.useGetPrivilegedUsersListQuery(null)
  const practiceClassApi = practicesApi.useGetPracticeClassesQuery(null)
  const guidelinesApi = practicesApi.useGetExistingPracticesQuery(null)
  const getScopesApi = scopesApi.useGetCertificationScopesQuery(null)
  const countryApi = metaApi.useGetCustomerCountriesQuery(null)
  const collectionApi = metaApi.useGetCollectionsQuery(null)
  const departmentsApi = metaApi.useGetDepartmentsTreeQuery(null)
  const legalEntitiesApi = metaApi.useGetLegalEntitiesQuery(null)
  const locationsApi = metaApi.useGetLocationsQuery(null)

  const topics = useMemo(() => {
    if (!topicsApi.isSuccess) {
      return []
    }

    return createDropdownData(topicsApi.data.data)
  }, [topicsApi.data, topicsApi.isSuccess])

  const flatTopics = useMemo(() => {
    if (!flatTopicsApi.isSuccess) {
      return []
    }

    return createDropdownData(flatTopicsApi.data.data)
  }, [flatTopicsApi.data, flatTopicsApi.isSuccess])

  const languages = useMemo(() => {
    if (!langApi.isSuccess) {
      return []
    }

    return createDropdownData(langApi.data.data)
  }, [langApi.data, langApi.isSuccess])

  const users = useMemo(() => {
    if (!privilegedUsersApi.isSuccess) {
      return []
    }

    return createDropdownData(privilegedUsersApi.data.data)
  }, [privilegedUsersApi.data, privilegedUsersApi.isSuccess])

  const guidelineClass = useMemo(() => {
    if (!practiceClassApi.isSuccess) {
      return []
    }

    return createDropdownData(practiceClassApi.data.data)
  }, [practiceClassApi.data, practiceClassApi.isSuccess])

  const guidelines = useMemo(() => {
    if (!guidelinesApi.isSuccess) {
      return []
    }

    return createDropdownData(guidelinesApi.data.data)
  }, [guidelinesApi.data, guidelinesApi.isSuccess])

  const scopes = useMemo(() => {
    if (!getScopesApi.isSuccess) {
      return []
    }

    return createDropdownData(getScopesApi.data.data)
  }, [getScopesApi.data, getScopesApi.isSuccess])

  const collections = useMemo(() => {
    if (!collectionApi.isSuccess) {
      return []
    }

    return createDropdownData(collectionApi.data.data)
  }, [collectionApi.data, collectionApi.isSuccess])

  const departments = useMemo(() => {
    if (!departmentsApi.isSuccess || !departmentsApi.data?.data) {
      return []
    }

    return createDropdownData(departmentsApi.data.data)
  }, [departmentsApi.data, departmentsApi.isSuccess])

  const countries = useMemo(() => {
    if (!countryApi.isSuccess) {
      return []
    }

    return createDropdownData(countryApi.data.data)
  }, [countryApi.data, countryApi.isSuccess])

  const locations = useMemo(() => {
    if (!locationsApi.isSuccess) {
      return []
    }

    return createDropdownData(locationsApi.data.data)
  }, [locationsApi.data, locationsApi.isSuccess])

  const legalEntities = useMemo(() => {
    if (!legalEntitiesApi.isSuccess) {
      return []
    }

    return createDropdownData(legalEntitiesApi.data.data)
  }, [legalEntitiesApi.data, legalEntitiesApi.isSuccess])

  const docTypes = useMemo(() => {
    if (!docTypesApi.isSuccess) {
      return []
    }

    return createDropdownData(docTypesApi.data.data)
  }, [docTypesApi.data, docTypesApi.isSuccess])

  return useMemo(() => {
    return {
      // guideline class
      docTypes,

      // guideline class
      guidelineClass,

      // topic (tree)
      topics,

      // topics (flat)
      flatTopics,

      // languages
      languages,

      // departments
      departments,

      // locations
      locations,

      // countries
      countries,

      // legal entities
      legalEntities,

      // access rights
      accessRights: AccessRights,

      // collections
      collections,

      // scopes
      scopes,

      // team member
      users,

      // existing guidelines, internal refs
      guidelines,
    }
  }, [
    docTypes,
    guidelineClass,
    topics,
    flatTopics,
    languages,
    departments,
    locations,
    countries,
    legalEntities,
    collections,
    scopes,
    users,
    guidelines,
  ])
}

export default usePopulateMetadata
