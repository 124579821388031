import {
  SearchQueryParams as PARAM,
  SearchQueryParams,
} from 'constants/application-constants'

import { createApi } from '@reduxjs/toolkit/query/react'
import { buildQueryString } from 'helpers'
import { TextSearchResponse } from 'redux-store/models'
import { baseQueryWithReauth } from 'redux-store/services/services.utils'
import { Topic } from 'types'

export type SearchFrontOfficeApiArgs = {
  search: string
  limit?: number
  filterByRelevance?: string
  department?: string[]
  languages?: string[]
  location?: string[]
  type?: string[]
  authors?: string[]
}

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getTopics: builder.query<Topic[], null>({
      query: () => 'topics',
    }),
    getSearchFrontOffice: builder.query<
      TextSearchResponse,
      Partial<Record<SearchQueryParams, string | undefined>>
    >({
      query: (args) => {
        // special handling of document types due to & in the id
        // TODO: use proper id for document types
        const tempArgs = { ...args }
        if (tempArgs[PARAM.TYPE]) {
          tempArgs[PARAM.TYPE] = tempArgs[PARAM.TYPE]
            .split(',')
            .map((t) => encodeURIComponent(t))
            .join(',')
        }
        return `search/frontoffice${buildQueryString(tempArgs)}`
      },
    }),
    postChapsSearch: builder.mutation({
      query: ({ search }) => ({
        url: 'search/chaps/advanced',
        method: 'POST',
        body: { search },
      }),
    }),
  }),
})

export const { useGetSearchFrontOfficeQuery } = searchApi
