import { Outlet, useParams } from 'react-router-dom'

import {
  DetailsLayout as Layout,
  PracticeHeader,
  PracticeInformation,
} from '../../components'
import { useParamRevisionStatus } from '../../hooks/useParamRevisionStatus'
import { usePractice } from '../../hooks/usePractice'

const BackOfficePractice = (): JSX.Element => {
  const { id = '' } = useParams()

  useParamRevisionStatus(false, id)
  usePractice(id)

  return (
    <Layout backLink="/practices" header={<PracticeHeader />}>
      <PracticeInformation />
      <Outlet />
    </Layout>
  )
}

export default BackOfficePractice
