import { useMemo } from 'react'

import { Avatar, Button, Card, Typography } from 'components/common'
import { metaApi } from 'components/features/Other/api'
import { FSelect } from 'components/formik-factory'
import { Form, Formik } from 'formik'
import { createSelectOptions } from 'helpers'
import { t } from 'i18next'
import { BarChart2 } from 'react-feather'
import { SelectOption } from 'types'

import styles from './UserOnboarding.module.scss'
import { UserOnboardingInformationForm } from './UserOnboarding.types'
import { UserOnboardingInfoValidationSchema } from './userOnboarding.validationSchema'
import LoadingIndicator from '../../../../common/LoadingIndicator/LoadingIndicator'
import { userApi } from '../../api/userApi'

const initialValues: UserOnboardingInformationForm = {
  location: null,
  department: null,
}

const createDropdownOptions = createSelectOptions({
  idField: 'id',
  labelField: 'name',
})

interface UserOnboardingInformationProps {
  handleInformationStep: (info: {
    location: string
    department: string
  }) => void
}

const UserOnboardingInformation = ({
  handleInformationStep,
}: UserOnboardingInformationProps): JSX.Element => {
  const currentUserApi = userApi.useGetCurrentUserQuery(null, {
    refetchOnMountOrArgChange: true,
  })
  const departmentApi = metaApi.useGetDepartmentsQuery(null)
  const locationApi = metaApi.useGetLocationsQuery(null)

  const onSubmit = ({
    department,
    location,
  }: UserOnboardingInformationForm) => {
    if (!department || !location) {
      return
    }

    handleInformationStep({
      location: location.id,
      department: department.id,
    })
  }

  const locationOptions: SelectOption[] = useMemo(() => {
    if (locationApi.isSuccess && locationApi.data) {
      return createDropdownOptions(locationApi.data.data)
    }

    return []
  }, [locationApi.data, locationApi.isSuccess])

  const departmentOptions: SelectOption[] = useMemo(() => {
    if (departmentApi.isSuccess && departmentApi.data) {
      return createDropdownOptions(
        departmentApi.data.data.filter((d) => d.id !== '_global')
      )
    }

    return []
  }, [departmentApi.data, departmentApi.isSuccess])

  /**
   * Prepare initial data with prefilled values from the user, if there are any
   */
  const initialValuesMemo = useMemo(() => {
    if (currentUserApi.data?.data && locationOptions && departmentOptions) {
      const _initialValues: UserOnboardingInformationForm = {
        location: {
          id: currentUserApi.data?.data.location_id ?? '',
          value: currentUserApi.data?.data.location_id ?? '',
          label:
            locationOptions
              .filter(
                (locationOption) =>
                  locationOption.id === currentUserApi.data?.data.location_id
              )
              .at(0)?.label ?? '',
        },
        department: {
          id: currentUserApi.data?.data?.department_id ?? '',
          value: currentUserApi.data?.data?.department_id ?? '',
          label:
            departmentOptions
              .filter(
                (departmentOption) =>
                  departmentOption.id ===
                  currentUserApi.data?.data.department_id
              )
              .at(0)?.label ?? '',
        },
      }

      return _initialValues
    }

    return initialValues
  }, [currentUserApi.data?.data, locationOptions, departmentOptions])

  /**
   * Make sure to not render the form until we have all pre-fill data that we need
   */
  if (
    currentUserApi.isSuccess !== true ||
    departmentApi.isSuccess !== true ||
    locationApi.isSuccess !== true
  ) {
    return (
      <Card className={styles.page}>
        <Card.Header className={styles.title}>
          <Avatar
            size="x-large"
            className={styles.avatar}
            content={<BarChart2 />}
          />
          <Typography type="display" size="x-small" fontWeight="bold">
            {t('onboarding.locationTitle')}
          </Typography>
          <Typography color="label">
            {t('onboarding.locationSubtitle')}
          </Typography>
        </Card.Header>
        <Card.Body className={styles.infoFields}>
          <LoadingIndicator />
        </Card.Body>
        <Card.Footer className={styles.actions}>
          <Button type="submit" disabled={true}>
            {t('onboarding.next')}
          </Button>
        </Card.Footer>
      </Card>
    )
  }

  return (
    <Formik
      initialValues={initialValuesMemo}
      validationSchema={UserOnboardingInfoValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, values }) => {
        const isButtonDisabled =
          !isValid || values.location?.id === '' || values.department?.id === ''

        return (
          <Form>
            <Card className={styles.page}>
              <Card.Header className={styles.title}>
                <Avatar
                  size="x-large"
                  className={styles.avatar}
                  content={<BarChart2 />}
                />
                <Typography type="display" size="x-small" fontWeight="bold">
                  {t('onboarding.locationTitle')}
                </Typography>
                <Typography color="label">
                  {t('onboarding.locationSubtitle')}
                </Typography>
              </Card.Header>
              <Card.Body className={styles.infoFields}>
                <FSelect
                  label={t('onboarding.locationLabel')}
                  name="location"
                  options={locationOptions}
                  isLoading={locationApi.isFetching}
                />
                <FSelect
                  label={t('onboarding.departmentLabel')}
                  name="department"
                  options={departmentOptions}
                  isLoading={departmentApi.isFetching}
                  disabled={!values.location}
                />
              </Card.Body>
              <Card.Footer className={styles.actions}>
                <Button type="submit" disabled={isButtonDisabled}>
                  {t('onboarding.next')}
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
}

export default UserOnboardingInformation
