import { RevisionStatus } from 'constants/application-constants'

import { useMemo } from 'react'

import {
  Badge,
  Button,
  Card,
  Modal,
  Separator,
  Typography,
} from 'components/common'
import { addToast } from 'components/features/Other/api'
import { userApi } from 'components/features/User/api'
import { format, formatDistance } from 'date-fns'
import { useAppDispatch, useToggle } from 'hooks'
import { t } from 'i18next'
import { useParams } from 'react-router'

import styles from './PreCheckStatusInfo.module.scss'
import { practicesApi } from '../../api'

const PreCheckStatusInfo = (): JSX.Element | null => {
  const { id: practiceId } = useParams()
  const dispatch = useAppDispatch()
  const [isModalOpen, setModalToggle] = useToggle(false)
  const httpGetPreCheckStatus =
    practicesApi.useGetPreCheckStatusByPracticeIdQuery(practiceId ?? '', {
      skip: !practiceId,
    })
  const [abortPreCheck] = practicesApi.useAbortCheckByPracticeIdMutation()
  const [fetchPracticeById] = practicesApi.useLazyGetPracticeByIdQuery()
  const [, httpGetUserState] = userApi.useLazyGetCurrentUserQuery()

  const httpGetPractice = practicesApi.useGetPracticeByIdQuery(practiceId ?? '')

  const isPreCheckStarted = useMemo(() => {
    if (!httpGetPractice.isSuccess) return false

    return httpGetPractice.data.data.revision_statuses?.includes(
      RevisionStatus.PRECHECK
    )
  }, [httpGetPractice.data, httpGetPractice.isSuccess])

  const currentUserIsFeedbacker: boolean = useMemo(() => {
    if (!httpGetUserState) return false
    if (!httpGetPreCheckStatus.data) return false

    const currentUserInPreCheck =
      httpGetPreCheckStatus.data.data.feedbackers_comment.find(
        (feedback) => feedback.user_id === httpGetUserState.data?.data.id
      )

    return Boolean(currentUserInPreCheck)
  }, [httpGetPreCheckStatus.data, httpGetUserState])

  if (
    httpGetPreCheckStatus.isFetching ||
    !httpGetPreCheckStatus.isSuccess ||
    httpGetPractice.isFetching
  ) {
    return null
  }

  if (!isPreCheckStarted) {
    return null
  }

  const handleEndPreCheck = async () => {
    if (!practiceId) return

    try {
      await abortPreCheck({
        id: practiceId,
        comment: 'Pre-check aborted by user',
      })

      await fetchPracticeById(practiceId)
      httpGetPractice.refetch()

      // TODO: negative scenarios

      dispatch(
        addToast({
          color: 'error',
          content: 'Feedback ended',
        })
      )
    } catch (err) {
      dispatch(
        addToast({ color: 'error', content: 'something has gone wrong' })
      )
    } finally {
      setModalToggle(false)
    }
  }

  return (
    <div className={styles.root}>
      <Modal open={isModalOpen} handleClose={() => setModalToggle(false)}>
        <Card className={styles.modalCard}>
          <Card.Header>
            <Typography type="display" size="small" fontWeight="bold">
              {t('preCheckAbortModal.title')}
            </Typography>
          </Card.Header>
          <Card.Body>
            <Typography color="label">
              {t('preCheckAbortModal.subtitle')}
            </Typography>
            <Typography color="label">
              {t('preCheckAbortModal.warning')}
            </Typography>
          </Card.Body>
          <Card.Footer className={styles.modalFooter}>
            <Button variant="text" onClick={() => setModalToggle(false)}>
              {t('preCheckAbortModal.cancel')}
            </Button>
            <Button color="error" onClick={handleEndPreCheck}>
              {t('preCheckAbortModal.confirm')}
            </Button>
          </Card.Footer>
        </Card>
      </Modal>
      <Badge size="large" color="tertiary">
        {t(
          currentUserIsFeedbacker
            ? 'preCheckStatusInfo.badgeReviewer'
            : 'preCheckStatusInfo.badgeViewer',
          {
            totalGiven: httpGetPreCheckStatus.data.data.total_feedbacks_given,
            totalReviewers: httpGetPreCheckStatus.data.data.total_feedbackers,
          }
        )}
      </Badge>
      <Card className={styles.card}>
        <Card.Body>
          <div className={styles.info}>
            <div className={styles.dataDisplay}>
              <Typography size="large" fontWeight="medium">
                {`(${httpGetPreCheckStatus.data.data.total_feedbacks_given}/${httpGetPreCheckStatus.data.data.total_feedbackers})`}
              </Typography>
              <Typography size="x-small">
                {t('preCheckStatusInfo.replies')}
              </Typography>
            </div>
            <Separator isVertical marginSize="small" />
            <div className={styles.dataDisplay}>
              <Typography size="large" fontWeight="medium">
                {httpGetPreCheckStatus.data.data.total_comments}
              </Typography>
              <Typography size="x-small">
                {t('preCheckStatusInfo.comments')}
              </Typography>
            </div>
          </div>
          <Separator marginSize="small" />
          <div className={styles.actions}>
            <Typography size="x-small" fontWeight="medium">
              {t('preCheckStatusInfo.dueDate', {
                timeLeft: httpGetPreCheckStatus.data.data.due_date
                  ? formatDistance(
                      new Date(),
                      new Date(httpGetPreCheckStatus.data.data.due_date)
                    )
                  : '',
                endDate: format(
                  new Date(httpGetPreCheckStatus.data.data.due_date),
                  'MMMM do'
                ),
              })}
            </Typography>
            <Button
              onClick={() => setModalToggle(true)}
              size="small"
              color="error"
              variant="secondary"
            >
              {t('preCheckStatusInfo.abort')}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default PreCheckStatusInfo
