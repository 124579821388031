import { Button, Typography } from 'components/common'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import styles from './PreCheckStarted.module.scss'

const PreCheckStarted = (): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()

  const { id: practiceId } = useParams()

  const handleBack = () => {
    if (practiceId) {
      navigate({
        pathname: location.pathname.split('/pre-check')[0],
        hash: location.hash,
        search: location.search,
      })
    } else {
      navigate('/practices')
    }
  }

  return (
    <div className={styles.root}>
      <Typography type="display" size="small" fontWeight="bold">
        Feedback round has started!
      </Typography>
      <Typography color="label">
        You can still edit the practice. You can view the status of the feedback
        at any time in the top bar.
      </Typography>
      <Button onClick={handleBack} className={styles.btn} size="large">
        Back to practice
      </Button>
    </div>
  )
}

export default PreCheckStarted
