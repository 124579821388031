import { DataNode } from '../MultiSelect'

/**
 *
 * @param {string} search - the value based on which the tree will be filtered
 * @param {DataNode[]} nodes - the value of the tree data. Originally the whole tree which then gets recursively filtered
 * @param {string[] selectedValues} - list of values that have already been selected and should always be visible
 *
 * @returns {DataNodes[]}  - Returns a tree that contains the matching value for "search param"
 *
 */

export const nestedFilter = (
  search: string,
  nodes: DataNode[],
  selectedValues: string[] = []
): DataNode[] => {
  return nodes.reduce((result, node) => {
    // recursively filter the children (if any). No "children" array present serve as exit condition
    const filteredChildren =
      node.children && nestedFilter(search, node.children, selectedValues)

    // predicate for matching the name
    const nameMatches =
      (node.label ?? node.id).toLowerCase().indexOf(search.toLowerCase()) !==
        -1 || selectedValues.includes(node.id)

    // check if the children have any matching nodes
    const childrenMatch = filteredChildren && filteredChildren.length
    const shouldKeep = nameMatches || childrenMatch

    return shouldKeep
      ? [
          ...result,
          {
            ...node,
            ...(childrenMatch ? { children: filteredChildren } : {}),
          },
        ]
      : result
  }, [] as DataNode[])
}
