import { SearchQueryParams } from 'constants/application-constants'

import { createApi } from '@reduxjs/toolkit/query/react'
import { buildQueryString } from 'helpers'
import { StarredItemResponse } from 'redux-store/models/meta'
import { TreeListArgs } from 'redux-store/models/misc'
import { baseQueryWithReauth } from 'redux-store/services/services.utils'
import {
  ApiSuccessResponse,
  CertificationScope,
  Collection,
  Country,
  Department,
  Language,
  StoredLocation,
  TimeFilter,
  Topic,
} from 'types'

import {
  GetLanguagesConfig,
  GetLanguagesQuery,
  GetLegalEntitiesResponse,
  GetTopicsListConfig,
  GetTopicsListQuery,
} from './metaApi.types'

export const metaApi = createApi({
  reducerPath: 'metaApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getTopicsList: builder.query<
      ApiSuccessResponse<Topic[]>,
      GetTopicsListConfig
    >({
      query: (config) => {
        const queryParams = buildQueryString({
          [GetTopicsListQuery.includeInterestData]: config?.includeInterestData
            ? '1'
            : '',
          [GetTopicsListQuery.depth]: config?.depth ? config.depth : '3',
        })
        return `topics${queryParams}`
      },
    }),
    getTopicsTree: builder.query<
      ApiSuccessResponse<Topic[]>,
      GetTopicsListConfig
    >({
      query: (config) => {
        const queryParams = buildQueryString({
          [GetTopicsListQuery.includeInterestData]: config?.includeInterestData
            ? '1'
            : '',
          [GetTopicsListQuery.depth]: config?.depth ? config.depth : '3',
        })
        return `topics/tree${queryParams}`
      },
    }),
    getCertifications: builder.query<
      ApiSuccessResponse<CertificationScope[]>,
      null | {
        depth: number
      }
    >({
      query: (config) =>
        `scopes/certification/tree?depth=${config ? config.depth : 3}`,
    }),
    getLanguages: builder.query<
      ApiSuccessResponse<Language[]>,
      GetLanguagesConfig
    >({
      query: (config) => {
        const queryParams = buildQueryString({
          [GetLanguagesQuery.onlyActive]: config?.activeOnly ? '1' : '',
          [GetLanguagesQuery.includeCustomerLanguage]:
            config?.includeCustomerLanguage ? '1' : '',
        })
        return `languages${queryParams}`
      },
    }),
    getLocations: builder.query<ApiSuccessResponse<StoredLocation[]>, null>({
      query: () => 'locations',
    }),
    getDepartments: builder.query<
      ApiSuccessResponse<Department[]>,
      string | null
    >({
      query: (locationId) =>
        `departments${locationId ? `?location_id=${locationId}` : ''}`,
    }),
    getDepartmentsTree: builder.query<
      ApiSuccessResponse<Department[]>,
      null | {
        depth: number
      }
    >({
      query: (config) => `departments/tree?depth=${config ? config.depth : 6}`,
    }),
    getCountries: builder.query<
      ApiSuccessResponse<Country[]>,
      undefined | null
    >({
      query: () => 'countries',
    }),
    getCustomerCountries: builder.query<
      ApiSuccessResponse<Country[]>,
      undefined | null
    >({
      query: () => 'countries?show_only_active=1',
    }),
    getTimeFilters: builder.query<
      ApiSuccessResponse<TimeFilter[]>,
      undefined | null
    >({
      query: () => 'time-filters',
    }),
    getLegalEntities: builder.query<
      GetLegalEntitiesResponse,
      TreeListArgs | null
    >({
      query: (arg) => `legal_entities/tree${buildQueryString(arg)}`,
    }),
    getOrganizationalRefs: builder.query<
      ApiSuccessResponse<{ id: string; name: string }[]>,
      any
    >({
      query: () => 'organizational-references/list',
    }),

    getCollections: builder.query<
      ApiSuccessResponse<Collection[]>,
      null | {
        depth: number
      }
    >({
      query: (config) => `collections/tree?depth=${config ? config.depth : 3}`,
    }),

    getStarredItems: builder.query<
      ApiSuccessResponse<StarredItemResponse>,
      null
    >({
      query: () => 'starred-items',
    }),
    deleteStarredItems: builder.mutation<any, string>({
      query: (starId) => ({
        url: `starred-items/${starId}`,
        method: 'DELETE',
      }),
    }),
    addSearchToFavorite: builder.mutation<
      unknown,
      {
        name: string
        config: Partial<Record<SearchQueryParams, string | string[] | boolean>>
      }
    >({
      query: ({ config, name }) => ({
        url: 'starred-items/search',
        method: 'POST',
        body: {
          name,
          config,
        },
      }),
    }),

    addStarredItem: builder.mutation<
      unknown,
      { type: 'practice' | 'document'; id: string }
    >({
      query: (body) => ({
        url: 'starred-items',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useGetTopicsListQuery, useGetLanguagesQuery } = metaApi
