import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'redux-store/services/services.utils'
import { ApiSuccessResponse, Scope } from 'types'

export const scopesApi = createApi({
  reducerPath: 'scopesApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getScopes: builder.query<ApiSuccessResponse<Scope[]>, null>({
      query: () => 'scopes/effectiveness',
    }),

    getCertificationScopes: builder.query<ApiSuccessResponse<Scope[]>, null>({
      query: () => 'scopes/certification',
    }),
  }),
})

export const { useGetScopesQuery, useGetCertificationScopesQuery } = scopesApi
