import clsx from 'clsx'
import { Button, ChoiceItem, Typography } from 'components/common'
import {
  FRadio,
  FTextArea,
  FTextField,
  FToggle,
} from 'components/formik-factory'
import { Form, Formik } from 'formik'
import { t } from 'i18next'

import styles from './ApprovalDetailsForm.module.scss'
import {
  ApprovalDetailsFormData,
  ApprovalDetailsFormProps,
} from './ApprovalDetailsForm.types'
import { approvalDetailsSchema } from './ApprovalDetailsForm.validationSchema'

const initialValues: ApprovalDetailsFormData = {
  comment: '',
  lengthOfApproval: 'major',
}

const ApprovalDetailsForm = ({
  handleCancel,
  handleSubmit,
}: ApprovalDetailsFormProps): JSX.Element => {
  const onCancelClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    handleCancel()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={approvalDetailsSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isValid, dirty }) => (
        <Form>
          <div className={styles.group}>
            <ChoiceItem
              element={
                <FRadio
                  name="lengthOfApproval"
                  value="major"
                  checked={values.lengthOfApproval === 'major'}
                />
              }
              id="major-approval"
              label={t('approvalDetail.majorLabel') ?? ''}
              description={t('approvalDetail.majorText') ?? ''}
              className={styles.label}
            />
            <ChoiceItem
              element={
                <FRadio
                  name="lengthOfApproval"
                  value="minor"
                  checked={values.lengthOfApproval === 'minor'}
                />
              }
              id="minor-approval"
              label={t('approvalDetail.minorLabel') ?? ''}
              description={t('approvalDetail.minorText') ?? ''}
              className={styles.label}
            />
          </div>
          <div className={styles.group}>
            <FTextArea
              label={t('approvalDetail.comment') ?? ''}
              name="comment"
              id="comment"
              autoFocus
            />
          </div>
          <div className={clsx(styles.group, styles.dueDate)}>
            <FTextField
              name="dueDate"
              id="dueDate"
              label={t('approvalDetail.dueDate') ?? ''}
              type="date"
              optional={true}
            />
            <Typography color="label">
              {t('approvalDetail.dueDateDescription')}
            </Typography>
          </div>
          <div className={styles.group}>
            <ChoiceItem
              element={<FToggle name="sendPreNotice" />}
              id="sendPreNotice"
              label={t('approvalDetail.informTitle')}
              description={t('approvalDetail.informDescription') ?? ''}
              className={styles.label}
            />
          </div>
          <div className={styles.footer}>
            <Button onClick={onCancelClick} variant="text">
              {t('approvalDetail.abort')}
            </Button>
            <Button type="submit" disabled={!isValid || !dirty}>
              {t('approvalDetail.submit')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ApprovalDetailsForm
