import { useDispatch } from 'react-redux'
import { ReduxStoreDispatch } from 'types'

/**
 * @function useAppDispatch - A wrapper around  `useDispatch` hook to make it TypeScript friendly
 *
 * [More info](https://redux.js.org/usage/usage-with-typescript#define-typed-hooks)
 */

const useAppDispatch = () => useDispatch<ReduxStoreDispatch>()

export default useAppDispatch
