export enum RevisionStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  PRECHECK = 'precheck',
  APPROVAL = 'approval',
  PREAPPROVAL = 'preapproval',
  REJECTED = 'rejected',
  ABORTED = 'aborted',
}

export default RevisionStatus

export type RevisionStatusString = keyof typeof RevisionStatus
export type RevisionStatuses = RevisionStatusString | RevisionStatus
